import * as React from 'react';
import { Space } from 'antd';
import { DateTime } from "src/components";
import colors from 'src/theme/colors.json';
import Icon from '../Icon/Icon';
import { PromotionState } from 'src/types';
import { findCashbackDateRangeOf } from 'src/utils/bonus';
import moment from 'moment';

interface IStatusProps {
}

const CustomerBonusStatus: React.FunctionComponent<IStatusProps & PromotionState> = (props) => {
  const { status, type, latestToggleAt, latestTypeToggleAt } = props;
  const isBonus = status === 'ON';

  const CashbackDate = React.useMemo(() => {
    if (type === 'CASHBACK') {
      const [latestMonday, latestSunday] = findCashbackDateRangeOf(new Date(), 'latest');
      const [nextMonday] = findCashbackDateRangeOf(new Date(), 'next');
      const isCashbackEnable = moment(latestTypeToggleAt || new Date().toISOString()).isBefore(moment(latestMonday));
      return (
        <>
          {isCashbackEnable ? `สิ้นสุดรอบวันที่ (${moment(latestSunday).format('DD/MM/YYYY')} 23:59)` : `รอบถัดไปวันที่ (${moment(nextMonday).format('DD/MM/YYYY')} 00:00)`}
        </>
      );
    }
    return null;
  }, [type]);

  return (
    <div>
      <Space>
        {isBonus && (
          <>
            <Icon name='outline-active-1' color={colors['@green-6']} size="18px" />
            รับ
          </>
        )}
        {!isBonus && (
          <>
            <Icon name='outline-close-2' color={colors['@red-5']} size="18px" />
            ไม่รับ
          </>
        )}
        {latestToggleAt && (<span>(<DateTime value={latestToggleAt.toString() || ''} />)</span>)}
      </Space>
      {isBonus && (
        <div>
          <Space>
            {type === 'DEPOSIT' && (
              <>
                <span>โบนัสเติมเงิน</span>
              </>
            )}
            {type === 'CASHBACK' && (
              <>
                <span>โบนัสคืนยอดเสีย</span>
              </>
            )}
            {latestTypeToggleAt && (<span>ปรับเมื่อ (<DateTime value={latestTypeToggleAt.toString() || ''} />)</span>)}
          </Space>
          {type === 'CASHBACK' && (
            <div>
              {CashbackDate}
            </div>
          )}
        </div>
      )}
    </div>
  )
};

export default CustomerBonusStatus;
