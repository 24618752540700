import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  useQueryClient,
} from "react-query";
import { toast } from "src/components";
import { GameBrands, PartyBrand, UpdateGameBrandResponse } from "src/types";
import { api } from "src/utils";

export interface GameBrandsRequest {
  skip?: number;
  limit?: number;
  partyBrand: PartyBrand;
  isProvider?: boolean;
}

export function useListGameBrands<T = GameBrands[]>(
  filters?: GameBrandsRequest,
  options?: UseQueryOptions<GameBrands[], unknown, T, any[]>
) {
  return useQuery(
    ["game_brand", filters],
    async () => {
      if (filters?.partyBrand) {
        const response = await api.get("game/brand", {
          params: { skip: filters?.skip, limit: filters?.limit, partyBrand: filters?.partyBrand, isProvider: true },
        });
        return response.data?.data;
      }
    },
    options
  );
}


export interface UpdateGameBrandForm {
  brandList: [];
}

export interface UpdateGameBrandParams {
  brandList: BrandList[];
}

export interface BrandList {
  uid: string,
  name: string,
  providerUid: string,
  isActive?: boolean,
  isUnderConstruction?: boolean,
  isHot?: boolean,
  isComingSoon?: boolean,
}

export interface UpdateGameBrandRequest {
  brandList: BrandList[];
}

export function useUpdateGameBrand(
  options?: UseMutationOptions<UpdateGameBrandResponse, unknown, UpdateGameBrandRequest, unknown>
) {
  const queryClient = useQueryClient();
  
  return useMutation(
    (brandList) => {
      return api.put(`game/brand/`, brandList);
    },
    {
      onError: () => {
        toast.error("แก้ไขไม่สำเร็จ");
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["game_brand"]);
        toast.success("แก้ไขสำเร็จ");
      },
      ...options,
    }
  );
}
