import { Col, Form, Input, Row, Tooltip, Button } from "antd";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import isEqual from "react-fast-compare";
import Icon from 'src/components/Icon/Icon';
import {
  Amount,
  ColumnType,
  CustomerStatistics,
  CustomerStatisticsHandler,
  DatePicker,
  PageContainer,
  PageHeader,
  SearchButton,
  Select,
  Table,
} from "src/components";
import { colors } from "src/constants";
import { useCustomerGameStats, useCustomerLogs } from "src/hooks";
import { CustomerLogType } from "src/types";
import { datetime, decimal, formatDatetime } from "src/utils";
import { addCommas } from "src/utils/number/number";
import BonusColumn from "src/components/TableColumns/BonusTypeColumn";

function AmountColor({ value }: { value: number }) {
  return (
    <div
      style={{
        textAlign: "right",
        color: value > 0 ? colors.green7 : value < 0 ? colors.red7 : colors.cloud9,
      }}
    >
      {value !== null ? addCommas(value > 0 ? `+${decimal(value)}` : decimal(value)) : "-"}
    </div>
  );
}

const gameProviderName: any = {
  "JOKER_GAME": "Joker",
  "PG_GAME": "PG Soft",
  "EVO_GAME": "EVO Play",
  "EVOLUTION": "Evolution",
  "SEXY": "Sexy",
  "SA": "SA",
  "BG": "Big Gaming",
  "DREAM_GAMING": "Dream Gaming",
  "JILI_GAME": "Jili",
  "PNG_GAME": "Play N Go",
  "PRAGMATIC_SLOT_GAME": "Pragmatic Slot",
  "AMBSLOT2_GAME":"AMB Slot",
  "RELAX_GAME": "Relax",
  "MICRO_GAME": "Micro",
  "YGGDRASIL_GAME": "Yggdrasil",
  "CQ9_GAME": "CQ9",
  "CG_GAME": "CreativeGaming",
  "NETENT2_GAME": "Netent2",
  "REDTIGER_GAME": "Redtiger",
  "FUNKY_GAME":"Funky",
  "I8_GAME": "I8",
  "MANNA_GAME": "Manna",
  "SPINIX_GAME": "Spinix",
  "TURBO_GAMES": "Turbo Games",
  "FC_GAME": "FC",
  "FASTSPIN_GAME": "FASTSPIN",
  "SPADE_GAME": "SPADE_GAME",
  "HACK_SAW_GAME": "HACK_SAW",
  "SPRIBE_GAME": "SPRIBE",
  "NLC_GAME": "NLC",
  "BTG_GAME": "BTG",
  "DRAGOONSOFT_GAME": "DRAGOONSOFT",
  "PLAY8_GAME": "PLAY8",
};

enum CustomerLogGroup {
  ALL = "ALL",
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
  GAME = "GAME",
  BONUS = "BONUS",
  VOID = "VOID",
  TOURNAMENT = "TOURNAMENT",
  TIP_CREDIT = "TIP_CREDIT",
  TIP_DEBIT = "TIP_DEBIT",
  RAIN_CREDIT = "RAIN_CREDIT",
  RAIN_DEBIT = "RAIN_DEBIT",
  TICKET = "TICKET",
  CONVERT = "CONVERT",
  CREDITANDDEBIT = "CREDITANDDEBIT",
  FREE_SPIN = "FREE_SPIN",
  PG_FREE_SPIN = "PG_FREE_SPIN",
  PG_TOURNAMENT = "PG_TOURNAMENT",
  CASINO_COMMISSION_01_PERCENT = "CASINO_COMMISSION_01_PERCENT",
  CASINO_COMMISSION_LOSING_STREAK = "CASINO_COMMISSION_LOSING_STREAK",
}

function SearchCustomerLog() {
  const [form] = Form.useForm();
  const [currentTab, setCurrentTab] = useState(0);
  const [filters, setFilters] = useState<{
    skip: number;
    limit: number;
    types: CustomerLogType[];
    startDate: string;
    endDate: string;
    customerCode: string;
  }>();
  const currentGroup = form.getFieldValue("group");
  const customerStatistics = useRef<CustomerStatisticsHandler>(null);
  const {
    data: customerLogs,
    isFetching: isFetchingCustomerLogs,
    refetch,
  } = useCustomerLogs(
    filters ? filters.customerCode : "",
    {
      skip: filters?.skip,
      limit: filters?.limit,
      startDate: filters?.startDate,
      endDate: filters?.endDate,
      types: filters?.types,
    },
    {
      enabled: Boolean(filters) && currentTab === 0,
      keepPreviousData: true,
    }
  );

  const { data: gameStats, isFetching: isFetchingGameStats } = useCustomerGameStats(
    filters ? filters.customerCode : "",
    {
      startDate: filters?.startDate,
      endDate: filters?.endDate,
    },
    {
      enabled: Boolean(filters) && currentTab === 1,
      keepPreviousData: true,
    }
  );

  const onFinish = async (values: any) => {
    const newFilters = {
      skip: 0,
      limit: 100,
      customerCode: values.customerCode,
      startDate: formatDatetime(values.datetimeRange[0]),
      endDate: formatDatetime(values.datetimeRange[1]),
      types: {
        [CustomerLogGroup.CREDIT]: [CustomerLogType.CREDIT],
        [CustomerLogGroup.DEBIT]: [CustomerLogType.DEBIT],
        [CustomerLogGroup.GAME]: [CustomerLogType.GAME],
        [CustomerLogGroup.BONUS]: [CustomerLogType.BONUS],
        [CustomerLogGroup.VOID]: [CustomerLogType.VOID],
        [CustomerLogGroup.CONVERT]: [CustomerLogType.CONVERT],
        [CustomerLogGroup.TOURNAMENT]: [CustomerLogType.TOURNAMENT],
        [CustomerLogGroup.TIP_CREDIT]: [CustomerLogType.TIP_CREDIT],
        [CustomerLogGroup.TIP_DEBIT]: [CustomerLogType.TIP_DEBIT],
        [CustomerLogGroup.RAIN_CREDIT]: [CustomerLogType.RAIN_CREDIT],
        [CustomerLogGroup.RAIN_DEBIT]: [CustomerLogType.RAIN_DEBIT],
        [CustomerLogGroup.TICKET]: [CustomerLogType.TICKET],
        [CustomerLogGroup.FREE_SPIN]: [CustomerLogType.FREE_SPIN],
        [CustomerLogGroup.PG_FREE_SPIN]: [CustomerLogType.PG_FREE_SPIN],
        [CustomerLogGroup.PG_TOURNAMENT]: [CustomerLogType.PG_TOURNAMENT],
        [CustomerLogGroup.CREDITANDDEBIT]: [CustomerLogType.CREDIT, CustomerLogType.DEBIT],
        [CustomerLogGroup.CASINO_COMMISSION_01_PERCENT] : [CustomerLogType.CASINO_COMMISSION_01_PERCENT],
        [CustomerLogGroup.CASINO_COMMISSION_LOSING_STREAK] : [CustomerLogType.CASINO_COMMISSION_LOSING_STREAK],
        [CustomerLogGroup.ALL]: [
          CustomerLogType.CREDIT,
          CustomerLogType.DEBIT,
          CustomerLogType.GAME,
          CustomerLogType.BONUS,
          CustomerLogType.VOID,
          CustomerLogType.CONVERT,
          CustomerLogType.TOURNAMENT,
          CustomerLogType.TIP_CREDIT,
          CustomerLogType.TIP_DEBIT,
          CustomerLogType.RAIN_CREDIT,
          CustomerLogType.RAIN_DEBIT,
          CustomerLogType.TICKET,
          CustomerLogType.FREE_SPIN,
          CustomerLogType.PG_FREE_SPIN,
          CustomerLogType.PG_TOURNAMENT,
          CustomerLogType.CASINO_COMMISSION_01_PERCENT,
          CustomerLogType.CASINO_COMMISSION_LOSING_STREAK,
        ],
      }[values.group as CustomerLogGroup],
    };

    if (isEqual(filters, newFilters)) {
      refetch();
      customerStatistics.current?.refetchBalance();
    } else {
      setFilters(newFilters);
    }
    setCurrentTab(0);
  };

  const columns: ColumnType<any>[][] = [
    [
      {
        title: "วันที่",
        dataIndex: "createdAt",
        key: 'date',
        width: 105,
        render: (value) => dayjs(value).isValid() ? datetime(value).format("DD/MM/YYYY") : '',
      },
      {
        title: "เวลา",
        dataIndex: "createdAt",
        key: 'time',
        width: 75,
        render: (value) => dayjs(value).isValid() ? datetime(value).format("HH:mm") : '',
      },
      {
        title: "รายการ",
        dataIndex: "transactionType",
        render: (value, record) => {
          switch (value) {
            case "Debit":
              return "ถอนเงิน";
            case "Credit":
              return "เติมเงิน";
            case "Game":
              return "เดิมพันเกม";
            case "Bonus":
              if (record.bonusType === 'CASHBACK') return 'คืนยอดเสีย'
              return "ฟรีโบนัส";
            case "Void":
              return "ยกเลิกรายการ";
            case "CONVERT":
              return "แปลงเป็นเงิน";
            case "TICKET":
              return "แลก Ticket";
            case "TOURNAMENT":
              return "รางวัลผู้ชนะทัวร์นาเม้นท์";
            case "TIP_CREDIT":
              return "รับทิป";
            case "TIP_DEBIT":
              return "ส่งทิป";
            case "RAIN_CREDIT":
              return "รับเงินโปรย";
            case "RAIN_DEBIT":
              return "การโปรยเงิน";
            case "FREE_SPIN":
              return "แลกสปินรายวัน";
            case "PG_FREE_SPIN":
              return "แลกฟรีสปินเกม";
            case "PG_TOURNAMENT":
              return "PG ทัวร์นาเม้นท์";
            case "CASINO_COMMISSION_01_PERCENT":
              return "โปรค่าคอมบาคาร่า 0.1% ทุกอาทิตย์";
            case "CASINO_COMMISSION_LOSING_STREAK":
              return "โปรค่าแทงเสีย 12 ไม้ บาคาร่า";
          }
        },
      },
      {
        title: <div style={{ textAlign: 'center' }}>
          <Tooltip placement="top" title="รับโบนัส">
            <Button type="text" style={{ padding: 0 }}>
              <Icon name="outline-tag" size="1rem" />
            </Button>
          </Tooltip>
        </div>,
        dataIndex: "isBonus",
        width: 50,
        align: "center",
        render: (value, record) => {
          if (record.transactionType === "Credit" || record.bonusType === 'CASHBACK') {
            return <BonusColumn isBonus={record.isBonus} bonusType={record.bonusType} />
          } else {
            return "-"
          }
        },
      },
      {
        title: "จำนวนเงิน",
        dataIndex: "amount",
        render: (value) => <AmountColor value={value} />,
      },
      {
        title: "โบนัส",
        dataIndex: "coinBonusAmount",
        width: 100,
        exclude:
          currentGroup === CustomerLogGroup.DEBIT || currentGroup === CustomerLogGroup.GAME || currentGroup === CustomerLogGroup.VOID,
        render: (value) => <AmountColor value={value} />,
      },
      {
        title: "เทิร์นโอเวอร์ส่วนโบนัส",
        dataIndex: "turnOverAmount",
        width: 200,
        render: (value) => <AmountColor value={value} />,
      },
      {
        title: "ชนะเดิมพัน",
        dataIndex: "payoutAmount",
        exclude:
          currentGroup === CustomerLogGroup.CREDIT || currentGroup === CustomerLogGroup.DEBIT,
        render: (value) => <AmountColor value={value} />,
      },
      {
        title: "ยอดเงินก่อนทำรายการ",
        dataIndex: "beforeBalance",
        render: (value) => <Amount value={value} />,
      },
      {
        title: "ยอดเงินหลังทำรายการ",
        dataIndex: "afterBalance",
        render: (value) => <Amount value={value} />,
      },
      {
        title: "ชื่อเกม",
        dataIndex: "gameName",
        render: (value) => <div>{value ? value : "-"}</div>,
      },
      // {
      //
      //
      //   key: "viewMore",
      //   align: "center",
      //   width: 60,
      //   render: () => <ViewMoreButton size="small" />,
      // },
    ],
    [
      {
        title: "ชื่อเกม",
        dataIndex: "gameName",
      },
      {
        title: "ค่ายเกม",
        dataIndex: "gameProvider",
        render: (value, record) => record?.gameProvider ? gameProviderName[record?.gameProvider] : "PG Soft",
      },
      {
        title: "จำนวนครั้งที่เล่น",
        dataIndex: "count",
        align: "right",
      },
    ],
  ];

  return (
    <PageContainer
      tabList={
        customerLogs
          ? [
            { tab: "รายการกระเป๋าเงิน", key: "0" },
            { tab: "รายการเกม", key: "1" },
          ]
          : undefined
      }
      tabActiveKey={currentTab.toString()}
      onTabChange={(activeKey) => {
        setCurrentTab(parseInt(activeKey));
      }}
      header={
        <>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              group: CustomerLogGroup.ALL,
              datetimeRange: [
                dayjs().hour(0).minute(0).second(0).millisecond(0),
                dayjs().hour(23).minute(59).second(59).millisecond(999),
              ],
            }}
          >
            <Row gutter={8} wrap={false}>
              <Col flex="auto">
                <Form.Item name="customerCode" label="รหัสหรือเบอร์โทร">
                  <Input size="large" placeholder="ใส่รหัสหรือเบอร์โทร" allowClear />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="group" label="การทำรายการ">
                  <Select>
                    <Select.Option value={CustomerLogGroup.ALL}>ทุกรายการ</Select.Option>
                    <Select.Option value={CustomerLogGroup.CREDIT}>เติมเงิน</Select.Option>
                    <Select.Option value={CustomerLogGroup.DEBIT}>ถอนเงิน</Select.Option>
                    <Select.Option value={CustomerLogGroup.CREDITANDDEBIT}>เติมเงินและถอนเงิน</Select.Option>
                    <Select.Option value={CustomerLogGroup.GAME}>เดิมพันเกม</Select.Option>
                    <Select.Option value={CustomerLogGroup.BONUS}>โบนัส</Select.Option>
                    <Select.Option value={CustomerLogGroup.CONVERT}>แปลงเป็นเงิน</Select.Option>
                    <Select.Option value={CustomerLogGroup.TOURNAMENT}>รางวัลผู้ชนะทัวร์นาเม้นท์</Select.Option>
                    <Select.Option value={CustomerLogGroup.TIP_CREDIT}>รับทิป</Select.Option>
                    <Select.Option value={CustomerLogGroup.TIP_DEBIT}>ส่งทิป</Select.Option>
                    <Select.Option value={CustomerLogGroup.RAIN_CREDIT}>รับโปรยเงิน</Select.Option>
                    <Select.Option value={CustomerLogGroup.RAIN_DEBIT}>การโปรยเงิน</Select.Option>
                    <Select.Option value={CustomerLogGroup.TICKET}>แลก Ticket</Select.Option>
                    <Select.Option value={CustomerLogGroup.VOID}>ยกเลิกรายการ</Select.Option>
                    <Select.Option value={CustomerLogGroup.FREE_SPIN}>แลกสปินรายวัน</Select.Option>
                    <Select.Option value={CustomerLogGroup.PG_FREE_SPIN}>แลกฟรีสปินเกม</Select.Option>
                    <Select.Option value={CustomerLogGroup.PG_TOURNAMENT}>PG ทัวร์นาเม้นท์</Select.Option>
                    <Select.Option value={CustomerLogGroup.CASINO_COMMISSION_01_PERCENT}>โปรค่าคอมบาคาร่า 0.1% ทุกอาทิตย์</Select.Option>
                    <Select.Option value={CustomerLogGroup.CASINO_COMMISSION_LOSING_STREAK}>โปรค่าแทงเสีย 12 ไม้ บาคาร่า</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="datetimeRange"
                  label={
                    <Row style={{ width: "340px" }}>
                      <Col span={12}>{"วันเวลาเริ่มต้น"}</Col>
                      <Col span={12}>{"วันเวลาสิ้นสุด"}</Col>
                    </Row>
                  }
                >
                  <DatePicker.RangePicker
                    size="large"
                    allowClear={false}
                    format="DD/MM/YYYY HH:mm"
                    showTime={{ format: "HH:mm" }}
                    style={{ width: "340px" }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={<></>}
                  shouldUpdate={(prevValue, curValue) =>
                    prevValue.customerCode !== curValue.customerCode
                  }
                >
                  {() => (
                    <SearchButton
                      size="large"
                      htmlType="submit"
                      disabled={!form.getFieldValue("customerCode")}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {filters && <CustomerStatistics customerCode={filters.customerCode} ref={customerStatistics} />}
        </>
      }
    >
      {currentTab === 0 ? (
        <Table
          rowKey="uid"
          columns={columns[0]}
          dataSource={customerLogs ? customerLogs.results : []}
          loading={{
            size: "large",
            spinning: isFetchingCustomerLogs,
            style: { maxHeight: "calc(100vh - 310px)" },
          }}
          pagination={{
            total: customerLogs?.total,
            current: filters ? filters.skip / filters.limit + 1 : 1,
            pageSize: filters ? filters.limit : 10,
            onChange: (page, pageSize) => {
              if (filters) {
                setFilters({ ...filters, skip: (page - 1) * pageSize, limit: pageSize });
              }
            },
          }}
          summary={(data) => {
            if (currentGroup !== CustomerLogGroup.ALL) {
              let totalAmount = 0;
              let totalCoinBonusAmount = 0;
              let totalPayoutAmount = 0;

              data.forEach(({ amount, coinBonusAmount, payoutAmount }) => {
                totalAmount += amount;
                totalCoinBonusAmount += coinBonusAmount;
                totalPayoutAmount += payoutAmount;
              });

              return (
                <Table.Summary>
                  <Table.Summary.Row style={{ fontWeight: "bold" }}>
                    <Table.Summary.Cell index={0} colSpan={2}>
                      รวมทั้งหมด
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <AmountColor value={totalAmount} />
                    </Table.Summary.Cell>
                    {currentGroup === CustomerLogGroup.CREDIT && (
                      <Table.Summary.Cell index={2}>
                        <AmountColor value={totalCoinBonusAmount} />
                      </Table.Summary.Cell>
                    )}
                    {currentGroup === CustomerLogGroup.GAME && (
                      <Table.Summary.Cell index={3}>
                        <AmountColor value={totalPayoutAmount} />
                      </Table.Summary.Cell>
                    )}
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }
          }}
        />
      ) : (
        <Table
          rowKey="uid"
          columns={columns[currentTab]}
          dataSource={gameStats ? gameStats : []}
          loading={{
            size: "large",
            spinning: isFetchingGameStats,
            style: { maxHeight: "calc(100vh - 310px)" },
          }}
          pagination={{
            total: gameStats?.length,
          }}
          summary={(data) => {
            let totalCount = 0;
            data.forEach(({ count }) => {
              totalCount += count;
            });

            return (
              <Table.Summary>
                <Table.Summary.Row style={{ fontWeight: "bold" }}>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    รวมทั้งหมด
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align={"right"}>
                    {totalCount}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      )}
    </PageContainer>
  );
}

export default SearchCustomerLog;
