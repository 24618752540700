import { BankAccount, BankCode, BankRow } from "src/types";

export type MatchResult = {
  isMatch: boolean;
  sameDigit: number;
};

const FAULT_CASE = {
  isMatch: false,
  sameDigit: 0,
}

export const matchBankAccount = (firstBank: BankAccount, secondBank: BankAccount): MatchResult => {
  if (firstBank.accountNumber.length !== secondBank.accountNumber.length || firstBank.bankCode !== secondBank.bankCode) {
    return FAULT_CASE;
  }

  let sameDigit = 0;
  firstBank.accountNumber.split('').forEach((_accountNumber, index) => {
    if (_accountNumber === secondBank.accountNumber[index]) {
      sameDigit++;
    }
  })
  if (sameDigit >= 4 && firstBank.bankCode === secondBank.bankCode) {
    return {
      isMatch: true,
      sameDigit,
    };
  }

  return { ...FAULT_CASE, sameDigit };
};

export const sortingBank = (currentBank: BankRow, nextBank: BankRow): number => {
  if (currentBank.bankCode === BankCode.TrueMoney) return 1
  if (nextBank.bankCode === BankCode.TrueMoney) return -1
  if (currentBank.bankName === nextBank.bankName) {
    if (currentBank.accountName < nextBank.accountName) return -1
    if (currentBank.accountName > nextBank.accountName) return 1
  } else {
    if (currentBank.bankName < nextBank.bankName) return -1
    if (currentBank.bankName > nextBank.bankName) return 1
  }
  return 0
};

export const onlyEnableBank = (_bank: BankRow): boolean => {
  return _bank.status !== 'Terminated' && _bank.status !== 'Banned'; 
}
