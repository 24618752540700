export const colors = {
  cloud1: "#f6f8fa",
  cloud2: "#eef1f3",
  cloud3: "#e2e9ed",
  cloud4: "#c9d4db",
  cloud5: "#a7b6c0",
  cloud6: "#85949e",
  cloud7: "#5f6d78",
  cloud8: "#374550",
  cloud9: "#1f2b35",
  cloud10: "#0a0f14",

  gray1: "#FFFFFF",
  gray2: "#FAFAFA",
  gray3: "#F5F5F5",
  gray4: "#F0F0F0",
  gray5: "#D9D9D9",
  gray6: "#BFBFBF",
  gray7: "#8C8C8C",
  gray8: "#595959",
  gray9: "#262626",
  gray10: "#000000",

  green1: "#effff9",
  green2: "#c5f9e6",
  green3: "#9af8d6",
  green4: "#57efb9",
  green5: "#29e2a0",
  green6: "#18c889",
  green7: "#0d9e6a",
  green8: "#058054",
  green9: "#005235",
  green10: "#042116",

  red1: "#fff1f4",
  red2: "#ffdae1",
  red3: "#ffb2c1",
  red4: "#ff869d",
  red5: "#ff4259",
  red6: "#f31641",
  red7: "#c70227",
  red8: "#92001c",
  red9: "#6b0014",
  red10: "#37000b",

  yellow1: "#fffbe6",
  yellow2: "#fff1b8",
  yellow3: "#ffe58f",
  yellow4: "#ffd666",
  yellow5: "#ffc53d",
  yellow6: "#faad14",
  yellow7: "#d48806",
  yellow8: "#ad6800",
  yellow9: "#ad6800",
  yellow10: "#613400",
};
