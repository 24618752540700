import { atom } from "recoil";
import { KEYS } from "../keys";

export type LoadingState = {
  isLoading: boolean;
};

const defaultState: LoadingState = {
  isLoading: false,
};

const loadingState = atom({
  key: KEYS.LOADING,
  default: defaultState,
});

export default loadingState;