import { Tabs as AntTabs, TabsProps as AntTabsProps } from "antd";
import classNames from "classnames";
import "./Tabs.less";

interface TabsProps extends AntTabsProps { }

function Tabs(props: TabsProps) {
  return <AntTabs size={props.size || 'large'} {...props} className={classNames('bo-tabs', props.className)} />;
}

Tabs.TabPane = AntTabs.TabPane;

export default Tabs;
