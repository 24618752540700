import { useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { requestLogin } from "../../services/login";
import { GOOGLE_CLIENT_ID, } from "../../models/buildtime-constant";
import { useSetRecoilState } from "recoil";
import { authState } from "../../recoils";

import './Login.scss';

const clientId = GOOGLE_CLIENT_ID;

function Login() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBeingLogin, setIsBeingLogin] = useState(false)
  const [loginForm] = Form.useForm<{ email: string, password: string }>()
  const setAuth = useSetRecoilState(authState)
  const navigate = useNavigate()

  useEffect(() => {
    console.log('Auth', clientId)
    const setAuth2 = async () => {
      /* global google */
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: clientId,
        callback: (response: any) => {
          console.log('response', response);
          updateUser({
            idToken: response.credential,
          });
        },
      })
      // @ts-ignore
      google.accounts.id.renderButton(
        document.getElementById('login-with-google'),
        { theme: 'outline', size: 'large' },
      )
    }
    setAuth2();
  }, []);

  const updateUser = async (currentUser: any) => {
    const requestLoginResponse = await requestLogin({
      email: currentUser.email || '',
      id_token: currentUser.idToken || '',
    })
    if (requestLoginResponse === null) {
      setIsModalVisible(true)
      return
    }
    setAuth({
      ...requestLoginResponse,
      pictureBase64: requestLoginResponse.picture_base64,
    });
    navigate('/')
  };

  return (
    <div className='login-page'>
      <h1 className='login-headline'>เข้าสู่ระบบ</h1>
      <div className='login-wrapper'>
        <div className='pigspin-logo' />
        <div className='welcome-text'>Backoffice ยินดีต้อนรับ</div>
        <div style={{ textAlign: 'center' }}>
          <button style={{ margin: '1.5rem', }} id="login-with-google" />
        </div>
      </div>
      <Modal
        visible={isBeingLogin}
        onCancel={() => { setIsBeingLogin(false) }}
        onOk={() => {
          updateUser(loginForm.getFieldsValue())
        }}
      >
        Login
        <Form form={loginForm}>
          <Form.Item name="email" label="Email">
            <Input type="email" placeholder="example@gmail.com" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={
          <div className="modal-header" style={{
            backgroundImage: 'url(/icons/alert-danger.svg)',
          }} />
        }
        visible={isModalVisible}
        wrapClassName="login-error-modal"
        closable={false}
        footer={false}
        width={`732px`}
        centered
      >
        <div className="modal-content-wrapper">
          <div className="body-headline">ไม่สามารถเข้าสู่ระบบ</div>
          <div className="pigspin-logo error-modal" />
          <div className="body-description">
            <label>เนื่องจากไม่พบบัญชี Google นี้ในระบบ</label>
            <label>กรุณาใช้บัญชีอื่นหรือติดต่อผู้ดูแล</label>
          </div>
          <Button
            type="primary"
            className="dismiss-button"
            onClick={() => setIsModalVisible(false)}
          >เข้าใจแล้ว</Button>
        </div>
      </Modal>
    </div>
  )
}

export default Login
