import dayjs from "dayjs";
import { AffiliateStateType } from "src/recoils/modules/affiliate";
import { Scope } from "src/types";
import { formatDatetime, formatUtcTime } from "src/utils";
import { DepositTransactionResponse, DEPOSIT_TRANSACTION_STATUS, WithdrawTransactionResponse, WITHDRAW_TRANSACTION_STATUS } from "../../services/transactions/transactions";
import { fetchTransactions, TransactionsFilters } from "../useTransactions";
import { FILTER, SearchCriteria } from "./type";

export const DEPOSIT_FILTER_PENDING_TRANSACTIONS = [
  { text: 'รอเติมเงิน', value: FILTER.PENDING_DEPOSIT },
  { text: 'รอแจ้งคืนเงิน', value: FILTER.PENDING_REFUND },
];

export const DEPOSIT_FILTER_HISTORY_TRANSACTIONS = [
  { text: 'ประวัติของฉัน', value: FILTER.ONLY_ME_DEPOSIT, },
  // { text: 'รายการทั้งหมด', value: FILTER.ALL_DEPOSIT, role: Scope.DepositHistoryShowAll, },
]

export const WITHDRAW_PROCESSING_TRANSACTIONS = [
  { text: 'กำลังดำเนินรายการ', value: FILTER.WITHDRAW_PROCESSING },
]

export const WITHDRAW_FILTER_PENDING_TRANSACTIONS = [
  { text: 'รอถอนเงิน', value: FILTER.PENDING_WITHDRAW },
  { text: 'รอคืนเงิน', value: FILTER.REFUND },
];

export const WITHDRAW_FILTER_HISTORY_TRANSACTIONS = [
  { text: 'ประวัติของฉัน', value: FILTER.ONLY_ME_WITHDRAW },
  // { text: 'รายการทั้งหมด', value: FILTER.ALL_WITHDRAW, role: Scope.WithdrawHistoryShowAll, },
];

export const SELECTED_TAP: Record<FILTER, FILTER> = {
  [FILTER.PENDING_DEPOSIT]: FILTER.PENDING_DEPOSIT,
  [FILTER.PENDING_REFUND]: FILTER.PENDING_REFUND,
  [FILTER.ONLY_ME_DEPOSIT]: FILTER.ONLY_ME_DEPOSIT,
  [FILTER.ALL_DEPOSIT]: FILTER.ONLY_ME_DEPOSIT,
  [FILTER.PENDING_WITHDRAW]: FILTER.PENDING_WITHDRAW,
  [FILTER.WITHDRAW_PROCESSING]: FILTER.WITHDRAW_PROCESSING,
  [FILTER.REFUND]: FILTER.PENDING_WITHDRAW,
  [FILTER.ONLY_ME_WITHDRAW]: FILTER.ONLY_ME_WITHDRAW,
  [FILTER.ALL_WITHDRAW]: FILTER.ONLY_ME_WITHDRAW,
  [FILTER.NONE]: FILTER.NONE,
}

export type Config = {
  call: (payload: any) => Promise<WithdrawTransactionResponse | DepositTransactionResponse | null>;
  payload: TransactionsFilters;
}

export type ConfigDictionary = {
  directCall: Config;
  otherTabCall: Config;
}

export const getCallConfig = (token: string, _searchCriteria: SearchCriteria, affiliateState: AffiliateStateType): ConfigDictionary => {
  const basePayload = {
    token,
    page: _searchCriteria.offset,
    pageSize: _searchCriteria.limit,
  }
  const callConfig: { [key in FILTER]: Config } = {
    [FILTER.PENDING_WITHDRAW]: {
      call: fetchTransactions,
      payload: {
        ...basePayload,
        isSent: false,
        orderBy: 'created_at',
        transferType: affiliateState.isAffiliateMode ? 'AFFILIATE' : undefined,
        withdrawStatuses: [WITHDRAW_TRANSACTION_STATUS.PENDING],
      }
    },
    [FILTER.WITHDRAW_PROCESSING]: {
      call: fetchTransactions,
      payload: {
        ...basePayload,
        isSent: true,
        orderBy: 'created_at',
        transferType: affiliateState.isAffiliateMode ? 'AFFILIATE' : undefined,
        withdrawStatuses: [WITHDRAW_TRANSACTION_STATUS.PENDING],
      }
    },
    [FILTER.REFUND]: {
      call: fetchTransactions,
      payload: {
        ...basePayload,
        orderBy: 'latest_action_at',
        refundStatuses: [DEPOSIT_TRANSACTION_STATUS.WAITING_TRANSFER],
      },
    },
    [FILTER.ALL_WITHDRAW]: {
      call: fetchTransactions,
      payload: {
        ...basePayload,
        orderBy: '-latest_action_at',
        transferType: affiliateState.isAffiliateMode ? 'AFFILIATE' : undefined,
        withdrawStatuses: [WITHDRAW_TRANSACTION_STATUS.COMPLETED, WITHDRAW_TRANSACTION_STATUS.REJECTED, WITHDRAW_TRANSACTION_STATUS.PENDING],
        refundStatuses: [DEPOSIT_TRANSACTION_STATUS.TRANSFER_BACK, DEPOSIT_TRANSACTION_STATUS.WAITING_TRANSFER],
      },
    },
    [FILTER.ONLY_ME_WITHDRAW]: {
      call: fetchTransactions,
      payload: {
        ...basePayload,
        orderBy: '-latest_action_at',
        transferType: affiliateState.isAffiliateMode ? 'AFFILIATE' : undefined,
        withdrawStatuses: [WITHDRAW_TRANSACTION_STATUS.COMPLETED, WITHDRAW_TRANSACTION_STATUS.REJECTED, WITHDRAW_TRANSACTION_STATUS.PENDING],
        refundStatuses: [DEPOSIT_TRANSACTION_STATUS.TRANSFER_BACK, DEPOSIT_TRANSACTION_STATUS.WAITING_TRANSFER],
        onlyMe: true,
        start: formatDatetime(dayjs().subtract(24, 'hour')),
        end: formatDatetime(dayjs()), 
      },
    },
    [FILTER.PENDING_DEPOSIT]: {
      call: fetchTransactions,
      payload: {
        ...basePayload,
        orderBy: 'transferred_at',
        start: formatDatetime(dayjs().subtract(24, 'hour')),
        end: formatDatetime(dayjs()),
        transferredAt: _searchCriteria.transferredAt ? formatUtcTime(_searchCriteria.transferredAt) : undefined,
        adminBankCode: _searchCriteria.adminBankCode ? _searchCriteria.adminBankCode : undefined,
        adminBankAccountName: _searchCriteria.adminBankAccountName ? _searchCriteria.adminBankAccountName : undefined,
        depositStatuses: [DEPOSIT_TRANSACTION_STATUS.PENDING],
      },
    },
    [FILTER.PENDING_REFUND]: {
      call: fetchTransactions,
      payload: {
        ...basePayload,
        orderBy: 'latest_action_at',
        adminBankCode: _searchCriteria.adminBankCode ? _searchCriteria.adminBankCode : undefined,
        adminBankAccountName: _searchCriteria.adminBankAccountName ? _searchCriteria.adminBankAccountName : undefined,
        depositStatuses: [DEPOSIT_TRANSACTION_STATUS.REJECTED],
      },
    },
    [FILTER.ONLY_ME_DEPOSIT]: {
      call: fetchTransactions,
      payload: {
        ...basePayload,
        orderBy: '-latest_action_at',
        depositStatuses: [DEPOSIT_TRANSACTION_STATUS.PENDING, DEPOSIT_TRANSACTION_STATUS.REJECTED, DEPOSIT_TRANSACTION_STATUS.COMPLETED, DEPOSIT_TRANSACTION_STATUS.CANCELLED],
        refundStatuses: [DEPOSIT_TRANSACTION_STATUS.TRANSFER_BACK, DEPOSIT_TRANSACTION_STATUS.WAITING_TRANSFER],
        onlyMe: true,
        start: formatDatetime(dayjs().subtract(24, 'hour')),
        end: formatDatetime(dayjs()), 
      },
    },
    [FILTER.ALL_DEPOSIT]: {
      call: fetchTransactions,
      payload: {
        ...basePayload,
        orderBy: '-latest_action_at',
        depositStatuses: [DEPOSIT_TRANSACTION_STATUS.PENDING, DEPOSIT_TRANSACTION_STATUS.REJECTED, DEPOSIT_TRANSACTION_STATUS.COMPLETED, DEPOSIT_TRANSACTION_STATUS.CANCELLED],
        refundStatuses: [DEPOSIT_TRANSACTION_STATUS.TRANSFER_BACK, DEPOSIT_TRANSACTION_STATUS.WAITING_TRANSFER],
      },
    },
    [FILTER.NONE]: {
      call: fetchTransactions,
      payload: basePayload,
    },
  }
  const otherTabFilter = {
    [FILTER.PENDING_WITHDRAW]: FILTER.REFUND,
    [FILTER.WITHDRAW_PROCESSING]: FILTER.REFUND,
    [FILTER.REFUND]: FILTER.PENDING_WITHDRAW,
    [FILTER.ALL_WITHDRAW]: FILTER.ONLY_ME_WITHDRAW,
    [FILTER.ONLY_ME_WITHDRAW]: FILTER.ALL_WITHDRAW,
    [FILTER.PENDING_DEPOSIT]: FILTER.PENDING_REFUND,
    [FILTER.PENDING_REFUND]: FILTER.PENDING_DEPOSIT,
    [FILTER.ONLY_ME_DEPOSIT]: FILTER.ALL_DEPOSIT,
    [FILTER.ALL_DEPOSIT]: FILTER.ONLY_ME_DEPOSIT,
    [FILTER.NONE]: FILTER.NONE,
  }
  const otherTabCall = callConfig[otherTabFilter[_searchCriteria.filter]] as Config;
  return {
    directCall: callConfig[_searchCriteria.filter],
    otherTabCall: {
      ...otherTabCall,
      payload: {
        ...otherTabCall.payload,
        page: 1,
        pageSize: 1,
      }
    },
  }
}
