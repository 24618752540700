import * as React from 'react';
import { useRecoilState } from 'recoil';
import { modalState } from '../../recoils';
import { AlertModal } from '../Modals';

interface IGolbalModalProps {
}

const GolbalModal: React.FunctionComponent<IGolbalModalProps> = (props) => {
  const [modal, setModal] = useRecoilState(modalState);
  const onClose = () => {
    setModal({
      ...modal,
      isModalVisible: false,
    });
  }
  const onConfirm = () => {
    if (modal.onConfirm) modal.onConfirm();
    onClose();
  }
  const onCancel = () => {
    if (modal.onCancel) modal.onCancel();
    onClose();
  }
  return (
    <AlertModal
      {...modal}
      className='GlobalModal'
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};

export default GolbalModal;
