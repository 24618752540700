import * as React from 'react';
import { BaseIconProps } from './type';

export type IAlertIconProps = BaseIconProps & {
  type: 'danger' | 'warning';
}

const AlertIcon: React.FunctionComponent<IAlertIconProps> = (props) => {
  const { size = 24 } = props;
  return (
    <svg
      style={{ width: `${size}px`, height: `${size}px` }}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx={24.5} cy={27} rx={12.5} ry={12} fill="#fff" />
      <path
        d="M45.12 34.085 29.705 7.316c-2.517-4.421-8.885-4.421-11.393 0L2.888 34.085C.37 38.507 3.503 44 8.584 44H39.37c5.081 0 8.27-5.548 5.751-9.915ZM24 38.062c-1.341 0-2.455-1.117-2.455-2.461 0-1.344 1.114-2.46 2.454-2.46a2.413 2.413 0 0 1 2.4 2.523c.064 1.28-1.114 2.398-2.4 2.398Zm2.236-15.909c-.108 1.907-.226 3.805-.335 5.712-.054.617-.054 1.18-.054 1.789-.054 1.007-.842 1.788-1.848 1.788-1.005 0-1.784-.726-1.847-1.734-.163-2.97-.335-5.884-.498-8.853-.055-.781-.109-1.571-.172-2.352 0-1.29.724-2.352 1.902-2.688a2.46 2.46 0 0 1 2.852 1.399c.173.39.227.78.227 1.235-.054 1.244-.172 2.478-.227 3.704Z"
        fill={`url(#${props.type})`}
      />
      <defs id="qwpdkwpqodk">
        <linearGradient
          id={props.type}
          x1={46}
          y1={4}
          x2={6.181}
          y2={47.801}
          gradientUnits="userSpaceOnUse"
        >
          {props.type === 'warning' && (
            <>
              <stop stopColor="#FFC53D" />
              <stop offset={1} stopColor="#FAAD14" />
            </>
          )}
          {props.type === 'danger' && (
            <>
              <stop stopColor="#FF3535" />
              <stop offset={1} stopColor="#D30000" />
            </>
          )}
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AlertIcon;
