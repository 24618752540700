import { Modal as AntModal, ModalFuncProps } from "antd"
import { AlertDanger2, AlertDecisionWarning } from "src/icons"
import "./Modal.less"

const Modal = {
  confirm: (props: ModalFuncProps) => {
    return AntModal.confirm({
      cancelText: "ไม่",
      okText: "ใช่ ต้องการ",
      width: "600px",
      autoFocusButton: null,
      centered: true,
      icon: (
        <div className="icon-container confirm">
          <AlertDecisionWarning />
        </div>
      ),
      ...props,
      className: "bo-modal",
    })
  },
  error: (props: ModalFuncProps) => {
    return AntModal.error({
      width: "600px",
      autoFocusButton: null,
      centered: true,
      icon: (
        <div className="icon-container error">
          <AlertDanger2 />
        </div>
      ),
      ...props,
      className: "bo-modal",
    })
  },
}

export default Modal
