import { Checkbox, Switch, TableColumnsType } from "antd";
import { FilterValue } from "antd/lib/table/interface";
import { UpdateGameRequest } from "src/hooks/useGames";
import { Games, GameStatus } from "src/types";
import { getColumnFilters } from "src/utils/table";
import toString from "lodash/toString";

interface GameColumnsPigbetTypes {
  games?: Games[];
  filteredInfo: Record<string, FilterValue | null>;
  handleUpdateGame: (params: UpdateGameRequest) => void;
  refType: string;
}

export const GameColumnsPigbet = ({
  games = [],
  filteredInfo,
  handleUpdateGame,
  refType,
}: GameColumnsPigbetTypes): TableColumnsType<Games> => [
  {
    key: "refType",
    title: "ค่ายเกม",
    dataIndex: "refType",
    filterIcon: () => null,
    filterDropdown: () => null,
    filters: getColumnFilters("refType", games as Record<string, any>[]),
    filteredValue: filteredInfo.refType || null,
    filterSearch: true,
    onFilter: (value, record: Games) => {
      return record?.refType === value;
    },
    width: 100,
  },
  {
    key: "gameName",
    title: "ชื่อเกม",
    dataIndex: "gameName",
    filters: getColumnFilters(
      "gameName",
      games.filter((game) => game.refType === refType) as Record<string, any>[]
    ),
    filteredValue: filteredInfo.gameName || null,
    filterSearch: true,
    onFilter: (value, record: Games) => {
      return record?.gameName === value;
    },
  },
  {
    key: "gameCode",
    title: "รหัสเกม",
    dataIndex: "gameCode",
    filters: getColumnFilters(
      "gameCode",
      games
        .filter((game) => game.refType === refType)
        .sort((prev, current) => {
          if (prev.gameCode > current.gameCode) return 1;
          if (prev.gameCode < current.gameCode) return -1;
          return 0;
        }) as Record<string, any>[]
    ),
    filteredValue: filteredInfo.gameCode || null,
    filterSearch: true,
    onFilter: (value, record: Games) => {
      return record?.gameCode === value;
    },
  },
  {
    title: "เกมยอดนิยม",
    dataIndex: "isPopular",
    key: "isPopular",
    filters: getColumnFilters("isPopular", games as Record<string, any>[]),
    filteredValue: filteredInfo.isPopular || null,
    filterSearch: true,
    onFilter: (value, record: Games) => {
      return toString(record?.isPopular) === toString(value);
    },
    render: (isPopular, record) => (
      <Checkbox
        checked={isPopular}
        onChange={(e) => handleUpdateGame({ ...record, isPopular: e.target.checked })}
      />
    ),
    width: 100,
  },
  {
    title: "การใช้งาน",
    dataIndex: "status",
    key: "status",
    filteredValue: filteredInfo.status || null,
    filters: [
      {
        text: "เปิดการใช้งาน",
        value: GameStatus.active,
      },
      {
        text: "ปิดการใช้งาน",
        value: GameStatus.inactive,
      },
    ],
    onFilter: (value, record: Games) => record?.status === value,
    render: (status, record) => (
      <Switch
        checked={status === GameStatus.active}
        checkedChildren="เปิด"
        unCheckedChildren="ปิด"
        onChange={(checked: boolean) => {
          handleUpdateGame({
            ...record,
            status: checked ? GameStatus.active : GameStatus.inactive,
          });
        }}
      />
    ),
  },
];
