import { Space } from "antd";
import { useEffect, useState } from "react";

import {
  Tabs,
  DailyQuest,
  WeeklyQuest,
} from "src/components";

function QuestManagement() {
  const [selectedTab, setSelectedTab] = useState<string>();

  useEffect(() => {
    setSelectedTab("daily");
  }, []);

  return (
    <div className="QuestDashboard">
      <Space>
        <div>
          <Tabs
            defaultActiveKey="daily"
            onChange={(_tab) => {
              setSelectedTab(_tab);
            }}
          >
            <Tabs.TabPane tab="ภารกิจรายวัน" key="daily"/>
            <Tabs.TabPane tab="ภารกิจรายสัปดาห์" key="weekly" />
          </Tabs>
        </div>
      </Space>
      {(selectedTab === "daily") && (
        <DailyQuest />
      )}
      {(selectedTab === "weekly") && (
        <WeeklyQuest />
      )}
    </div>
  );
}

export default QuestManagement;
