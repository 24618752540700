import { ApartmentOutlined, CaretDownOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { TableColumnsType, Breadcrumb, Space, Table as AntdTable, Row, Col } from 'antd';
import dayjs from 'dayjs';
import * as React from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { AffiliateCustomer } from 'src/hooks/affiliate/type';
import { useAffiliate } from 'src/hooks/affiliate';
import { Amount, Button, ColumnType, DateTime, PaginationTypes, Table, TableProps } from '..';
import { CustomerStatusMessage, IconStatus2 } from '../Bank/Status';
import './OrgChartTable.less';
import { getColumnFilters } from 'src/utils/table';
import { ColumnGroupType } from 'antd/lib/table';
import { useRecoilValue } from 'recoil';
import { authInfoState } from 'src/recoils';
import { Role } from 'src/types';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

interface IOrgChartTableProps {
  userCode: string;
}

export type OrgChartHandler = {
  setBreadcrumb: (customerCode: string[]) => void;
  getOrgChart: () => void;
}

const OrgChartTable = React.forwardRef<OrgChartHandler, IOrgChartTableProps>((props, ref) => {
  const {
    downline,
    self,
    upline,
    breadcrumb,
    isOrgchartLoading,
    getOrgChart,
    setBreadcrumb,
  } = useAffiliate({
    userCode: props.userCode,
    from: dayjs(),
    to: dayjs(),
  });
  const { role } = useRecoilValue(authInfoState)
  const [pagination, setPagination] = React.useState<PaginationTypes>({
    limit: 10,
    current: 1,
  });
  const [filteredInfo, setFilteredInfo] = React.useState<Record<string, FilterValue | null>>({});
  const [sortedInfo, setSortedInfo] = React.useState<SorterResult<AffiliateCustomer>>({});
  const naviagate = useNavigate();

  const clearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
  }
  const handleChange: TableProps<AffiliateCustomer>['onChange'] = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<AffiliateCustomer>);
  };

  const filterColumn = (key: keyof AffiliateCustomer): ColumnGroupType<AffiliateCustomer> | ColumnType<AffiliateCustomer> => ({
    key,
    dataIndex: key,
    filteredValue: filteredInfo[key] || null,
    filters: getColumnFilters(key, downline),
    filterSearch: true,
    onFilter: (value, record) => record[key] === value,
  })

  const columns: TableColumnsType<AffiliateCustomer> = [
    {
      title: 'รหัสลูกค้า',
      dataIndex: 'userCode',
      key: 'userCode',
      width: 180,
      render: (value, record) => {
        if (record.hasChild && role === Role.OWNER) {
          return (
            <Link style={{ textDecoration: 'underline' }} to={`?userCode=${record.userCode}`}>{record.userCode}</Link>
          )
        }
        return record.userCode;
      },
      ...filterColumn('userCode'),
    },
    {
      title: 'ชื่อลูกค้า',
      ...filterColumn('name'),
    },
    {
      title: 'เบอร์โทรศัพท์',
      ...filterColumn('phone'),
    },
    {
      title: 'ช่องทางการสมัคร',
      ...filterColumn('channel'),
    },
    {
      title: 'วันที่สมัคร',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => (<DateTime value={value} />),
      sorter: (a, b) => b.createdAt.diff(a.createdAt),
      sortOrder: sortedInfo.field === 'createdAt' ? sortedInfo.order : null,
    },
    // TODO : if want to show kyc on Orgchart
    // {
    //   title: 'ยืนยันตัวตน',
    //   dataIndex: 'kycText',
    //   key: 'kycText',
    //   width: 150,
    //   render: (value, record) => {
    //     return (
    //       <Space>
    //         <IconStatus2 type={record.hasKyc ? 'active' : 'stop'} />
    //         {value}
    //       </Space>
    //     )
    //   },
    //   ...filterColumn('kycText'),
    // },
    {
      title: 'รายได้รวม',
      dataIndex: 'income',
      key: 'income',
      render: (value) => (<Amount allowZero value={value} />),
      sorter: (a, b) => b.income - a.income,
      sortOrder: sortedInfo.field === 'income' ? sortedInfo.order : null,
    },
    {
      title: 'สถานะ', render: (value, record) => (<CustomerStatusMessage status={record.status} />),
      ...filterColumn('statusText'),

    },
  ];

  React.useEffect(() => {
    if (props.userCode) {
      getOrgChart()
    }
  }, [props.userCode]);

  React.useImperativeHandle(ref, () => ({
    setBreadcrumb,
    getOrgChart,
  }));

  const renderCustomerRow = (_customer: AffiliateCustomer, className?: string) => {
    if (!_customer) return;
    const {
      userCode,
      name,
      phone,
      channel,
      createdAt,
      status,
      income,
      hasKyc,
      kycText,
    } = _customer;
    const isSelf = className === 'self';
    return (
      <AntdTable.Summary.Row className={classnames('ant-table-row', className)}>
        <AntdTable.Summary.Cell index={0}>{isSelf && downline.length < 1 ? '' : <CaretDownOutlined />}&nbsp;&nbsp;{userCode} {className === 'self' && downline.length > 0 && `(${downline.length})`}</AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={1}>{name}</AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={2}>{phone}</AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={3}>{channel}</AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={4}><DateTime value={createdAt.toString()} /></AntdTable.Summary.Cell>
        {/* 
        <AntdTable.Summary.Cell index={5}>
           TODO : if want to show kyc on Orgchart
          {isSelf && (
            <Space>
              <IconStatus2 type={hasKyc ? 'active' : 'stop'} />
              {kycText}
            </Space>
          )} 
        </AntdTable.Summary.Cell>
          */}
        <AntdTable.Summary.Cell index={5}><Amount allowZero={isSelf} value={income} /></AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={6}><CustomerStatusMessage status={status} /></AntdTable.Summary.Cell>
      </AntdTable.Summary.Row>
    );
  };

  return (
    <>
      <Row wrap={false}>
        <Col flex="30px"><ApartmentOutlined /></Col>
        <Col flex="auto">
          <Breadcrumb className="OrgChart_Breadcrumb" separator=">">
            {breadcrumb.map((userCode) => (
              <Breadcrumb.Item
                key={userCode}
                className="Breadcrumb_Item"
                onClick={() => {
                  setBreadcrumb(
                    breadcrumb.slice(0, breadcrumb.indexOf(userCode) + 1)
                  )
                  naviagate(`?userCode=${userCode}`,);

                }}
              >
                {userCode}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Col>
        <Col flex="none">
          <Button type="text" shape="round" onClick={clearFilter}>
            ล้างตัวกรองทั้งหมด
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <Table
        keepShowTable
        loading={isOrgchartLoading}
        className="AffiliateOrgChart"
        scroll={{ x: 957 }}
        onChange={handleChange}
        columns={columns}
        dataSource={downline}
        summary={() => (
          <AntdTable.Summary fixed="top">
            {upline && renderCustomerRow(upline)}
            {self && renderCustomerRow(self, 'self')}
          </AntdTable.Summary>
        )}
        pagination={{
          current: pagination.current,
          pageSize: pagination.limit,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, current: page, limit: pageSize });
          },
        }}
      />
    </>
  );
});

export default OrgChartTable;
