import { CustomerItem } from "src/services/service.type";
import { BankCode, CustomerRow, CustomerStatus } from "src/types";
import { mapToBankName } from "./mapToBank";
import { datetime } from "src/utils/datetime";
import dayjs from "dayjs";

export const mapToCustomerText = (status: CustomerStatus): string => {
  const mapper = {
    [CustomerStatus.ACTIVE]: 'เปิดใช้งาน',
    [CustomerStatus.BLOCKED]: 'ถูกล็อค PIN',
    [CustomerStatus.DISABLED]: 'ถูกระงับใช้งาน',
  };
  return mapper[status];
};

export const mapToCustomer = (customer: CustomerItem): CustomerRow => {
  let _promotionUpdatedAt = dayjs(customer.promotion_state?.last_update).isValid() ? datetime(customer.promotion_state?.last_update).toDate() : undefined;
  let _latestToggleAt = dayjs(customer.promotion_state?.latest_toggle_at).isValid() ? datetime(customer.promotion_state?.latest_toggle_at).toDate() : undefined;
  let _latestTypeToggleAt = dayjs(customer.promotion_state?.latest_type_toggle_at).isValid() ? datetime(customer.promotion_state?.latest_type_toggle_at).toDate() : undefined;
  return {
    customerId: customer.user_code || customer.uid,
    name: customer.display_name,
    customerCode: customer.player_username,
    phone: customer.phone_number || '',
    promotionState: {
      status: customer.promotion_state.status,
      type: customer.promotion_state.promotion_type,
      lastUpdate: _promotionUpdatedAt,
      latestToggleAt: _latestToggleAt,
      latestTypeToggleAt: _latestTypeToggleAt,
    },
    upline: {
      player_username: customer.upline.player_username
    },
    bank: {
      bankCode: customer.bank_account?.bank_code || BankCode.NONE,
      bankName: mapToBankName(customer.bank_account?.bank_code || BankCode.NONE),
      accountNumber: customer.bank_account?.number || '',
      accountName: customer.bank_account?.name || '',
      accountNameEN: customer.bank_account?.name_en || '',
      default_bgb_deposit_bank_code: customer.bank_account?.default_bgb_deposit_bank_code,
      default_group_bank_code: customer.bank_account?.default_group_bank_code,
    },
    trueWalletId: customer.bank_account?.true_wallet_id || '',
    isLineLinked: (customer.line_user_id && customer.line_user_id.length > 0) || false,
    lineUserId: customer.line_user_id || undefined,
    status: customer.status,
    statusText: mapToCustomerText(customer.status),
    pin: customer.pin,
    createdAt: dayjs(customer.created_at).isValid() ? datetime(customer.created_at) : undefined,
    referrer: customer.referrer,
    hasKyc: customer && customer.identity_number ? customer.identity_number?.length > 0 : false,
    firstName: customer.first_name || '',
    lastName: customer.last_name || '',
    playerNickname: customer.player_nickname || '',
  }
}
