import { Col, Row } from "antd";
import { BankLogo } from "src/components";
import { BankCode } from "src/types";
import { getBankFormat } from "src/utils/textOperation/textOperation";

export interface BankAccountProps {
  bankCode: BankCode;
  accountName: string | null;
  accountNumber: string;
}

function BankAccount({ bankCode, accountName, accountNumber }: BankAccountProps) {
  return (
    <Row wrap={false} align="middle" style={{ height: "100%", columnGap: "8px" }}>
      <Col flex="none" style={{ display: "flex" }}>
        <BankLogo bankCode={bankCode} />
      </Col>
      <Col>
        <div style={{ fontSize: "14px", lineHeight: "20px" }}>{accountName}</div>
        <div style={{ fontSize: "12px", lineHeight: "16px" }}>{isNaN(Number(accountNumber)) ? accountNumber: getBankFormat(accountNumber)}</div>
      </Col>
    </Row>
  );
}

export default BankAccount;
