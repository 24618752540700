import { Tabs } from "src/components";

export interface PageContainerProps {
  header?: React.ReactNode;
  tabList?: { key: string; tab: React.ReactNode }[];
  children?: React.ReactNode;
  tabActiveKey?: string;
  onTabChange?: (activeKey: string) => void;
}

function PageContainer(props: PageContainerProps) {
  return (
    <>
      {props.header}
      {props.tabList?.length && (
        <Tabs activeKey={props.tabActiveKey} onChange={props.onTabChange}>
          {props.tabList.map((tabProps) => (
            <Tabs.TabPane {...tabProps} />
          ))}
        </Tabs>
      )}
      <div style={{ backgroundColor: "white", borderRadius: "8px", padding: 16 }}>
        {props.children}
      </div>
    </>
  );
}

export default PageContainer;
