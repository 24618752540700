import { AxiosRequestConfig } from 'axios';
import { TransactionItem } from 'src/hooks/useTransaction/type';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse2 } from "../service.type";

export type AdjustTransactionRequest = {
  primary_transaction_uid: string;
  secondary_transaction_uid: string;
  user_uid: string;
  token: string;
};

export type AdjustTransactionResponse = BaseResponse2<{}>;

export const adjustTransaction = async ({
  primary_transaction_uid,
  secondary_transaction_uid,
  token,
  user_uid,
}: AdjustTransactionRequest): Promise<AdjustTransactionResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/payment/deposit/adjust-transaction',
    method: 'POST',
    data: {
      primary_transaction_uid,
      secondary_transaction_uid,
      customer_code: user_uid,
    },
    headers: {
      token,
    }
  };
  return fetchWithJSON<AdjustTransactionResponse>(pigspinBackofficeApiInstance, requestConfig);
};
