import { ENABLE_DEV_MODE } from "src/models/buildtime-constant";
import { useQuery } from "./useQuery"

export type FeatureToggleHookState = {
  rejectWithdraw: boolean;
  validateBank: boolean;
}

export const useFeatureToggle = (): FeatureToggleHookState => {
  const query = useQuery();

  // TODO wrap dev mode
  const enableDevMode = ENABLE_DEV_MODE;
  return {
    validateBank: enableDevMode && query.get('validateBank') === 'yes',
    rejectWithdraw: enableDevMode &&  query.get('rejectwithdraw') === 'yes',
  }
}