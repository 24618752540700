import React from 'react';
import { Avatar, Layout, Menu, Dropdown } from 'antd';
import { Link, useLocation } from "react-router-dom";
import classnames from 'classnames';
import { useRecoilValue, useRecoilState } from "recoil";
import { authInfoState, authState, recoilAction } from "../../recoils";
import { requestLogOut } from "../../services/logout";

import './AuthenticatedLayout.scss';
import Icon, { IIconProps } from '../Icon/Icon';
import { RouteConfigValue, routesConfig } from '../../config/routes';
import PageHeader from '../PageHeader';
const { Header, Content, Sider } = Layout;
const MenuTitle = () => {
  const { name = '', role = '' } = useRecoilValue(authState);
  return (
    <div className="avatar-title">
      <div><b>ชื่อ: </b>{name}</div>
      <div><b>ตำแหน่ง: </b>{role}</div>
      <div className="line" />
    </div>
  );
}



const MenuComponent: React.FC<{}> = () => {
  const [{ token = '' }, setAuth] = useRecoilState(authState);
  const signOut = async (token: string) => {
    requestLogOut(token)
    recoilAction.removeAll();
    setAuth({});
    console.log('User signed out.');
  }
  return (
    <Menu className="avatar-menu">
      <Menu.ItemGroup key="menu" title={<MenuTitle />}>
        <Menu.Item key="logout">
          <div onClick={() => signOut(token)}>
            ออกจากระบบ
          </div>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
}

const LeftMenu = () => {
  const location = useLocation();
  const selectingRoute = React.useMemo(() => Object.values(routesConfig).find(route => route.path === location.pathname), [location]);
  return (
    <div className="container">
      <img
        src="/images/menu.svg"
        height="30"
        width="30"
        alt="menu"
      />
      <img
        src="/images/logo.svg"
        height="28"
        width="162"
        alt="logo"
      />
      <PageHeader title={selectingRoute?.pageHeader || selectingRoute?.linkText} />
    </div>
  )
};

const RightMenu = () => {
  const [{ pictureBase64 = null }] = useRecoilState(authState);
  return (
    <div className="container">
      <div className="authenticated-layout-button--check">
        <img
          src="/images/warning.svg"
          height="20"
          width="20"
          alt="warning"
        />
        แจ้งตรวจสอบ!
      </div>
      <Link to="/searchTransaction" className="authenticated-layout-button--search">
        <img
          src="/images/search.svg"
          height="20"
          width="20"
          alt="search"
        />
        ค้นหารายการ
      </Link>
      <div className="circle-button">
        <img
          src="/images/bell.svg"
          height="20"
          width="20"
          alt="bell"
        />
      </div>
      <Dropdown overlay={<MenuComponent />} placement="bottomRight" trigger={['click']} arrow>
        {pictureBase64 ?
          <Avatar className="avatar" src={`data:image/png;base64,${pictureBase64}`} /> :
          <Avatar className="avatar" icon={(
            <div className="circle-button">
              A
            </div>
          )} />
        }
      </Dropdown>
    </div>
  );
}
const NavBar = () => (
  <Header className="container-menu">
    <LeftMenu />
    <RightMenu />
  </Header>
);

const MenuIcon: React.FC<IIconProps> = (props) => {
  return <Icon name={props.name} color='white' size='22px' classNames='ant-menu-item-icon' />
}

export const AuthenticatedLayout = ({ children }: { children: React.ReactNode }) => {
  const authInfo = useRecoilValue(authInfoState);
  const { scope } = authInfo;
  const [collapsed, setCollapsed] = React.useState(false);
  const onCollapse = () => {
    setCollapsed(!collapsed);
  }

  const renderMenuItem = (routeConfig: RouteConfigValue) => {
    const icon = routeConfig.antdIcon ? <routeConfig.antdIcon style={{ fontSize: 22, color: 'white' }} /> : <MenuIcon name={routeConfig.iconName} />
    return (
      <Menu.Item key={routeConfig.path} icon={icon} >
        <Link to={routeConfig.path}>
          {routeConfig.linkText}
        </Link>
      </Menu.Item>
    )
  };

  const withPermission = (route: RouteConfigValue): boolean => {
    if (route.disabled) return false;
    return authInfo.scope[route.scope]?.enable || false;
  }

  const reportMenus = [
    routesConfig.reportTransactionAmount,
    routesConfig.reportIssue,
    routesConfig.reportDepositWithdraw,
    routesConfig.reportDepositAmount,
    routesConfig.reportTransaction,
  ].filter(withPermission)


  const transactionActionMenus = [
    routesConfig.transactionActionDeposit,
    routesConfig.transactionActionAddTransfer,
    routesConfig.transactionRealtimeTransfer,
    routesConfig.searchTransaction,
  ].filter(withPermission)

  const transactionHistoryMenus = [
    routesConfig.transactionHistoryBank,
    routesConfig.transactionHistoryTrueWallet,
    routesConfig.transactionHistoryQr,
    // routesConfig.transactionHistoryScoreLog,
  ].filter(withPermission)

  const customerMenus = [
    routesConfig.transactionHistoryGameLog,
    routesConfig.transactionHistoryBonus,
    routesConfig.ticketManagement,
    routesConfig.customerInfoList,
    routesConfig.customerInfoQuestion,
  ].filter(withPermission)

  const settingMenus = [
    routesConfig.settingBanner,
    routesConfig.settingBankAccount,
    routesConfig.settingConfig,
    routesConfig.settingPayment,
    routesConfig.settingGame,
    routesConfig.settingQuest,
    routesConfig.settingAdmin,
  ].filter(withPermission)

  const affiliateMenus = [
    routesConfig.affiliateOrgChart,
    routesConfig.affiliateOverview,
  ].filter(withPermission)

  const menuGroupClassName = classnames({
    'collapsed-title': collapsed,
  })
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <NavBar />
      <Layout>
        <Sider id="AppSideBar" width={250} collapsedWidth={72} collapsible collapsed={collapsed} onCollapse={onCollapse} breakpoint="xxl">
          <Menu
            mode="inline"
            expandIcon
            defaultSelectedKeys={['0']}
            defaultOpenKeys={['sub1']}
            className="sider-menu"
          >
            {transactionActionMenus.length > 0 && (
              <Menu.ItemGroup key="transactionAction" title="การทำรายการ" className={menuGroupClassName}>
                {transactionActionMenus.map(route => renderMenuItem(route))}
              </Menu.ItemGroup>
            )}
            {customerMenus.length > 0 && (
              <Menu.ItemGroup key="customerMenus" title="เกี่ยวกับลูกค้า" className={menuGroupClassName}>
                {customerMenus.map(route => renderMenuItem(route))}
              </Menu.ItemGroup>
            )}
            {transactionHistoryMenus.length > 0 && (
              <Menu.ItemGroup key="transactionHistory" title="ข้อมูลการเดินบัญชีรับเงิน" className={menuGroupClassName}>
                {transactionHistoryMenus.map(route => renderMenuItem(route))}
              </Menu.ItemGroup>
            )}
            {reportMenus.length > 0 && (
              <Menu.ItemGroup key="report" title="รายงาน" className={menuGroupClassName}>
                {reportMenus.map(route => renderMenuItem(route))}
              </Menu.ItemGroup>
            )}
            {affiliateMenus.length > 0 && (
              <Menu.ItemGroup key="affiliate" title="แนะนำเพื่อน" className={menuGroupClassName}>
                {affiliateMenus.map(route => renderMenuItem(route))}
              </Menu.ItemGroup>
            )}
            {settingMenus.length > 0 && (
              <Menu.ItemGroup key="setting" title="การตั้งค่าและจัดการ" className={menuGroupClassName}>
                {settingMenus.map(route => renderMenuItem(route))}
              </Menu.ItemGroup>
            )}
          </Menu>
        </Sider>
        <Layout>
          <Content className="content-layout-background">
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

// ลงเวลาฝากเงิน, ตั้งค่าธนาคาร, Search, สมัครสมาชิกลูกค้า, รายชื่อลูกค้า, ตั้งค่าเกม, gamelog, scorelog, login(for admin), register(for admin), จัดการพนักงาน(role and permission),
