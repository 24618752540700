import NiceModal from "@ebay/nice-modal-react";
import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Space, Button, TableProps } from "antd";
import { useEffect, useState, useRef } from "react";
import type { FilterConfirmProps } from 'antd/es/table/interface';
import type { FilterValue } from 'antd/es/table/interface';
import { useRecoilState } from "recoil";
import { modalState } from "src/recoils";

import {
  Tabs,
  DailyQuestCreate,
  DailyQuestEdit,
  ColumnType,
  PageContainer,
  AddButton,
  Table,
  EditDropdown,
} from "src/components";
import { DeleteQuest, QuestManagementHook, QuestManagementFilters } from "src/hooks";
import { QuestManagementAPI, QuestType } from "src/types";

export const DailyQuest = () => {
  /** starts search*/
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<Input>(null);

  type DataIndex = keyof QuestManagementAPI;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<QuestManagementAPI> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
  });
  /** end search*/

  const [, setModal] = useRecoilState(modalState);
  const { mutate: deleteQuest } = DeleteQuest();
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [filters, setFilters] = useState<QuestManagementFilters>();
  const [selectedTab, setSelectedTab] = useState<string>();

  const { data: questList, isFetching, isError, refetch: fetchQuestList } = QuestManagementHook(filters);

  const handleChange: TableProps<QuestManagementAPI>['onChange'] = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  useEffect(() => {
    setSelectedTab("any");
    setFilters({
      questType: QuestType.ANY,
    });
  }, []);

  const columns: ColumnType<QuestManagementAPI>[] = [
    {
      title: "เควส",
      dataIndex: "questName",
      width: 200,
      key: 'questName',
      ...getColumnSearchProps('questName'),
    },
    {
      title: "ประเภท",
      dataIndex: "questType",
      width: 100,
      key: 'questType',
    },
    {
      title: "เลเวล",
      dataIndex: "questLevel",
      width: 100,
      key: 'questLevel',
    },
    {
      title: "รางวัล",
      dataIndex: "reward",
      width: 100,
      key: 'reward',
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (record) => (
        <>
          {record.questLevel && (
            <EditDropdown
              size="middle"
              showEdit
              showDelete
              onEdit={() => NiceModal.show(DailyQuestEdit, {quest: record, onClose: ()=> fetchQuestList()})}
              onDelete={() => {
                setModal({
                  isModalVisible: true,
                  type: "warning",
                  title: "ลบรายการ",
                  content: "ต้องการลบเควส " + (record.gameName ? record.gameName : "") + " " + record.questName + " ใช่หรือไม่?",
                  buttonType: "question",
                  onConfirm: () => deleteQuest({questUid: record.questUid},{ onSuccess: ()=> fetchQuestList() }),
                  
                });
              }}
            />
          )}
        </>
      ),
    },
  ];


  const operations = <AddButton key="add" onClick={() => NiceModal.show(DailyQuestCreate, {
    onClose: () => fetchQuestList(),
  })}>สร้างภารกิจรายวัน</AddButton>;

  return (
    <PageContainer>
      <Row className="SearchPanel" gutter={[8, 0]}>
        <Col flex="1 1 200px">
          <div className="tab-container">
            <Tabs
              defaultActiveKey="any"
              onChange={(_tab) => {
                setSelectedTab(_tab);
                if (_tab == "any"){
                  setFilters({
                    questType: QuestType.ANY,
                  });
                } else if (_tab == "login"){
                  setFilters({
                    questType: QuestType.LOGIN,
                  });
                }
              }}
              tabBarExtraContent={operations}
            >
              <Tabs.TabPane tab="ทำ Turn Over" key="any"/>
              <Tabs.TabPane tab="Log In" key="login" />
            </Tabs>
          </div>
        </Col>
      </Row>
      {(selectedTab === "any") && (
        <Table
          style={{ whiteSpace: 'pre' }}
          rowKey="questUid"
          columns={columns}
          dataSource={isError ? [] : questList?.results}
          loading={{
            size: "large",
            spinning: isFetching,
            style: { maxHeight: "calc(100vh - 310px)" },
          }}
          pagination={{ total: questList?.total }}
          onChange={handleChange} 
        />
      )}
      {(selectedTab === "login") && (
        <Table
          style={{ whiteSpace: 'pre' }}
          rowKey="questUid"
          columns={columns}
          dataSource={isError ? [] : questList?.results}
          loading={{
            size: "large",
            spinning: isFetching,
            style: { maxHeight: "calc(100vh - 310px)" },
          }}
          pagination={{ total: questList?.total }}
        />
      )}
      
    </PageContainer>
  );
};

export default DailyQuest;
