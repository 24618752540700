import React from 'react';

// REF : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
const flattened = (arr: any[]) => [].concat(...arr);

export function autoLineBreakerWithBr(
  text: string,
  separator: string | RegExp = '\n',
): React.ReactNode[] {
  const textFragments = text.split(separator);
  return flattened(textFragments
    .map((line, index) => {
      if (index + 1 === textFragments.length) {
        // NOTE : The last line should not contain <br /> after itself.
        return line;
      }
      return [line, React.createElement('br', { key: `br${line.toString() + index.toString()}` })];
    }));
}

export function getCrossBrowserLINEUrl(rawUrl: string): string {
  const safariRegex = /^((?!chrome|android).)*safari/i;
  if (safariRegex.test(navigator.userAgent)) {
    return `${rawUrl}#~`;
  }
  return rawUrl;
}

export const markX = (text: string, _from: number, _to: number): string => {
  const from = _from;
  const to = _to >= text.length ? text.length : _to + 1;
  if (from < to) return `${text.substr(0, from)}${'X'.repeat((to - from))}${text.substr(to)}`;
  return text;
};

export function getMaskedCitizenID(rawCitizenIDText: string): string {
  if (rawCitizenIDText.length !== 13) {
    return rawCitizenIDText;
  }
  const markedCitizenID = markX(markX(rawCitizenIDText, 5, 9), 12, 13);
  const firstPieces = markedCitizenID.charAt(0);
  const secondPieces = markedCitizenID.slice(1, 5);
  const thridPieces = markedCitizenID.slice(5, 10);
  const fourthPieces = markedCitizenID.slice(10, 12);
  const lastPieces = markedCitizenID.slice(12);
  return [firstPieces, secondPieces, thridPieces, fourthPieces, lastPieces].join('-');
}

export function getMaskedLaserID(rawLaserIDText: string): string {
  const markedLaserID = markX(rawLaserIDText, 5, 9);
  const firstPieces = markedLaserID.slice(0, 5);
  const secondPieces = markedLaserID.slice(5, 10);
  const lastPieces = markedLaserID.slice(10, 13);
  return [firstPieces, secondPieces, lastPieces].join('');
}

export function getMaskedPhoneNumber(rawPhoneNumber: string): string {
  if (!rawPhoneNumber) {
    return '';
  }
  if (rawPhoneNumber.length !== 10) {
    return rawPhoneNumber;
  }
  const markedPhoneNumber = markX(rawPhoneNumber, 3, 7);
  const firstPieces = markedPhoneNumber.slice(0, 3);
  const secondPieces = markedPhoneNumber.slice(3, 6);
  const lastPieces = markedPhoneNumber.slice(6);
  return [firstPieces, secondPieces, lastPieces].join('-');
}

export function getMaskedBankAccountNumber(
  rawBankAccount: string,
  separator?: string,
): string {
  const markedPhoneNumber = markX(rawBankAccount, 3, 6);
  const firstPieces = markedPhoneNumber.slice(0, 3);
  const secondPieces = markedPhoneNumber.slice(3, 7);
  const lastPieces = markedPhoneNumber.slice(7, markedPhoneNumber.length);
  return [firstPieces, secondPieces, lastPieces].join(separator || '');
}

export function getFirstNameAndLastName(fullName: string): [string, string] {
  const trimedName = fullName.trim();
  const firstName = trimedName.split(' ', 1)[0];
  const indexOfFirstCharOfLastName = trimedName.indexOf(' ');
  if (indexOfFirstCharOfLastName === -1) {
    return [firstName, ''];
  }
  const lastName = trimedName.slice(indexOfFirstCharOfLastName + 1);
  return [firstName, lastName];
}

export function getBankFormat(rawBankNumber: string): string {
  if (rawBankNumber.length === 10) {
    const firstPieces = rawBankNumber.slice(0, 3);
    const secondPieces = rawBankNumber.slice(3, 4);
    const thirdPieces = rawBankNumber.slice(4, 9);
    const lastPieces = rawBankNumber.slice(9, rawBankNumber.length);
    return [firstPieces, secondPieces, thirdPieces, lastPieces].join('-');
  }
  if (rawBankNumber.length > 10) {
    const first = rawBankNumber.slice(0, 4);
    const second = rawBankNumber.slice(4, 5);
    const third = rawBankNumber.slice(5, rawBankNumber.length - 1);
    const fourth = rawBankNumber.slice(rawBankNumber.length - 1, rawBankNumber.length);
    return [first, second, third, fourth].join('-');
  }
  return rawBankNumber;
}

export function getPhoneFormat(rawPhoneNumber: string): string {
  if (rawPhoneNumber.length < 10) {
    return rawPhoneNumber;
  }
  const firstPieces = rawPhoneNumber.slice(0, 3);
  const secondPieces = rawPhoneNumber.slice(3, 6);
  const thirdPieces = rawPhoneNumber.slice(6, rawPhoneNumber.length);
  return [firstPieces, secondPieces, thirdPieces].join(' ');
}

export const removeTag = (rawHtml: string): string => {
  return rawHtml.replaceAll('<br />', '\n').replace(/(<([^>]+)>)/ig, '');
};


export const removeDivTag = (rawHtml: string): string => {
  return rawHtml.replace(/<div>/g, '</div>').replace(/<\/div>/g, '');
};

export const addDivTag = (rawHtml: string | undefined): string => {
  return rawHtml ? `<div>${rawHtml}</div>` : '';
};