import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import classNames from 'classnames';
import ErrorIcon from '../../../assets/logo/modal-error-icon.svg';
import WarningIcon from '../../../assets/logo/modal-warning-icon.svg';
import SuccessIcon from '../../../assets/logo/modal-success-icon.svg'
import './AlertModal.scss';
import Button from 'src/components/Button/Button';
import Modal from 'src/components/Modal/Modal';

export type AlertModalType = 'error' | 'warning' | 'success'
type AlertModalButtonType = 'confirm' | 'question'

export type AlertModalProps = {
  type: AlertModalType;
  isModalVisible: boolean;
  buttonType: AlertModalButtonType;
  title: string | JSX.Element;
  content: string | JSX.Element;
  className?: string;
  footer?: JSX.Element;
  closeable?: boolean;
  maskClosable?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  confirmText?: string;
}

const renderErrorTitle = () => (
  <div className="AlertModal--error">
    <img
      src={ErrorIcon}
      height="44"
      width="44"
      alt="errorIcon"
    />
  </div>
);

const renderWarningTitle = () => (
  <div className="AlertModal--warning">
    <img
      src={WarningIcon}
      height="44"
      width="44"
      alt="warningIcon"
    />
  </div>
)

const renderSuccessTitle = () => (
  <div className="AlertModal--success">
    <img
      src={SuccessIcon}
      height="44"
      width="44"
      alt="successIcon"
    />
  </div>
);

const renderConfirmButton = (onClick: () => void, confirmText: string) => (
  <div className="AlertModal confirm-button">
    <Button
      size="large"
      type="primary"
      style={{ width: 164 }}
      onClick={onClick}
    >
      {confirmText || 'เข้าใจแล้ว'}
    </Button>
  </div>
);

type QuestionButtonProps = {
  onCancelClick: () => void;
  onConfirmClick: () => void;
  confirmText: string;
};

const RenderQuestionButton: React.FC<QuestionButtonProps> = ({
  onCancelClick,
  onConfirmClick,
  confirmText,
}) => {
  const [isClicking, setIsClicking] = React.useState<boolean>(false);
  const handleConfirm = () => {
    if (isClicking) return;
    setIsClicking(true)
    onConfirmClick()
  }
  return (
    <div className="AlertModal question-button">
      <Button
        size="large"
        style={{ width: 164 }}
        onClick={onCancelClick}
      >
        ไม่
      </Button>
      <Button
        size="large"
        type="primary"
        style={{ width: 164 }}
        onClick={handleConfirm}
      >{confirmText || 'ใช่ ต้องการ'}
      </Button>
    </div>
  )
};

const renderHeaderTitle = {
  error: renderErrorTitle(),
  warning: renderWarningTitle(),
  success: renderSuccessTitle()
}

const AlertModal = ({
  type,
  buttonType,
  title,
  content,
  footer,
  className,
  isModalVisible,
  closeable = false,
  maskClosable = false,
  onCancel = () => { },
  onConfirm = () => { },
  confirmText = '',
}: AlertModalProps) => {

  const renderButton = {
    confirm: renderConfirmButton(onConfirm, confirmText),
    question: <RenderQuestionButton onCancelClick={onCancel} onConfirmClick={onConfirm} confirmText={confirmText} />,
  }

  const renderButtonWithContent = (
    <div>
      {footer}
      {renderButton[buttonType]}
    </div>
  )

  return (
    <>
      <Modal
        destroyOnClose
        className={classNames('AlertModal', className)}
        title={renderHeaderTitle[type]}
        visible={isModalVisible}
        onCancel={onCancel}
        closable={closeable}
        maskClosable={maskClosable}
        footer={footer ? renderButtonWithContent : null}
        centered
      >
        <div className="title">{title}</div>
        <div className="content">{content}</div>
        {!footer && renderButton[buttonType]}
      </Modal>
    </>
  );
}


export const AlertNiceModal = NiceModal.create((props: AlertModalProps) => {
  const modal = useModal();
  return (
    <AlertModal
      {...props}
      isModalVisible={modal.visible}
      onCancel={() => {
        if (props.onCancel) { props.onCancel(); }
        modal.hide();
      }}
      onConfirm={() => {
        if (props.onConfirm) { props.onConfirm(); }
        modal.hide();
      }}
    />
  );
});

export default AlertModal;
