import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Col, Divider, Modal, Row, Skeleton, Space } from "antd";
import { DateTime, ViewMoreLogs } from "src/components";
import { colors } from "src/constants";
import { RealtimeTransferHistoryItem } from "src/hooks/useRealtimeTransferHistory";
import { autoLineBreakerWithBr } from "src/utils/textOperation/textOperation";

const RealtimeTransferViewMoreModal = NiceModal.create(
  ({ logs }: RealtimeTransferHistoryItem) => {
    const { visible, hide, remove } = useModal();

    return (
      <Modal
        focusTriggerAfterClose={false}
        visible={visible}
        afterClose={remove}
        title="ข้อมูลเพิ่มเติม"
        closable={false}
        footer={null}
        width={600}
        centered
      >
        {!logs ? (
          <Skeleton active />
        ) : (
          <>
            <ViewMoreLogs logs={logs} divider />
          </>
        )}
        <Row justify="center" style={{ marginTop: "32px" }}>
          <Button type="primary" size="large" style={{ width: 150 }} onClick={hide}>
            เข้าใจแล้ว
          </Button>
        </Row>
      </Modal>
    );
  }
);

export default RealtimeTransferViewMoreModal;
