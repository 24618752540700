import React from "react";
import { Typography, Dropdown, Tooltip } from "antd";
import { DEPOSIT_STATUS, Transaction, WITHDRAW_STATUS } from "./type";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { Pill, PillStyle, SOLID } from "src/components";
import colors from 'src/theme/colors.json';

export const TransactionStatus: React.FunctionComponent<Transaction> = (props) => {
  if (props.editingBy) {
    return (
      <Typography.Text>
        {props.statusMessage}
      </Typography.Text>
    )
  }
  const getTextColor = () => {
    if ([DEPOSIT_STATUS.COMPLETED, DEPOSIT_STATUS.TRANSFER_BACK, WITHDRAW_STATUS.COMPLETED].some(_s => _s === props.status)) return 'success';
    if ([WITHDRAW_STATUS.REJECTED, DEPOSIT_STATUS.CANCELLED].some(_s => _s === props.status)) return 'danger';
    return 'warning';
  }
  return (
    <Typography.Text type={getTextColor()}>
      {props.status !== WITHDRAW_STATUS.UNCONFIRM && props.statusMessage}
      {props.status === WITHDRAW_STATUS.UNCONFIRM && (
        <Tooltip trigger={['hover']} title={(
          <>
            ธนาคารล่ม
            <br />
            กรุณาเช็คจากแอปธนาคาร
          </>
        )}>
          <span>
            <Icon name="outline-check-1" />
          </span>
          &nbsp;
          {props.statusMessage}
        </Tooltip>
      )}
    </Typography.Text>
  )
};

export type ActionColumnProps = Transaction & {
  action?: () => void;
  dropdownChildren?: JSX.Element;
};
export const ActionColumn: React.FC<ActionColumnProps> = (props) => {
  let icon = '';
  let text = '';
  if (props.status === DEPOSIT_STATUS.PENDING) {
    icon = 'outline-credit-add-1';
    text = 'เติมเงิน';
  } else if (props.status === DEPOSIT_STATUS.REJECTED) {
    icon = 'outline-notify-1';
    text = 'การคืนเงิน';
  } else if (props.status === WITHDRAW_STATUS.PENDING || props.status === WITHDRAW_STATUS.UNCONFIRM) {
    icon = 'outline-credit-remove-1';
    text = 'โอนเงิน';
  } else if (props.status === DEPOSIT_STATUS.WAITING_TRANSFER) {
    icon = 'outline-transferback-1';
    text = 'คืนเงิน';
  }
  const doesSomeoneDoing = props.editingBy !== '';
  const button =
    (
      <Button
        icon={<Icon name={icon} size="18px" />}
        type="primary"
        disabled={doesSomeoneDoing}
        onClick={() => { props.action && props.action() }}
      >
        &nbsp;{text}
      </Button>
    );
  if (props.dropdownChildren && !doesSomeoneDoing) {
    return (
      <Dropdown overlay={props.dropdownChildren} placement="bottomRight" >
        {button}
      </Dropdown>
    );
  }
  return button;
};

export type MoreDetailColumnProps = Transaction & {
  action?: () => void;
};
export const MoreDetailColumn: React.FC<MoreDetailColumnProps> = (props) => {
  return (
    <Button
      type="default"
      style={{ borderColor: '#5F6D78' }}
      onClick={() => { props.action && props.action() }}
      icon={<Icon name="outline-more-1" size="18px" color="#374550" />}
    />
  )
};

export type CancelReserveProps = {
  action?: () => void;
};

export const CancelReserve: React.FC<CancelReserveProps> = (props) => {
  return (
    <Button
      type="danger"
      icon={<Icon name="outline-close-1" size="20px" />}
      onClick={() => { props.action && props.action() }}
    />
  );
};

export type EditColumnProps = Transaction & {
  action: () => void;
};

export const EditColumn: React.FC<EditColumnProps> = (props) => {
  return (
    <Button style={{ background: '#5F6D78', color: 'white', border: '#5F6D78' }} icon={<Icon name="outline-edit-1" />} />
  );
}

export const Remark: React.FC<Transaction> = (props) => {
  const { adjustType, remark } = props;

  const withBaseStyle = (pillStyle: PillStyle): PillStyle => {
    return {
      wrapper: {
        ...pillStyle.wrapper,
        width: 120,
      },
      left: {
        ...pillStyle.left,
        color: '#FFF',
      },
      right: {
        ...pillStyle.right,
        color: '#FFF',
      },
    }
  }
  switch (adjustType) {
    case 'WRONG_SLIP_VOIDABLE':
      return (
        <>
          {remark}
          <Pill
            customStyles={withBaseStyle(SOLID({ backgroundLeft: colors["@green-6"], backgroundRight: colors["@volcano-6"], }))}
            left={(<>ตัดได้</>)}
            right={(<>ผิดสลิป</>)}
          />
        </>
      )
    case 'WRONG_SLIP_UNVOIDABLE':
      return (
        <>
          {remark}
          <Pill
            customStyles={withBaseStyle(SOLID({ backgroundLeft: colors["@cloud-6"], backgroundRight: colors["@volcano-6"] }))}
            left={(<>ตัดไม่ได้</>)}
            right={(<>ผิดสลิป</>)}
          />
        </>
      )
    case 'WRONG_USER_VOIDABLE':
      return (
        <>
          {remark}
          <Pill
            customStyles={withBaseStyle(SOLID({ backgroundLeft: colors["@green-6"], backgroundRight: colors["@yellow-6"] }))}
            left={(<>ตัดได้</>)}
            right={(<>ผิดยูส</>)}
          />
        </>
      )
    case 'WRONG_USER_UNVOIDABLE':
      return (
        <>
          {remark}
          <Pill
            customStyles={withBaseStyle(SOLID({ backgroundLeft: colors["@cloud-6"], backgroundRight: colors["@yellow-6"] }))}
            left={(<>ตัดไม่ได้</>)}
            right={(<>ผิดยูส</>)}
          />
        </>
      )
    default:
      return <>{remark}</>
  }
}
