import { Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { AffiliateTransaction, AFFILIATE_STATUS, AFFILIATE_TYPE } from 'src/hooks/affiliate';
import colors from 'src/theme/colors.json';
import { Amount } from '..';
import Icon from '../Icon/Icon';

export type AffiliateColumnProps = AffiliateTransaction & {

}

export const expriedIn = (props: AffiliateColumnProps) => props.expiredAt.diff(dayjs().subtract(1, 'day'), 'days');

export const isExpired = (props: AffiliateColumnProps): boolean => expriedIn(props) < 1;

export const AFFILIATE_COLOR_TYPE: { [key in AFFILIATE_TYPE]: string } = {
  [AFFILIATE_TYPE.FIRST_DEPOSIT]: colors['@golden-purple-6'],
  [AFFILIATE_TYPE.KYC]: colors['@orange-6'],
  [AFFILIATE_TYPE.LOSSING]: colors['@blue-7'],
  [AFFILIATE_TYPE.NONE]: '',
  [AFFILIATE_TYPE.WITHDRAW]: '',
};

export const AffiliateTypeText: React.FC<{ type: AFFILIATE_TYPE, text: string }> = (props) => {
  return (
    <Typography.Text style={{ color: AFFILIATE_COLOR_TYPE[props.type] }}>
      {props.text}
    </Typography.Text>
  );
};

export const AffiliateType: React.FC<AffiliateColumnProps> = (props) => {
  if (props.affiliateType === AFFILIATE_TYPE.WITHDRAW) {
    return (<div>-</div>);
  }
  return (
    <Typography.Text style={{ color: AFFILIATE_COLOR_TYPE[props.affiliateType] }}>
      <div
        style={{
          overflowY: 'hidden',
          height: 25,
        }}>
        <AffiliateTypeText type={props.affiliateType} text={props.affiliateTypeText || ''} />
        {!props.isAuto && (
          <Typography.Text>
            (กำหนดเอง)
          </Typography.Text>
        )}
      </div>
      {props.isAuto && props.affiliateType === AFFILIATE_TYPE.LOSSING && (
        <div>
          {isExpired(props) ? (
            <Space style={{ backgroundColor: colors['@cloud-4'], color: 'white', padding: '2px 6px', borderRadius: 2 }}>
              <Icon name="outline-info-1" />
              <div>
                <div style={{ lineHeight: 1.2 }}>หมดอายุ</div>
                <div style={{ lineHeight: 1.2 }}>{props.expiredAt.format('DD/MM/YY')}</div>
              </div>
            </Space>
          ) : (
            <Space style={{ backgroundColor: colors['@cloud-4'], color: 'white', padding: '2px 6px', borderRadius: 2 }}>
              <Icon name="outline-info-1" />
              <span>เหลือเวลา {expriedIn(props)} วัน</span>
            </Space>
          )}
        </div>
      )}
      {!props.isAuto && props.remarkType === 'อื่นๆ' && (
        <Space style={{ backgroundColor: colors['@cloud-4'], color: 'white', padding: '2px 6px', borderRadius: 2, marginTop: 4 }}>
          <Icon name="outline-info-1" />
          <div style={{ textAlign: 'left' }}>{props.remarkType}</div>
        </Space>
      )}
    </Typography.Text>
  )
};

export const AffiliateStatus: React.FC<AffiliateColumnProps> = (props) => {
  const { affiliateType, status, statusText } = props;
  const getStyle = (): React.CSSProperties => {
    if ((affiliateType === AFFILIATE_TYPE.WITHDRAW && status === AFFILIATE_STATUS.REJECTED) ||
      (affiliateType !== AFFILIATE_TYPE.WITHDRAW && status === AFFILIATE_STATUS.DELETED)) {
      return { color: colors['@red-6'] };
    }
    if ((affiliateType === AFFILIATE_TYPE.LOSSING && isExpired(props)) ||
      (status === AFFILIATE_STATUS.PENDING)) {
      return { color: colors['@gray-9'] };
    }
    return { color: colors['@green-7'] };
  }

  return (
    <Typography.Text style={getStyle()}>
      {statusText}
    </Typography.Text>
  )
};

export const AffiliateAmount: React.FC<AffiliateColumnProps> = (props) => {
  const isAffiliateWithdraw = props.affiliateType === AFFILIATE_TYPE.WITHDRAW;
  return <Amount value={isAffiliateWithdraw ? props.amount * -1 : props.amount} color={isAffiliateWithdraw ? colors['@red-6'] : undefined} />;
};

export const AffiliateIncome: React.FC<AffiliateColumnProps> = (props) => {
  const { income } = props;
  if (!income) {
    return <Amount value={income} />
  }
  return (
    <Typography.Text style={{ color: colors['@green-6'] }}>
      <Amount value={income} color={colors['@green-6']} prefix="+" />
    </Typography.Text>
  );
};