import * as React from 'react';
import classnames from 'classnames';
import AntdButton, { ButtonProps as AntButtonProps } from 'antd-button-color';
import './Button.less';

export type ButtonProps = AntButtonProps & {
};

const Button: React.FunctionComponent<ButtonProps> = (props) => {
  return (
    <AntdButton
      {...props}
      onClick={(e) => {
        props.onClick && props.onClick(e)
        e.currentTarget.blur()
      }}
      className={classnames(
        'backoffice-button',
        props.className,
      )}
    >
      {props.children}
    </AntdButton>
  );
};

export default Button;
