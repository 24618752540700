import * as React from 'react';
import { BankAccountStatus, CustomerStatus } from 'src/types';
import colors from 'src/theme/colors.json';
import Icon from '../Icon/Icon';
import { Space } from 'antd';
import { mapToCustomerText } from 'src/mapper/mapToCustomer';

interface IStatusProps {
  type: BankAccountStatus | CustomerStatus;
}

export type IconStatusProps = {
  type: 'active' | 'disabled' | 'warning' | 'stop';
};

export const IconStatus2: React.FC<IconStatusProps> = (props) => {
  switch (props.type) {
    case 'active':
      return <Icon name='outline-active-1' color={colors['@green-6']} size="18px" />;
    case 'disabled':
      return <Icon name='outline-active-1' color={colors['@red-5']} size="18px" />;
    case 'warning':
      return <Icon name='outline-warning-1' color={colors['@yellow-6']} size="18px" />;
    case 'stop':
      return <Icon name='outline-stop-1' color={colors['@red-5']} size="18px" />;
    default:
      return null;
  }
};

export const IconStatus: React.FC<IStatusProps> = (props) => {
  switch (props.type) {
    case 'Active':
    case CustomerStatus.ACTIVE:
      return <Icon name='outline-active-1' color={colors['@green-6']} size="18px" />;
    case CustomerStatus.DISABLED:
      return <Icon name='outline-active-1' color={colors['@red-5']} size="18px" />;
    case CustomerStatus.BLOCKED:
    case 'OverTransaction':
      return <Icon name='outline-warning-1' color={colors['@yellow-6']} size="18px" />;
    case 'Closed':
      return <Icon name='outline-stop-1' color={colors['@red-5']} size="18px" />;
    default:
      return null;
  }
};

const Status: React.FunctionComponent<IStatusProps> = (props) => {
  const { type } = props;
  switch (type) {
    case 'Active':
    case CustomerStatus.ACTIVE:
      return (
        <>
          <IconStatus {...props} />
          &nbsp;เปิดใช้งาน
        </>
      )

    case CustomerStatus.BLOCKED:
      return (
        <>
          <IconStatus {...props} />
          &nbsp;ถูกล็อค PIN
        </>
      )
    case CustomerStatus.DISABLED:
      return (
        <>
          <IconStatus {...props} />
          &nbsp;ถูกระงับใช้งาน
        </>
      )
    case 'Closed':
      return (
        <>
          <IconStatus {...props} />
          &nbsp;พักใช้งาน
        </>
      )
    case 'OverTransaction':
      return (
        <>
          <IconStatus {...props} />
          &nbsp;มียอดเกินกำหนด
        </>
      )
    case 'Banned':
      return (
        <>
          ถูกธนาคารระงับใช้งาน
        </>
      )
    case 'Terminated':
      return (
        <>
          สาเหตุอื่นๆ
        </>
      )
    default:
      return null;
  }
};

export const IsAutoStatus: React.FC<{ enabled: boolean }> = ({ enabled }) => {
  if (enabled) {
    return (
      <>
        <Icon name='outline-active-1' color={colors['@green-6']} size="18px" />
        &nbsp;เปิด
      </>
    )
  }
  return (
    <>
      <Icon name='outline-stop-1' color={colors['@red-5']} size="18px" />
      &nbsp;ปิด
    </>
  )
};


export const CustomerStatusMessage: React.FC<{ status: CustomerStatus }> = (props) => {
  return (
    <Space>
      <IconStatus type={props.status} />
      {mapToCustomerText(props.status)}
    </Space>
  )
}

export default Status;
