import { AxiosRequestConfig } from "axios";
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse } from "../service.type";

export type RegisterAdminRequest = {
  email: string;
  personal_number: string;
  role_uuid: string;
}

export type RegisterAdminResponse = BaseResponse

export const registerAdmin = async (request: RegisterAdminRequest, token: string = '') => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: 'v1/users/register',
      method: 'POST',
      data: request,
      headers: { token }
    };

    const result = await fetchWithJSON<RegisterAdminResponse>(pigspinBackofficeApiInstance, requestConfig);
    return result.data
  } catch (error) {
    return null;
  }
}
