import { AxiosRequestConfig } from 'axios';
import { BankCode } from 'src/types';
import { pigspinBackofficeApiInstance } from '../../utils/api/AxiosInstance';
import { fetchWithJSON } from '../../utils/api/fetch';
import { BaseResponse2 } from '../service.type';

export type SearchUserInfoRequest = {
  token: string;
  value: string
}

export type UserInfo = {
  customer_uid: string;
  customer_code: string;
  phone_number: string;
  bank: {
    bank_code: BankCode;
    bank_account_name: string;
    bank_account_number: string;
  },
  last_deposit_datetime: Date;
  last_deposit_amount: number;
  last_withdraw_datetime: Date;
  last_withdraw_amount: number;
  current_balance: number;
  user_cash_affiliate_balance?: number;
}

export type SearchUserInfoResponse = BaseResponse2<UserInfo>;

export const searchUserInfo = async (payload: SearchUserInfoRequest): Promise<SearchUserInfoResponse | null> => {
  const params = new URLSearchParams({
    text_search: `${payload.value}`
  })
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/payment/deposit/search-customer?${params.toString()}`,
      method: 'GET',
      headers: { token: payload.token },
    };

    const result = await fetchWithJSON<SearchUserInfoResponse>(pigspinBackofficeApiInstance, requestConfig);
    return result
  }
  catch (error) {
    return null
  }
};