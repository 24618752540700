import { useQuery, UseQueryOptions } from "react-query";
import { PolymorphicTransactionWithLogs } from "src/types";
import { api } from "src/utils";

export function useTransaction<T = PolymorphicTransactionWithLogs>(
  uid: string,
  options?: UseQueryOptions<PolymorphicTransactionWithLogs, unknown, T, any[]>
) {
  return useQuery(
    ["transactions", uid],
    async () => {
      const response = await api.get(`payment/transactions/${uid}`);
      return response.data.data;
    },
    options
  );
}
