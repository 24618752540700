import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authInfoState, modalState } from 'src/recoils';
import { Modal, Form, Input } from 'antd';
import './CustomerConfirmDeleteModal.scss';
import Button from 'src/components/Button/Button';
import { CustomerRow } from 'src/types';
import { DeleteCustomer } from 'src/services/customer/customer';

export type CustomerConfirmDeleteModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (value: boolean) => void;
  editingCustomer?: CustomerRow;
}

const CustomerConfirmDeleteModal = ({
    open,
    onClose,
    onConfirm,
    editingCustomer,
  }: CustomerConfirmDeleteModalProps) => {

  const { token } = useRecoilValue(authInfoState);
  const [isValidForm, setIsValidForm] = React.useState<boolean>(false);
  const [ form ] = Form.useForm();
  const onSubmit = async (values: any) => {
    let returnStatus = false
    const requestBody = {
      token: token,
      user_code: editingCustomer?.customerId,
    }
    const isEmailAdminSuccess = await DeleteCustomer(requestBody)
    if (isEmailAdminSuccess !== null) {
      returnStatus = true
    }
    onConfirm(returnStatus)
  }

  return (
    <Modal
      title={'ใส่เบอร์โทรศัพท์ลูกค้าเพื่อยืนยันการลบบัญชี'}
      visible={open}
      footer={(
          <>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              disabled={!isValidForm}
              style={{ backgroundColor: 'red', borderColor: '#d9d9d9', width: 160}}
              onClick={onSubmit}
            >ยืนยันลบบัญชี</Button>
            <Button size="large" onClick={() => {form.resetFields(); onClose(); setIsValidForm(false)}} style={{ width: 100 }}>ยกเลิก</Button>
          </>
        )}
      closable={false}
      wrapClassName="delete-customer-modal"
      centered
      destroyOnClose={true}
    >
      <div className="content-wrapper">
        <Form
          className="form-register"
          // onFinish={onSubmit}
          autoComplete="off"
          layout="vertical"
          form={form}
          requiredMark={false}
        >
          <div className="form-wrapper">
            <Form.Item
              label=''
              name='phone'
              rules={[
                {
                  async validator(_,value) {
                    if (!value) {
                      setIsValidForm(false)
                      return Promise.reject('กรุณาใส่เบอร์โทรศัพท์')
                    }
                    if (value == editingCustomer?.phone) {
                      setIsValidForm(true)
                      return Promise.resolve()
                    } else {
                      setIsValidForm(false)
                      return Promise.reject('เบอร์โทรศัพท์ไม่ถูกต้อง')
                    }
                  }
                },
              ]}
              validateTrigger="onChange"
              shouldUpdate={true}
            >
              <Input placeholder="เบอร์โทรศัพท์" />
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CustomerConfirmDeleteModal;
