import * as React from "react"
import { BaseIconProps } from "./type"


const Check: React.FC<BaseIconProps> = (props: BaseIconProps) => {
  const { size = 24 } = props;
  return (
    <svg
      style={{ width: `${size}px`, height: `${size}px` }}
      viewBox={`0 0 48 48`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={23.5} cy={24.5} r={18.5} fill="#fff" />
      <path
        d="M24 2C11.87 2 2 11.87 2 24s9.87 22 22 22 22-9.87 22-22S36.13 2 24 2Zm12.296 16.21-14.06 13.95a2.163 2.163 0 0 1-3.033.056l-7.444-6.782a2.236 2.236 0 0 1-.165-3.088 2.181 2.181 0 0 1 3.088-.11l5.9 5.403 12.57-12.571a2.197 2.197 0 0 1 3.144 0c.882.882.882 2.26 0 3.143Z"
        fill="url(#icon_check)"
      />
      <defs>
        <linearGradient
          id="icon_check"
          x1={46}
          y1={2}
          x2={2}
          y2={46}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18C889" />
          <stop offset={1} stopColor="#0AA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Check
