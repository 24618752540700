import { useMutation, UseMutationOptions } from "react-query";
import { toast } from "src/components";
import { api } from "src/utils";

export function useDeleteTransaction(
  options?: UseMutationOptions<unknown, unknown, string, unknown>
) {
  return useMutation(
    (uid: string) => api.delete("payment/deposit/transaction", { data: { transactionUid: uid } }),
    {
      onError: () => toast.error("ลบรายการไม่สำเร็จ"),
      onSuccess: () => toast.success("ลบรายการสำเร็จ"),
      ...options,
    }
  );
}
