import { AxiosRequestConfig } from 'axios';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse } from "../service.type";

export type CreateRefundTransaction = {
  token: string;
  bank_code: string;
  bank_account_number: string;
  bank_account_name: string;
  transaction_uid: string;
};

export type CreateRefundTransactionResponse = BaseResponse & {
  data: CreateRefundTransaction;
};

export const requestCreateRefundTransaction = async (request: CreateRefundTransaction): Promise<BaseResponse | null> => {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/payment/deposit/request-refund-transactions',
    method: 'POST',
    headers: {
      token: request.token,
    },
    data: request,
  };
  return fetchWithJSON<CreateRefundTransactionResponse>(pigspinBackofficeApiInstance, requestConfig);
};
