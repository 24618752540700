import { Button, ButtonProps } from "src/components";
import { OutlineSearch2 } from "src/icons";

export interface SearchButtonProps extends Omit<ButtonProps, "type" | "icon"> {}

function SearchButton(props: SearchButtonProps) {
  return (
    <Button {...props} type="primary" icon={<OutlineSearch2 style={{ fontSize: "22px" }} />} />
  );
}

export default SearchButton;
