import { Col, Divider, Form, Input, Row, Space, TableColumnsType, TableProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import NiceModal from "@ebay/nice-modal-react";
import HtmlParser from 'react-html-parser';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddButton, Amount, Button, DatePicker, DateTime, EditDropdown, SearchButton, Select, Statistic, Table, Tabs, ViewMoreLogs, ViewMoreButton, TransactionViewMoreModal } from 'src/components';
import { AffiliateAmount, AffiliateIncome, AffiliateStatus, AffiliateType, AffiliateTypeText, AFFILIATE_COLOR_TYPE } from 'src/components/Affiliate/Columns';
import DeskPanel from 'src/components/DeskPanel/DesktPanel';
import RadioButton from 'src/components/RadioButton/RadioButton';
import {
  useAffiliate,
  AffiliateTransaction,
  MonthlyTransaction,
  AFFILIATE_TYPE,
  mapToAffiliateTypeText,
  AFFILIATE_STATUS,
} from 'src/hooks/affiliate';
import { getFilterOptions } from 'src/utils/getFilterOptions';
import { numberWithCommas, numberWithoutCommas } from 'src/utils/number/number';
import colors from 'src/theme/colors.json';
import './affiliate.scss';
import { getColumnFilters } from 'src/utils/table';
import { ColumnGroupType, ColumnType, FilterValue, SorterResult } from 'antd/lib/table/interface';
import { CommonNiceModal } from 'src/components/Modal/Modal';
import AddAffiliateForm, { AffiliateForm } from 'src/components/Affiliate/AddAffiliateForm';
import { useRecoilState } from 'recoil';
import { affiliateStateAtom, modalState } from 'src/recoils';
import CopyableMessageBox from 'src/components/CopyableMessageBox/CopyableMessageBox';
import { removeTag } from 'src/utils/textOperation/textOperation';
import { AffiliateTransactionType } from 'src/hooks/affiliate/useAffiliate';
import { AlertNiceModal } from 'src/components/Modals';

interface IAffiliateOverviewProps {
}

const dividerStyle: React.CSSProperties = {
  borderColor: 'white',
  opacity: 0.5,
};

export type FormType = {
  textSearch: string;
  status?: string;
  datetimeRange: Dayjs[];
};

interface PaginationTypes {
  limit: number;
  current: number;
}

const initialPagination: PaginationTypes = {
  limit: 10,
  current: 1,
};

const KYC_BONUS = 50;

const injectTag = (rawHtml: string) => {
  return HtmlParser(rawHtml, {
    transform: (node) => {
      if (node.type !== 'tag') return;
      if (node.name === 'br') {
        return <br />
      }
      const color: any = {
        yellow: AFFILIATE_COLOR_TYPE.VERIFY_KYC,
        li_yellow: AFFILIATE_COLOR_TYPE.VERIFY_KYC,
        purple: AFFILIATE_COLOR_TYPE.FIRST_DEPOSIT,
        li_purple: AFFILIATE_COLOR_TYPE.FIRST_DEPOSIT,
        blue: AFFILIATE_COLOR_TYPE.REACH_TARGET,
        li_blue: AFFILIATE_COLOR_TYPE.REACH_TARGET,
        red: colors['@red-6'],
      };
      if (node.name === 'li_yellow' || node.name === 'li_purple' || node.name === 'li_blue') {
        return (
          <li style={{ color: color[node.name] }}>
            {node?.children?.map((i: any) => (<div>{i.data}</div>))}
          </li>
        )
      }
      if (node.name === 'yellow' || node.name === 'purple' || node.name === 'blue' || node.name === 'red') {
        return (
          <strong style={{ color: color[node.name] }}>
            {node?.children?.map((i: any) => i.data)}
          </strong>
        );
      }

    }
  })
}

const getRawHtmlMessageToCustomer = (affiliate: AffiliateTransaction): string => {
  const { downlineUserCode, affiliateType, createdAt, income, amount, status, isAuto } = affiliate;
  const date = `${createdAt.format('DD/MM/YYYY')} : ${createdAt.format('HH:mm')}`;
  const _income = numberWithCommas(income, 2);
  const _amount = numberWithCommas(amount, 2);
  const messageToCustomer: any = {
    [AFFILIATE_TYPE.KYC]: `รายได้จากการ <yellow>ยืนยันตัวตน</yellow><br />ของรหัสสมาชิก ${downlineUserCode} (ผู้ถูกแนะนำ)<br />วันที่ ${date}<br />จำนวน ${numberWithCommas(KYC_BONUS, 2)} บาท <yellow>(โบนัส)</yellow>`,
    [AFFILIATE_TYPE.LOSSING]: `รายได้จากการ <blue>ยอดเสีย</blue><br />ของรหัสสมาชิก ${downlineUserCode} (ผู้ถูกแนะนำ)<br />วันที่ ${date}<br />จำนวน ${_income} บาท`,
    [AFFILIATE_TYPE.FIRST_DEPOSIT]: `รายได้จากการ <purple>เพื่อนทำเทิร์นเงินสดครบ</purple><br />ของรหัสสมาชิก ${downlineUserCode} (ผู้ถูกแนะนำ)<br />วันที่ ${date}<br />จำนวน ${_income} บาท <purple>(โบนัส)</purple>`,
  }
  const rules: any = {
    [AFFILIATE_TYPE.KYC]: `<br /><br /><yellow>กติกา</yellow><ul><li_yellow>ผู้ถูกแนะนำยืนยันตัวตนสำเร็จ</li_yellow><li_yellow>ได้รับโบนัส ${KYC_BONUS} บาท ทำเทิร์น 40 เท่า ถอนได้สูงสุด 50 บาท</li_yellow></ul>`,
    [AFFILIATE_TYPE.LOSSING]: `<br /><br /><blue>กติกา</blue><ul><li_blue>ได้รับเงินสด 10% ของยอดเสียของผู้ถูกแนะนำเป็นเวลา 90 วัน<br />(10% x (${_amount}) = ${_income} บาท)</li_blue></ul>`,
    [AFFILIATE_TYPE.FIRST_DEPOSIT]: `<br /><br /><purple>กติกา</purple><ul><li_purple>ผู้แนะนำทำเทิร์นครบ 40 เท่าของยอดโบนัส ${_income} บาท ถอนได้สูงสุด 50 บาท</li_purple></ul>`,
  }
  const withRules = (rawText: string, key: string) => {
    if (isAuto) return `${rawText}${rules[key]}`
    return rawText;
  };
  const withPrefix = (rawText: string) => {
    const prefix = (status === AFFILIATE_STATUS.DELETED) ? '<red>โดนยกเลิก</red> ' : 'ได้รับ';
    return `${prefix}${rawText}`;
  };
  return withRules(withPrefix(messageToCustomer[affiliateType]), affiliateType);
};

const AffiliateOverview: React.FunctionComponent<IAffiliateOverviewProps> = (props) => {
  const defaultFrom = dayjs().startOf('date');
  const defaultTo = dayjs().endOf('date');
  const [addForm] = Form.useForm<AffiliateForm>();
  const [, setModal] = useRecoilState(modalState);
  const [affiliateState, setAffiliateState] = useRecoilState(affiliateStateAtom);
  const { search } = useLocation();
  const navigate = useNavigate()
  const [form] = Form.useForm<FormType>();
  const params = React.useMemo(() => new URLSearchParams(search), [search]);
  const userCode = React.useMemo(() => params.get('userCode') || '', [params.get('userCode')]);
  const {
    affilateTransactions,
    monthlyTransactions,
    summary,
    isTransactionLoading,
    getStatisticSummary,
    getAffiliateTransactions,
    createAffiliateTransaction,
    isCreatingTransaction,
    getAffiliateMonthlyTransactions,
    deleteAffiliateTransaction,
  } = useAffiliate({
    userCode,
    from: dayjs(params.get('from') || defaultFrom),
    to: dayjs(params.get('to') || defaultTo),
    affiliateType: params.get('status') === 'ALL' ? undefined : params.get('status') as AffiliateTransactionType,
  });
  const [selectedAffiliateType, setSelectedAffiliateType] = React.useState<AFFILIATE_TYPE | 'all'>('all');
  const [selectedTab, setSelectedTab] = React.useState('overview');
  const [pagination, setPagination] = React.useState<PaginationTypes>(initialPagination);
  const [filteredInfo, setFilteredInfo] = React.useState<Record<string, FilterValue | null>>({});
  const [sortedInfo, setSortedInfo] = React.useState<SorterResult<AffiliateTransaction>>({});

  const filteredAffiliateTransactions = React.useMemo(() => {
    return affilateTransactions.filter(i => (selectedAffiliateType === 'all' || i.affiliateType === selectedAffiliateType))
  }, [selectedAffiliateType, affilateTransactions])

  const filterColumn = (key: keyof AffiliateTransaction): ColumnGroupType<AffiliateTransaction> | ColumnType<AffiliateTransaction> => ({
    key,
    dataIndex: key,
    filteredValue: filteredInfo[key] || null,
    filters: getColumnFilters(key, filteredAffiliateTransactions),
    filterSearch: true,
    onFilter: (value, record) => record[key] === value,
  })

  const overviewColumns: TableColumnsType<AffiliateTransaction> = [
    {
      title: (
        <>
          <div>วันที่</div>
          <div>ทำรายการ</div>
        </>
      ),
      width: 116,
      dataIndex: 'createdAt',
      render: (value) => <DateTime value={value} />,
      sorter: (a, b) => b.createdAt.diff(a.createdAt),
      sortOrder: sortedInfo.field === 'createdAt' ? sortedInfo.order : null,
    },
    {
      title: (
        <>
          <div>ประเภท</div>
          <div>รายการ</div>
        </>
      ),
      width: 100,
      ...filterColumn('typeText'),
    },
    {
      title: (
        <>
          <div>รหัสสมาชิก</div>
          <div>(ดาวน์ไลน์)</div>
        </>
      ),
      width: 120,
      ...filterColumn('downlineUserCode'),
    },
    {
      title: 'ที่มา',
      dataIndex: 'affiliateTypeText',
      width: 155,
      ...filterColumn('affiliateTypeText'),
      render: (value, row) => {
        return (
          <div style={{ textAlign: 'right' }}>
            <AffiliateType {...row} />
          </div>
        );
      }
    },
    {
      title: 'สถานะ',
      width: 113,
      ...filterColumn('statusText'),
      render: (value, record) => {
        return (
          <div style={{ textAlign: 'right' }}>
            <AffiliateStatus {...record} />
          </div>
        );
      },
    },
    {
      title: (
        <>
          <div>ยอดทำรายการ</div>
          <div>(THB)</div>
        </>
      ),
      dataIndex: 'amount',
      width: 102,
      render: (value, record) => <AffiliateAmount {...record} />
    },
    {
      title: (
        <>
          <div>เทิร์นที่ต้องทำ</div>
          <div>(THB)</div>
        </>
      ),
      dataIndex: 'turnoverAmount',
      width: 102,
      render: (value) => <Amount value={value} />
    },
    {
      title: (
        <>
          <div>เทิร์นที่ทำแล้ว</div>
          <div>(THB)</div>
        </>
      ),
      dataIndex: 'turnovered',
      width: 102,
      render: (value) => <Amount value={value} />
    },
    {
      title: (
        <>
          <div>รายได้จาก</div>
          <div>โปรโมชั่น(THB)</div>
        </>
      ),
      dataIndex: 'income',
      width: 110,
      render: (value, record) => <AffiliateIncome {...record} />
    },
    {
      title: (
        <>
          <div>ยอดคงเหลือ</div>
          <div>(THB)</div>
        </>
      ),
      dataIndex: 'balance',
      width: 102,
      render: (value) => <Amount allowZero value={value} />
    },
    {
      title: (
        <>
          <div>วันที่</div>
          <div>อัพเดตล่าสุด</div>
        </>
      ),
      dataIndex: 'latestActionAt',
      width: 116,
      render: (value) => <DateTime value={value} />,
      sorter: (a, b) => b.latestActionAt.diff(a.latestActionAt),
      sortOrder: sortedInfo.field === 'latestActionAt' ? sortedInfo.order : null,
    },
    {
      title: '',
      dataIndex: 'logs',
      key: 'logs',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (logs, record) => {
        if (record.affiliateType === AFFILIATE_TYPE.WITHDRAW) {
          return (
            <ViewMoreButton
              size="small"
              onClick={() =>
                NiceModal.show(TransactionViewMoreModal, {
                  transactionUid: record.uid,
                })
              }
            />
          )
        }
        return (
          <Space>
            <ViewMoreButton
              size="small"
              onClick={() => {
                const messageToCustomer = getRawHtmlMessageToCustomer(record);
                NiceModal.show(CommonNiceModal, {
                  centered: true,
                  title: 'ข้อมูลเพิ่มเติม',
                  okText: 'เพิ่มรายได้',
                  footer: null,
                  children: (
                    <div style={{ maxWidth: 500, margin: 'auto' }}>
                      <ViewMoreLogs
                        divider
                        logs={record.logs}
                        render={(log) => {
                          return (
                            <>
                              {HtmlParser(log.remark, {
                                transform: (node) => {
                                  if (node.type !== 'tag') return;
                                  if (node.name === 'br') {
                                    return <br />
                                  }
                                  if (node.name === 'yellow' || node.name === 'purple' || node.name === 'blue' || node.name === 'red') {
                                    const color: any = {
                                      yellow: AFFILIATE_COLOR_TYPE.VERIFY_KYC,
                                      purple: AFFILIATE_COLOR_TYPE.FIRST_DEPOSIT,
                                      blue: AFFILIATE_COLOR_TYPE.REACH_TARGET,
                                      red: colors['@red-6'],
                                    };
                                    return (
                                      <strong style={{ color: color[node.name] }}>
                                        {node?.children?.map((i: any) => i.data)}
                                      </strong>
                                    );
                                  }

                                }
                              })}
                            </>
                          )
                        }}
                      />
                      <CopyableMessageBox
                        topic="ข้อความแจ้งลูกค้า"
                        message={removeTag(messageToCustomer)}
                        render={(_message) => {
                          return (
                            <div>
                              {injectTag(messageToCustomer)}
                            </div>
                          );
                        }}
                      />
                      <div style={{ textAlign: 'center', marginTop: 16 }}>
                        <Button size="large" type="primary" style={{ minWidth: 150 }} onClick={() => { NiceModal.hide(CommonNiceModal) }}>
                          เข้าใจแล้ว
                        </Button>
                      </div>
                    </div>
                  ),
                })
              }}
            />
          </Space>
        )
      },
    },
    {
      title: '',
      dataIndex: 'logs',
      key: 'logs',
      align: 'center',
      fixed: 'right',
      width: 50,
      render: (logs, record) => {
        if ((record.affiliateType === AFFILIATE_TYPE.WITHDRAW) ||
          (record.status === AFFILIATE_STATUS.DELETED)) return;
        return (
          <Space>
            <EditDropdown
              size="small"
              deleteText="ยกเลิกรายได้"
              showEdit={false}
              showDelete
              onDelete={() => {
                setModal({
                  isModalVisible: true,
                  type: 'warning',
                  title: 'ยกเลิกรายได้',
                  content: 'ต้องการยกเลิกรายได้ใช่หรือไม่',
                  buttonType: 'question',
                  onConfirm: () => {
                    deleteAffiliateTransaction(record.uid);
                  }
                })
              }}
            />
          </Space>
        )
      },
    }
  ];

  const monthlyColumns: TableColumnsType<MonthlyTransaction> = [
    {
      title: 'เดือน',
      dataIndex: 'month',
      key: 'month',
      render: (value: Dayjs) => (<>{value.format('MM/YYYY')}</>),
      sorter: (a, b) => b.month.diff(a.month),
    },
    {
      title: 'ยอดรวม (THB)',
      dataIndex: 'summary',
      key: 'summary',
      render: (value) => <Amount allowZero value={value} />,
      sorter: (a, b) => b.summary - a.summary,
    },
    {
      title: 'ที่มา',
      dataIndex: 'summary',
      key: 'affiliateType',
      className: 'subTable',
      render: (value, row) => {
        return (
          <table>
            <tr><td><AffiliateTypeText type={AFFILIATE_TYPE.KYC} text="ยืนยันตัวตน" /></td></tr>
            <tr><td><AffiliateTypeText type={AFFILIATE_TYPE.FIRST_DEPOSIT} text="เพื่อนทำเทิร์นเงินสดครบ" /></td></tr>
            <tr><td><AffiliateTypeText type={AFFILIATE_TYPE.LOSSING} text="ยอดเสีย" /></td></tr>
          </table>
        )
      }
    },
    {
      title: 'รายได้จากโปรโมชั่น (THB)',
      dataIndex: 'summary',
      key: 'income',
      className: 'subTable',
      render: (value, row) => {
        return (
          <table>
            <tr><td><Amount allowZero value={row.kyc} /></td></tr>
            <tr><td><Amount allowZero value={row.firstDeposit} /></td></tr>
            <tr><td><Amount allowZero value={row.lossing} /></td></tr>
          </table>
        )
      }
    },
  ];

  const handleMenuFilter = (value: string) => {
    setSelectedAffiliateType(value as AFFILIATE_TYPE | 'all');
  };

  const clearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    handleMenuFilter('all');
  }
  const handleChange: TableProps<AffiliateTransaction>['onChange'] = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<AffiliateTransaction>);
  };

  const initialForm = () => {
    form.setFieldsValue({
      textSearch: userCode,
      status: params.get('status') || 'ALL',
      datetimeRange: [
        params.get('from') ? dayjs(params.get('from')) : defaultFrom,
        params.get('to') ? dayjs(params.get('to')) : defaultTo,
      ],
    });
  }

  const getTransactionData = () => {
    if (selectedTab === 'overview') { getAffiliateTransactions(); }
    if (selectedTab === 'monthly') { getAffiliateMonthlyTransactions(); }
  }

  React.useEffect(() => {
    initialForm();
    getStatisticSummary();
    getTransactionData();
  }, [params]);

  React.useEffect(() => {
    getTransactionData();
  }, [selectedTab]);

  React.useEffect(() => {
    setAffiliateState({ isAffiliateMode: true })
    return () => {
      setAffiliateState({ isAffiliateMode: false })
    }
  }, [])

  return (
    <div id="AffiliateOverview">
      {userCode.length > 0 && (
        <>
          <Row gutter={[8, 0]}>
            <Col flex="1 1 200px">
            </Col>
            <Col flex="0 1 160px" style={{ textAlign: 'right' }}>
              <AddButton
                key="add-affiliate"
                disabled={userCode.length < 1}
                onClick={() => {
                  NiceModal.show(CommonNiceModal, {
                    centered: true,
                    title: 'เพิ่มรายได้',
                    okText: 'เพิ่มรายได้',
                    children: (
                      <div style={{ maxWidth: 330, margin: 'auto' }}>
                        <AddAffiliateForm form={addForm} isLoading={isCreatingTransaction} />
                      </div>
                    ),
                    onCancel: () => {
                      addForm.resetFields();
                    },
                    onOk: async () => {
                      try {
                        const result = await addForm.validateFields();
                        NiceModal.show(AlertNiceModal, {
                          type: 'warning',
                          isModalVisible: true,
                          title: 'เพิ่มรายการรายได้',
                          content: 'ต้องการเพิ่มรายการรายได้ใช่หรือไม่',
                          buttonType: 'question',
                          onConfirm: async () => {
                            const _addForm = addForm.getFieldsValue();
                            await createAffiliateTransaction({
                              affiliateType: _addForm.affiliateType,
                              amount: 0,
                              turnover: numberWithoutCommas(_addForm.turnOver || ''),
                              incomeAmount: numberWithoutCommas(_addForm.amount || ''),
                              upline: userCode,
                              downline: _addForm.userCode,
                              remarkType: _addForm.remarkType,
                              remark: _addForm.remark || '',
                            });
                            addForm.resetFields();
                          },
                        });
                      } catch {

                      }
                    }
                  })
                }}
              >
                เพิ่มรายได้
              </AddButton>
            </Col>
          </Row>
          <br />
        </>
      )}
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={(form) => {
            const { textSearch, status, datetimeRange } = form;
            const ignoreSearch = (
              (selectedTab === 'overview' && (!textSearch || !datetimeRange)) ||
              (selectedTab === 'monthly' && (!datetimeRange))
            );
            if (ignoreSearch) return;
            const [from, to] = datetimeRange;
            const isSameUserCode = textSearch === userCode;
            const isSameStatus = status === params.get('status');
            const isSameFromDate = dayjs(params.get('from')).isSame(from);
            const isSameToDate = dayjs(params.get('to')).isSame(to);
            const shouldRefetch =
              (selectedTab === 'monthly' && isSameUserCode && isSameFromDate && isSameToDate) ||
              (isSameUserCode && isSameFromDate && isSameToDate && isSameStatus)
              ;
            if (shouldRefetch) {
              getStatisticSummary();
              getTransactionData();
            }

            const queryParams = new URLSearchParams();
            queryParams.append('userCode', textSearch);
            if (status) queryParams.append('status', status);
            const withMode = (date: dayjs.Dayjs, mode?: string): dayjs.Dayjs => mode === 'monthly' ? date.startOf('month') : date;
            queryParams.append('from', withMode(from, selectedTab).format())
            queryParams.append('to', withMode(to, selectedTab).format())
            navigate(`?${queryParams.toString()}`);
          }}>
          <Row gutter={[16, 16]}>
            <Col flex="auto">
              <Form.Item name="textSearch" label="รหัสหรือเบอร์โทร">
                <Input size="large" placeholder="ใส่รหัสหรือเบอร์โทร" />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item name="status" label="การทำรายการ">
                <Select width='100%' disabled={selectedTab === 'monthly'}>
                  <Select.Option value={'ALL'}>ทุกรายการ</Select.Option>
                  <Select.Option value={'DEBIT'}>รายได้</Select.Option>
                  <Select.Option value={'CREDIT'}>ถอนเงิน</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="datetimeRange"
                label={
                  <Row style={{ width: "340px" }}>
                    <Col span={12}>{"วันเวลาเริ่มต้น"}</Col>
                    <Col span={12}>{"วันเวลาสิ้นสุด"}</Col>
                  </Row>
                }
              >
                <DatePicker.RangePicker
                  picker={selectedTab === 'monthly' ? 'month' : 'date'}
                  size="large"
                  allowClear={false}
                  format="DD/MM/YYYY HH:mm"
                  showTime={{ format: "HH:mm" }}
                  style={{ width: "340px" }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label=" ">
                <SearchButton
                  size="large"
                  htmlType="submit"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {userCode.length > 0 && (
        <div
          style={{
            borderRadius: 8,
            padding: "0px 16px",
            background: "linear-gradient(225deg, #9737C1 0%, #5B4ABF 100%)",
          }}
        >
          <Row>
            <Col span={5} style={{ padding: '8px 0' }} >
              <h3 style={{ color: "white" }}>ยอดเงินในกระเป๋ารายได้ปัจจุบัน (THB)</h3>
              <Row>
                <Col>
                  <Statistic title="ยอดเงินรวม" value={(summary.balance).toFixed(2)} />
                </Col>
              </Row>
            </Col>
            <Col span={1} style={{ textAlign: 'center' }}>
              <Divider type="vertical" style={{ ...dividerStyle, height: '100%' }} />
            </Col>
            <Col span={18} style={{ padding: '8px 0' }}>
              <h3 style={{ color: "white" }}>ภาพรวมรายได้ทั้งหมดจากการแนะนำเพื่อน (THB)</h3>
              <Row>
                <Col flex="auto">
                  <Statistic title="กระเป๋ารายได้" value={(summary.income).toFixed(2)} />
                </Col>
                <Divider type="vertical" style={{ ...dividerStyle, height: '60px' }} />
                <Col flex="auto">
                  <Statistic title="เติมเงินครั้งแรก(เพื่อนทำเทิร์น)" value={(summary.firstDeposit).toFixed(2)} />
                </Col>
                <Divider type="vertical" style={{ ...dividerStyle, height: '60px' }} />
                <Col flex="auto">
                  <Statistic title="ยืนยันตัวตน" value={(summary.kyc).toFixed(2)} />
                </Col>
                <Divider type="vertical" style={{ ...dividerStyle, height: '60px' }} />
                <Col flex="auto">
                  <Statistic title="ยอดเสีย" value={(summary.lossing).toFixed(2)} />
                </Col>
                <Divider type="vertical" style={{ ...dividerStyle, height: '60px' }} />
                <Col flex="auto">
                  <Statistic title="ยอดถอน" value={(summary.withdraw).toFixed(2)} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
      {userCode.length > 0 ? (
        <>
          <Tabs onChange={(tab) => { setSelectedTab(tab) }} >
            <Tabs.TabPane tab="ทั่วไป" key="overview">
              <DeskPanel
                header={(
                  <div className="header">
                    <RadioButton
                      value={selectedAffiliateType}
                      options={getFilterOptions({
                        columnName: "affiliateType",
                        allData: affilateTransactions as Record<string, any>[],
                        withAll: true,
                        withFormat: (key) => mapToAffiliateTypeText(key as AFFILIATE_TYPE),
                      }).filter(option => option.key !== AFFILIATE_TYPE.WITHDRAW)
                        .map(v => ({
                          ...v,
                          value: v.value,
                        }))}
                      onChange={(e) => handleMenuFilter(e.target.value)}
                    />
                    <span onClick={clearFilter} className="clear-filter">
                      ล้างตัวกรองทั้งหมด
                    </span>
                  </div>
                )}
                body={(
                  <Table
                    onChange={handleChange}
                    loading={isTransactionLoading}
                    columns={overviewColumns}
                    dataSource={filteredAffiliateTransactions}
                    pagination={{
                      current: pagination.current,
                      pageSize: pagination.limit,
                      onChange: (page, pageSize) => {
                        setPagination({ ...pagination, current: page, limit: pageSize });
                      },
                    }}
                  />
                )}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="รายเดือน" key="monthly">
              <DeskPanel
                body={(
                  <Table
                    columns={monthlyColumns}
                    dataSource={monthlyTransactions}
                  />
                )}
              />
            </Tabs.TabPane>
          </Tabs>
        </>
      ) : (
        <DeskPanel
          body={(
            <div style={{ padding: '200px 0' }}>
              <Table
                loading={false}
                columns={[]}
                dataSource={[]}
              />
            </div>
          )}
        />
      )}
    </div>
  );
};

export default AffiliateOverview;
