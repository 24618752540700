import classNames from 'classnames';
import * as React from 'react';

export interface IIconProps {
  name: string;
  color?: string;
  size?: string;
  overwriteStyle?: React.CSSProperties;
  tag?: string;
  classNames?: string;
}

const Icon: React.FunctionComponent<IIconProps> = (props) => {
  const classname = classNames(`icon-${props.name}`, props.classNames);
  const style = {
    color: props.color,
    fontSize: props.size,
    verticalAlign: 'middle',
    lineHeight: '1',
    ...props.overwriteStyle,
  }
  let Tag = `i` as keyof JSX.IntrinsicElements;
  if (props.tag) {
    Tag = `${props.tag}` as keyof JSX.IntrinsicElements;
  }
  return <Tag className={classname} style={style} />;
};

export default Icon;
