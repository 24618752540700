import * as React from 'react';
import { Radio, RadioChangeEvent, RadioGroupProps } from 'antd';

import './RadioButton.scss';

export type Option = {
  key?: string;
  text: JSX.Element | string;
  value: string;
};

interface IRadioButtonProps {
  value?: any;
  onChange?: (e: RadioChangeEvent) => void;
  options: Option[];
};

const RadioButton: React.FunctionComponent<IRadioButtonProps> = (props) => {
  return (
    <Radio.Group className='backoffice-radio-button' onChange={props.onChange} value={props.value}>
      {props.options.map((option, i) => (
        <Radio.Button key={i} value={option.value}>{option.text}</Radio.Button>
      ))}
    </Radio.Group>
  );
};

export const RadioGroup: React.FunctionComponent<RadioGroupProps> = (props: any) => {
  return (<Radio.Group className='backoffice-radio-button asasa' {...props}>
    {props.children}
  </Radio.Group>)
}

export default RadioButton;
