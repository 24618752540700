import { useQuery, UseQueryOptions } from "react-query";
import { CustomerLog, CustomerLogType, Pagination } from "src/types";
import { api } from "src/utils";

export interface UseCustomerLogsFilters {
  skip?: number;
  limit?: number;
  types?: CustomerLogType[];
  startDate?: string;
  endDate?: string;
}

export function useCustomerLogs<T = Pagination<CustomerLog>>(
  customerCode: string,
  filters?: UseCustomerLogsFilters,
  options?: UseQueryOptions<Pagination<Pagination<CustomerLog>>, unknown, T, any[]>
) {
  return useQuery(
    ["customerLogs", customerCode, filters],
    async () => {
      const response = await api.get(`payment/customer/${customerCode}/logs`, {
        params: filters,
      });
      return response.data.data;
    },
    options
  );
}
