import * as React from "react";
import dayjs from "dayjs";
import { Row, Col, TableColumnsType, Form, Input, Image } from "antd";
import isEqual from "react-fast-compare";

import colors from "src/theme/colors.json";
import { BankCode, QRPaymentReportItem } from "src/types";

import { useQRPaymentReport, UseQRPaymentReportFilters } from "src/hooks/useQRPaymentReport";
import { getBankFormat, getPhoneFormat } from "src/utils/textOperation/textOperation";
import { getColumnFilters } from "src/utils/table";

import BankLogo from "src/components/BankLogo";
import PageHeader from "src/components/PageHeader/PageHeader";
import Space from "src/components/Space/Space";
import DeskPanel from "src/components/DeskPanel/DesktPanel";
import { DatePicker, SearchButton, Table } from "src/components";
import { AmountColumn } from "src/components/Table";

import "./QRPaymentReport.scss";

interface IQRPaymentPageProps {}

const DISPLAY_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";

export const QRPaymentReportPage: React.FunctionComponent<IQRPaymentPageProps> = (_) => {
  const [fetchFilters, setFetchFilters] = React.useState<UseQRPaymentReportFilters>();
  const [previewImageIndex, setPreviewImageIndex] = React.useState<number | null>(null);

  const [form] = Form.useForm();
  const { data, isFetching, refetch } = useQRPaymentReport(fetchFilters, { enabled: !!fetchFilters });

  const getDisplayReportResults = () =>
    data?.results.map((rawReport) => {
      return {
        ...rawReport,
        createdDateTime: dayjs(rawReport.createdDateTime).format(DISPLAY_DATE_TIME_FORMAT),
        expiredAt: dayjs(rawReport.expiredAt).format(DISPLAY_DATE_TIME_FORMAT),
        lastUpdatedDateTime: dayjs(rawReport.lastUpdatedDateTime).format(DISPLAY_DATE_TIME_FORMAT),
        customerAccountNumber: getBankFormat(rawReport.customerAccountNumber || ""),
        pigAccountNumber: getBankFormat(rawReport.pigAccountNumber || ""),
        bankPromptpayNo: getPhoneFormat(rawReport.bankPromptpayNo || ""),
      };
    });

  const displayReportResults = React.useMemo(getDisplayReportResults, [data]);

  const renderBankAccountCell = (
    bankAccountName: string | null,
    bankAccountNumber: string | null,
    bankPromptpayNo: string | null,
    bankCode?: BankCode | null
  ) => {
    return (
      <Space>
        <Col>
          <BankLogo bankCode={bankCode || undefined} />
        </Col>
        <Col>
          <Row>{bankAccountName}</Row>
          <Row>{bankAccountNumber}</Row>
          <Row>{bankPromptpayNo}</Row>
        </Col>
      </Space>
    );
  };

  const renderQRImageCell = (value: string, row: QRPaymentReportItem, index: number) => (
    <>
      <Image
        src={value}
        height={64}
        width={64}
        hidden
        onError={(e) => {
          e.currentTarget.parentElement?.nextElementSibling?.setAttribute(
            "style",
            `color: ${colors["@gray-6"]}; text-decoration: line-through; pointer-events: none; transition: color ease-out 250ms;`
          );
        }}
        preview={{
          visible: previewImageIndex === index,
          src: value,
          onVisibleChange: (isVisible) => setPreviewImageIndex(isVisible ? index : null),
          title: `QR Ref : ${row.qrRefCode}`,
        }}
      />
      <strong
        onClick={() => setPreviewImageIndex(index)}
        style={{
          color: colors["purple-6"],
          cursor: "zoom-in",
          transition: "color ease-out 0.5s",
        }}
      >
        QR Image
      </strong>
    </>
  );

  const getAllColumns = (): TableColumnsType<QRPaymentReportItem> => {
    return [
      {
        className: "table-cell",
        title: "รหัสลูกค้า",
        dataIndex: "customerCode",
        key: "customerCode",
        filterSearch: true,
        filters: getColumnFilters("customerCode", displayReportResults || []),
        onFilter: (value, row) => row.customerCode?.includes(value.toString()) || false,
      },
      {
        className: "table-cell",
        title: "Request Amount",
        dataIndex: "requestAmount",
        key: "requestAmount",
        render: (value) => <AmountColumn amount={value} />,
      },
      {
        className: "table-cell",
        title: "Generate Amount",
        dataIndex: "generateAmount",
        key: "generateAmount",
        render: (value) => <AmountColumn amount={value} />,
      },
      {
        className: "table-cell",
        title: "บัญชี PIGSPIN",
        dataIndex: "pigAccountName",
        key: "pigAccountName",
        render: (value, row) =>
          renderBankAccountCell(value, row.pigAccountNumber, row.bankPromptpayNo, row.pigBankCode),
      },
      {
        className: "table-cell",
        title: "QR Code",
        dataIndex: "qrCodeUrl",
        key: "qrCodeUrl",
        render: renderQRImageCell,
      },
      {
        className: "table-cell",
        title: "QR Ref.",
        dataIndex: "qrRefCode",
        key: "qrRefCode",
      },
      {
        className: "table-cell",
        title: "Transfer Status",
        dataIndex: "isTransferred",
        key: "isTransferred",
        render: (value: boolean) => (
          <strong style={{ color: value ? colors["@green-6"] : colors["@yellow-6"] }}>
            {value ? "Yes" : "No"}
          </strong>
        ),
      },
      {
        className: "table-cell",
        title: "วันเวลาหมดอายุ",
        dataIndex: "expiredAt",
        key: "expiredAt",
        filterSearch: true,
        filters: getColumnFilters("expiredAt", displayReportResults || []),
        onFilter: (value, row) => row.expiredAt?.includes(value.toString()) || false,
      },
      {
        className: "table-cell",
        title: "วันเวลาสร้าง",
        dataIndex: "createdDateTime",
        key: "createdDateTime",
        filterSearch: true,
        filters: getColumnFilters("createdDateTime", displayReportResults || []),
        onFilter: (value, row) => row.createdDateTime?.includes(value.toString()) || false,
      },
      {
        className: "table-cell",
        title: "วันเวลาอัพเดตล่าสุด",
        dataIndex: "lastUpdatedDateTime",
        key: "lastUpdatedDateTime",
        filterSearch: true,
        filters: getColumnFilters("lastUpdatedDateTime", displayReportResults || []),
        onFilter: (value, row) => row.lastUpdatedDateTime?.includes(value.toString()) || false,
      },
    ];
  };

  const onFinish = (value: any) => {
    const newFetchFilters: UseQRPaymentReportFilters = {
      searchCustomer: value?.search,
      startTime: value?.datetimeRange[0].toDate(),
      endTime: value?.datetimeRange[1].toDate(),
    };
    if (isEqual(fetchFilters, newFetchFilters)) {
      refetch();
    } else {
      setFetchFilters(newFetchFilters);
    }
    setPreviewImageIndex(null);
  };

  return (
    <div className="QRPaymentReportPage">
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          datetimeRange: [dayjs().startOf("day"), dayjs().endOf("day")],
          search: undefined,
        }}
      >
        <Row gutter={8} wrap={false} align="bottom">
          <Col flex="auto">
            <Form.Item name="search" label="รหัสหรือเบอร์โทร">
              <Input size="large" placeholder="ใส่รหัสหรือเบอร์โทร" allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="datetimeRange"
              label={
                <Row style={{ width: "340px" }}>
                  <Col span={12}>{"วันเวลาเริ่มต้น"}</Col>
                  <Col span={12}>{"วันเวลาสิ้นสุด"}</Col>
                </Row>
              }
            >
              <DatePicker.RangePicker
                size="large"
                allowClear={false}
                format="DD/MM/YYYY HH:mm"
                showTime={{ format: "HH:mm" }}
                style={{ width: "340px" }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item shouldUpdate>
              {() => (
                <SearchButton
                  size="large"
                  htmlType="submit"
                  disabled={!form.getFieldValue("datetimeRange") || isFetching}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <DeskPanel
        body={
          <Table
            className="main-table"
            rowKey="reportId"
            dataSource={displayReportResults}
            columns={getAllColumns()}
            loading={isFetching}
            size="small"
            bordered
          />
        }
      />
    </div>
  );
};

export default QRPaymentReportPage;
