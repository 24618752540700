import * as React from 'react';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import CopyableMessageBox from 'src/components/CopyableMessageBox/CopyableMessageBox';
import AlertModal, { AlertModalType } from '../AlertModal/AlertModal';

export type ITransactionResultModalProps = {
  type: AlertModalType;
  title?: string | JSX.Element;
  topicMessageBox?: string;
  message?: string;
}

const TransactionResultModalComponent: React.FC<ITransactionResultModalProps> = (props) => {
  const { type, title, topicMessageBox, message } = props;
  const { visible, remove } = useModal();
  return (
    <AlertModal
      type={type}
      isModalVisible={visible}
      buttonType="confirm"
      title={title || ''}
      content={
        <CopyableMessageBox
          topic={topicMessageBox || ''}
          message={message || ''}
        />
      }
      maskClosable={false}
      closeable={false}
      onConfirm={() => { remove() }}
      onCancel={() => { remove() }}
    />
  );
};

const TransactionResultModal = NiceModal.create((props: ITransactionResultModalProps) => (<TransactionResultModalComponent {...props} />))

export default TransactionResultModal;
