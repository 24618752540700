import React from "react";
import { useRecoilValue } from "recoil";
import { mapToDepositUserInfo } from "src/mapper/mapToDepositUserInfo";
import { authState } from "src/recoils";
import { searchUserInfo } from "src/services/transactions";
import { UserInfo } from "./type";

export type UserInfoState = {
  isLoading: boolean;
  userInfo: UserInfo;
  doSearchingUserInfo: (value: string) => Promise<UserInfo>;
  setUserInfo: (u: UserInfo) => void;
}

export const useUserInfo = (): UserInfoState => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [userInfo, setUserInfo] = React.useState<UserInfo>({ isComplete: false })
  const { token = '' } = useRecoilValue(authState);

  const doSearchingUserInfo = async (textSearch: string): Promise<UserInfo> => {
    const defaultResponse = { isComplete: false, isFound: false };
    const _textSearch = textSearch.trim();
    const isEmptyTextSearch = _textSearch.length < 1;
    if (isEmptyTextSearch) {
      setUserInfo(defaultResponse);
      return defaultResponse;
    }
    setIsLoading(true);
    const response = await searchUserInfo({
      token,
      value: _textSearch
    });

    if (response?.status === "SUCCESS") {
      const userInfo = mapToDepositUserInfo(response.data);
      setUserInfo(mapToDepositUserInfo(response.data) || undefined)
      setIsLoading(false);
      return userInfo;
    }
    else {
      setUserInfo({ isComplete: true, isFound: false })
    }
    setIsLoading(false);
    return { isComplete: true, isFound: false }
  };

  return {
    isLoading,
    userInfo,
    doSearchingUserInfo,
    setUserInfo,
  }
};
