import dayjs from "dayjs";
import { datetime } from "src/utils";
import { numberWithoutCommas, addCommas } from "src/utils/number/number";

export function DateTime({ value }: { value: string }) {
  return <>{dayjs(value).isValid() ? datetime(value).format("DD/MM/YYYY HH:mm") : null}</>;
}

export function Amount({ value, color, prefix, allowZero }: { value?: string | number, color?: string, prefix?: string, allowZero?: boolean }) {
  const _allowZero = allowZero && value !== undefined && numberWithoutCommas(value?.toString()) === 0
  return (
    <div style={{ textAlign: "right", color: color }}>{prefix}{(value || _allowZero) ? addCommas(numberWithoutCommas(value).toFixed(2)) : "-"}</div>
  );
}

export function Int({ value }: { value?: string | number }) {
  return (
    <div style={{ textAlign: "right" }}>{value ? addCommas(numberWithoutCommas(value).toFixed(0)) : "-"}</div>
  );
}
