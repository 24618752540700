import { AxiosRequestConfig } from 'axios';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse } from "../service.type";
import { DEPOSIT_TRANSACTION_STATUS } from '../transactions';

export type ChangeTransactionStatusRequest = {
  transaction_uid: string;
  transaction_status: DEPOSIT_TRANSACTION_STATUS;
  token: string;
};

export type ChangeTransactionStatusResponse = BaseResponse;

export const requestChangeTransactionStatus = async ({
  transaction_uid,
  transaction_status,
  token
}: ChangeTransactionStatusRequest): Promise<ChangeTransactionStatusResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/payment/deposit/transactions',
    method: 'PUT',
    data: {
      transaction_uid,
      transaction_status,
    },
    headers: { token }
  };
  return fetchWithJSON<BaseResponse>(pigspinBackofficeApiInstance, requestConfig);
};
