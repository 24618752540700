import { TimePicker as AntdTimePicker, TimePickerProps } from 'antd';
import cx from 'classnames';
import * as React from 'react';
import './TimePicker.less';

export type ITimePickerProps = TimePickerProps & {
  hideOptions?: boolean;
}

const TimePicker: React.FunctionComponent<ITimePickerProps> = (props) => {
  const [isOpenDatePicker, setIsOpenDatePicker] = React.useState<boolean>(props.defaultOpen || false);

  return (
    <AntdTimePicker
      size="large"
      popupClassName={cx('backoffice-timepicker-dropdown', { 'backoffice-timepicker-dropdown-hide': props.hideOptions })}
      format={isOpenDatePicker ? 'HHmm' : 'HH:mm'}
      onOpenChange={(e) => { setIsOpenDatePicker(e) }}
      className="backoffce-datepicker"
      {...props}
      style={{ width: 100, ...props.style }}
    />
  );
};

export default TimePicker;
