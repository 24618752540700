import * as React from 'react';
import classnames from 'classnames';
import { Switch as AntdSwitch, SwitchProps } from 'antd';
import './Switch.less';

interface ISwitchProps {
}

const Switch: React.FunctionComponent<ISwitchProps & SwitchProps> = (props) => {
  return (
    <AntdSwitch
      checkedChildren="เปิด" unCheckedChildren="ปิด"
      {...props}
      className={classnames('backoffice-switch', props.className)}
    />
  );
};

export default Switch;
