import * as React from 'react';
import { Col, Row } from 'antd';
import Label from './Label';
import './LabelAndValue.less';

export type ILabelAndValueProps = {
  label: string | JSX.Element;
  value: string | JSX.Element;
}

const LabelAndValue: React.FunctionComponent<ILabelAndValueProps> = (props) => {
  return (
    <Row className="label-and-value">
      <Col span={12}><Label>{props.label}</Label></Col>
      <Col span={12}>{props.value}</Col>
    </Row>
  );
};

export default LabelAndValue;
