import { Col, PageHeader, Row } from "antd";
import * as React from "react";
import { Tabs } from "src/components";
import Table from "src/components/Table";
import DeskPanel from "src/components/DeskPanel/DesktPanel";
import { useSystemStatus, withoutGameProvider } from "src/hooks/useSystemStatus";
import { getColumns } from "./config/pigspin-columns";
import { ProductType } from "src/services/systemStatus/systemStatus";

export interface ISystemStatusProps {}

// NOTE : BE day
const mapDay: any = {
  "0": "จ",
  "1": "อ",
  "2": "พ",
  "3": "พฤ",
  "4": "ศ",
  "5": "ส",
  "6": "อา",
};

// NOTE : dayjs numbers from 0 (Sunday) to 6 (Saturday)
const dayjsToBeDay: any = {
  "0": "6",
  "1": "0",
  "2": "1",
  "3": "2",
  "4": "3",
  "5": "4",
  "6": "5",
};

const Header = () => (
  <Row gutter={[8, 0]} style={{ marginBottom: 10 }}>
    <Col flex="1 1 200px">
      <PageHeader title="คลังข้อความ" style={{ fontSize: 20, padding: 0 }} />
    </Col>
  </Row>
);

const projectTabKeys = {
  pigspin: "PIG_SPIN",
  pigbet: "PIG_BET",
};

const SystemStatus: React.FunctionComponent<ISystemStatusProps> = (props) => {
  const [activeTab, setActiveTab] = React.useState<ProductType>("PIG_SPIN");
  const pigspinSystemStatus = useSystemStatus({ productType: "PIG_SPIN" });
  const pigSpinSystemStatusList = pigspinSystemStatus.systemStatusList.filter(withoutGameProvider);
  const pigbetSystemStatus = useSystemStatus({ productType: "PIG_BET" });
  const pigBetSystemStatusList = pigbetSystemStatus.systemStatusList.filter(withoutGameProvider);

  return (
    <div id="SystemStatus">
      <Tabs
        defaultActiveKey={projectTabKeys.pigspin}
        activeKey={activeTab}
        onChange={(v) => setActiveTab(v as ProductType)}
      >
        <Tabs.TabPane tab="PIGSPIN" key={projectTabKeys.pigspin}>
          <DeskPanel
            body={
              <>
                <Header />
                <Table
                  isLoading={pigspinSystemStatus.isLoading}
                  isEmpty={pigSpinSystemStatusList.length < 1}
                  scroll={{ x: 800 }}
                  totalItem={0}
                  offset={0}
                  limit={99}
                  onPaginationChange={() => {}}
                  hideOnSinglePage={true}
                  dataSource={pigSpinSystemStatusList}
                  columns={getColumns({
                    dayjsToBeDay,
                    mapDay,
                    updateSystemStatusItem: pigspinSystemStatus.updateSystemStatus,
                  })}
                />
              </>
            }
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="PIGBET" key={projectTabKeys.pigbet}>
          <DeskPanel
            body={
              <>
                <Header />
                <Table
                  isLoading={pigbetSystemStatus.isLoading}
                  isEmpty={pigBetSystemStatusList.length < 1}
                  scroll={{ x: 800 }}
                  totalItem={0}
                  offset={0}
                  limit={99}
                  onPaginationChange={() => {}}
                  hideOnSinglePage={true}
                  dataSource={pigBetSystemStatusList}
                  columns={getColumns({
                    dayjsToBeDay,
                    mapDay,
                    updateSystemStatusItem: pigbetSystemStatus.updateSystemStatus,
                  })}
                />
              </>
            }
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default SystemStatus;
