import * as React from 'react';
import { RouteConfigValue } from 'src/config/routes';

export const withTitle = <P extends object>(
  Component: React.ComponentType<P>,
  routeConfig?: Omit<RouteConfigValue, 'PageComponent'>,
): React.FC<P> => {
  return (props) => {
    React.useEffect(() => {
      document.title = `${routeConfig?.linkText} PIGSPIN BACKOFFICE`;
    }, []);
    return React.useMemo(() => <Component {...props} />, [props])
  };
};

