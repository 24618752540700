import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Col, Form, Input, Row, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { ReactNode, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { emulateTab } from 'emulate-tab';
import {
  amountValidator,
  BankAccountSelect,
  BankSelect,
  Button,
  DatePicker,
  MoneyInput,
} from "src/components";
import {
  DepositTransactionCreate,
  useDepositTransactionBulkCreate,
  useDepositTransactionUpdate,
} from "src/hooks";
import { Transaction } from "src/hooks/useTransaction/type";
import { useBank } from "src/hooks/useTransaction/useBank";
import { OutlineAdd1, OutlineClose2 } from "src/icons";
import {
  DEFAULT_DIGITS_REQUIRE,
  getCustomerBankDigitsRequire,
} from "src/mapper/mapToBank";
import { modalState } from "src/recoils";
import { BankCode } from "src/types";
import { datetime, decimal, formatDatetime } from "src/utils";
import TimePicker from "../Input/TimePicker";
import Modal from "../Modal/Modal";
import "./DepositTransactionModalForm.less";

export type DepositTransactionModalFormProps = {
  transaction?: Transaction;
  onClose?: () => void;
  onSuccess?: () => void;
};

export interface DepositTransactionModalFormTransaction {
  toBankAccountUid: string;
  fromBankCode?: BankCode;
  fromBankAccountName?: string | null;
  fromBankAccountNumber?: string;
  transferredDate: Dayjs;
  transferredTime: Dayjs;
  amount: string;
}

export interface DepositTransactionModalFormValues {
  transactions: DepositTransactionModalFormTransaction[];
}

const DepositTransactionModalForm = NiceModal.create(
  ({ transaction, ...props }: DepositTransactionModalFormProps) => {
    const bankState = useBank();
    const bgbBankAccountOptions = bankState.depositBankList.map((bankAccount) => ({
      label: {
        bankCode: bankAccount.bankCode,
        accountName: bankAccount.accountName,
        accountNumber: bankAccount.accountNumber,
      },
      value: bankAccount.bankId,
    }));

    const stringifiedTransactions = useRef<string[]>([]);
    const [isValid, setIsValid] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [duplicatedIndexes, setDuplicatedIndexes] = useState<number[]>();
    const [customerBankDigitsRequire, setCustomerBankDigitsRequire] = useState<number[]>(
      transaction
        ? [
          getCustomerBankDigitsRequire({
            adminBankCode: transaction.adminBank.bankCode,
            customerBankCode: transaction.customerBank.bankCode,
          }),
        ]
        : []
    );

    const [form] = Form.useForm<DepositTransactionModalFormValues>();
    const [, setModal] = useRecoilState(modalState);
    const { visible, hide, remove } = useModal();
    const { mutate: bulkCreateTransaction } = useDepositTransactionBulkCreate();
    const { mutate: updateTransaction } = useDepositTransactionUpdate();

    const findBankAccountId = () => {
      return (
        bgbBankAccountOptions?.find(
          (option) =>
            option.label.accountNumber === transaction?.adminBank.accountNumber &&
            option.label.bankCode === transaction?.adminBank.bankCode
        )?.value || undefined
      );
    };

    const findBankAccountBankCode = (transactionId: string): BankCode => {
      return (
        bgbBankAccountOptions?.find((option) => option.value === transactionId)?.label.bankCode ||
        BankCode.NONE
      );
    };

    const stringify = (transaction: Partial<DepositTransactionModalFormTransaction>) => {
      return (
        "" +
        transaction.toBankAccountUid +
        transaction.fromBankCode +
        transaction.fromBankAccountNumber +
        transaction.transferredDate?.format("YYYY-MM-DD") +
        transaction.transferredTime?.format("HH:mm") +
        decimal(transaction.amount || "")
      );
    };

    let canSubmit = isValid && !duplicatedIndexes;
    const initialValues: Partial<DepositTransactionModalFormTransaction> = {
      transferredDate: dayjs(),
      transferredTime: dayjs(),
    };
    if (transaction) {
      canSubmit = isValid && hasChanged;

      const transferredAt = dayjs(transaction.transferAt);
      initialValues.toBankAccountUid = findBankAccountId();
      initialValues.fromBankCode = transaction.customerBank.bankCode;
      initialValues.fromBankAccountName = transaction.customerBank.accountName;
      initialValues.fromBankAccountNumber = transaction.customerBank.accountNumber || "";
      initialValues.transferredDate = transferredAt;
      initialValues.transferredTime = transferredAt;
      initialValues.amount = decimal(transaction.amount);
    }

    const stringifiedInitialValues = stringify(initialValues);
    const handleValuesChange = (changedValues: any, values: DepositTransactionModalFormValues) => {
      const changedIndex = changedValues.transactions.length - 1;
      const stringifiedTransaction = stringify(values.transactions[changedIndex]);

      if (transaction) {
        // Edit transaction
        if (stringifiedTransaction !== stringifiedInitialValues) {
          setHasChanged(true);
        } else {
          setHasChanged(false);
        }
      } else {
        // Create transaction
        if (values.transactions.length > 1) {
          const index = stringifiedTransactions.current.indexOf(stringifiedTransaction);
          const hasDuplicated = index !== -1 && index !== changedIndex;
          if (hasDuplicated) {
            setDuplicatedIndexes([index, changedIndex]);
          } else if (duplicatedIndexes) {
            setDuplicatedIndexes(undefined);
          }
        }
        stringifiedTransactions.current[changedIndex] = stringifiedTransaction;
      }
    };

    const handleFieldsChange = (changedFields: any[], allFields: any[]) => {
      if (changedFields[0].name.join() === "transactions") {
        form.validateFields();
      } else {
        const allRequiredFields = allFields.filter(field => field.name[2] !== 'fromBankAccountNumber' && field.name[2] !== 'fromBankCode')
        setIsValid(allRequiredFields.every((field) => field.value && field.errors.length === 0));
      }
    };

    const handleClose = () => {
      hide();
      props.onClose?.();
    };

    const handleSuccess = () => {
      handleClose();
      props.onSuccess?.();
    };

    const handleFinish = ({ transactions }: DepositTransactionModalFormValues) => {
      const mapper = (
        transaction: DepositTransactionModalFormTransaction,
        index?: number
      ): DepositTransactionCreate => ({
        bgbBankCodeUuid: transaction.toBankAccountUid,
        fromBankCode: transaction.fromBankCode || undefined,
        fromBankAccountName: transaction.fromBankAccountName || undefined,
        fromBankAccountNumber: transaction.fromBankAccountNumber || undefined,
        transferredAt: formatDatetime(transaction.transferredDate, transaction.transferredTime),
        amount: decimal(transaction.amount),
      });

      if (transaction) {
        updateTransaction(
          { ...mapper(transactions[0]), transactionUid: transaction.transactionId },
          { onSuccess: () => handleSuccess() }
        );
      } else {
        setModal({
          isModalVisible: true,
          type: "warning",
          title: "เพิ่มรายการ",
          content: "ต้องการเพิ่มรายการเติมเงินใช่หรือไม่",
          buttonType: "question",
          onConfirm: () => {
            bulkCreateTransaction(
              {
                transactionList: transactions.map((transaction, index) => ({
                  ...mapper(transaction, index),
                })),
              },
              {
                onSuccess: ({ data }) => {
                  if (data.remainder.length) {
                    setIsValid(false);
                    form.setFieldsValue({
                      transactions: data.remainder.map(({ data }: any) => {
                        const transferredAt = dayjs(data.transferredAt);
                        return {
                          toBankAccountUid: data.bgbBankCodeUuid,
                          fromBankCode: data.fromBankCode,
                          fromBankAccountName: data.fromBankAccountName,
                          fromBankAccountNumber: data.fromBankAccountNumber,
                          transferredDate: transferredAt,
                          transferredTime: transferredAt,
                          amount: data.amount,
                        };
                      }),
                    });
                  } else {
                    handleSuccess();
                  }
                },
              }
            );
          },
        });
      }
    };

    return (
      <Modal
        centered
        className="deposit-transaction-modal-form"
        width="fit-content"
        visible={visible}
        afterClose={remove}
        focusTriggerAfterClose={false}
        closable={false}
        title={
          <div style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>
            {transaction ? "แก้ไขรายการ" : "การเพิ่มรายการเติมเงิน"}
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
            <Button key="cancel" size="large" onClick={handleClose} style={{ width: "100px" }}>
              ยกเลิก
            </Button>
            <Button
              key="submit"
              size="large"
              type="primary"
              onClick={() => form.submit()}
              disabled={!canSubmit}
              style={{ width: "160px" }}
            >
              {transaction ? "บันทึก" : "เพิ่มรายการเติมเงิน"}
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          preserve={false}
          style={{ overflow: "auto" }}
          onValuesChange={handleValuesChange}
          onFieldsChange={handleFieldsChange}
          onFinish={handleFinish}
        >
          <Form.List name="transactions" initialValue={[{ ...initialValues }]}>
            {(fields, { add, remove }) => {
              const removeRow = (name: number) => {
                setCustomerBankDigitsRequire(
                  customerBankDigitsRequire.filter((value, index) => index !== name)
                );
                stringifiedTransactions.current.splice(name, 1);
                remove(name);
              };
              const addRow = (defaultValue: any) => {
                setCustomerBankDigitsRequire([
                  ...customerBankDigitsRequire,
                  DEFAULT_DIGITS_REQUIRE,
                ]);
                add(defaultValue);
              };
              return (
                <div style={{ display: "inline-block" }}>
                  <Row style={{ marginBottom: 10 }}>
                    <Col span={11}><strong>ข้อมูลบัญชีรับเงิน</strong></Col>
                    <Col span={12}><strong>ข้อมูลบัญชีลูกค้า</strong></Col>
                  </Row>
                  {fields.map(({ name }, index) => {
                    const firstRow = name === 0;
                    const disableRow = duplicatedIndexes && !duplicatedIndexes.includes(name);
                    const getLabel = (text: string): ReactNode =>
                      firstRow ? <label>{text}</label> : null;
                    return (
                      <div key={name} style={{ position: "relative", marginBottom: "8px" }}>
                        {duplicatedIndexes?.includes(name) && (
                          <div
                            style={{
                              backgroundColor: "#FFF1F4",
                              position: "absolute",
                              width: "100%",
                              height: "60px",
                              bottom: "14px",
                            }}
                          />
                        )}
                        <Space size="large">
                          <Space direction="vertical">
                            <Form.Item
                              name={[name, "toBankAccountUid"]}
                              label={getLabel("PIGSPIN")}
                            >
                              <BankAccountSelect
                                autoFocus={
                                  !transaction && !stringifiedTransactions.current[0] ? true : false
                                }
                                defaultOpen={
                                  !transaction && !stringifiedTransactions.current[0] ? true : false
                                }
                                disabled={disableRow}
                                placeholder="เลือกบัญชี PIGSPIN"
                                options={bgbBankAccountOptions || []}
                                onChange={(value) => {
                                  const tempCustomerBankDigitsRequire = [
                                    ...customerBankDigitsRequire,
                                  ];
                                  tempCustomerBankDigitsRequire[name] =
                                    getCustomerBankDigitsRequire({
                                      customerBankCode:
                                        form.getFieldsValue().transactions[name].fromBankCode || BankCode.NONE,
                                      adminBankCode: findBankAccountBankCode(value),
                                    });
                                  setCustomerBankDigitsRequire(tempCustomerBankDigitsRequire);
                                  emulateTab();
                                }}
                              />
                            </Form.Item>
                          </Space>
                          <Space direction="vertical">
                            <Space align="start">
                              <Form.Item
                                name={[name, "transferredDate"]}
                                label={getLabel("วันที่โอน")}
                              >
                                <DatePicker
                                  disabledDate={(d) => !d || d.isAfter(new Date())}
                                  disabled={disableRow}
                                />
                              </Form.Item>
                              <Form.Item name={[name, "fromBankCode"]} label={getLabel("ธนาคาร")}>
                                <BankSelect
                                  autoFocus={
                                    !transaction && stringifiedTransactions.current[0]
                                      ? true
                                      : false
                                  }
                                  disabled={disableRow}
                                  onChange={(bankCode) => {
                                    const tempCustomerBankDigitsRequire = [
                                      ...customerBankDigitsRequire,
                                    ];
                                    tempCustomerBankDigitsRequire[name] =
                                      getCustomerBankDigitsRequire({
                                        customerBankCode: bankCode,
                                        adminBankCode: findBankAccountBankCode(
                                          form.getFieldsValue().transactions[name].toBankAccountUid
                                        ),
                                      });
                                    setCustomerBankDigitsRequire(tempCustomerBankDigitsRequire);
                                    emulateTab();
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                name={[name, "fromBankAccountNumber"]}
                                label={getLabel("เลขที่บัญชี (ตัวเลขที่รู้)")}
                                validateFirst
                                dependencies={[
                                  ["transactions", name, "toBankAccountUid"],
                                  ["transactions", name, "fromBankCode"],
                                ]}
                                rules={[
                                  { pattern: new RegExp(/^[0-9]+$|x/), message: "ใส่ตัวเลขหรือ x เท่านั้น" },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="ใส่เลขที่บัญชีลูกค้า"
                                  style={{ width: 150 }}
                                  maxLength={12}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') { emulateTab(); }
                                  }}
                                  disabled={disableRow}
                                />
                              </Form.Item>
                              <Form.Item
                                name={[name, "transferredTime"]}
                                label={getLabel("เวลาโอน")}
                              >
                                <TimePicker
                                  hideOptions
                                  disabled={disableRow}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') { emulateTab(); }
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                className="amount"
                                name={[name, "amount"]}
                                label={getLabel("จำนวนเงิน")}
                                rules={[{ validator: amountValidator }]}
                              >
                                <MoneyInput
                                  size="large"
                                  disabled={disableRow}
                                  placeholder="ใส่จำนวนเงิน"
                                  style={{ width: 150, textAlign: "right" }}
                                  onKeyPress={async (e: any) => {
                                    if (e.key === "Enter" && canSubmit && !transaction) {
                                      addRow({
                                        toBankAccountUid:
                                          form.getFieldValue("transactions")[fields.length - 1]
                                            .toBankAccountUid,
                                        transferredDate: dayjs(),
                                        transferredTime: dayjs(),
                                      });
                                    }
                                  }}
                                />
                              </Form.Item>
                              {fields.length > 1 && (
                                <Form.Item label={getLabel("")}>
                                  <div
                                    style={{
                                      display: disableRow ? "none" : "flex",
                                      alignItems: "center",
                                      height: "40px",
                                    }}
                                  >
                                    <OutlineClose2
                                      style={{ fontSize: 16 }}
                                      onClick={() => removeRow(name)}
                                    />
                                  </div>
                                </Form.Item>
                              )}
                            </Space>
                          </Space>
                        </Space>
                      </div>
                    );
                  })}
                  {duplicatedIndexes && (
                    <div style={{ textAlign: "center", color: "#C70227", marginBottom: "20px" }}>
                      ข้อมูลซ้ำ กรุณาแก้ไขข้อมูล
                    </div>
                  )}
                  {!transaction && (
                    <Button
                      block
                      type="dashed"
                      disabled={!canSubmit || fields.length >= 10}
                      style={{ marginBottom: 8 }}
                      onClick={() =>
                        addRow({
                          toBankAccountUid:
                            form.getFieldValue("transactions")[fields.length - 1].toBankAccountUid,
                          transferredDate: dayjs(),
                          transferredTime: dayjs(),
                        })
                      }
                    >
                      <Space>
                        <OutlineAdd1 style={{ fontSize: "16px" }} />
                        เพิ่มรายการ
                      </Space>
                    </Button>
                  )}
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Modal>
    );
  }
);

export default DepositTransactionModalForm;
