import * as React from "react"
import { BaseIconProps } from "./type";

const AlertDanger: React.FC<BaseIconProps> = (props) => {
  const { size = 24 } = props;
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: `${size}px`, height: `${size}px`, ...props.style }}
      viewBox="0 0 44 40"
      {...props}
    >
      <path
        d="M43.12 30.085 27.705 3.317c-2.517-4.423-8.885-4.423-11.393 0L.888 30.085C-1.63 34.507 1.503 40 6.584 40H37.37c5.081 0 8.27-5.548 5.751-9.915ZM22 34.062c-1.341 0-2.455-1.117-2.455-2.461 0-1.344 1.114-2.46 2.454-2.46a2.413 2.413 0 0 1 2.4 2.524c.064 1.28-1.114 2.397-2.4 2.397Zm2.236-15.909c-.108 1.907-.226 3.805-.335 5.712-.054.617-.054 1.18-.054 1.789-.054 1.007-.842 1.788-1.848 1.788-1.005 0-1.784-.726-1.847-1.734-.163-2.97-.335-5.884-.498-8.853-.055-.781-.109-1.571-.172-2.352 0-1.29.724-2.352 1.902-2.688a2.46 2.46 0 0 1 2.852 1.399c.173.39.227.78.227 1.235-.054 1.244-.172 2.478-.227 3.704Z"
        fill="url(#alert_danger)"
      />
      <defs>
        <linearGradient
          id="alert_danger"
          x1={44}
          y1={0}
          x2={-0.395}
          y2={39.556}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3535" />
          <stop offset={1} stopColor="#D30000" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default AlertDanger;
