import * as React from 'react';
import { useRecoilState } from 'recoil';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { loadingState } from '../../recoils';
import Modal from './Modal';
import './InProgressModal.less';

const InprogressModal: React.FunctionComponent = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 62 }} spin />;
  const [loading,] = useRecoilState(loadingState);

  return (
    <Modal
      centered
      footer={null}
      visible={loading.isLoading}
      className='InprogressModal'
    >
      <Spin indicator={antIcon} />
      <br />
      <br />
      <div>กำลังดำเนินการ</div>
      <div>กรุณารอซักครู่</div>
    </Modal>
  );
};

export default InprogressModal;
