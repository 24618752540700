import React from "react"
import { CustomerPage } from "src/pages/customer"
import { BankPage } from "../pages/bank"
import { Deposit } from "../pages/deposit"
import { Home, } from "../pages/home"
import { Transfer } from "../pages/transfer"
import { Users } from "../pages/users"
import { CustomerBonus, TicketManagement, SearchTransaction, SearchWalletTransaction, QuestManagement } from "src/pages"
import { Scope } from "src/types"
import systemStatus from "src/pages/systemStatus"
import { BankReportPage } from "src/pages/bankReport"
import { TrueWalletReportPage } from "src/pages/trueWalletReport"
import { QRPaymentReportPage } from "src/pages/qrPaymentReport"
import { RealtimeTransferPage } from "src/pages/realtimeTransfer"
import { GameProvider } from "src/pages/gameProvider"
import { PaymentSettingPage } from "src/pages/paymentSetting"
import { FourZeroFourPage } from "src/pages/404"
import { FourZeroThreePage } from "src/pages/403"
import ConfigurationPage from "src/pages/configuration"
import { SettingOutlined, ApartmentOutlined, SolutionOutlined } from "@ant-design/icons"
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon"
import { AffiliateOrgChart, AffiliateOverview } from "src/pages/affiliate"

export type RouteConfigName = 'default'
  | 'reportIssue' | 'reportTransactionAmount' | 'reportDepositWithdraw' | 'reportDepositAmount' | 'reportTransaction'
  | 'transactionActionDeposit' | 'transactionActionAddTransfer' | 'transactionRealtimeTransfer'
  | 'transactionHistoryBonus' | 'ticketManagement' | 'transactionHistoryTrueWallet' | 'transactionHistoryBank' | 'transactionHistoryQr' | 'transactionHistoryGameLog' | 'transactionHistoryScoreLog'
  | 'customerInfoList' | 'customerInfoQuestion'
  | 'settingBanner' | 'settingPayment' | 'settingGame' | 'settingQuest' | 'settingBankAccount' | 'settingAdmin' | 'settingConfig' | 'searchTransaction'
  | 'affiliateOrgChart' | 'affiliateOverview'

export type ExceptionRoute = 'four0four' | 'four0three';

export type RouteConfigValue = {
  path: string;
  iconName: string;
  linkText: string;
  PageComponent: React.FC;
  scope: Scope;
  disabled?: boolean;
  pageHeader?: string;
  antdIcon?: React.ForwardRefExoticComponent<Pick<AntdIconProps, "size" | "className" | "style">>;
}

export const routesConfig: Record<RouteConfigName, RouteConfigValue> = {
  default: { path: '/', iconName: '', linkText: 'เติมเงิน', PageComponent: Deposit, scope: Scope.All },
  reportIssue: { path: `/reportIssue`, iconName: 'outline-report-1', linkText: 'จำนวนรายการ', PageComponent: Home, scope: Scope.All, disabled: true, },
  reportTransactionAmount: { path: `/reportTransactionAmount`, iconName: 'outline-check-1', linkText: 'ปัญหาการทำรายการ', PageComponent: Home, scope: Scope.All, disabled: true, },
  reportDepositWithdraw: { path: `/reportDepositWithdraw`, iconName: 'outline-report-2', linkText: 'เติม-ถอนเงิน', PageComponent: Home, scope: Scope.All, disabled: true, },
  reportDepositAmount: { path: `/reportDepositAmount`, iconName: 'outline-report-3', linkText: 'จำนวนเงินฝาก', PageComponent: Home, scope: Scope.All, disabled: true, },
  reportTransaction: { path: `/reportTransaction`, iconName: 'outline-report-4', linkText: 'Transaction', PageComponent: Home, scope: Scope.All, disabled: true, },
  transactionActionDeposit: { path: '/transactionActionDeposit', iconName: 'outline-credit-add-1', linkText: 'เติมเงิน', PageComponent: Deposit, scope: Scope.DepositPage },
  transactionRealtimeTransfer: { path: '/transactionRealtimeTransfer', iconName: 'outline-active-1', linkText: 'ตรวจสอบยอดเงิน', PageComponent: RealtimeTransferPage, scope: Scope.RealtimeTransfer },
  transactionActionAddTransfer: { path: '/transactionActionAddTransfer', iconName: 'outline-credit-remove-1', linkText: 'โอนเงิน', PageComponent: Transfer, scope: Scope.WithdrawPage },
  transactionHistoryBonus: { path: '/transactionHistoryBonus', iconName: 'outline-bonus-2', linkText: 'โบนัสลูกค้า', PageComponent: CustomerBonus, scope: Scope.BonusPage },
  ticketManagement: { path: '/ticketManagement', iconName: 'outline-ticket', linkText: 'Ticket ลูกค้า', PageComponent: TicketManagement, scope: Scope.TicketManagePage },
  transactionHistoryTrueWallet: { path: '/transactionHistoryTrueWallet', iconName: 'outline-true-1', linkText: 'ทรูวอลเล็ท', pageHeader: 'การเดินบัญชีทรูวอลเล็ท', PageComponent: TrueWalletReportPage, scope: Scope.ReportPage },
  transactionHistoryBank: { path: '/transactionHistoryBank', iconName: 'outline-bank-1', linkText: 'ธนาคาร', pageHeader: 'การเดินบัญชีธนาคาร', PageComponent: BankReportPage, scope: Scope.ReportPage },
  transactionHistoryQr: { path: '/transactionHistoryQr', iconName: 'outline-qr-1', linkText: 'QR Payment', pageHeader: 'การเดินบัญชีช่องทางQR', PageComponent: QRPaymentReportPage, scope: Scope.ReportPage },
  transactionHistoryGameLog: { path: '/transactionHistoryGameLog', iconName: 'outline-cash-wallet-1', linkText: 'กระเป๋าเงิน/เกมลูกค้า', PageComponent: SearchWalletTransaction, scope: Scope.WalletPage },
  transactionHistoryScoreLog: { path: '/transactionHistoryScoreLog', iconName: 'outline-score-1', linkText: 'บันทึกการใช้เครดิต', PageComponent: Home, scope: Scope.All },
  customerInfoList: { path: '/customerInfoList', iconName: 'outline-user-info-2', linkText: 'รายชื่อลูกค้า', PageComponent: CustomerPage, scope: Scope.All },
  customerInfoQuestion: { path: '/customerInfoQuestion', iconName: 'outline-question-1', linkText: 'ถาม-ตอบลูกค้า', PageComponent: Home, scope: Scope.All, disabled: true, },
  settingBanner: { path: '/settingBanner', iconName: 'outline-message-1', linkText: 'ป้ายข้อความหน้าเว็บ', PageComponent: systemStatus, scope: Scope.BannerPage, },
  settingPayment: { path: '/settingPayment', iconName: 'vector', linkText: 'ระบบชำระเงิน', PageComponent: PaymentSettingPage, scope: Scope.PaymentSettingPage, },
  settingGame: { path: '/settingGame', iconName: 'outline-game-manage-1', linkText: 'จัดการเกม', PageComponent: GameProvider, scope: Scope.GamePage, },
  settingQuest: { path: '/settingQuest', iconName: 'outline-bonus-1', linkText: 'ตั้งค่าภารกิจ', PageComponent: QuestManagement, scope: Scope.QuestManagePage, },
  settingBankAccount: { path: '/settingBankAccount', iconName: 'outline-bank-manage-1', linkText: 'บัญชีธนาคาร', PageComponent: BankPage, scope: Scope.ManageBank },
  settingAdmin: { path: '/settingAdmin', iconName: 'outline-employee-manage-1', linkText: 'พนักงาน', PageComponent: Users, scope: Scope.RegisterPage },
  settingConfig: { path: '/settingConfig', iconName: 'outline-bank-manage-1', antdIcon: SettingOutlined, linkText: 'กลุ่มธนาคาร', PageComponent: ConfigurationPage, scope: Scope.SettingConfigPage },
  searchTransaction: { path: '/searchTransaction', iconName: 'outline-search-1', linkText: 'ค้นหารายการ', PageComponent: SearchTransaction, scope: Scope.All },
  affiliateOrgChart: { path: '/affiliateOrgChart', iconName: '', antdIcon: ApartmentOutlined, linkText: 'องค์กร', PageComponent: AffiliateOrgChart, scope: Scope.AffiliateOrgChartPage },
  affiliateOverview: { path: '/affiliateOverview', iconName: '', antdIcon: SolutionOutlined, linkText: 'ภาพรวมรายได้', PageComponent: AffiliateOverview, scope: Scope.AffiliateOverviewPage },
}

export const exceptionRoute: Record<ExceptionRoute, RouteConfigValue> = {
  four0four: { path: '*', iconName: '', linkText: '404', PageComponent: FourZeroFourPage, scope: Scope.All },
  four0three: { path: '*', iconName: '', linkText: '403', PageComponent: FourZeroThreePage, scope: Scope.All },
};
