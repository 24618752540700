import { message } from "antd"
import { toast as toastify, ToastContent, ToastOptions } from "react-toastify"
import { AlertDanger, AlertSuccess } from "src/icons"
import "./Toast.less"

const toast = {
  error: (text?: string | ToastContent, options?: ToastOptions<{}> | undefined) => {
    toastify.warning(text, options)
    // const hide = message.error({
    //   className: "bo-toast toast-error",
    //   icon: <AlertDanger />,
    //   content: text,
    //   onClick: () => hide(),
    // })
  },
  success: (text?: string | ToastContent, options?: ToastOptions<{}> | undefined) => {
    toastify.success(text, options)
    // const hide = message.success({
    //   className: "bo-toast toast-success",
    //   icon: <AlertSuccess />,
    //   content: text,
    //   onClick: () => hide(),
    // })
  },
}

export default toast
