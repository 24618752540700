import { atom } from "recoil";
import { AlertModalProps } from "../../components/Modals/AlertModal/AlertModal";
import { KEYS } from "../keys";

const defaultState: AlertModalProps = {
  isModalVisible: false,
  title: '',
  content: '',
  type: 'success',
  buttonType: 'confirm',
};

const modalState = atom({
  key: KEYS.MODAL,
  default: defaultState,
});

export default modalState;