import { AxiosRequestConfig } from "axios";
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse } from "../service.type";

export type EditAdminRequest =  {
  user_uuid: string;
  personal_number: string;
  email: string;
  role_uuid: string;
  status: string;
}

export type EditAdminResponse = BaseResponse

export const editAdmin = async (request: EditAdminRequest, token: string = '') => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: 'v1/users',
      method: 'PUT',
      data: request,
      headers: { token }
    };
    const result = await fetchWithJSON<EditAdminResponse>(pigspinBackofficeApiInstance, requestConfig);
    return result.data
  } catch (error) {
    return null
  }
}