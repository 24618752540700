import NiceModal from "@ebay/nice-modal-react";
import { Col, Form, Input, Row } from "antd";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AddButton,
  Amount,
  CoinWalletModalForm,
  CoinWalletStatus,
  CoinWalletViewMoreModal,
  ColumnType,
  CustomerStatistics,
  CustomerStatisticsHandler,
  DatePicker,
  DateTime,
  EditDropdown,
  PageContainer,
  PageHeader,
  SearchButton,
  Table,
  ViewMoreButton,
} from "src/components";
import { useCoinWallets, UseCoinWalletsFilters, useRevokeCoinWallet } from "src/hooks";
import { authInfoState, authState, modalState } from "src/recoils";
import { CoinWallet, Scope } from "src/types";
import { formatDatetime } from "src/utils";

function CustomerBonus() {
  const [form] = Form.useForm();
  const { scope } = useRecoilValue(authInfoState);
  const [auth] = useRecoilState(authState);
  const [, setModal] = useRecoilState(modalState);
  const customerStatistics = useRef<CustomerStatisticsHandler>(null);

  const [filters, setFilters] = useState<UseCoinWalletsFilters>();

  const { data, refetch, remove, isFetching, isError } = useCoinWallets(filters, {
    enabled: !!filters,
    keepPreviousData: true,
  });

  const { mutate: revoke } = useRevokeCoinWallet();

  const onFinish = (values: any) => {
    customerStatistics.current?.refetchBalance();
    remove();
    setFilters({
      start: formatDatetime(values.datetimeRange[0]),
      end: formatDatetime(values.datetimeRange[1]),
      customerCode: values.customerCode,
    });
  };

  const columns: ColumnType<CoinWallet>[] = [
    {
      title: "วันเวลาได้โบนัส",
      dataIndex: "createdAt",
      width: 100,
      render: (value) => <DateTime value={value} />,
    },
    {
      title: "โปรโมชั่น",
      dataIndex: "name",
      width: 100,
    },
    {
      title: "เติมเงิน (THB)",
      dataIndex: ["cashWalletTransaction", "transactionAmount"],
      render: (value) => <Amount value={value ? value : 0} />,
    },
    {
      title: "โบนัส (THB)",
      dataIndex: "initialBalance",
      render: (value) => <Amount value={value} />,
    },
    {
      title: "เทิร์นที่ต้องทำ (THB)",
      dataIndex: "totalTurnover",
      render: (value) => <Amount value={value} />,
    },
    {
      title: "เทิร์นที่ทำแล้ว (THB)",
      dataIndex: "currentTurnover",
      render: (value) => <Amount value={value} />,
    },
    {
      title: "โบนัสคงเหลือ (THB)",
      dataIndex: "currentBalance",
      render: (value) => <Amount value={value} />,
    },
    {
      title: "แปลงเป็นเงิน (THB)",
      dataIndex: "convertedToCashAmount",
      render: (value, record) => <Amount value={record.isConvertedToCash ? value : 0} />,
    },
    {
      title: "วันหมดอายุ",
      dataIndex: "expireAt",
      width: 100,
      render: (value) => <DateTime value={value} />,
    },
    {
      title: "สถานะ",
      key: "status",
      render: (_, record) => <CoinWalletStatus wallet={record} />,
    },
    {
      title: "วันเวลาอัพเดทล่าสุด",
      dataIndex: "updatedAt",
      width: 100,
      render: (value) => <DateTime value={value} />,
    },
    {
      key: "viewMore",
      align: "center",
      width: 40,
      render: (_, record) => (
        <ViewMoreButton
          size="small"
          onClick={() => {
            NiceModal.show(CoinWalletViewMoreModal, { walletUid: record.uid });
          }}
        />
      ),
    },
  ];

  const actionColumn: ColumnType<CoinWallet> = {
    key: "action",
    align: "center",
    width: 40,
    fixed: "right",
    render: (_, record) => {
      if (
        (auth.role === "DM" || auth.role === "Owner") &&
        ["active", "used"].includes(record.status)
      ) {
        return (
          <EditDropdown
            size="small"
            showEdit={true}
            showCancel={true}
            editText="แก้ไขโบนัส"
            cancelText="ยกเลิกโบนัส"
            onEdit={() =>
              NiceModal.show(CoinWalletModalForm, { wallet: record, onSuccess: () => refetch() })
            }
            onCancel={() => {
              setModal({
                isModalVisible: true,
                type: "warning",
                title: "ยกเลิกโบนัส",
                content: "ต้องการยกเลิกโบนัสใช่หรือไม่",
                buttonType: "question",
                onConfirm: async () => revoke(record.uid, { onSuccess: () => refetch() }),
              });
            }}
          />
        );
      }
    },
  };

  return (
    <PageContainer
      header={
        <PageHeader
          title=" "
          extra={[scope[Scope.CreateBonus]?.enable
            && data && filters?.customerCode && (auth.role === "DM" || auth.role === "Owner") && (
              <AddButton
                key="add"
                onClick={() =>
                  NiceModal.show(CoinWalletModalForm, {
                    customerCode: filters.customerCode,
                    onSuccess: () => refetch(),
                  })
                }
              >
                เพิ่มโบนัส
              </AddButton>
            ),
          ]}
        >
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              group: 1,
              datetimeRange: [
                dayjs().hour(0).minute(0).second(0).millisecond(0),
                dayjs().hour(23).minute(59).second(59).millisecond(999),
              ],
            }}
          >
            <Row gutter={8} wrap={false}>
              <Col flex="auto">
                <Form.Item name="customerCode" label="รหัสหรือเบอร์โทร">
                  <Input size="large" placeholder="ใส่รหัสหรือเบอร์โทร" allowClear />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="datetimeRange"
                  label={
                    <Row style={{ width: "340px" }}>
                      <Col span={12}>{"วันเวลาเริ่มต้น"}</Col>
                      <Col span={12}>{"วันเวลาสิ้นสุด"}</Col>
                    </Row>
                  }
                >
                  <DatePicker.RangePicker
                    size="large"
                    allowClear={false}
                    format="DD/MM/YYYY HH:mm"
                    showTime={{ format: "HH:mm" }}
                    style={{ width: "340px" }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={<></>}
                  shouldUpdate={(prevValue, curValue) =>
                    prevValue.customerCode !== curValue.customerCode
                  }
                >
                  {() => (
                    <SearchButton
                      size="large"
                      htmlType="submit"
                      disabled={!form.getFieldValue("customerCode")}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>

          {data && (
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <CustomerStatistics customerCode={filters?.customerCode || ''} ref={customerStatistics} />
              </Col>
            </Row>
          )}
        </PageHeader>
      }
    >
      <Table
        rowKey="uid"
        columns={scope[Scope.EditBonus]?.enable ? [...columns, actionColumn] : columns}
        dataSource={isError ? [] : data?.results}
        loading={{
          size: "large",
          spinning: isFetching,
          style: { maxHeight: "calc(100vh - 310px)" },
        }}
        pagination={{ total: data?.total }}
      />
    </PageContainer>
  );
}

export default CustomerBonus;
