import { Input, InputProps } from "antd";
import React from "react";

export const amountValidatorAllowZero = (_: any, value: string | undefined) => {
  if (value) {
    if (!value.match(/^\d*\.?\d*$/)) {
      return Promise.reject(new Error("ใส่ตัวเลขเท่านั้น"));
    }
    const number = parseFloat(value);
    if (number < 0) {
      return Promise.reject(new Error("ยอดต้องไม่ติดลบ"));
    } else if (number > 2000000) {
      return Promise.reject(new Error("ยอดเงินเกินลิมิต"));
    }
  }
  return Promise.resolve();
};

export const amountValidator = (_: any, value: string | undefined) => {
  if (value) {
    if (!value.match(/^\d*\.?\d*$/)) {
      return Promise.reject(new Error("ใส่ตัวเลขเท่านั้น"));
    }
    const number = parseFloat(value);
    if (number <= 0) {
      return Promise.reject(new Error("ยอดต้องมากกว่า 0"));
    } else if (number > 2000000) {
      return Promise.reject(new Error("ยอดเงินเกินลิมิต"));
    }
  }
  return Promise.resolve();
};

export interface MoneyInputProps extends Omit<InputProps, "onChange"> {
  value?: string | number;
  onChange?: (value: string) => void;
}

function MoneyInput({ value, onChange, ...props }: MoneyInputProps) {
  const formatter = (value?: string) => {
    return value?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const parser = (value: string) => {
    return value.replace(/(\.\d{2})(\d+)/, "$1").replace(/,/g, "");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parser(e.target.value);
    onChange?.(newValue);
  };

  return (
    <Input {...props} type="text" onChange={handleChange} value={formatter(value?.toString())} />
  );
}

export default MoneyInput;
