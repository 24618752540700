import * as React from 'react';
import { Input, Typography, Statistic, Spin } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { addCommas } from '../../../utils/number/number';
import { TransactionState } from '../../../hooks/useTransaction/useTransaction'
import { DEPOSIT_TRANSACTION_STATUS, requestPostConfirmTransaction } from '../../../services/transactions';
import { toast } from "react-toastify";
import AlertModal, { AlertNiceModal } from "../AlertModal/AlertModal";
import Button from '../../Button/Button';
import Icon from "../../../components/Icon/Icon";
import Modal from '../../Modal/Modal'
import './DepositModal.scss'
import { useRecoilState, useRecoilValue } from "recoil";
import { authState, modalState } from "../../../recoils";
import CopyableMessageBox from '../../CopyableMessageBox/CopyableMessageBox';
import { DEPOSIT_STATUS, Transaction, UserInfo as UserInfoType } from "../../../hooks/useTransaction/type";
import UserInfo from 'src/components/UserInfo/UserInfo';
import UserInfoNotFound from 'src/components/UserInfo/UserInfoNotFound';
import { useUserInfo } from 'src/hooks/useTransaction/useUserInfo';
import TransactionInfo from 'src/components/TransactionInfo';
import { matchBankAccount } from 'src/utils/bank';
import ResultBanner, { BannerType } from 'src/components/Banner/ResultBanner';
import colors from 'src/theme/colors.json';
import { adjustTransaction } from 'src/services/adjustTransaction/adjustTransaction';
import { putDepositTransaction } from 'src/services/transactions/transactions';
import { BaseResponse } from 'src/services/service.type';

export type ModalState = {
  isConfirmAlertVisible: boolean;
  isRejectAlertVisible: boolean;
  isConfirmRejectAlertVisible: boolean;
  isTransactionReserved: boolean;
}

export type DepositModalProps = {
  depositState: TransactionState;
  byUserAccount?: string;
}

type RejectTransaction = {
  transaction: Transaction | undefined;
  userInfo: UserInfoType;
};

const DepositModalComponent = ({
  depositState,
  byUserAccount,
}: DepositModalProps) => {
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [modal, setModal] = React.useState<ModalState>({
    isConfirmAlertVisible: false,
    isRejectAlertVisible: false,
    isConfirmRejectAlertVisible: false,
    isTransactionReserved: false,
  });
  const {
    isConfirmAlertVisible,
    isRejectAlertVisible,
    isConfirmRejectAlertVisible,
    isTransactionReserved,
  } = modal;
  const [, setGlobalModal] = useRecoilState(modalState);
  const [textSearch, setTextSearch] = React.useState<string>('');
  const [rejectTransaction, setRejectTransaction] = React.useState<RejectTransaction>();

  const { token = '' } = useRecoilValue(authState);
  const {
    isLoading,
    setUserInfo,
    doSearchingUserInfo,
    userInfo,
  } = useUserInfo();

  const {
    adjustingTransaction,
    depositingTransaction,
    depositingUser,
    reserveTransaction,
    cancelReserveTransaction,
    setDepositingTransaction,
    cancelReserveUser,
    shouldCallAdjustDeposit,
    setShouldCallAdjustDeposit,
  } = depositState;

  const transaction = depositingTransaction;
  const inputRef = React.useRef<Input>(null)

  React.useEffect(() => {
    if (byUserAccount) {
      doSearchingUserInfo(byUserAccount)
    } else {
      setTextSearch('');
      setUserInfo({ isComplete: false, isFound: false });
    }
  }, [depositingTransaction, depositingUser,]);

  const onCancelDepositModalHandle = async () => {
    setDepositingTransaction(undefined)
    if (depositingTransaction) { await cancelReserveTransaction(depositingTransaction, DEPOSIT_STATUS.PENDING); }
    inputRef.current?.setValue("");
    setUserInfo({ isComplete: false, isFound: false });
    setShouldCallAdjustDeposit(false);
  }

  const onConfirmTransaction = () => {
    setModal({
      ...modal,
      isConfirmAlertVisible: true,
    });
  }

  const onRejectTransaction = () => {
    setRejectTransaction({
      userInfo: userInfo,
      transaction: depositingTransaction,
    })
    setModal({
      ...modal,
      isRejectAlertVisible: true,
    })
  }

  const onCancelConfirmationModal = () => {
    setModal({
      ...modal,
      isConfirmAlertVisible: false,
      isRejectAlertVisible: false,
      isConfirmRejectAlertVisible: false,
    })
  }

  const onCancelAlertModalHandle = async () => {
    setModal({
      ...modal,
      isConfirmAlertVisible: false,
      isRejectAlertVisible: false,
      isConfirmRejectAlertVisible: false,
      isTransactionReserved: false,
    })
    if (adjustingTransaction) { await cancelReserveTransaction(adjustingTransaction); }
    if (depositingUser) { await cancelReserveUser(depositingUser); }
    onCancelDepositModalHandle()
  }

  const onConfirmDepositAlertModalHandle = () => {
    if (isProcessing) return;

    setIsProcessing(true);
    if (shouldCallAdjustDeposit) {
      adjustTransaction({
        token,
        primary_transaction_uid: adjustingTransaction?.transactionId || '',
        secondary_transaction_uid: depositingTransaction?.transactionId || '',
        user_uid: userInfo.userCode || '',
      }).then(() => {
        setIsProcessing(false);
        const diffOfAmount = adjustingTransaction && depositingTransaction ? adjustingTransaction?.amount - depositingTransaction?.amount : 0;
        const hasRefund = diffOfAmount < 0;
        setGlobalModal({
          isModalVisible: true,
          type: 'success',
          title: hasRefund ? 'เติมเงินเพิ่มสำเร็จ' : 'ยกเลิกรายการสำเร็จ',
          content: hasRefund ? (
            <div style={{ textAlign: 'center' }}>
              เนื่องจากยอดเงินที่ลูกค้าได้จากการเติมผิดสลิปน้อยกว่ายอดที่ลูกค้าเติมจริง <br />
              ระบบจึงทำการเพิ่มเงินส่วนต่างให้กับลูกค้า {depositingUser?.userAccount} <br />
              จำนวนเงิน {addCommas(Math.abs(diffOfAmount).toFixed(2) || '0.00')} บาท
            </div>) : (
            <div style={{ textAlign: 'center' }}>
              เนื่องจากยอดเงินที่เติมผิดสลิปมากกว่ายอดที่ลูกค้าเติมจริง<br />
              ระบบจึงยกเลิกรายการ เพื่อป้องกันการเติมซ้ำ
            </div>
          ),
          buttonType: 'confirm',
          onConfirm: () => {
            onCancelAlertModalHandle();
          },
        })
      }).catch((error) => {
        setIsProcessing(false);
        setModal({
          ...modal,
          isConfirmAlertVisible: false,
        })
        toast.warning("เติมเงินไม่สำเร็จ")
        console.log('error', error, error.response); // TODO : check response
      });
    } else {
      requestPostConfirmTransaction({
        transaction_uid: `${depositingTransaction?.transactionId}`,
        user_code: `${userInfo?.userCode}`,
        customer_code: `${userInfo?.userAccount}`,
        transaction_status: DEPOSIT_TRANSACTION_STATUS.COMPLETED,
        token
      }).then((result) => {
        setIsProcessing(false);
        if (result?.status === "SUCCESS") {
          setModal({
            ...modal,
            isConfirmAlertVisible: false,
          })
          NiceModal.show(AlertNiceModal, {
            type: 'success',
            isModalVisible: true,
            buttonType: 'confirm',
            title: 'เติมเงินสำเร็จแล้ว',
            content: (
              <CopyableMessageBox
                topic="ข้อความแจ้งลูกค้า"
                message={`เติมเงิน ${transaction?.amount && addCommas(transaction?.amount.toFixed(2))} บาท ให้ลูกค้า ${userInfo.phoneNumber} เรียบร้อยแล้วค่ะ`}
              />
            ),
            maskClosable: false,
            closeable: false,
            onConfirm: onCancelAlertModalHandle,
          })
        } else if (result?.service_code === "BOE-4002") {
          setModal({
            ...modal,
            isConfirmAlertVisible: false,
            isTransactionReserved: true
          })
        } else {
          setModal({
            ...modal,
            isConfirmAlertVisible: false,
          })
          toast.warning("เติมเงินไม่สำเร็จ")
        }
      })
    }
  }

  const onConfirmRejectDepositAlertModalHandle = () => {
    const confirmRejectThen = (result: BaseResponse | null) => {
      if (result?.status === "SUCCESS") {
        setModal({
          ...modal,
          isRejectAlertVisible: false,
          isConfirmRejectAlertVisible: true,
        })
        // TODO : refactor isConfirmRejectAlertVisible to this (in future)
        // NiceModal.show(TransactionResultModal, {
        //   type: "success",
        //   title: "ปฏิเสธการเติมเงิน",
        //   topicMessageBox: "ข้อความแจ้งลูกค้า",
        //   message: `ขออภัยไม่สามารถเติมเงิน {xxx.xx} ให้ลูกค้า {xxxxxxxxxx} บาท ได้ค่ะ\nเนื่องจากเลขที่บัญชีไม่ตรงกับที่คุณลงทะเบียน\nกรุณาแจ้งข้อมูลธนาคารเพื่อใช้โอนเงินกลับค่ะ ${transactions[0]?.amount && addCommas(transactions[0]?.amount.toFixed(2))} บาท\n ให้ลูกค้า {userInfo?.userAccount} เรียบร้อยแล้วค่ะ`,
        // })
      } else if (result?.service_code === "BOE-4002") {
        setModal({
          ...modal,
          isRejectAlertVisible: false,
          isTransactionReserved: true
        })
      } else {
        setModal({
          ...modal,
          isRejectAlertVisible: false,
        })
        toast.warning("ปฏิเสธรายการไม่สำเร็จ")
      }
    }
    if (userInfo?.userCode) {
      requestPostConfirmTransaction({
        transaction_uid: `${depositingTransaction?.transactionId}`,
        user_code: `${userInfo?.userCode}`,
        customer_code: `${userInfo?.userAccount}`,
        transaction_status: DEPOSIT_TRANSACTION_STATUS.REJECTED,
        token
      }).then(confirmRejectThen)
    } else {
      putDepositTransaction({
        token,
        transaction_uid: `${depositingTransaction?.transactionId}`,
        transaction_status: DEPOSIT_TRANSACTION_STATUS.CANCELLED,
      }).then((reseponse) => {
        if (reseponse.service_code === 'BOE-2000') {
          toast.success("ปฏิเสธรายการสำเร็จแล้ว")
        } else {
          toast.warning("ปฏิเสธรายการไม่สำเร็จ")
        }
        setModal({
          ...modal,
          isRejectAlertVisible: false,
        })
        if (depositingTransaction) cancelReserveTransaction(depositingTransaction, DEPOSIT_STATUS.PENDING)
      }).catch((e) => {
        toast.warning("ปฏิเสธรายการไม่สำเร็จ")
        setModal({
          ...modal,
          isRejectAlertVisible: false,
        })
        if (depositingTransaction) cancelReserveTransaction(depositingTransaction, DEPOSIT_STATUS.PENDING)
      })
    }

  }

  const header = (
    <div>การเติมเงิน</div>
  )

  const renderCountDown = () => {
    return (
      <Statistic.Countdown
        format="mm:ss"
        value={Date.now() + 60 * 1000 * 5}
        valueStyle={{ fontSize: '16px' }}
        className="withdraw-modal-countdown"
        onFinish={() => onCancelDepositModalHandle()}
      />
    );
  };

  const footer = (
    <>
      <Button
        style={{ width: 176 }}
        type="danger"
        size="large"
        disabled={!userInfo.isComplete || !userInfo.isFound}
        onClick={() => { onRejectTransaction() }}
      >
        <Icon classNames="icon" name="filled-clear-2" size="22px" />
        ปฏิเสธรายการ
      </Button>
      <Button
        className="button cancel-button"
        style={{ width: 100 }}
        size="large"
        onClick={() => { onCancelDepositModalHandle() }}
      >
        ยกเลิก
      </Button>
      <Button
        style={{ width: 176 }}
        type="success"
        size="large"
        onClick={() => { onConfirmTransaction() }}
        disabled={!userInfo.isComplete || !userInfo.isFound}
      >
        <Icon classNames="icon" name="filled-select-1" size="22px" />
        เติมเงิน
      </Button>
    </>
  )

  const beforeSearch = <div className="empty-div" />;

  const searchResult = () => {
    const resultMatching = depositingTransaction?.customerBank && userInfo.bankAccount ?
      matchBankAccount(depositingTransaction?.customerBank, userInfo.bankAccount) : {
        isMatch: false,
        sameDigit: 0,
      };
    const isExactlyMatch = resultMatching.sameDigit === userInfo.bankAccount?.accountNumber.length;
    return userInfo.isComplete && userInfo.isFound ? (
      <>
        {resultMatching.isMatch && (<ResultBanner type={BannerType.Success} text={isExactlyMatch ? 'เลขที่บัญชีตรงกับที่ลงทะเบียน' : 'เลขที่บัญชีบางส่วนตรงกับที่ลงทะเบียน'} />)}
        {!resultMatching.isMatch && (<ResultBanner type={BannerType.Error} text="เลขที่บัญชีไม่ตรงกับที่ลงทะเบียน" />)}
        <div style={{ height: 10 }} />
        <UserInfo
          title='ผลการค้นหา'
          isResultMatch={resultMatching.isMatch}
          userCode={userInfo.userAccount || ''}
          phone={userInfo.phoneNumber || ''}
          bankCode={userInfo.bankAccount?.bankCode}
          bankAccountName={userInfo.bankAccount?.accountName}
          bankAccountNumber={userInfo.bankAccount?.accountNumber}
          lastTransactionAt={userInfo?.lastDepositDateTime}
          lastTransactionAmount={userInfo.lastDepositAmount}
          balance={userInfo.currentBalance}
        />
      </>
    ) : (
      <UserInfoNotFound />
    )
  }

  const onSearch = (value: string) => {
    if (!value) {
      setUserInfo({ isComplete: false, isFound: false });
    } else {
      doSearchingUserInfo(value)
    }
  }
  return (
    <>
      <Modal
        className="deposit-modal"
        visible={depositingTransaction !== undefined}
        maskClosable={false}
        title={header}
        footer={footer}
        centered
      >
        <Spin spinning={isLoading}>
          {transaction && (
            <>
              {renderCountDown()}
              <AlertModal
                type="warning"
                isModalVisible={isConfirmAlertVisible}
                buttonType="question"
                title="เติมเงิน"
                content="ต้องการเติมเงินใช่หรือไม่"
                maskClosable={false}
                closeable={false}
                onCancel={onCancelConfirmationModal}
                onConfirm={onConfirmDepositAlertModalHandle}
              />
              <AlertModal
                type="warning"
                isModalVisible={isRejectAlertVisible}
                buttonType="question"
                title="ปฏิเสธรายการ"
                content="ต้องการปฏิเสธรายการใช่หรือไม่"
                maskClosable={false}
                closeable={false}
                onCancel={onCancelConfirmationModal}
                onConfirm={onConfirmRejectDepositAlertModalHandle}
              />
              <AlertModal
                type="error"
                isModalVisible={isTransactionReserved}
                buttonType="confirm"
                title="ไม่สามารถทำรายการได้"
                content="เนื่องจากรายการนี้ถูกดำเนินการแล้ว"
                maskClosable={false}
                closeable={false}
                onConfirm={onCancelAlertModalHandle}
              />
              <TransactionInfo transaction={transaction} />
            </>
          )}
          <div className="search-bar-wrapper">
            {byUserAccount && (
              <Typography.Title level={4} style={{ textAlign: 'center' }}>
                รหัสลูกค้า : <Typography.Text style={{ color: colors['purple-6'] }}>{byUserAccount}</Typography.Text>
              </Typography.Title>
            )}
            {!byUserAccount && (
              <>
                <div className="text">ค้นหาข้อมูลลูกค้า</div>
                <Input.Search value={textSearch} onChange={(e) => { setTextSearch(e.target.value.replace(/-/g, '').replace(/\s/g, '')) }} className="search-bar" placeholder="รหัสหรือเบอร์โทร" allowClear enterButton size={"large"} onSearch={onSearch} ref={inputRef} />
              </>
            )}
            {userInfo.isComplete && !isLoading ? searchResult() : beforeSearch}
          </div>
        </Spin>
      </Modal>
      {rejectTransaction?.transaction && (
        <AlertModal
          type="success"
          isModalVisible={isConfirmRejectAlertVisible}
          buttonType="question"
          title="ปฏิเสธรายการสำเร็จแล้ว"
          content={
            <CopyableMessageBox
              topic="ข้อความแจ้งลูกค้า"
              message={`ขออภัยไม่สามารถเติมเงิน ${rejectTransaction?.transaction?.amount && addCommas(rejectTransaction?.transaction?.amount.toFixed(2))} บาท\nให้ลูกค้า ${rejectTransaction.userInfo.phoneNumber} ได้ค่ะ\nเนื่องจากเลขที่บัญชีไม่ตรงกับที่คุณลงทะเบียน\nกรุณาแจ้งข้อมูลธนาคารเพื่อใช้โอนเงินกลับค่ะ`}
            />
          }
          footer={(
            <div style={{ textAlign: "center", margin: "20px 0px 32px 0px" }}>
              <Typography.Title level={3}>
                แจ้งคืนเงิน
              </Typography.Title>
              <Typography.Text>
                คุณต้องการแจ้งคืนเงินตอนนี้เลยหรือไม่
              </Typography.Text>
            </div>
          )}
          maskClosable={false}
          closeable={false}
          onConfirm={() => {
            onCancelAlertModalHandle();
            if (rejectTransaction?.transaction) {
              reserveTransaction(rejectTransaction?.transaction, DEPOSIT_STATUS.REJECTED);
            }
          }}
          onCancel={() => {
            onCancelAlertModalHandle()
          }}
        />
      )}
    </>
  );
};

const DepositModal = (props: DepositModalProps) => {
  return React.useMemo(() => <DepositModalComponent {...props} />, [props.depositState.depositingTransaction, props.byUserAccount]);
}

export default DepositModal;
