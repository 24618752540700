import NiceModal from "@ebay/nice-modal-react";
import { useState, useEffect } from "react";
import { Space, Button } from "antd";
import { TableProps } from "antd/es/table";
import { FilterValue } from "antd/lib/table/interface";
import { Tabs, Table } from "src/components";
import Icon from 'src/components/Icon/Icon';
import DeskPanel from "src/components/DeskPanel/DesktPanel";
import { GameBrandColumns } from "src/components/TableColumns/GameBrandColumn";
import { GameBrandManage } from "src/components/Games";
import { GameBrands, PartyBrand } from "src/types";
import GamesPage from 'src/pages/games/Games';
import { useListGameBrands, GameBrandsRequest, useUpdateGameBrand, BrandList } from "src/hooks/useGameBrand";
import isEmpty from "lodash/isEmpty";
import { useRecoilState } from 'recoil';
import { modalState } from 'src/recoils';
import "./GameProvider.scss";

interface IGameProviderProps { }

interface PaginationTypes {
  limit: number;
  current: number;
}

const initialPagination: PaginationTypes = {
  limit: 10,
  current: 1,
};

export const GameProvider: React.FunctionComponent<IGameProviderProps> = () => {

  const [globalModal, setModal] = useRecoilState(modalState);
  const [filters, setFilters] = useState<GameBrandsRequest>({ partyBrand: PartyBrand.PIGSPIN });
  const { data: brands, isFetching, refetch: fetchGameBrands } = useListGameBrands(filters);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const { mutate: updateGame, isLoading: isUpdatingGame } = useUpdateGameBrand();
  const [pagination, setPagination] = useState<PaginationTypes>(initialPagination);
  const [selectedFilter, setSelectedFilter] = useState<GameBrands | null>();
  
  const handleChange: TableProps<GameBrands>["onChange"] = (_, filters) => {
    setFilteredInfo(filters);
  };

  const handleMenuFilter = (value: GameBrands) => {
    if (isEmpty(value)) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(value);
    }
    setPagination({ ...pagination, current: 1 });
  };

  const handleUpdateGame = (brands: BrandList) => {
    
    setModal({
      isModalVisible: true,
      type: 'warning',
      title: brands.isUnderConstruction ? 'ปิดการใช้งาน' : 'เปิดการใช้งาน',
      content: 'ต้องการ'+(brands.isUnderConstruction ? 'ปิดการใช้งาน' : 'เปิดการใช้งาน')+'ค่ายเกม ' + brands.name  +' ใช่หรือไม่',
      buttonType: 'question',
      confirmText: 'ยืนยัน',
      onConfirm: () => {
        updateGame({brandList: [brands]});
      }
    })
  };

  useEffect(() => {
    setFilters({ partyBrand: PartyBrand.PIGSPIN });
  }, []);
  
  return (
    <div className="game-page">
      <Space>
        <div>
          <Tabs
            defaultActiveKey="PIGSPIN"
            onChange={(_tab) => {
              setSelectedFilter(null);

              if(_tab=='PIGSPIN'){
                setFilters({ partyBrand: PartyBrand.PIGSPIN });
              } else if(_tab=='PIGBET') {
                setFilters({ partyBrand: PartyBrand.PIGBET });
              }
            }}
          >
            <Tabs.TabPane tab="PIGSPIN" key="PIGSPIN"/>
            <Tabs.TabPane tab="PIGBET" key="PIGBET" />
          </Tabs>
        </div>
      </Space>
      <div className="game-page">
        <DeskPanel
          header={
            <div className="header">
              <div>
                  <Icon name="outline-game-manage-1" size="large" />
                  {(!selectedFilter) && (
                    <Button 
                      type="text" 
                    >ค่ายเกมทั้งหมด</Button>
                  )}
                  {selectedFilter && (
                    <>
                      <Button 
                        type="link" 
                        onClick={(e) => setSelectedFilter(null)}
                      >ค่ายเกมทั้งหมด</Button>&gt;&nbsp;&nbsp;
                      <span>{selectedFilter?.name}</span>
                    </>
                  )}
              </div>
              {!selectedFilter && (
                <Button 
                  type="primary" 
                  onClick={(e) => NiceModal.show(GameBrandManage, {
                    brandList: brands,
                    onClose: () => fetchGameBrands(),
                    onSuccess: () => fetchGameBrands(),
                  })}
                >
                  จัดการค่ายเกม
                </Button>
              )}
            </div>
          }
          body={
            <>
            {!selectedFilter && (
                <Table
                  showHeader
                  columns={GameBrandColumns({ brands, filteredInfo, handleUpdateGame, handleMenuFilter })}
                  dataSource={brands?.filter(brand => brand.isActive)}
                  onChange={handleChange}
                  loading={isFetching || isUpdatingGame}
                  pagination={{
                    current: pagination.current,
                    pageSize: pagination.limit,
                    onChange: (page, pageSize) => {
                      setPagination({ ...pagination, current: page, limit: pageSize });
                    },
                  }}
                />
            )}
            {selectedFilter && (
              <GamesPage brand={selectedFilter} partyBrand={filters} />
            )}
            </>
          }
        />
      </div>
    </div>
  );
};

export default GameProvider;
