import { Form, Spin, Row, Col, Input, Typography, Radio } from 'antd';
import NiceModal from "@ebay/nice-modal-react";
import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TEN_DIGITS_BANK } from 'src/mapper/mapToBank';
import colors from 'src/theme/colors.json';
import { authInfoState, modalState } from 'src/recoils';
import { ClearCustomer, ClearCustomerRequest, CustomerBaseRequest, CustomerBaseResponse, PostCustomer, PostCustomerResponse, PutCustomer, PutCustomerRequest, PutCustomerResponse } from 'src/services/customer/customer';
import { BankCode, CustomerRow, CustomerStatus, Scope } from 'src/types';
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find';
import Status from '../Bank';
import CustomerBonusStatus from '../CustomerBonus';
import PubnubChatStatus from '../PubnubChat';
import BankSelect from '../BankSelect';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Space from '../Space/Space';
import BankAccountSelect, { BankAccountSelectOption } from 'src/components/BankAccountSelect';
import TransactionResultModal from '../Modals/TransactionResultModal/TransactionResultModal';
import { Icon, toast } from 'src/components';
import { ValidateInput } from 'src/hooks/type';
import { useDepositBankAccounts, ChatStatus } from 'src/hooks';
import './CustomerForm.less';

import { CustomerConfirmDeleteModal } from "../../components/Modals";

const PHONE_DIGITS = 10;
const PIN_DIGITS = 6;

const TRUE_MIN_DIGITS = 3;
const TRUE_MAX_DIGITS = 18;


export type CustomerFormResult = {
  phoneNumber?: ValidateInput;
  accountNumber?: ValidateInput;
  accountName?: ValidateInput;
  accountNameEN?: ValidateInput;
  trueWalletId?: ValidateInput;
}

interface ICustomerFormProps {
  open: boolean;
  editingCustomer?: CustomerRow;
  onFinish: () => void;
  onCancel: () => void;
  setSearchCriteria: () => void;
}

export type CustomerFormRef = {
  bank: BankCode;
  accountName: string;
  accountNameEN: string;
  accountNumber: string;
  nameIdCard?: string;
  lineLinked: boolean;
  phone: string;
  promotionState: boolean;
  pin: string;
  status: CustomerStatus;
  trueWalletId: string;
  bank_pigspin: string;
}

const CustomerForm: React.FunctionComponent<ICustomerFormProps> = (props) => {
  const {
    open,
    editingCustomer,
  } = props
  const { token, scope } = useRecoilValue(authInfoState);
  const [globalModal, setModal] = useRecoilState(modalState);
  const isEditing = editingCustomer ? true : false
  const canEditBank = !isEditing ? true : scope[Scope.EditCustomerKycBank]?.enable;
  const [form] = Form.useForm<CustomerFormRef>()
  const [customerFormResult, setCustomerFormResult] = React.useState<CustomerFormResult>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [maxDigitsBank, setMaxDigitsBank] = React.useState<number>(10);
  const [isTrueWalletValid, setIsTrueWalletValid] = React.useState(true)
  const [isValidForm, setIsValidForm] = React.useState<boolean>(false);
  const [bankPigspins, setBankPigspins] = React.useState<BankAccountSelectOption[]>([])
  const { data: depositBankList, refetch: fetchDepositBankList } = useDepositBankAccounts(undefined, editingCustomer?.bank.default_group_bank_code, false)
  const { data: pubnubChatStatus, refetch: fetchPubnubChatStatus } = ChatStatus(editingCustomer?.customerId, false)
  const getBankPigSpinValue = (bgbDefaultBankCode?: string, groupBankCode?: string): string => {
    return JSON.stringify({ default_bgb_deposit_bank_code: bgbDefaultBankCode, default_group_bank_code: groupBankCode })
  }

  React.useEffect(() => {
    const bankPigSpinsOptions: BankAccountSelectOption[] | undefined = depositBankList?.map((bankList): BankAccountSelectOption => {
      return { value: getBankPigSpinValue(bankList.bgbDepositBankCode, bankList.groupBankCode), label: { accountName: bankList.bgbDepositBankAccountName, accountNumber: bankList.bgbDepositBankAccountNumber, bankCode: bankList.groupBankBankCode } }
    })
    setBankPigspins(bankPigSpinsOptions || [])
  }, [depositBankList])

  const onFinishHandler = () => {
    setCustomerFormResult({})
    props.onFinish();
  }

  const onCancalHandler = () => {
    setCustomerFormResult({})
    props.onCancel();
  }

  const duplicateInfomations = (result: CustomerBaseResponse) => {
    setCustomerFormResult({
      phoneNumber: result.phone_number_existed ? { validateStatus: 'error', help: 'เบอร์โทรศัพท์ซ้ำในระบบ' } : undefined,
      accountNumber: result.bank_account_existed ? { validateStatus: 'error', help: 'เลขที่บัญชีซ้ำในระบบ' } : undefined,
      trueWalletId: result.true_wallet_id_existed ? { validateStatus: 'error', help: 'ทรูวอลเล็ทไอดีซ้ำในระบบ' } : undefined,
    })
  }
  const bankMissmatch = () => {
    setCustomerFormResult({
      accountName: { validateStatus: 'error', help: 'ชื่อบัญชีไม่ถูกต้อง' },
      accountNumber: { validateStatus: 'error', help: 'เลขที่บัญชีไม่ถูกต้อง' },
    })
  }

  const updateCustomer = async (customerRequest: PutCustomerRequest) => {
    try {
      const response = await PutCustomer(customerRequest)
      if (response.service_code === 'BOE-2000') {
        toast.success('แก้ไขสำเร็จ')
        onFinishHandler()
      }
    } catch (e: any) {
      const response: PutCustomerResponse = e.response.data;
      if (response.service_code === 'BOE-4091') {
        bankMissmatch();
      } else if (response.service_code === 'BOE-4092') {
        duplicateInfomations({ ...response.data });
      } else {
        toast.error('แก้ไขไม่สำเร็จ');
      }
    }
  }

  const confirmSubmitForm = () => {
    setModal({
      isModalVisible: true,
      type: 'warning',
      title: 'แก้ไขข้อมูลลูกค้า',
      content: 'ต้องการแก้ไขข้อมูลลูกค้าใช่หรือไม่',
      buttonType: 'question',
      onConfirm: () => {
        onSubmit();
      }
    })
  }

  const confirmClearUser = () => {
    const clearCustomer = async (customerRequest: ClearCustomerRequest) => {
      try {
        const response = await ClearCustomer(customerRequest)
        if (response.service_code === 'BOE-2000') {
          toast.success('ระงับบัญชีสำเร็จ')
          onFinishHandler()
        }
      } catch (e: any) {
        toast.error('ระงับบัญชีไม่สำเร็จ');
      }
    }
    setModal({
      isModalVisible: true,
      type: 'warning',
      title: 'บัญชีซ้ำ',
      content: 'ต้องการระงับใช้งานบัญชีและลบเบอร์โทรศัพท์ใช่หรือไม่',
      buttonType: 'question',
      onConfirm: async () => {
        if (!editingCustomer) return;
        await clearCustomer({
          token,
          user_code: editingCustomer.customerId,
        });
      }
    })
  }

  const registerCustomer = async () => {
    const _form = form.getFieldsValue();
    try {
      const response = await PostCustomer({
        token,
        bank_code: _form.bank,
        bank_account_number: _form.accountNumber,
        bank_account_name: _form.accountName,
        bank_account_name_en: _form.accountNameEN,
        phone_number: _form.phone,
        pin: _form.pin,
        true_wallet_id: _form.trueWalletId || undefined,
      });
      if (response.service_code === 'BOE-2000') {
        onFinishHandler();
        NiceModal.show(TransactionResultModal, {
          type: "success",
          title: "สมัครสมาชิกสำเร็จแล้ว",
          topicMessageBox: "ข้อความแจ้งลูกค้า",
          message: `แอดมินดำเนินการสมัครสมาชิกให้คุณลูกค้าเรียบร้อยแล้วค่ะ\nวิธีการเข้าสู่ระบบ\n1. ใส่เบอร์โทรศัพท์ ${_form.phone}\n2. ใส่รหัสผ่าน ${_form.pin}\n3. ตั้งรหัส PIN ใหม่ เพื่อใช้เข้าสู่ระบบครั้งต่อไป`
        })
      }
    } catch (e: any) {
      const response: PostCustomerResponse = e.response.data;
      if (response.service_code === 'BOE-4091') {
        bankMissmatch();
      } else if (response.service_code === 'BOE-4092') {
        duplicateInfomations({ ...response.data });
      } else {
        toast.error('สมัครสมาชิกไม่สำเร็จ');
      }
    }
  }

  const onSubmit = async () => {
    setIsLoading(true);
    if (isEditing) {
      if (!editingCustomer) return setIsLoading(false);
      const isRemovingPhone = editingCustomer?.phone.length > 0 && form.getFieldsValue().phone.length <= 0;
      if (isRemovingPhone) {
        toast.error('ไม่อนุญาตให้ลบเบอร์โทรศัพท์');
        setIsLoading(false);
        return;
      };
      const _form = form.getFieldsValue();
      let _bankPigspin: Partial<CustomerBaseRequest> = {}
      try {
        _bankPigspin = JSON.parse(_form.bank_pigspin)
      } catch (error) {
        console.error('Cannot parse form value bank_pigspin')
      }
      await updateCustomer({
        token,
        user_code: editingCustomer.customerId,
        player_username: editingCustomer.customerCode,
        bank_code: _form.bank || undefined,
        bank_account_number: _form.accountNumber || undefined,
        bank_account_name: _form.accountName || undefined,
        bank_account_name_en: _form.accountNameEN || undefined,
        phone_number: _form.phone || undefined,
        default_bgb_deposit_bank_code: _bankPigspin?.default_bgb_deposit_bank_code,
        default_group_bank_code: _bankPigspin?.default_group_bank_code,
        status: _form.status,
        true_wallet_id: _form.trueWalletId || undefined,
        line_user_id: editingCustomer.lineUserId || undefined,
      });
    } else {
      await registerCustomer();
    }
    setIsLoading(false);
  }

  const isEditFormChanged = (editForm: CustomerFormRef): boolean => {
    if (editingCustomer) {
      const nothingChange =
        editForm.status === editingCustomer.status &&
        editForm.phone === editingCustomer.phone &&
        editForm.bank === editingCustomer.bank.bankCode &&
        editForm.accountName === editingCustomer.bank.accountName &&
        editForm.accountNameEN === editingCustomer.bank.accountNameEN &&
        editForm.accountNumber === editingCustomer.bank.accountNumber &&
        editForm.trueWalletId === editingCustomer.trueWalletId &&
        editForm.bank_pigspin === getBankPigSpinValue(editingCustomer.bank.default_bgb_deposit_bank_code, editingCustomer.bank.default_group_bank_code)
      return !nothingChange
    }
    return false;
  }

  React.useEffect(() => {
    setIsValidForm(false)
    if (editingCustomer) {
      fetchDepositBankList()
      fetchPubnubChatStatus()
      form.setFields([
        { name: 'phone', value: editingCustomer.phone },
        { name: 'pin', value: editingCustomer.pin },
        { name: 'lineLinked', value: editingCustomer.isLineLinked },
        { name: 'bank', value: editingCustomer.bank.bankCode !== BankCode.NONE ? editingCustomer.bank.bankCode : null },
        { name: 'accountName', value: editingCustomer.bank.accountName },
        { name: 'accountNameEN', value: editingCustomer.bank.accountNameEN },
        { name: 'accountNumber', value: editingCustomer.bank.accountNumber },
        { name: 'trueWalletId', value: editingCustomer.trueWalletId },
        { name: 'status', value: editingCustomer.status, },
        { name: 'nameIdCard', value: `${editingCustomer.firstName} ${editingCustomer.lastName}`},
      ])
    } else {
      form.resetFields()
    }
  }, [editingCustomer]);

  React.useEffect(() => {
    if (editingCustomer) {
      const _bankPigspin = getBankPigSpinValue(editingCustomer.bank.default_bgb_deposit_bank_code, editingCustomer.bank.default_group_bank_code)
      const hasDefaultValue = find(bankPigspins, ['value', _bankPigspin])
      if (!isEmpty(bankPigspins) && hasDefaultValue) {
        form.setFieldsValue({ bank_pigspin: _bankPigspin })
      } else {
        form.setFieldsValue({ bank_pigspin: undefined })
      }
    }
  }, [editingCustomer, bankPigspins])

  React.useEffect(() => {
    setIsValidForm(false)
    form.resetFields()
  }, [open]);

  // ConfirmDeleteCustomer
  const [openConfirmDeleteCustomer, setOpenConfirmDeleteCustomer] = React.useState<boolean>(false);
  const handleOpenConfirmDeleteCustomerModal = () => {
    setOpenConfirmDeleteCustomer(true)
  }
  const handleCloseConfirmDeleteCustomerModal = () => {
    setOpenConfirmDeleteCustomer(false)
  }
  const handleDeleteCustomerModal = (returnStatus: boolean) => {
    setOpenConfirmDeleteCustomer(false)
    if (returnStatus) {
      onCancalHandler()
      props.setSearchCriteria()
      toast.success("ลบบัญชีสำเร็จ")
    } else {
      toast.error("ลบบัญชีสำเร็จไม่สำเร็จ")
    }
  }

  return (
    <Modal
      centered
      visible={open || isEditing}
      className="CustomerForm"
      title={isEditing ? 'แก้ไขข้อมูลลูกค้า' : 'สมัครสมาชิกลูกค้า'}
      footer={(
        <>
          <Button size='large' style={{ width: 100 }} onClick={onCancalHandler} >
            ยกเลิก
          </Button>
          <Button
            disabled={!isValidForm}
            htmlType='submit' size='large' type='primary' style={{ width: 180 }}
            onClick={() => {
              if (isEditing) {
                confirmSubmitForm();
              } else {
                onSubmit();
              }
            }}
          >
            {isEditing ? 'บันทึก' : 'สมัครสมาชิก'}
          </Button>
        </>
      )}
    >
      <div className='customer-form' style={{ paddingLeft: 10, paddingRight: 10 }}>
        <Spin spinning={isLoading}>
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onError={(value) => {
              console.log('value', value);

            }}
            onValuesChange={(value, _form) => {
              const isChangingTrueWallet = 'trueWalletId' in value;
              const isTrueWalletError = form.getFieldsError().filter(f => f.name[0] === 'trueWalletId' && f.errors.length > 0).length > 0;
              if (isChangingTrueWallet && isTrueWalletError) {
                setIsTrueWalletValid(false)
              } else {
                setIsTrueWalletValid(true)
              }
            }}
            onFieldsChange={(_form, _fields) => {
              const allRequireField = _fields
                .filter(_fields => {
                  const field = _fields.name.toString();
                  return ['trueWalletId', 'lineLinked', 'accountNameEN'].indexOf(field) < 0
                })
                .every(_field => _field.value !== undefined && _field.value !== "");
              const hasErrorField = form.getFieldsError().some(_f => _f.errors.length > 0);
              if (editingCustomer) {
                setIsValidForm(allRequireField && !hasErrorField && isEditFormChanged(form.getFieldsValue()));
              } else {
                setIsValidForm(allRequireField && !hasErrorField);
              }

            }}
          >
            <Typography.Title level={5}>
              <Row gutter={[16, 16]}>
                <Col flex="auto">
                  <div>
                    การเข้าสู่ระบบ
                  </div>
                </Col>
                <Col flex="auto" style={{ textAlign: 'right' }}>
                  {isEditing && (
                    (!editingCustomer?.hasKyc && editingCustomer?.bank.bankCode === BankCode.NONE && editingCustomer?.playerNickname !== '0000000000')
                      ? (<Button shape="round" onClick={() => { confirmClearUser() }}>บัญชีซ้ำ</Button>)
                      : null
                  )}
                </Col>
              </Row>
            </Typography.Title>
            <Row gutter={[16, 16]}>
              <Col span={isEditing ? 24 : 12}>
                <Form.Item
                  label="เบอร์โทรศัพท์"
                  name="phone"
                  required={canEditBank ? true : false}
                  validateStatus={customerFormResult.phoneNumber?.validateStatus}
                  help={customerFormResult.phoneNumber?.help}
                  rules={[
                    { pattern: new RegExp(`([0]{1}[0-9]{${PHONE_DIGITS - 1}})|^\S*$`), message: 'รูปแบบไม่ถูกต้อง' },
                  ]}
                >
                  <Input placeholder='ใส่เบอร์โทรศัพท์' maxLength={PHONE_DIGITS} size='large' disabled={canEditBank ? false : true} />
                </Form.Item>
              </Col>
              {!isEditing && (
                <Col span={12}>
                  <Form.Item
                    label="รหัส PIN"
                    name="pin"
                    required
                    rules={[
                      { pattern: new RegExp(`(^[0-9]{${PIN_DIGITS}})|^\S*$`), message: 'รูปแบบไม่ถูกต้อง' },
                    ]}
                  >
                    <Input placeholder='ใส่เลข 6 ตัว' maxLength={PIN_DIGITS} size='large' />
                  </Form.Item>
                </Col>
              )}
            </Row>
            {/* TODO : bring back when integrate link line */}
            {/* {isEditing && (
              <>
                <Typography.Title level={5}>
                  การเชื่อมต่อ
                </Typography.Title>
                <Form.Item
                  name="lineLinked"
                >
                  <Switch disabled /><span style={{ margin: '0 10px' }}>การเชื่อมต่อไลน์ (Coming soon)</span>
                </Form.Item>
              </>
            )} */}
            <Typography.Title level={5}>
              ข้อมูลธนาคาร
            </Typography.Title>
            {isEditing && (
              <Form.Item
                label="ชื่อตามบัตรประชาชน"
                name="nameIdCard"
              >
                <Input placeholder='-' size='large' disabled />
              </Form.Item>
            )}
            <Form.Item
              label="ธนาคาร"
              name="bank"
              required={canEditBank ? true : false}
            >
              <BankSelect
                disabled={canEditBank ? false : true}
                width='100%'
                placeholder="เลือกธนาคาร"
                onChange={(bankCode) => {
                  const isTenDigitsBank = TEN_DIGITS_BANK.some(b => b === bankCode)
                  const changingMaxDigitsBank = isTenDigitsBank ? 10 : 12
                  if (changingMaxDigitsBank !== maxDigitsBank) {
                    setMaxDigitsBank(changingMaxDigitsBank)
                    form.setFields([{ name: 'accountNumber', value: '' }])
                    setIsValidForm(false);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="ชื่อบัญชี"
              name="accountName"
              required={canEditBank ? true : false}
              validateStatus={customerFormResult.accountName?.validateStatus}
              help={customerFormResult.accountName?.help}
            >
              <Input placeholder='ใส่ชื่อบัญชี' size='large' disabled={canEditBank ? false : true} />
            </Form.Item>
            <Form.Item
              label="ชื่อบัญชี EN"
              name="accountNameEN"
              validateStatus={customerFormResult.accountNameEN?.validateStatus}
              help={customerFormResult.accountNameEN?.help}
            >
              <Input placeholder='ใส่ชื่อบัญชี' size='large' disabled={canEditBank ? false : true} />
            </Form.Item>
            <Form.Item
              label="เลขที่บัญชี"
              name="accountNumber"
              required={canEditBank ? true : false}
              validateStatus={customerFormResult.accountNumber?.validateStatus}
              help={customerFormResult.accountNumber?.help}
              rules={[
                { pattern: new RegExp(`(^[0-9]{${maxDigitsBank}})|^\S*$`), message: 'รูปแบบไม่ถูกต้อง' },
              ]}
            >
              <Input placeholder='ใส่เลขที่บัญชี' maxLength={maxDigitsBank} size='large' disabled={canEditBank ? false : true} />
            </Form.Item>
            {
              editingCustomer &&
              <Form.Item
                label="บัญชีธนาคาร PIGSPIN"
                name="bank_pigspin"
                required={canEditBank ? true : false}
              >
                <BankAccountSelect
                  disabled={canEditBank ? false : true}
                  options={bankPigspins}
                  width='100%'
                  placeholder="บัญชีธนาคาร PIGSPIN"
                  onChange={(bankCode) => {
                    try {
                      const value = JSON.parse(bankCode)
                      form.setFieldsValue({ bank_pigspin: getBankPigSpinValue(value.default_bgb_deposit_bank_code, value.default_group_bank_code) })
                    } catch (error) {
                      console.error('Cannot parse bank code')
                    }
                  }}
                />
              </Form.Item>
            }
            <Typography.Title level={5}>
              ข้อมูลทรูมันนี่
            </Typography.Title>
            {/* TODO : Integrate Truemoney */}
            <Form.Item
              label="ทรูวอลเล็ท ไอดี"
              name="trueWalletId"
              validateStatus={customerFormResult.trueWalletId?.validateStatus}
              help={customerFormResult.trueWalletId?.help}
            >
              <Input
                disabled={canEditBank ? false : true}
                placeholder='ใส่ทรูวอลเล็ท ไอดี (ถ้ามี)'
                onBlur={() => { form.setFieldsValue({ trueWalletId: (form.getFieldsValue().trueWalletId) }); }}
                size='large' />
            </Form.Item>
            {
              !isTrueWalletValid && (
                <div>
                  <Typography.Text>รูปแบบชื่อทรูวอลเล็ท ไอดี</Typography.Text>
                </div>
              )
            }
            {
              isEditing && (
                <>
                  <Space direction="vertical" size="large" style={{ padding: "10px 0 0 0", width: "100%" }}>
                    <PubnubChatStatus user_uuid={editingCustomer?.customerId} chatStatus={pubnubChatStatus?.results} />
                  </Space>
                  <Space direction="vertical" size="large" style={{ padding: "10px 0 0 0", width: "100%" }}>
                    <div>
                      <Row>
                        <Col span={24} >
                          <div>
                            <Typography.Title level={5}>
                              สถานะ
                            </Typography.Title>
                          </div>
                          <Form.Item name="status" style={{ border: `1px solid ${colors['@gray-4']}`, borderRadius: 6, padding: '10px 20px' }}>
                            <Radio.Group>
                              <Space direction="vertical">
                                <Radio value={CustomerStatus.ACTIVE}><Status type={CustomerStatus.ACTIVE} /></Radio>
                                <Radio value={CustomerStatus.DISABLED}><Status type={CustomerStatus.DISABLED} /></Radio>
                                <Radio value={CustomerStatus.BLOCKED}><Status type={CustomerStatus.BLOCKED} /></Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Row gutter={[10, 10]}>
                            <Col span={3}>
                              <Typography.Text strong>
                                ไลน์
                              </Typography.Text>
                            </Col>
                            <Col span={21}>
                              {editingCustomer?.isLineLinked ? (
                                <Space>
                                  <Icon name="outline-linkline-1" color={colors['@green-6']} size="18px" />
                                  เชื่อมต่อ
                                </Space>
                              ) : (
                                <Space>
                                  <Icon name="outline-unlinkline-1" color={colors['@cloud-6']} size="18px" />
                                  ไม่เชื่อมต่อ
                                </Space>
                              )}
                            </Col>
                            <Col span={3}>
                              <Typography.Text strong>
                                โบนัส
                              </Typography.Text>
                            </Col>
                            <Col span={21}>
                              {editingCustomer !== undefined && (<CustomerBonusStatus {...editingCustomer.promotionState} />)}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    {/*<div>
                      <Button type='link' size='small' style={{ color: "red", padding: 0}} onClick={() => { handleOpenConfirmDeleteCustomerModal() }}>
                        <span style={{ textDecoration: 'underline'}}>ลบบัญชีทิ้ง</span>
                      </Button>
                    </div>*/}
                  </Space>
                </>
              )
            }
          </Form >
        </Spin >
        <CustomerConfirmDeleteModal
          open={openConfirmDeleteCustomer}
          onClose={handleCloseConfirmDeleteCustomerModal}
          onConfirm={(value: boolean) => { handleDeleteCustomerModal(value) }}
          editingCustomer={editingCustomer}
        />
      </div >
    </Modal >
    
  );
};

export default CustomerForm;
