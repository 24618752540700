import * as React from 'react';
import { Space as AntdSpace, SpaceProps } from 'antd';

export type ISpaceProps = SpaceProps & {
}

const Space: React.FunctionComponent<ISpaceProps> = (props) => {
  return (
    <AntdSpace {...props}>
      {props.children}
    </AntdSpace>
  );
};

export default Space;
