import { useMutation, UseMutationOptions } from "react-query";
import { toast } from "src/components";
import { CoinWalletUpdate } from "src/types";
import { api } from "src/utils";

export function useUpdateCoinWallet(
  options?: UseMutationOptions<unknown, unknown, CoinWalletUpdate, unknown>
) {
  return useMutation((wallet) => api.patch("payment/coin-wallets", wallet), {
    onError: () => toast.error("แก้ไขไม่สำเร็จ"),
    onSuccess: () => toast.success("แก้ไขสำเร็จ"),
    ...options,
  });
}
