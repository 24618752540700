import { Typography } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import InfoGraphic from 'src/assets/images/error-page-404.png'
import Button from 'src/components/Button/Button';
import DeskPanel from 'src/components/DeskPanel/DesktPanel';
import Space from 'src/components/Space/Space';
import colors from 'src/theme/colors.json';

interface IFourZeroFourPageProps {
}

export const FourZeroFourPage: React.FunctionComponent<IFourZeroFourPageProps> = (props) => {
  return (
    <div>
      <DeskPanel
        body={(
          <div style={{ textAlign: 'center' }}>
            <Space style={{ minHeight: '80vh', textAlign: 'center' }}>
              <div>
                <img
                  src={InfoGraphic}
                  alt='error-page-403.png'
                  style={{
                    width: '500px',
                  }}
                />
                <Typography.Title level={3} style={{ color: colors['@cloud-10'], margin: '1rem 0' }}>ไม่มีหน้านี้แล้ว</Typography.Title>
                <Typography.Text style={{ color: colors['@cloud-7'] }}>ขออภัย หน้าที่คุณเยี่ยมชมไม่มีอยู่</Typography.Text>
                <div style={{ margin: '1rem 0' }}>
                  <Button type='primary' size='large' style={{ width: 167 }}>
                    <Link to={'/'}>
                      กลับหน้าหลัก
                    </Link>
                  </Button>
                </div>
              </div>
            </Space>
          </div >
        )}
      />
    </div >
  );
};

export default FourZeroFourPage;
