import { Col, Divider, Row, Space } from 'antd';
import * as React from 'react';
import colors from 'src/theme/colors.json';
import { autoLineBreakerWithBr } from 'src/utils/textOperation/textOperation';
import { DateTime } from '..';

export type Log = {
  logId: string;
  createdAt: Date;
  remark: string;
}

interface IViewMoreProps {
  render?: (log: Log) => JSX.Element;
  divider?: boolean;
  logs: Log[];
}

const ViewMoreLogs: React.FunctionComponent<IViewMoreProps> = (props) => {
  const { logs, render, divider } = props;
  return (
    <Space direction="vertical" size="large" style={{ padding: "0 20px", width: "100%" }}>
      {!!logs?.length && (
        <div>
          <Row>
            <Col span={9}>
              <div style={{ fontWeight: 700, color: colors['@cloud-6'] }}>วันเวลาทำรายการ</div>
            </Col>
            <Col span={15}>
              <div style={{ fontWeight: 700, color: colors['@cloud-6'] }}>ทำรายการ</div>
            </Col>
          </Row>
          {logs?.map((log) => (
            <>
              <Row key={log.logId}>
                <Col span={9}><DateTime value={log.createdAt.toString()} /></Col>
                <Col span={15}>{render ? render(log) : autoLineBreakerWithBr(log.remark)}</Col>
              </Row>
              {divider && (<Divider />)}
            </>
          ))}
        </div>
      )}
    </Space>
  );
};

export default ViewMoreLogs;
