import * as React from 'react';
import { OUTLINE, Pill, Icon, ResultBanner, PillStyle } from 'src/components';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal/Modal';
import TransactionInfo from 'src/components/TransactionInfo';
import './AdjustTransactionModal.less';
import { Col, Row, Spin, Typography } from 'antd';
import colors from 'src/theme/colors.json';
import { AdjustTransactionStep, initialResult, RESULT_CONFIG, TransactionState } from 'src/hooks/useTransaction/useTransaction';
import { DEPOSIT_STATUS } from 'src/hooks/useTransaction/type';
import { wrongUser, wrongSlip } from 'src/services/adjustTransaction';
import { useRecoilValue } from 'recoil';
import { authState } from 'src/recoils';
import Toast from 'src/components/Toast';
import { useUserInfo } from 'src/hooks/useTransaction/useUserInfo';
import { GetTransactionDetail, TransactionDetailItem } from 'src/services/transactions/transactions';
import { addCommas } from 'src/utils/number/number';

export type IAdjustTransactionModalProps = TransactionState & {
}

const transactionValueField = { fontSize: 16, fontWeight: 700 };
const withPillBaseStyle = (pillStyle: PillStyle): PillStyle => ({
  wrapper: { ...pillStyle.wrapper },
  left: { ...pillStyle.left, padding: '10px 0' },
  right: { ...pillStyle.right, padding: '10px 0' },
});

export const AdjustTransactionModalComponent: React.FC<IAdjustTransactionModalProps> = (props) => {
  const { adjustingTransaction, cancelReserveTransaction, reserveTransaction, reserveUser, setShouldCallAdjustDeposit, step, setStep } = props;
  const [selectingTransaction, setSelectingTransaction] = React.useState<TransactionDetailItem>();
  const { token = '' } = useRecoilValue(authState);
  const { doSearchingUserInfo } = useUserInfo();

  const onCloseModal = () => {
    if (adjustingTransaction) cancelReserveTransaction(adjustingTransaction);
  };

  const onClickWrongUser = async () => {
    try {
      const response = await wrongUser({
        token,
        user_uid: adjustingTransaction?.userUid || '',
        transaction_uid: adjustingTransaction?.transactionId || '',
      });
      if (response.service_code === 'BOE-2000') {
        setStep({
          ...step,
          at: AdjustTransactionStep.AdjustUserSuccess,
          isLoading: false,
        });
        return;
      }
      if (response.service_code === 'BOE-2001' && adjustingTransaction) {
        setStep({
          ...step,
          at: AdjustTransactionStep.AdjustUserError,
          isLoading: false,
          payload: {
            newTransaction: {
              ...adjustingTransaction,
              transactionId: response.data.uid,
            }
          }
        });
        return;
      }
      Toast.error('ไม่สามารถแก้ไขรายการได้')
    } catch {
      Toast.error('ไม่สามารถแก้ไขรายการได้')
    }
  };

  const onClickWrongSlip = async () => {
    try {
      const response = await wrongSlip({
        token,
        user_uid: adjustingTransaction?.userUid || '',
        transaction_uid: adjustingTransaction?.transactionId || '',
      });
      if (response.service_code === 'BOE-2000') {
        setStep({
          ...step,
          at: AdjustTransactionStep.AdjustSlipSuccess,
          isLoading: false,
        });
        return;
      }
      if (response.service_code === 'BOE-2001') {
        setStep({
          ...step,
          at: AdjustTransactionStep.AdjustSlipError,
          isLoading: false,
        });
        return;
      }
      Toast.error('ไม่สามารถแก้ไขรายการได้')
    } catch {
      Toast.error('ไม่สามารถแก้ไขรายการได้')
    }
  };

  const callReserveUser = async () => {
    const userInfo = await doSearchingUserInfo(adjustingTransaction?.userAccount || '');
    reserveUser(userInfo);
  };

  const nextStep = async () => {
    switch (step.at) {
      case AdjustTransactionStep.AdjustUserSuccess:
        if (adjustingTransaction) { reserveTransaction(adjustingTransaction, DEPOSIT_STATUS.PENDING); }
        break;
      case AdjustTransactionStep.AdjustUserError:
        const newTransaction = step.payload.newTransaction;
        if (newTransaction) { reserveTransaction(newTransaction, DEPOSIT_STATUS.PENDING); }
        break;
      case AdjustTransactionStep.AdjustSlipError:
        await callReserveUser()
        setShouldCallAdjustDeposit(true);
        break;
      case AdjustTransactionStep.AdjustSlipSuccess:
        await callReserveUser()
        break;
      default:
        break;
    }
  }

  const callGetTransactionDetail = async () => {
    try {
      const response = await GetTransactionDetail({ token, transaction_uid: adjustingTransaction?.transactionId || '', user_uid: adjustingTransaction?.userUid || '' });
      if (response.service_code === 'BOE-2000') {
        setSelectingTransaction(response.data);
      } else {
        Toast.error("ไม่สามารถค้นหาข้อมูลได้")
      }
    } catch {
      Toast.error("ไม่สามารถค้นหาข้อมูลได้")
    }
  }

  const renderFooter = () => {
    return (
      <>
        {step.at === AdjustTransactionStep.NONE && (
          <>
            <Button
              className="button cancel-button"
              style={{ width: 100 }}
              size="large"
              onClick={onCloseModal}
            >
              ยกเลิก
            </Button>
            <Button
              style={{ width: 176 }}
              type="warning"
              size="large"
              onClick={onClickWrongUser}
            >
              <Icon classNames="icon" name="outline-user-1" size="22px" />
              &nbsp;ตัดเงินผิดยูส
            </Button>
            <Button
              style={{ width: 176, background: colors['@volcano-6'] }}
              type="danger"
              size="large"
              onClick={onClickWrongSlip}
            >
              <Icon classNames="icon" name="outline-slip-1" size="22px" />
              &nbsp;ตัดเงินผิดสลิป
            </Button>
          </>
        )}
        {step.at !== AdjustTransactionStep.NONE && (
          <>
            <Button
                className="button cancel-button"
                style={{ width: 100, float: 'none' }}
                size="large"
                onClick={onCloseModal}
              >
                ปิด
            </Button>
            {/* <Button
              style={{ width: 176, float: 'none' }}
              type="primary"
              size="large"
              onClick={onCloseModal}
            >
              ยกเลิก
            </Button> */}
          </>
        )}
      </>
    )
  };

  const renderTransactionDetail = () => {
    if (!adjustingTransaction
      || step.at === AdjustTransactionStep.AdjustSlipSuccess
      || step.at === AdjustTransactionStep.AdjustUserSuccess
    ) return null;
    return (
      <>
        <Col span={24}>
          <Typography.Title level={5} style={{ margin: 'auto', textAlign: 'center', padding: '1rem' }}>
            ข้อมูลการเติมเงิน
          </Typography.Title>
        </Col>
        <Col span={8}>
          <Pill
            customStyles={withPillBaseStyle(OUTLINE(colors['@green-6'], colors['@green-7']))}
            left={(
              <>
                <div>รหัสลูกค้า</div>
                <div style={transactionValueField}>{selectingTransaction?.customer_code || '-'}</div>
              </>
            )}
            right={(
              <>
                <div>เบอร์โทรศัพท์</div>
                <div style={transactionValueField}>{selectingTransaction?.phone_number || '-'}</div>
              </>
            )}
          />
        </Col>
        <Col span={8}>
          <Pill
            customStyles={withPillBaseStyle(OUTLINE(colors['@cloud-4'], colors['@cloud-10']))}
            left={(
              <>
                <div>ยอดก่อนเติม</div>
                <div style={transactionValueField}>{addCommas((
                  selectingTransaction && (selectingTransaction?.amount < selectingTransaction?.cash_balance) ?
                  selectingTransaction.cash_balance - selectingTransaction.amount
                    : 0
                ).toFixed(2))}</div>
              </>
            )}
            right={(
              <>
                <div>ยอดหลังเติม</div>
                <div style={transactionValueField}>{selectingTransaction?.cash_balance ? addCommas(selectingTransaction?.cash_balance.toFixed(2)) : '0.00'}</div>
              </>
            )}
          />
        </Col>
        <Col span={8}>
          <Pill
            customStyles={withPillBaseStyle(OUTLINE(colors['@green-6'], colors['@green-7']))}
            left={(
              <>
                <div>โบนัสที่ได้รับ</div>
                <div style={transactionValueField}>{selectingTransaction?.coin_bonus ? addCommas(selectingTransaction?.coin_bonus.toFixed(2)) : '-'}</div>
              </>
            )}
            right={(
              <>
                <div>ยอดเงินที่ถูกเติม</div>
                <div style={transactionValueField}>{selectingTransaction?.amount ? addCommas(selectingTransaction?.amount.toFixed(2)) : '-'}</div>
              </>
            )}
          />
        </Col>
      </>
    )
  };

  React.useEffect(() => {
    setStep(initialResult);
    if (adjustingTransaction) { callGetTransactionDetail(); }
  }, [adjustingTransaction]);

  return (
    <>
      <Modal
        centered
        className="adjust-transaction-modal"
        visible={adjustingTransaction !== undefined}
        title={<div>การตัดเงินและแก้ไขผิดยูส/ผิดสลิป</div>}
        footer={renderFooter()}
      >
        {adjustingTransaction && (
          <>
            <TransactionInfo transaction={adjustingTransaction} />

            <Row gutter={[8, 8]} style={{ padding: '1rem', paddingTop: 0 }}>
              {renderTransactionDetail()}
              <Col span={24}>
                {step && step.at !== AdjustTransactionStep.NONE && (
                  <Spin spinning={false}>
                    <>
                      <ResultBanner
                        type={RESULT_CONFIG[step.at].type}
                        text={RESULT_CONFIG[step.at].text}
                      />
                      <div style={{
                        textAlign: 'center',
                        background: RESULT_CONFIG[step.at].descriptionBackground,
                        color: colors['@cloud-10'],
                        fontSize: 16,
                        fontWeight: 700,
                        padding: '12px',
                        borderRadius: '4px',
                        marginTop: '1rem',
                        lineHeight: 2,
                      }}>
                        {RESULT_CONFIG[step.at].description(adjustingTransaction)}
                      </div>
                    </>
                  </Spin>
                )}
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export const AdjustTransactionModal = (props: IAdjustTransactionModalProps) => React.useMemo(() => <AdjustTransactionModalComponent {...props} />, [props])

export default AdjustTransactionModal;
