import { useQuery, UseQueryOptions } from "react-query";
import { VipHistoryAPI, VipHistoryResult } from "src/types";
import { api } from "src/utils";

export interface VipHistoryRequest {
  customerCode: string;
}

export const mapToVipHistoryResult = (_item: VipHistoryResult): VipHistoryResult => {
  return {
    customerCode: _item.customerCode,
    tier: _item.tier,
    level: _item.level,
    receivedAt: _item.receivedAt,
    type: _item.type,
    detail: _item.detail,
  }
}

export function useListVipHistory<T = VipHistoryAPI<VipHistoryResult>>(
  filters?: VipHistoryRequest,
  options?: UseQueryOptions<VipHistoryAPI<VipHistoryResult>, unknown, T, any[]>
) {
  return useQuery(
    ["vip_history", filters],
    async () => {
      if (filters?.customerCode) {
        const response = await api.get("loyalty-shop/vip/redeem/history/"+filters?.customerCode);
        return {
          ...response.data.data,
          result: response.data.data.result.map(mapToVipHistoryResult),
        };
      } else {
        return { turnOver: 0, vipLevel: 0, result: [] };
      }
    },
    options
  );
}

