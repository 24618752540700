import * as React from 'react';
import { Form, Input, Select, Space } from 'antd';
import { useRecoilValue } from 'recoil';
import { ColumnsType } from 'antd/lib/table';

import { Button, EditButton, InputNumber, PageHeader } from 'src/components';
import DeskPanel from 'src/components/DeskPanel/DesktPanel';
import Table from 'src/components/Table';
import { ConfigItem, useConfig } from 'src/hooks/useConfig';
import { authInfoState } from 'src/recoils';

interface IConfigurationPageProps {
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text' | 'dropdown';
  record: ConfigItem;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {

  const FormInput = React.useMemo(() => {
    switch (inputType) {
      case 'number':
        return <InputNumber />;
      case 'text':
        return <Input />;
      case 'dropdown':
        return (
          <Select>
            <Select.Option value={0}>ไม่ตั้งเวลา</Select.Option>
            <Select.Option value={5}>5 นาที</Select.Option>
            <Select.Option value={10}>10 นาที</Select.Option>
            <Select.Option value={15}>15 นาที</Select.Option>
          </Select>
        );
      default:
        return null;
    }
  }, [inputType]);

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {FormInput}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};



const ConfigurationPage: React.FunctionComponent<IConfigurationPageProps> = (props) => {
  const [form] = Form.useForm<ConfigItem>();
  const { scope } = useRecoilValue(authInfoState);
  const [editingId, setEditingId] = React.useState('');
  const isEditing = (record: ConfigItem) => record.id === editingId;
  const { configList, callUpdateConfig } = useConfig();

  const edit = (record: Partial<ConfigItem> & { key: React.Key }) => {
    form.setFieldsValue(record);
    setEditingId(record.id || '');
  };

  const cancel = () => {
    setEditingId('');
  };

  const columns: ColumnsType<ConfigItem> = [
    {
      title: 'การตั้งค่าไม่ลงเวลารายการย้อนหลัง',
      dataIndex: 'name',
    },
    {
      title: '',
      dataIndex: 'value',
      render: (value: number, row: ConfigItem) => {
        if (value === 0) return 'ไม่ตั้งเวลา';
        return (
          <div>
            {value} นาที
          </div>
        )
      },
      onCell: (record: ConfigItem) => ({
        record,
        editing: isEditing(record),
        inputType: 'dropdown',
        dataIndex: 'value',
        title: 'value',
      }),
    },
    {
      title: '',
      dataIndex: 'value',
      fixed: 'right',
      width: 170,
      render: (value: string, row: ConfigItem) => {
        if (scope['EDIT-CONFIG']?.enable) {
          const editable = isEditing(row);
          return (
            <div style={{ textAlign: 'right' }}>
              {editable ? (
                <Space>
                  <Button type="primary" onClick={async () => {
                    await callUpdateConfig({
                      bankCode: row.id,
                      delayTime: form.getFieldsValue().value,
                    })
                    cancel();
                  }}>
                    บันทึก
                  </Button>
                  <Button onClick={() => cancel()}>
                    ยกเลิก
                  </Button>
                </Space>
              ) : (
                <Space>
                  <EditButton onClick={() => {
                    edit({
                      ...row,
                      key: row.id,
                    });
                  }} />
                </Space>
              )}
            </div>
          );
        }
        return null;
      },
    }
  ]

  return (
    <div id="ConfigurationPage">
      <DeskPanel
        body={(
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              isLoading={false}
              isEmpty={false}
              scroll={{ x: 800 }}
              totalItem={0}
              offset={0}
              limit={99}
              onPaginationChange={() => {
                cancel();
              }}
              hideOnSinglePage={true}
              dataSource={configList}
              columns={columns}
            />
          </Form>
        )}
      />
    </div>
  );
};

export default ConfigurationPage;
