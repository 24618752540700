import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Space, Input, Button, Switch } from 'antd';
import {DateTime} from "src/components";
import './PubnubChat.scss';
import { api } from "src/utils";
import SpaceCom from '../Space/Space';
import { useRecoilState } from "recoil";
import { authState } from "src/recoils";
import { toast } from 'src/components';
import { PubnubTokenStatus } from "src/types";
import { BanChatManual, CancelBanChat } from "src/hooks/pubnubStatus";

interface IStatusProps {
  chatStatus?: PubnubTokenStatus;
  user_uuid?: string | undefined
}

const banLimit = 1825

const PubnubChatStatus: React.FunctionComponent<IStatusProps> = (props) => {
  const { chatStatus, user_uuid } = props;
  const [auth] = useRecoilState(authState);
  const [pubnubChatStatus, setPubnubChatStatus] = useState<PubnubTokenStatus | undefined>();
  const [banDisabled, setBanDisabled] = useState(true);
  const [banDayInput, setBanDayInput] = useState('');
  const [banRain, setBanRain] = useState(false)
  const { refetch: fetchPubnubBanChat, isFetching } = BanChatManual(user_uuid, Number(banDayInput), false);
  const { refetch: fetchPubnubCancelBanChat, isFetching: isCancelFetching } = CancelBanChat(user_uuid, false);
  const canTrigger = (auth.role !== "DM") && (auth.role !== "Owner");
  
  useEffect(() => {
    setPubnubChatStatus(chatStatus)
    if (chatStatus?.rainBanned !== undefined) {
      setBanRain(chatStatus.rainBanned);
    }
  }, [user_uuid, chatStatus])

  const onBanDayInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setBanDayInput(inputValue);
    setBanDisabled(!(Number(inputValue) > 0 && Number(inputValue) <= banLimit));
  };

  const handleBanRain = async (checked: boolean) => {
    setBanRain(checked);
    const response = await api.get("/loyalty-shop/pubnub/banrain", { params: { userUuid: user_uuid, status: checked }});
    if (pubnubChatStatus) {
      setPubnubChatStatus({
        ...(pubnubChatStatus || {}),
        rainBanned: checked
      });
    }
  };

  const handleBan = async () => {
    try {
      if (Number(banDayInput) > 0 && Number(banDayInput) <= banLimit) {
        setBanDisabled(true);
        const response = await fetchPubnubBanChat();
        const { data: banData, isSuccess } = response;
        if (isSuccess && pubnubChatStatus) {
          setPubnubChatStatus({
            ...(pubnubChatStatus || {}),
            isBan: true,
            isWrite: false,
            startBanDate: banData?.startDate || null,
            endBanDate: banData?.endDate || null
          });
        }
      }
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const handleCancelBanChat = async () => {
    try {
      const response = await fetchPubnubCancelBanChat();
      const { data: cancelBanData, isSuccess } = response;
      if (isSuccess && pubnubChatStatus) {
        toast.success('ยกเลิกแบนสำเร็จ')
        setBanDisabled(false);
        setPubnubChatStatus({
          ...(pubnubChatStatus || {}),
          isBan: false,
          isWrite: true,
          startBanDate: null,
          endBanDate: null
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  const renderBanDate = () => {
    if (pubnubChatStatus?.isBan) {
      return (
        <div>
          <Col span={22}>
            <label>ตั้งแต่วันที่</label>
            &nbsp;
            <DateTime value={pubnubChatStatus?.startBanDate || ''} /> -
            &nbsp;
            <DateTime value={pubnubChatStatus?.endBanDate || ''} />
          </Col>
          <Col span={24}>
            <div>
              <Button size='large' danger onClick={handleCancelBanChat} disabled={isCancelFetching || canTrigger}>
                ยกเลิกแบนแชท
              </Button>
            </div>
          </Col>
        </div>
      );
    }
  };

  return (
    <>
      {pubnubChatStatus && (
        <Row>
          <Col span={22}>
            <div>
              <Typography.Title level={5}>แบนแชท</Typography.Title>
            </div>
          </Col>
          {pubnubChatStatus?.isWrite === false && !pubnubChatStatus.isBan && (
            <Col span={24}>
              <div>
                <label>จำนวนเทิร์น {pubnubChatStatus.turnover}/{pubnubChatStatus.totalTurnover}</label>
              </div>
            </Col>
          )}
        </Row>
      )}

      {pubnubChatStatus?.isBan && (
        <Row>
          <Col span={24}>{renderBanDate()}</Col>
        </Row>
      )}

      {pubnubChatStatus?.isWrite && !pubnubChatStatus.isBan && (
        <Row gutter={[10, 10]}>
          <Col span={5}>
            <Space align="baseline">
              <Input
                type="text"
                className="pubnub-chat-input-ban"
                onChange={onBanDayInputChange}
                disabled={isFetching || canTrigger}
                value={banDayInput}
              />
            </Space>
          </Col>
          <Col span={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Space align="center">
              <span>วัน</span>
            </Space>
          </Col>
          <Col span={18} style={{ textAlign: 'right' }}>
            <Button type="primary" danger onClick={handleBan} disabled={banDisabled || canTrigger}>
              แบน
            </Button>
          </Col>
        </Row>
      )}

      {pubnubChatStatus && (
        <>
          <Row style={{ marginTop: 16 }}>
            <Col span={22}>
              <div>
                <Typography.Title level={5}>แบนรับเงินโปรย</Typography.Title>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={22}>
              <Switch
                checkedChildren="แบน"
                unCheckedChildren="ไม่แบน"
                defaultChecked={banRain}
                disabled={canTrigger}
                checked={banRain}
                onChange={(checked) => handleBanRain(checked)}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default PubnubChatStatus;
