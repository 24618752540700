import { Col, Form, Input, Row } from 'antd';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchButton } from 'src/components';
import OrgChartTable, { OrgChartHandler } from 'src/components/Affiliate/OrgChartTable';
import DeskPanel from 'src/components/DeskPanel/DesktPanel';

interface IAffiliateOrgChartProps {
}

const AffiliateOrgChart: React.FunctionComponent<IAffiliateOrgChartProps> = (props) => {
  const { search } = useLocation();
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const orgChart = React.useRef<OrgChartHandler>(null);
  const params = React.useMemo(() => new URLSearchParams(search), [search]);

  React.useEffect(() => {
    form.setFieldsValue({
      textSearch: params.get('userCode')
    });
  }, [params.get('userCode')]);

  return (
    <div id="AffiliateOrgChart">
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={(form) => {
            const shouldRefetch = form.textSearch === params.get('userCode');
            if (shouldRefetch) {
              orgChart.current?.getOrgChart();
              return;
            }
            orgChart.current?.setBreadcrumb([]);
            if (form.textSearch) {
              navigate(`?userCode=${form.textSearch}`);
            }
          }}>
          <Row gutter={[16, 16]}>
            <Col span={12} lg={8} xl={6}>
              <Form.Item name="textSearch" label="รหัสหรือเบอร์โทร">
                <Input size="large" placeholder="ใส่รหัสหรือเบอร์โทร" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label=" ">
                <SearchButton
                  size="large"
                  htmlType="submit"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {params.get('userCode') && (
        <DeskPanel
          body={(
            <OrgChartTable ref={orgChart} userCode={params.get('userCode') || ''} />
          )}
        />
      )}
    </div>
  );
};

export default AffiliateOrgChart;
