import * as React from 'react';
import classnames from 'classnames';
import { Space, SpaceProps } from 'antd';
import { BankAccount } from 'src/types';
import { getBankFormat } from 'src/utils/textOperation/textOperation';
import BankLogo from '../BankLogo';
import './Account.less';

export type IAccountProps = BankAccount & SpaceProps & {
  bankLogoStyles?: React.CSSProperties;
  font?: 'normal' | 'blod';
  size?: 'small' | 'middle' | 'large';
}

const Account: React.FC<IAccountProps> = (props) => {
  const { size = 'middle' } = props
  return (
    <Space className={classnames('back-office-account', props.className)}>
      {props.bankCode && (<BankLogo bankCode={props.bankCode} style={{ ...props.bankLogoStyles }} />)}
      <div>
        <div className={classnames('back-office-account-name', {
          'back-office-account-name--bold': props.font === 'blod',
          'back-office-account-name--large': size === 'large',
        })}>{props.accountName || '-'}</div>
        <div className={classnames('back-office-account-account-number', {
          'back-office-account-account-number--bold': props.font === 'blod',
          'back-office-account-account-number--large': size === 'large',
        })}>{props.accountNumber && getBankFormat(props.accountNumber)}</div>
      </div>
    </Space>
  );
};

export default Account;
