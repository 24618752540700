import { AxiosRequestConfig } from 'axios';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse2 } from "../service.type";

export type WrongUserRequest = {
  transaction_uid: string;
  token: string;
  user_uid: string;
};

export type WrongUserResponse = BaseResponse2<{
  created_at: Date;
  status: string;
  uid: string;
  updated_at: Date;
}>;

export const wrongUser = async ({
  transaction_uid,
  token,
  user_uid,
}: WrongUserRequest): Promise<WrongUserResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/payment/deposit/wrong-user',
    method: 'POST',
    data: {
      transaction_uid,
      customer_code: user_uid,
    },
    headers: {
      token,
    }
  };
  return fetchWithJSON<WrongUserResponse>(pigspinBackofficeApiInstance, requestConfig);
};
