import {AxiosError, AxiosRequestConfig} from 'axios';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse } from "../service.type";

export enum VERIFY_PERSONAL_NUMBER_STATUS {
  SUCCESS = 200,
  EXIST = 400,
}

export type VerifyPersonalNoRequest = {
  personal_number: string;
}

export type VerifyPersonalNoResponse = BaseResponse & {
  data: {};
}

export const verifyPersonalNo = async (request: VerifyPersonalNoRequest, token: string = '') => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: 'v1/users/verification/personal-number',
      method: 'POST',
      data: request,
      headers: { token }
    };
    const result = await fetchWithJSON<VerifyPersonalNoResponse>(pigspinBackofficeApiInstance, requestConfig);

    return result.code
  }
  catch (error) {
    const err = error as AxiosError
    if (err.response) {
      return err.response.status
    }
    return null
  }
}
