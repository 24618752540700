import { useQuery, UseQueryOptions } from "react-query";
import { CoinWallet, Pagination } from "src/types";
import { api } from "src/utils";

export interface UseCoinWalletsFilters {
  customerCode: string;
  start?: string;
  end?: string;
}

interface PaginationWithSummary extends Pagination<CoinWallet> {
  summary?: {
    cashBalance: number;
    coinBalance: number;
    totalBalance: number;
  };
}

export function useCoinWallets<T = PaginationWithSummary>(
  filters?: UseCoinWalletsFilters,
  options?: UseQueryOptions<PaginationWithSummary, unknown, T, any[]>
) {
  return useQuery(
    ["coin-wallets", filters],
    async () => {
      const response = await api.get("payment/coin-wallets", {
        params: { customerCode: filters?.customerCode, start: filters?.start, end: filters?.end },
      });
      return response.data.data;
    },
    options
  );
}
