import { BankCode } from 'src/types'
import { UserInfo as UserInfoType } from '../hooks/useTransaction/type'
import { UserInfo } from '../services/transactions'
import { mapToBankName } from "./mapToBank"

export const mapToDepositUserInfo = (_userinfo: UserInfo): UserInfoType => {
  return {
    userAccount: _userinfo.customer_code,
    userCode: _userinfo.customer_uid,
    phoneNumber: _userinfo.phone_number,
    bankAccount: {
      bankCode: _userinfo.bank.bank_code || BankCode.NONE,
      accountName: _userinfo.bank.bank_account_name || '',
      accountNumber: _userinfo.bank.bank_account_number || '',
      bankName: mapToBankName(_userinfo.bank.bank_code) || '',
    },
    lastDepositDateTime: new Date(_userinfo.last_deposit_datetime),
    lastDepositAmount: _userinfo.last_deposit_amount,
    lastWithdrawDatetime: new Date(_userinfo.last_withdraw_datetime),
    lastWithdrawAmount: _userinfo.last_withdraw_amount,
    currentBalance: _userinfo.current_balance,
    affiliateBalance: _userinfo.user_cash_affiliate_balance,
    isComplete: true,
    isFound: true,
  }
}
