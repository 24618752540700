import Icon from "@ant-design/icons/es/components/Icon"
import { ReactComponent as AlertDanger2SVG } from "src/assets/icons/alert-danger-2.svg"
import { ReactComponent as AlertDangerSVG } from "src/assets/icons/alert-danger.svg"
import { ReactComponent as AlertDecisionWarningSVG } from "src/assets/icons/alert-decision-warning.svg"
import { ReactComponent as AlertSuccessSVG } from "src/assets/icons/alert-success.svg"
import { ReactComponent as NoData1SVG } from "src/assets/icons/no-data-1.svg"
import { ReactComponent as NoData2SVG } from "src/assets/icons/no-data-2.svg"
import { ReactComponent as OutlineActive1SVG } from "src/assets/icons/outline-active-1.svg"
import { ReactComponent as OutlineAdd1SVG } from "src/assets/icons/outline-add-1.svg"
import { ReactComponent as OutlineArrowDown1SVG } from "src/assets/icons/outline-arrow-down-1.svg"
import { ReactComponent as OutlineClear1SVG } from "src/assets/icons/outline-clear-1.svg"
import { ReactComponent as OutlineClose2SVG } from "src/assets/icons/outline-close-2.svg"
import { ReactComponent as OutlineEdit1SVG } from "src/assets/icons/outline-edit-1.svg"
import { ReactComponent as OutlineInfo1SVG } from "src/assets/icons/outline-info-1.svg"
import { ReactComponent as OutlineLinkLine1SVG } from "src/assets/icons/outline-linkline-1.svg"
import { ReactComponent as OutlineMore1SVG } from "src/assets/icons/outline-more-1.svg"
import { ReactComponent as OutlineSearch2SVG } from "src/assets/icons/outline-search-2.svg"
import { ReactComponent as OutlineStop1SVG } from "src/assets/icons/outline-stop-1.svg"
import { ReactComponent as OutlineUnLinkLine1SVG } from "src/assets/icons/outline-unlinkline-1.svg"

export type IconProps = any

export function AlertDanger(props: IconProps) {
  return <Icon {...props} component={AlertDangerSVG} />
}

export function AlertDanger2(props: IconProps) {
  return <Icon {...props} component={AlertDanger2SVG} />
}

export function AlertDecisionWarning(props: IconProps) {
  return <Icon {...props} component={AlertDecisionWarningSVG} />
}

export function AlertSuccess(props: IconProps) {
  return <Icon {...props} component={AlertSuccessSVG} />
}

export function NoData1(props: IconProps) {
  return <Icon {...props} component={NoData1SVG} />
}

export function NoData2(props: IconProps) {
  return <Icon {...props} component={NoData2SVG} />
}

export function OutlineActive1(props: IconProps) {
  return <Icon {...props} component={OutlineActive1SVG} />
}

export function OutlineAdd1(props: IconProps) {
  return <Icon {...props} component={OutlineAdd1SVG} />
}

export function OutlineArrowDown1(props: IconProps) {
  return <Icon {...props} component={OutlineArrowDown1SVG} />
}

export function OutlineClear1(props: IconProps) {
  return <Icon {...props} component={OutlineClear1SVG} />
}

export function OutlineClose2(props: IconProps) {
  return <Icon {...props} component={OutlineClose2SVG} />
}

export function OutlineEdit1(props: IconProps) {
  return <Icon {...props} component={OutlineEdit1SVG} />
}

export function OutlineInfo1(props: IconProps) {
  return <Icon {...props} component={OutlineInfo1SVG} />
}

export function OutlineLinkLine1(props: IconProps) {
  return <Icon {...props} component={OutlineLinkLine1SVG} />
}

export function OutlineMore1(props: IconProps) {
  return <Icon {...props} component={OutlineMore1SVG} />
}

export function OutlineSearch2(props: IconProps) {
  return <Icon {...props} component={OutlineSearch2SVG} />
}

export function OutlineStop1(props: IconProps) {
  return <Icon {...props} component={OutlineStop1SVG} />
}

export function OutlineUnLinkLine1(props: IconProps) {
  return <Icon {...props} component={OutlineUnLinkLine1SVG} />
}
