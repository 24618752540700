import React from 'react';
import { addCommas } from "src/utils/number/number";
import { getPhoneFormat } from 'src/utils/textOperation/textOperation';

export const AmountColumn: React.FC<{ amount: number, dashed?: boolean }> = (props) => {
  return (
    <div style={{ textAlign: 'right', paddingRight: '10px' }}>
      {props.dashed && '-'}
      {!props.dashed && (props.amount ? addCommas(props.amount.toFixed(2)) : '0.00')}
    </div>
  );
}

export const PhoneColumn: React.FC<{ phoneNumber: string }> = (props) => {
  return (
    <div>
      {props.phoneNumber ? getPhoneFormat(props.phoneNumber) : '-'}
    </div>
  );
}

export const ChannelColumn: React.FC<{ channel: string }> = (props) => {
  return props.channel ? (
    <div style={{ paddingRight: '2ch' }}>
      {props.channel ? getPhoneFormat(props.channel) : '-'}
    </div>
  ) : (<></>);
}