import * as React from 'react';
import Banner from './Banner';
import colors from 'src/theme/colors.json';
import Check from '../Icon/Check';
import AlertDanger from '../Icon/AlertDanger';

export enum BannerType {
  Success = 'Success',
  Error = 'Error',
}

export type ResultBannerProps = {
  type: BannerType;
  text: string | JSX.Element;
  style?: React.CSSProperties;
};

const BANNER_CONFIG = {
  [BannerType.Success]: {
    color: colors['@green-7'],
    background: colors['@green-1'],
    icon: Check,
  },
  [BannerType.Error]: {
    color: colors['@red-7'],
    background: colors['@red-1'],
    icon: AlertDanger,
  },
}

const ResultBanner: React.FunctionComponent<ResultBannerProps> = (props) => {
  const IconComponent = BANNER_CONFIG[props.type].icon;
  return (
    <Banner
      style={props.style}
      color={BANNER_CONFIG[props.type].color}
      background={BANNER_CONFIG[props.type].background}
      content={(
        <>
         <IconComponent size={24} />
        {props.text}
        </>
      )}
    />
  );
};

export default ResultBanner;
