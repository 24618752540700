import { AxiosRequestConfig } from 'axios';
import { Role } from 'src/types';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseRequest, BaseResponse, BaseResponse2 } from "../service.type";

export type LoginRequest = {
  id_token?: string;
  email?: string;
};

export type UserInfo = {
  token?: string;
  name?: string;
  picture_base64?: string;
  role?: Role;
};

export type LoginResponse = BaseResponse & {
  data: UserInfo;
};

export const requestLogin = async (request: LoginRequest): Promise<UserInfo | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/login',
      method: 'POST',
      data: request,
    };

    const result = await fetchWithJSON<LoginResponse>(pigspinBackofficeApiInstance, requestConfig);

    return result.data as UserInfo;
  }
  catch (error) {
    return null;
  }
};

export type CheckTokenReseponse = BaseResponse2<{
  expired: boolean;
}>;

export const checkToken = async (request: BaseRequest): Promise<CheckTokenReseponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/users/check-token',
    method: 'GET',
    headers: {
      token: request.token,
    },
  };
  return await fetchWithJSON<CheckTokenReseponse>(pigspinBackofficeApiInstance, requestConfig);
}
