import { Dayjs } from "dayjs";
import { Log } from "src/components/ViewMore/ViewMoreLogs";
import { CustomerStatus, TransactionType } from "src/types";

// TODO : change to proper response
export type ApiAffiliateCustomer = {
  key?: string;
  phoneNumber: string;
  channel: string; // missing field
  income: number; // missing field
  status: CustomerStatus;
  uid: string;
  updatedAt: Date;
  createdAt: Date;
  downlineUserUid: string;
  uplineUserUid: string;
  refBy: 'LINK' | 'CODE';
  isExpired: boolean;
  expiredAt: Date;
  userCode: string;
  gameToken: string;
  playerNickname: string;
  playerUsername: string;
  currencyCode: string;
  displayName: string;
  identityNumber: boolean;
  downlineInfo?: {
    createdAt: Date;
    summaryIncome: number;
    userNickname: string;
    userUid: string;
  };
}
// TODO : change to proper response
export type ApiAffiliateTransaction = {
  createdAt: Dayjs;
  type: 'INCOME' | 'WITHDRAW';
  downlineUserCode: string;
  affiliateType: AFFILIATE_TYPE;
  status: AFFILIATE_STATUS;
  amount: number;
  turnoverAmount: number;
  turnovered: number;
  income: number;
  balance: number;
  latestActionAt: Dayjs;
};
// TODO : change to proper response
export type ApiMonthlyTransaction = {
  month: Dayjs;
  summary: number;
  kyc: number;
  firstDeposit: number;
  lossing: number;
}

export enum AFFILIATE_TYPE {
  FIRST_DEPOSIT = 'FIRST_DEPOSIT',
  KYC = 'VERIFY_KYC',
  LOSSING = 'REACH_TARGET',
  WITHDRAW = 'WITHDRAW',
  NONE = 'NONE',
};

export enum AFFILIATE_STATUS {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
};

export type AffiliateCustomer = {
  key: string;
  userCode: string;
  phone: string;
  name: string;
  channel: string;
  createdAt: Dayjs;
  income: number;
  status: CustomerStatus;
  hasChild: boolean;
  kycText: string;
  hasKyc: boolean;
  statusText?: string;
};

export type StatisticSummaryData = {
  userCode: string;
  balance: number;
  incomeOverview: {
    summary: number;
    firstDeposit: number;
    kycVerified: number;
    reachTarget: number;
    withdraw: number;
  };
};

export type AffiliateTransaction = {
  uid: string;
  createdAt: Dayjs;
  createReferAt: Dayjs;
  expiredAt: Dayjs;
  type: TransactionType;
  downlineUserCode: string;
  affiliateType: AFFILIATE_TYPE;
  status: AFFILIATE_STATUS;
  amount: number;
  bonus: number;
  turnoverAmount: number;
  turnovered: number;
  income: number;
  balance: number;
  latestActionAt: Dayjs;
  isAuto: boolean;
  logs: Log[];
  remark?: string;
  remarkType?: string;

  typeText?: string;
  affiliateTypeText?: string;
  statusText?: string;
};

export type AffiliateTransactionItem = {
  uid: string;
  updatedAt: Date;
  createdAt: Date;
  affiliateUserBalanceUserUid: string;
  downlineUid: string;
  transactionType: "DEBIT" | "CREDIT";
  affiliateType: AFFILIATE_TYPE;
  remainingBalance?: string;
  turnoverAmount?: string;
  turnoverSuccessAmount?: string;
  incomeAmount?: string;
  amount?: string;
  bonus?: string;
  status: AFFILIATE_STATUS;
  remark?: string;
  remarkType?: string;
  downlineInfo?: {
    createdAt: Date;
    userNickname: string;
    userUid: string;
    expiredAt: Date;
  };
  logs: Log[];
};

export type AffiliateTransactionData = {
  results: AffiliateTransactionItem[];
  total: {
    all: number;
    first_deposit: number;
    kyc_verified: number;
    reach_target: number;
    withdraw: number;
  };
}

export type ApiMonthlyTransactionItem = {
  month: string;
  summary: number;
  kycVerified: number;
  firstDeposit: number;
  reachTarget: number;
}

export type MonthlyTransaction = {
  month: Dayjs;
  summary: number;
  kyc: number;
  firstDeposit: number;
  lossing: number;
}
