import { useMutation, UseMutationOptions } from "react-query";
import { toast } from "src/components";
import { api } from "src/utils";

export function useRevokeCoinWallet(
  options?: UseMutationOptions<unknown, unknown, string, unknown>
) {
  return useMutation((uid: string) => api.patch(`payment/coin-wallets/${uid}/revoke`, {}), {
    onError: () => toast.error("ยกเลิกโบนัสไม่สำเร็จ"),
    onSuccess: () => toast.success("ยกเลิกโบนัสสำเร็จ"),
    ...options,
  });
}
