import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  useQueryClient,
} from "react-query";
import { toast } from "src/components";
import { Games, GameStatus, UpdateGameResponse } from "src/types";
import { api } from "src/utils";

interface GamesRequest {
  brand?: string;
}

export function useListGames<T = Games[]>(
  params?: GamesRequest,
  options?: UseQueryOptions<Games[], unknown, T, any[]>
) {
  return useQuery(
    ["games"],
    async () => {
      const response = await api.get(params?.brand ? `game/${params?.brand}/` : "game");
      return response.data?.data;
    },
    options
  );
}

interface UpdateGameParams {}

export interface UpdateGameRequest {
  isNewCard?: boolean;
  isNew?: boolean;
  gameId?: string;
  isLive?: boolean;
  isPopular?: boolean;
  order?: number;
  status?: GameStatus;
  refType?: string;
  isLobbyBanner?: boolean;
}

export function useUpdateGamePigbet(
  params?: UpdateGameParams,
  options?: UseMutationOptions<UpdateGameResponse, unknown, UpdateGameRequest, unknown>
) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ gameId, isNewCard, isNew, isPopular, status, isLobbyBanner, refType, isLive }) => {
      return api.put("game/update_game_entity/", {
        game_id: gameId,
        status,
        ref_type: refType,
        is_lobby_banner: isLobbyBanner,
        is_popular: isPopular,
        is_new: isNew,
        is_new_card: isNewCard,
        is_live: isLive,
      });
    },
    {
      onError: () => {
        toast.error("แก้ไขไม่สำเร็จ");
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["games"]);
        toast.success("แก้ไขสำเร็จ");
      },
      ...options,
    }
  );
}
