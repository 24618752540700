import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal, Form, Row } from "antd";
import { useUpdateGameBrand, BrandList } from "src/hooks/useGameBrand";
import { useState, useEffect } from 'react';
import { TreeTransfer } from "src/components/Games/TreeTransfer"
import { GameBrands } from "src/types";
import { useRecoilState } from 'recoil';
import { modalState } from 'src/recoils';

interface RecordType {
  key: string;
  status: boolean;
  title: string;
  children?: RecordType[];
}

export type GameBrandManageProps = {
  brandList: GameBrands[];
  onClose?: () => void;
  onSuccess?: () => void;
};

const groupBy = (collection: GameBrands | any, property: string) => {
  var i = 0, val, index,
      values = [], result = [];
  
  for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      var child = { key: collection[i]['name'], status:collection[i]['isActive'], title: collection[i]['name'] }
      if (index > -1) {
        result[index].children.push(child)
      } else {
        var attr = { key: collection[i]['providerName'], status:false, title: collection[i]['providerName'], children: [child] }
        values.push(val);
        result.push(attr);
      }
  }
  return result;
}

const providerPrefix = 'Provider - '

export const GameBrandManage = NiceModal.create(({ ...props }: GameBrandManageProps) => {
  
  const { visible, hide, remove } = useModal();
  const [globalModal, setModal] = useRecoilState(modalState);

  const handleClose = () => {
    hide();
    props.onClose?.();
  };

  const { mutate: update } = useUpdateGameBrand();
  const brands: GameBrands[] = props.brandList.map((brand) => ({...brand, providerName: `${providerPrefix}${brand.providerName}`}))
  
  
  const activeBrand = brands.filter((item) => item.isActive === true).map((item) => item);
  const treeData = groupBy(brands, "providerName");
  const treeDataRight = groupBy(activeBrand, "providerName");
  
  const transferDataSource: RecordType[] = [];
  function flatten(list: RecordType[] = []) {
    list.forEach((item) => {
      transferDataSource.push(item as RecordType);
      flatten(item.children);
    });
  }
  flatten(treeDataRight);

  const initialTargetKeys = transferDataSource.filter((item) => item.status === true).map((item) => item.key);
  const [targetKeys, setTargetKeys] = useState(initialTargetKeys);
  const handleFinish = () => {
    const listbrand: BrandList[] = brands.map(({ ...props }) => ({
      ...props,
      isActive: targetKeys.includes(props.name as string) ? true : false,
	  providerName: props.providerName.split(providerPrefix)?.[1]
    }));
    setModal({
      isModalVisible: true,
      type: 'warning',
      title: 'ยืนยันการตั้งค่าค่ายเกมทั้งหมด',
      content: '',
      buttonType: 'question',
      confirmText: 'ยืนยัน',
      onConfirm: () => {
        update({brandList: listbrand})
        hide();
        props.onClose?.();
      }
    })
  };

  useEffect(() => {
    setTargetKeys(initialTargetKeys);
  }, []);

  return (
    <Modal
        centered
        className="game-brand-modal-form"
        width={800}
        visible={visible}
        afterClose={remove}
        focusTriggerAfterClose={false}
        closable={false}
        title="จัดการค่ายเกมทั้งหมด"
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
            <Button key="cancel" size="large" onClick={handleClose} style={{ width: "100px" }}>
              ยกเลิก
            </Button>
            <Button
              key="submit"
              type="primary"
              size="large" 
              onClick={() => handleFinish()}
              style={{ width: "160px" }}
            >
              ยืนยัน
            </Button>
          </div>
        }
      >
        <Form
            layout="vertical"
            autoComplete="off"
            requiredMark={true}
            preserve={true}
            onFinish={handleFinish}
          >
            <div>
              <Row justify="center" align="top">
                <TreeTransfer dataSource={treeData} targetKeys={targetKeys} onChange={setTargetKeys} />
              </Row>
            </div>
          </Form>
      </Modal>
  );
});

export default GameBrandManage;
