import { useQuery, UseQueryOptions } from "react-query";

import { BaseResponse2 } from "src/services/service.type";
import { Pagination, BankReportItem } from "src/types";
import { api } from "src/utils";

export interface UseBankReportFilters {
  /** @description PIGSPIN bank account number to search */
  bankAccount?: string;
  startTime?: Date;
  endTime?: Date;
}

type BankResponseData = Omit<Pagination<BankReportItem>, "page" | "pageType" | "pageSize">;

export function useBankReport<T = BankResponseData>(
  filters?: UseBankReportFilters,
  options?: UseQueryOptions<BankResponseData, unknown, T, any[]>
) {
  return useQuery(
    ["bankReport", filters],
    async (): Promise<BankResponseData> => {
      const response = await api.get<BaseResponse2<BankResponseData>>("report/bank-statement", {
        params: filters,
      });
      return response.data.data
    },
    options,
  );
}
