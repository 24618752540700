import * as React from 'react';
import { Pagination, Table as AntdTable, TableProps } from 'antd';
import classnames from 'classnames';
import NoData from '../Icon/NoData';
import './Table.less';
import NotFound from '../Icon/NotFound';

export type OurTableProps = TableProps<any> & {
  totalItem: number;
  offset: number;
  offsetBuffer?: number; // normally offet start with 0 if offsetBuffer is 1, offset will start with 1
  limit: number;
  isLoading?: boolean;
  isEmpty?: boolean;
  isSearchNotFound?: boolean;
  hideOnSinglePage?: boolean;
  scrollX?: number;
  pageSizeOptions?: string[];
  onPaginationChange: (offset: number, limit: number) => void;
};

const Table: React.FunctionComponent<OurTableProps> = (props) => {
  const { isEmpty, isSearchNotFound, isLoading, scrollX = 1400, offsetBuffer = 0 } = props;
  const renderEmptyState = () => {
    if (isLoading) return null;
    return (
      <div className="backoffice-table-empty-state">
        <div>
          {isEmpty && (<NoData />)}
          {isSearchNotFound && (<NotFound />)}
          <div className="backoffice-table-empty-state-text">
            {isEmpty && (<label>ยังไม่มีรายการ</label>)}
            {isSearchNotFound && (<label>ไม่พบรายการ</label>)}
          </div>
        </div>
      </div>
    )
  };

  return (
    <>
      {!isEmpty && !isSearchNotFound ? (
        <>
          <AntdTable sticky scroll={{ x: scrollX }} {...props} className={classnames('backoffice-table', props.className)} loading={isLoading} pagination={false} />
          <div
            className="backoffice-table-pagination"
          >
            <Pagination
              size="small"
              pageSizeOptions={props.pageSizeOptions || ['10', '50', '100', '200']}
              showSizeChanger
              showQuickJumper
              showTotal={(total, range) => `รายการทั้งหมด ${total}`}
              pageSize={props.limit}
              onChange={(offset, limit) => {
                props.onPaginationChange && props.onPaginationChange(offset - offsetBuffer, limit);
              }}
              hideOnSinglePage={props.hideOnSinglePage}
              current={props.offset + offsetBuffer}
              total={props.totalItem}
            />
          </div>
        </>
      ) : renderEmptyState()}
    </>
  );
};
export const SELECTION_COLUMN = AntdTable.SELECTION_COLUMN;
export default Table;