import * as React from 'react';
import { InputNumber as AntdInputNumber, InputNumberProps } from "antd";
import classnames from 'classnames';
import './InputNumber.less';

interface IInputNumberProps {
}

const InputNumber: React.FunctionComponent<IInputNumberProps & InputNumberProps<string>> = (props) => {
  return (
    <AntdInputNumber
      {...props}
      className={classnames('InputNumber', props.className)}
    />
  );
};

export default InputNumber;
