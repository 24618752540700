import React from 'react';
import NiceModal from "@ebay/nice-modal-react"
import { QueryClientProvider } from "react-query"
import { Route, Routes, useNavigate } from "react-router-dom"
import { Flip, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useRecoilState, useRecoilValue } from "recoil"
import { AuthenticatedLayout } from "./components/AuthenticatedLayout"
import GolbalModal from "./components/Modal/GlobalModal"
import InprogressModal from "./components/Modal/InProgressModal"
import { routesConfig, exceptionRoute } from "./config/routes"
import { Login } from "./pages/login"
import { authInfoState, authState, modalState } from "./recoils"
import "./styles/icons.css"
import "antd-button-color/dist/css/style.less"
import { checkToken } from './services/login/login';
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from './utils';
import { withTitle } from './pages/withTitle';
import npmPackage from '../package.json';

export const TO_CHECK_TOKEN_IN_MINUTE = 4;
const BO_VERSION = `Backoffice version :  ${npmPackage.version}`;
const FourZeroFourPage = withTitle(exceptionRoute.four0four.PageComponent, exceptionRoute.four0four);
const FourZeroThreePage = withTitle(exceptionRoute.four0three.PageComponent, exceptionRoute.four0three);

function App() {
  const [auth, setAuth] = useRecoilState(authState)
  const { token = "" } = auth
  const { scope } = useRecoilValue(authInfoState)
  const [, setModal] = useRecoilState(modalState);
  const navigate = useNavigate()

  const showLogoutModal = () => {
    setModal({
      isModalVisible: true,
      type: 'error',
      title: 'ไม่สามารถดำเนินการต่อได้',
      content: (
        <>
          เนื่องจากเซสชันหมดอายุ
          <br />
          โปรดเข้าสู่ระบบอีกครั้งเพื่อต่ออายุเซสชัน
        </>
      ),
      buttonType: 'confirm',
      confirmText: 'กลับไปหน้าแรก',
      onConfirm: () => {
        setAuth({})
      }
    })
  }
  const callCheckToken = async () => {
    if (token) {
      try {
        const response = await checkToken({ token })
        if (response.service_code === 'BOE-4000' && response.service_message.startsWith("Invalid token")) {
          showLogoutModal();
        }
      } catch (e) {
        showLogoutModal();
      }
    }
  };

  const routes = React.useMemo(() => {
    return Object.values(routesConfig).filter(route => !route.disabled).map((route, index) => {
      const { PageComponent, ...restConfig } = route;
      const PageWithTitle = withTitle(PageComponent, restConfig);
      return (
        <Route key={index} path={route.path} element={scope[route.scope]?.enable ? <PageWithTitle /> : <FourZeroThreePage />} />
      )
    })
  }, [scope]);

  React.useEffect(() => {
    if (auth.token) {
      window.addEventListener("focus", callCheckToken);
      callCheckToken();
    } else {
      navigate('/login');
      window.removeEventListener("focus", callCheckToken);
    }
    return () => {
      window.removeEventListener("focus", callCheckToken);
    };
  }, [auth.token]);

  React.useEffect(() => {
    console.info(BO_VERSION);
  }, [])

  if (token !== "") {
    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <NiceModal.Provider>
          <AuthenticatedLayout>
            <Routes>
              {routes}
              <Route path="*" element={<FourZeroFourPage />} />
            </Routes>
            <GolbalModal />
            <InprogressModal />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover={false}
              transition={Flip}
              theme="colored"
            />
          </AuthenticatedLayout>
        </NiceModal.Provider>
      </QueryClientProvider>
    )
  } else {
    return <Login />
  }
}

export default App
