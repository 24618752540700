import { BankAccount, BankAccountProps, Select, SelectProps } from "src/components";

export interface BankAccountSelectOption {
  label: BankAccountProps;
  value: string;
}

export interface BankAccountSelectProps extends SelectProps<string> {
  options: BankAccountSelectOption[];
}

function BankAccountSelect({ options, ...props }: BankAccountSelectProps) {
  return (
    <Select {...props}>
      {options.map(({ label, value }) => (
        <Select.Option key={value} value={value}>
          <BankAccount {...label} />
        </Select.Option>
      ))}
    </Select>
  );
}

export default BankAccountSelect;
