import { Col, Row, Spin, Statistic as AntStatistic, StatisticProps } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { colors } from "src/constants";
import { api } from "src/utils";

export function Statistic({ title, ...props }: StatisticProps) {
  return (
    <AntStatistic
      {...props}
      title={<div style={{ color: colors.gray1, opacity: 0.6 }}>{title}</div>}
      valueStyle={{ color: "white", fontSize: 18, fontWeight: 700 }}
      precision={2}
    />
  );
}

export interface CustomerStatisticsProps {
  customerCode: string;
}

export type CustomerStatisticsHandler = {
  refetchBalance: () => void;
};

const CustomerStatisticsComponent: React.ForwardRefRenderFunction<CustomerStatisticsHandler, CustomerStatisticsProps> = ({ customerCode }, ref) => {
  const { data, isFetching, refetch } = useQuery(
    ["customerBalance", customerCode],
    async () => {
      const response = await api.get(`payment/customer/${customerCode}/balance`);
      return response.data.data as {
        cashBalance: number;
        coinBalance: number;
        totalBalance: number;
      };
    },
    { keepPreviousData: true }
  );

  React.useImperativeHandle(ref, () => ({
    refetchBalance: () => {
      refetch();
    }
  }));

  if (!data) return null;

  return (
    <Spin spinning={isFetching}>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={24}>
          <div
            style={{
              borderRadius: 8,
              padding: "8px 16px",
              background: "linear-gradient(225deg, #9737C1 0%, #5B4ABF 100%)",
            }}
          >
            <h3 style={{ color: "white" }}>ยอดเงินในกระเป๋าปัจจุบัน (THB)</h3>
            <Row>
              <Col span={8}>
                <Statistic title="ยอดเงินรวม" value={data?.totalBalance} />
              </Col>
              <Col span={8}>
                <Statistic title="กระเป๋าเงินสด" value={data?.cashBalance} />
              </Col>
              <Col span={8}>
                <Statistic title="กระเป๋าโบนัส" value={data?.coinBalance} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Spin>
  );
}

export const CustomerStatistics = React.forwardRef(CustomerStatisticsComponent);
export default CustomerStatistics;
