import { Col, Row, Space, Typography, Tag } from "antd";
import dayjs from "dayjs";
import NiceModal from "@ebay/nice-modal-react";
import { CheckOutlined } from "@ant-design/icons/lib/icons";
import Switch, { EditButton } from "src/components";
import { SystemStatusItem } from "src/hooks/useSystemStatus";
import HtmlParser from "react-html-parser";
import colors from "src/theme/colors.json";
import { SystemStatusEditForm } from './system-status-edit-form';

interface IGetColumnsProps {
  dayjsToBeDay?: () => void;
  updateSystemStatusItem: (item: SystemStatusItem) => void;
  mapDay: any;
}

export const getColumns = ({
  updateSystemStatusItem,
  mapDay,
}: IGetColumnsProps) => {
  return [
    {
      title: "ลำดับ",
      width: 50,
      dataIndex: "key",
      key: "key",
      render: (value: string, item: SystemStatusItem, index: number) => {
        return <Space>{index + 1}</Space>;
      },
    },
    {
      title: "สถานะระบบ",
      width: 128,
      dataIndex: "key",
      key: "key",
      render: (value: string, item: SystemStatusItem) => {
        return <Space>{item.name}</Space>;
      },
    },
    {
      title: "เปิด/ปิด",
      dataIndex: "value",
      width: 78,
      key: "value",
      render: (value: string, item: SystemStatusItem) => {
        const now = dayjs();
        // let inTime = false;
        let checked = false;
        let pendingStatus = false;
        if (item.type === "now") {
          checked = item.enabled;
        }
        if (item.type === "schedule") {
          //   inTime =
          //     dayjs(item.startTime).isBefore(now) &&
          //     now.isBefore(item.endTime) &&
          //     item.daily.some((d) => d === `${dayjsToBeDay[now.day()]}`);
          checked = item.enabled;
          pendingStatus = !item.enabled;
        }
        if (item.type === "custom") {
          checked = item.enabled;
          pendingStatus = !item.enabled && now.isBefore(item.endDatetime);
        }
        return (
          <Space>
            <Switch
              checked={checked}
              style={{ backgroundColor: pendingStatus ? "orange" : undefined }}
              onClick={() => {
                if (item.enabled) {
                  updateSystemStatusItem({
                    ...item,
                    enabled: false,
                    type: "now",
                  });
                  return;
                }
                updateSystemStatusItem({ ...item, enabled: true });
              }}
            />
          </Space>
        );
      },
    },
    {
      title: "วันเวลาที่แสดง",
      dataIndex: "value",
      width: 172,
      key: "value",
      render: (value: string, item: SystemStatusItem) => {
        return (
          <div>
            {item.type === "schedule" && (
              <>
                <div>
                  ทุกวัน{" "}
                  {item.daily
                    .sort()
                    .map((day) => mapDay[day])
                    .reduce((acc, day) => `${acc},${day}`)}
                </div>
                <div>
                  {dayjs(item.startTime).format("HH:mm")} - {dayjs(item.endTime).format("HH:mm")}
                </div>
              </>
            )}
            {item.type === "custom" && (
              <Space>
                {dayjs(item.startDatetime).format("DD/MM/YY HH:mm")} -{" "}
                {dayjs(item.endDatetime).format("DD/MM/YY HH:mm")}
              </Space>
            )}
            {item.type === "now" && (item.enabled ? "ทันที" : "-")}
          </div>
        );
      },
    },
    {
      title: "ข้อความ",
      dataIndex: "value",
      key: "value",
      render: (value: string, item: SystemStatusItem) => {
        return (
          <Row align="middle" style={{ padding: 4 }}>
            <Col flex="1 1 90%">
              <Typography.Text>{item.text ? HtmlParser(item.text) : "-"}</Typography.Text>
              <div style={{ marginTop: 8 }}>
                {item.toast && (
                  <Tag color={colors["@pink-6"]} icon={<CheckOutlined />}>
                    ป้ายข้อความหน้าเว็บ
                  </Tag>
                )}
                {item.notification && (
                  <Tag color={colors["@yellow-6"]} icon={<CheckOutlined />}>
                    กล่องข้อความแจ้งเตือน
                  </Tag>
                )}
              </div>
            </Col>
            <Col flex="1 1 20px" style={{ textAlign: "right" }}>
              <EditButton
                size="small"
                onClick={() => {
                  NiceModal.show(SystemStatusEditForm, {
                    item,
                    submit: (form: any, daily: any) => {
                      const time = form.type === "custom" ? form.customTime : form.scheduleTime;
                      updateSystemStatusItem({
                        ...item,
                        daily,
                        startTime: time && time[0] ? time?.[0].toDate() : undefined,
                        endTime: time && time[1] ? time?.[1].toDate() : undefined,
                        startDatetime: time && time?.[0] ? time?.[0].toDate() : undefined,
                        endDatetime: time && time?.[0] ? time?.[1].toDate() : undefined,
                        type: form.type || "now",
                        text: form.content || "",
                        toast:
                          form.textPositions?.some((position: any) => position === "toast") ||
                          false,
                        notification:
                          form.textPositions?.some(
                            (position: any) => position === "notification"
                          ) || false,
                      });
                    },
                  });
                }}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
};
