import NiceModal from "@ebay/nice-modal-react";
import { Tooltip } from "antd";
import { useRecoilState } from "recoil";
import { DepositTransactionModalForm, EditDropdown } from "src/components";
import { useDeleteTransaction, useRevertRejectWithdraw } from "src/hooks";
import { DEPOSIT_STATUS, Transaction, WITHDRAW_STATUS } from "src/hooks/useTransaction/type";
import { authState, modalState } from "src/recoils";
import Button from "../Button";
import Icon from "../Icon/Icon";
export interface TransactionActionsProps {
  transaction: Transaction;
  onSuccess?: () => void;
}

function TransactionActions({ transaction, onSuccess }: TransactionActionsProps) {
  const [auth] = useRecoilState(authState);
  const [, setModal] = useRecoilState(modalState);
  const { mutate: deleteTransaction } = useDeleteTransaction();
  const { mutate: revertRejectWithdraw } = useRevertRejectWithdraw();

  if (auth.role !== "ADMIN") {
    if (transaction.type === 'DEPOSIT' && transaction.status === DEPOSIT_STATUS.PENDING) {
      return (
        <EditDropdown
          size="middle"
          showEdit = {transaction.matchingState === "MANUAL"}
          showDelete
          onEdit={() =>
            NiceModal.show(DepositTransactionModalForm, {
              transaction: transaction,
              onSuccess: () => onSuccess?.(),
            })
          }
          onDelete={() => {
            setModal({
              isModalVisible: true,
              type: "warning",
              title: "ลบรายการ",
              content: "ต้องการลบรายการเติมเงินนี้ใช่หรือไม่",
              buttonType: "question",
              onConfirm: () =>
                deleteTransaction(transaction.transactionId, {
                  onSuccess: () => onSuccess?.(),
                }),
            });
          }}
        />
      );
    }
  }
  if (transaction.status === WITHDRAW_STATUS.REJECTED) {
    return (
      <Tooltip overlay={(<div>ยกเลิกปฏิเสธโอนเงิน</div>)}>
        <Button
          type="warning"
          icon={<Icon name="outline-close-2" />}
          onClick={() => {
            setModal({
              isModalVisible: true,
              type: "warning",
              title: "ยกเลิกปฏิเสธถอนเงิน",
              content: "ต้องการยกเลิกการปฏิเสธถอนเงินของรายการนี้ใช่หรือไม่",
              buttonType: "question",
              onConfirm: () => {
                revertRejectWithdraw(transaction.transactionId);
                setTimeout(() => {
                  onSuccess?.();
                },800)
              },
            });
          }}
        />
      </Tooltip>
    );
  }
  return null;
}

export default TransactionActions;
