import toString from 'lodash/toString';
import upperFirst from 'lodash/upperFirst';
import { ColumnFilterItem } from "antd/lib/table/interface";
import isEmpty from 'lodash/isEmpty';

export function getColumnFilters(columnName: string, allData: Record<string, unknown>[]) {
  if(isEmpty(allData)) return [];
  return Array.from(
    new Set(
      allData.map((dt) =>
         JSON.stringify({
          text: upperFirst(toString( dt[columnName] )) || "",
          value: toString(dt[columnName]) || "",
        })
      )
    )
  ).map((td) => JSON.parse(td)) as ColumnFilterItem[];
}
