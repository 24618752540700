import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import './styles/scss/main.scss';
import './styles/theme.less';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import th_TH from 'antd/es/locale/th_TH';
import { Locale } from 'antd/es/locale-provider';
import npmPackage from '../package.json';

const locale: Locale = {
  ...th_TH,
  Pagination: {
    ...th_TH.Pagination,
    jump_to: 'ไปที่หน้า',
    page: '',
  },
};

const element = (
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <ConfigProvider locale={locale}>
          <App />
        </ConfigProvider>
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>
);

const container = document.getElementById('root');
const appVersion = document.getElementById('app_version');
appVersion?.setAttribute('content', npmPackage.version);
ReactDOM.render(
  element,
  container
);
