import { Dayjs } from "dayjs";
import {
  BankAccount,
  DepositTransaction,
  DepositTransactionStatus,
  PolymorphicTransaction,
  RefundTransaction,
  WithdrawTransaction,
  WithdrawTransactionStatus,
  EditTransaction,
  BonusType
} from "src/types";
import { TransactionLog } from "../../services/transactions";

export enum DEPOSIT_STATUS {
  PENDING = 'DEPOSIT_PENDING',
  REJECTED = 'DEPOSIT_REJECTED',
  CANCELLED =  'DEPOSIT_CANCELLED',
  CANCEL_REJECT = 'DEPOSIT_CANCEL_REJECT',
  TRANSFER_BACK = 'DEPOSIT_TRANSFER_BACK',
  COMPLETED = 'DEPOSIT_COMPLETED',
  WAITING_TRANSFER = 'DEPOSIT_WAITING_TRANSFER',
  NONE = 'DEPOSIT_NONE',
};

export enum WITHDRAW_STATUS {
  REJECTED = 'WITHDRAW_REJECTED',
  COMPLETED = 'WITHDRAW_COMPLETED',
  NONE = 'WITHDRAW_NONE',
  PENDING = 'WITHDRAW_PENDING',
  UNCONFIRM = 'WITHDRAW_UNCONFIRM',
};

export type TransactionType = "DEPOSIT" | "WITHDRAW" | "REFUND";
export type AdjustType = "WRONG_SLIP_VOIDABLE" | "WRONG_SLIP_UNVOIDABLE" | "WRONG_USER_VOIDABLE" | "WRONG_USER_UNVOIDABLE" | "NONE";

export type Transaction = {
  adjustType: AdjustType;
  transactionId: string;
  transferAt: Date;
  transferAtDate: string;
  transferAtTime: string;
  updatedAt: Date;
  createdAt: Date;
  userAccount: string;
  userUid: string;
  adminBank: BankAccount;
  customerBank: BankAccount;
  customerBankNumber: string;
  amount: number;
  isBonus: boolean;
  bonusType?: BonusType;
  bonus: number;
  status: DEPOSIT_STATUS | WITHDRAW_STATUS;
  raw_status: WithdrawTransactionStatus | DepositTransactionStatus;
  statusMessage: string;
  remark: string;
  editingBy: string;
  emailEditingBy: string;
  editTransaction?: EditTransaction;
  logs?: TransactionLog[]
  customerPhoneNumber: string;
  matchingState: "AUTO" | "MANUAL"
  type: TransactionType;
  isSending: boolean;
  rawData: PolymorphicTransaction
};

export enum TRANSFER_TYPE {
  NORMAL = 'NORMAL',
  AFFILIATE = 'AFFILIATE',
};

export enum FILTER {
  PENDING_DEPOSIT = 'PENDING_DEPOSIT',
  PENDING_REFUND = 'PENDING_REFUND',
  ONLY_ME_DEPOSIT = 'ONLY_ME_DEPOSIT',
  ALL_DEPOSIT = 'ALL_DEPOSIT',

  PENDING_WITHDRAW = 'PENDING_WITHDRAW',
  WITHDRAW_PROCESSING = 'WITHDRAW_PROCESSING',
  REFUND = 'REFUND',
  ONLY_ME_WITHDRAW = 'ONLY_ME_WITHDRAW',
  ALL_WITHDRAW = 'ALL_WITHDRAW',

  NONE = 'NONE',
};

export type SearchCriteria = {
  limit: number;
  offset: number;
  filter: FILTER;
  transferredAt?: Dayjs;
  adminBankCode?: string
  adminBankAccountName?: string;
};

export type SearchResult = SearchCriteria & {
  totalPage: number;
  totalTransaction: number;
  totalOtherTransaction: number;
};

export type SearchValue = {
  value: string;
}

export type BankAccountWithBankName = BankAccount & {
  bankName: string
}

export type UserInfo = {
  userAccount?: string; // PSXXXXXX
  userCode?: string;
  phoneNumber?: string;
  bankAccount?: BankAccountWithBankName;
  lastDepositDateTime?: Date;
  lastDepositAmount?: number;
  lastWithdrawDatetime?: Date;
  lastWithdrawAmount?: number;
  currentBalance?: number;
  affiliateBalance?: number;
  isComplete: boolean;
  isFound?: boolean;
}


export interface DepositTransactionItem extends DepositTransaction { };

export interface WithdrawTransactionItem extends WithdrawTransaction { };

export type TransactionItem = DepositTransactionItem & WithdrawTransactionItem & RefundTransaction;
