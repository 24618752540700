import NiceModal from "@ebay/nice-modal-react";
import { TransactionViewMoreModal, ViewMoreButton } from "src/components";
import { Transaction } from "src/hooks/useTransaction/type";
export interface TransactionViewMoreProps {
  transaction: Transaction;
}

function TransactionViewMore({ transaction }: TransactionViewMoreProps) {
  return (
    <ViewMoreButton
      size="middle"
      onClick={() =>
        NiceModal.show(TransactionViewMoreModal, {
          transactionUid: transaction.transactionId
        })
      }
    />
  );
}

export default TransactionViewMore;
