import NiceModal from "@ebay/nice-modal-react";
import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Space, Button, Spin, Form} from "antd";
import { useState, useRef } from "react";
import * as React from 'react';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { useRecoilState } from "recoil";
import { modalState } from "src/recoils";
import {
  Tabs,
  WeeklyQuestCreate,
  WeeklyQuestEdit,
  ColumnType,
  PageContainer,
  AddButton,
  Table,
  EditDropdown,
} from "src/components";
import { DeleteWeeklyQuest, WeeklyQuestManagementHook, WeeklyQuestEditTicketForm, UpdateWeeklyQuestTicket } from "src/hooks";
import { WeeklyQuestManagementAPI } from "src/types";

export const WeeklyQuest = () => {
  /** starts search*/
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<Input>(null);

  type DataIndex = keyof WeeklyQuestManagementAPI;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<WeeklyQuestManagementAPI> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
  });
  /** end search*/

  const [, setModal] = useRecoilState(modalState);
  const { mutate: deleteQuest } = DeleteWeeklyQuest();
  const { data: questList, isFetching, isError, refetch: fetchQuestList } = WeeklyQuestManagementHook();

  const columns: ColumnType<WeeklyQuestManagementAPI>[] = [
    {
      title: "ค่ายเกมส์",
      dataIndex: "gameRefType",
      width: 100,
      key: 'gameRefType',
      render: (value, record) => {
        if (record.gameRefType === null){
          return 'PG';
        } else {
          return record.gameRefType 
        }
      },
    },
    {
      title: "เกมส์",
      dataIndex: "gameName",
      width: 100,
      key: 'gameName',
      ...getColumnSearchProps('gameName'),
    },
    {
      title: "เควส",
      dataIndex: "questName",
      width: 300,
      key: 'questName',
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (record) => (
        <>
          <EditDropdown
            size="middle"
            showEdit
            showDelete
            onEdit={() => NiceModal.show(WeeklyQuestEdit, {quest: record, onClose: ()=> fetchQuestList()})}
            onDelete={() => {
              setModal({
                isModalVisible: true,
                type: "warning",
                title: "ลบรายการ",
                content: "ต้องการลบเควส " + (record.gameName ? record.gameName : "") + " " + record.questName + " ใช่หรือไม่?",
                buttonType: "question",
                onConfirm: () => deleteQuest({questUid: record.uid},{ onSuccess: ()=> fetchQuestList() }),
                
              });
            }}
          />
        </>
      ),
    },
  ];

  const operations = <AddButton key="add" onClick={() => NiceModal.show(WeeklyQuestCreate, {
    onClose: () => fetchQuestList(),
  })}>สร้างภารกิจรายสัปดาห์</AddButton>;

  const [form] = Form.useForm<WeeklyQuestEditTicketForm>();
  const { mutate: update } = UpdateWeeklyQuestTicket();

  const handleFinish = (value: WeeklyQuestEditTicketForm) => {
    update(value, { onSuccess:() => fetchQuestList() })
  };

  React.useEffect(() => {
    form.setFieldsValue({ 
      ticket: questList?.ticket
    });
  }, [questList]);

  return (
    <PageContainer>
      <Row className="SearchPanel" gutter={[8, 0]}>
        <Col flex="1 1 200px">
          <div className="tab-container">
            <Tabs
              defaultActiveKey="any"
              tabBarExtraContent={operations}
            >
              <Tabs.TabPane tab="ทำยอด Earn" key="any"/>
            </Tabs>
          </div>
        </Col>
      </Row>

      {questList?.ticket && (
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Spin spinning={isFetching}>
              <div
                style={{
                  borderRadius: 8,
                  padding: "8px 16px",
                  background: "linear-gradient(225deg, #9737C1 0%, #5B4ABF 100%)",
                }}
              >
                <Form
                  form={form}
                  layout="vertical"
                  autoComplete="off"
                  requiredMark={true}
                  preserve={false}
                  style={{ overflow: "auto" }}
                  onFinish={handleFinish}
                >
                  <div className="weekly-quest-update-ticket">
                    <Row gutter={[16, 16]}>
                      <Col span={4}>
                        <Form.Item style={{ color: "white" }} label="จำนวน Ticket ปัจจุบัน" name="ticket"
                          rules={[{ required: true, message: 'โปรดระบุรางวัล (Ticket)' }]}>
                          <Input></Input>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label=" ">
                          <Button
                            key="submit"
                            type="primary"
                            onClick={() => form.submit()}
                            style={{ width: "160px" }}
                          >
                            บันทึก
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </Spin>
          </Col>
        </Row>
      )}

      <Table
        style={{ whiteSpace: 'pre' }}
        rowKey="uid"
        columns={columns}
        dataSource={isError ? [] : questList?.results}
        loading={{
          size: "large",
          spinning: isFetching,
          style: { maxHeight: "calc(100vh - 310px)" },
        }}
        pagination={{ total: questList?.total }}
      />
    </PageContainer>
  );
};

export default WeeklyQuest;
