import { AxiosRequestConfig } from 'axios';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse } from "../service.type";

export type UserListRequest = {
  token: string;
}

export type UserInfo = {
  uid: string;
  updated_at: string;
  created_at: string;
  name: string;
  email: string;
  role: string;
  status: string;
  personal_number: string;
}

type UserList = {
  total: number;
  results: UserInfo[]
}

type UserListResponse = BaseResponse & {
  data: UserList
}

export const getUserList = async (token: string): Promise<UserList|null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/users',
      method: 'GET',
      headers: { token },
    };

    const result = await fetchWithJSON<UserListResponse>(pigspinBackofficeApiInstance, requestConfig);
    return result.data as UserList;
  }
  catch (error) {
    return null;
  }
};


