import { AxiosRequestConfig, AxiosError } from 'axios';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse } from "../service.type";

export enum VERIFY_EMAIL_STATUS {
  SUCCESS = 200,
  EXIST = 400,
}

export type VerifyEmailRequest = {
  email: string;
}

export type VerifyEmailResponse = BaseResponse & {
  data: {};
}

export const verifyEmail = async (request: VerifyEmailRequest, token: string = '') => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: 'v1/users/verification/email',
      method: 'POST',
      data: request,
      headers: { token }
    };
    const result = await fetchWithJSON<VerifyEmailResponse>(pigspinBackofficeApiInstance, requestConfig)
    return result.code
  }
  catch (error) {
    const err = error as AxiosError
    if (err.response) {
      return err.response.status
    }
    return null
  }
}
