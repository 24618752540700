import { AxiosError } from "axios"
import { useMutation } from "react-query"
import { Modal, toast } from "src/components"
import { BankCode, Response } from "src/types"
import { api } from "src/utils"

export interface DepositTransactionCreate {
  bgbBankCodeUuid: string
  fromBankCode?: BankCode
  fromBankAccountName?: string
  fromBankAccountNumber?: string
  transferredAt: string
  amount: string
}

export interface DepositTransactionBulkCreate {
  transactionList: DepositTransactionCreate[]
}

export interface DepositTransactionUpdate extends DepositTransactionCreate {
  transactionUid: string
}

export function useDepositTransactionBulkCreate() {
  return useMutation(
    (data: DepositTransactionBulkCreate) =>
      api.post("/payment/deposit/transactions", data).then((res: any) => res.data),
    {
      onError: () => toast.error("ระบบขัดข้องไม่สามารถทำรายการได้"),
      onSuccess: ({ data }: Response<any>, variables) => {
        const remain = data.remainder.length
        const total = variables.transactionList.length
        if (remain) {
          if (remain === total) {
            toast.error(`เพิ่มรายการไม่สำเร็จเพราะซ้ำในระบบ ${remain} รายการ`)
          } else {
            toast.error(
              `เพิ่มรายการสำเร็จ ${
                total - remain
              } รายการ และไม่สำเร็จเพราะซ้ำในระบบ ${remain} รายการ`
            )
          }
        } else {
          toast.success(`เพิ่มรายการสำเร็จ ${total} รายการ`)
        }
      },
    }
  )
}

export function useDepositTransactionUpdate() {
  return useMutation(
    (data: DepositTransactionUpdate) =>
      api.put("/payment/deposit/transaction", data).then((res: any) => res.data),
    {
      onError: (error: AxiosError) => {
        if (error.response?.data.service_message === "Deplicated transaction") {
          Modal.error({
            title: "ไม่สามารถทำรายการได้",
            content: "เนื่องจากรายการที่แก้ไขซ้ำกับข้อมูลในระบบ",
            okText: "เข้าใจแล้ว"
          });
        } else {
          toast.error("แก้ไขไม่สำเร็จ")
        }
      },
      onSuccess: () => toast.success("แก้ไขสำเร็จ"),
    }
  )
}
