import { useMemo, useState, useEffect } from "react";
import { Space } from "antd";
import Switch, { Table } from "src/components";
import { GameColumns } from "src/components/TableColumns/GameColumn";
import { GameColumnsPigbet } from "src/components/TableColumns/GameColumnPigbet";
import { GameBrands, PartyBrand } from "src/types";
import {
  UpdateGameRequest,
  useListGames,
  useUpdateGamePigbet,
} from "src/hooks/useGames";
import { useUpdateGameBrand, BrandList, GameBrandsRequest } from "src/hooks/useGameBrand";
import { TableProps } from "antd/es/table";
import { Games } from "src/types";
import { FilterValue } from "antd/lib/table/interface";
import colors from "src/theme/colors.json";
import { useRecoilState } from "recoil";
import { modalState } from "src/recoils";
import "./Games.scss";

interface IGamesPageProps {
  brand: GameBrands;
  partyBrand: GameBrandsRequest;
}

interface PaginationTypes {
  limit: number;
  current: number;
}

const initialPagination: PaginationTypes = {
  limit: 100,
  current: 1,
};

export const GamesPage: React.FunctionComponent<IGamesPageProps> = (prop) => {
  const [globalModal, setModal] = useRecoilState(modalState);
  const { data: games, isFetching } = useListGames({ brand: prop?.brand?.name });
  const { mutate: updateGame, isLoading: isUpdatingGame } = useUpdateGamePigbet();
  const { mutate: updateBrandGame } = useUpdateGameBrand();
  const [pagination, setPagination] = useState<PaginationTypes>(initialPagination);
  const [selectedFilter, setSelectedFilter] = useState<GameBrands>(prop.brand);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [refType, setrefType] = useState<string>("");

  const gameProviderStatus = useMemo(() => {
    if (!selectedFilter) return undefined;
    return selectedFilter;
  }, [selectedFilter]);

  const handleChange: TableProps<Games>["onChange"] = (_, filters) => {
    setFilteredInfo(filters);
  };

  const handleUpdateGamePigbet = (params: UpdateGameRequest) => {
    updateGame(params);
  };

  const gameColumnPartBrandMapper = {
    [PartyBrand.PIGSPIN]: {
      columnConfig: GameColumns,
      handleUpdateGame: handleUpdateGamePigbet,
    },
    [PartyBrand.PIGBET]: {
      columnConfig: GameColumnsPigbet,
      handleUpdateGame: handleUpdateGamePigbet,
    },
  };

  const handleUpdateBrandGame = (brands: BrandList) => {
    setModal({
      isModalVisible: true,
      type: "warning",
      title: brands.isUnderConstruction ? "ปิดการใช้งาน" : "เปิดการใช้งาน",
      content:
        "ต้องการ" +
        (brands.isUnderConstruction ? "ปิดการใช้งาน" : "เปิดการใช้งาน") +
        "ค่ายเกม " +
        brands.name +
        " ใช่หรือไม่",
      buttonType: "question",
      confirmText: "ยืนยัน",
      onConfirm: () => {
        updateBrandGame({ brandList: [brands] });
        setSelectedFilter({
          ...selectedFilter,
          isUnderConstruction: brands.isUnderConstruction || false,
        });
      },
    });
  };

  useEffect(() => {
    setFilteredInfo({ ...filteredInfo, refType: [prop.brand.name] });
    setrefType(prop.brand.name);
  }, []);

  return (
    <>
      {gameProviderStatus && (
        <div
          style={{
            padding: 12,
            borderRadius: "8px 8px 0px 0px",
            transitionDuration: "0.3s",
            background: gameProviderStatus.isUnderConstruction
              ? colors["@red-4"]
              : colors["@green-2"],
          }}
        >
          <Space>
            <div>{gameProviderStatus.name}</div>
            <div>
              <Switch
                checked={gameProviderStatus.isUnderConstruction ? false : true}
                checkedChildren="เปิด"
                unCheckedChildren="ปิด"
                onChange={(tab) => {
                  handleUpdateBrandGame({
                    ...gameProviderStatus,
                    isUnderConstruction: (tab ? false : true) || false,
                    name: gameProviderStatus.name,
                  });
                }}
              />
            </div>
          </Space>
        </div>
      )}
      <Table
        showHeader
        columns={gameColumnPartBrandMapper[prop.partyBrand.partyBrand].columnConfig({
          games,
          filteredInfo,
          handleUpdateGame: gameColumnPartBrandMapper[prop.partyBrand.partyBrand].handleUpdateGame,
          refType,
        })}
        dataSource={games}
        onChange={handleChange}
        loading={isFetching || isUpdatingGame}
        pagination={{
          current: pagination.current,
          pageSize: pagination.limit,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, current: page, limit: pageSize });
          },
        }}
      />
    </>
  );
};

export default GamesPage;
