import { bankCodeToBankName } from "src/mapper";
import { BankCode } from "src/types";

export interface BankLogoProps {
  bankCode?: BankCode;
  showName?: boolean;
  width?: string | number;
  height?: string | number;
  style?: React.CSSProperties;
  rtl?: boolean;
}

const bankOptionText = {
  'KBANK': 'KBANK',
  'SCB': 'SCB',
  'TRUEWALLET': 'TRUE W',
  'BAAC': 'BAAC',
  'BAY': 'BAY',
  'BBL': 'BBL',
  'CIMB': 'CIMB',
  'GHBANK': 'GHBANK',
  'GSB': 'GSB',
  'IBANK': 'IBANK',
  'KK': 'KK',
  'KTB': 'KTB',
  'LHBANK': 'LHBANK',
  'TTB': 'TTB',
  'UOB': 'UOB',
  'NONE': '',
};

function BankLogo({ bankCode, showName, style, ...props }: BankLogoProps) {
  const logo = (
    <img
      width={props.width || 32}
      height={props.height || 32}
      {...props}
      src={require(`src/assets/images/bank/${bankCode || "NONE"}.png`).default}
      alt={`${bankCode}-logo`}
      style={{ borderRadius: "4px" }}
    />
  )
  const text = (
    <>
      {showName && (bankCode && bankCode !== BankCode.NONE ? bankOptionText[bankCode] : '')}
    </>
  )
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        columnGap: "8px",
        height: "100%",
        ...style,
      }}
    >
      {props.rtl ? (
        <>
          {text}
          {logo}
        </>) : (
        <>
          {logo}
          {text}
        </>
      )}
    </div>
  );
}

export default BankLogo;
