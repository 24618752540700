import jwt_decode from "jwt-decode";

export type AuthInfo = {
  user_uid: string;
  scope_list: string[];
  created_at: Date;
}

export const decodeAuthToken = (token: string): AuthInfo => {
  return jwt_decode(token);
}
