import { AxiosRequestConfig } from 'axios';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";

export type LogOutRequest = {
  token: string;
};

export const requestLogOut = (token: string) => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/logout',
      method: 'POST',
      headers: { token },
    };

    fetchWithJSON(pigspinBackofficeApiInstance, requestConfig);
  }
  catch (error) {
    return ;
  }
};


