import fnsFormat from 'date-fns/format';
import fnsGetYear from 'date-fns/getYear';
import fnsGetMonth from 'date-fns/getMonth';
import fnsGetDate from 'date-fns/getDate';
import fnsGetHours from 'date-fns/getHours';
import fnsGetMinutes from 'date-fns/getMinutes';
import fnsGetSeconds from 'date-fns/getSeconds';
import isValid from 'date-fns/isValid';

const BE_OFFSET = 543;

export enum YEAR_FORMAT {
  AD = 'AD',
  BE = 'BE',
}

export const convertYear = (
  year: number,
  toFormat: YEAR_FORMAT,
): number => {
  switch (toFormat) {
    case YEAR_FORMAT.BE:
      return year + BE_OFFSET;
    case YEAR_FORMAT.AD:
      if (year >= BE_OFFSET) {
        return year - BE_OFFSET;
      }
      return 0;
    default:
      return 0;
  }
};

export const formatDateRequest = (
  date: Date
): string => {
  return date.toISOString();
};

export const toDate = (date: Date | string) => {
  return new Date(date);
  // TOCHECK : this logic will conflict with dayjs config on ./datetime.ts
  // TODO: remove it when BE send utc format
  const _date = new Date(date);
  const utcDate = Date.UTC(
    fnsGetYear(_date),
    fnsGetMonth(_date),
    fnsGetDate(_date),
    fnsGetHours(_date),
    fnsGetMinutes(_date),
    fnsGetSeconds(_date),
  )
  return new Date(utcDate);
};

// 01/02/2022
export const formatDate = (date: Date, nonUtcDate?: boolean): string => {
  if(!isValid(date)) return '';
  if(nonUtcDate)  return fnsFormat(date, 'dd/MM/yyyy');
  return fnsFormat(toDate(date), 'dd/MM/yyyy');
};

export const formatDateDashCase = (date: Date, nonUtcDate?: boolean):string => {
  if(!isValid(date)) return '';
  if(nonUtcDate)  return fnsFormat(date, 'yyyy-MM-dd');
  return fnsFormat(toDate(date), 'yyyy-MM-dd');
}

// 05:55
export const formatTime = (date: Date, nonUtcDate?: boolean): string => {
  if(!isValid(date)) return '';
  if(nonUtcDate)  return fnsFormat(date, 'HH:mm');
  return fnsFormat(toDate(date), 'HH:mm');
};

// 01/02/2022 05:55
export const formatDateTime = (date: Date, nonUtcDate?: boolean): string => {
  if(!isValid(new Date(date))) return '';
  if(nonUtcDate)  return fnsFormat(date, 'dd/MM/yyyy HH:mm');
  return fnsFormat(toDate(date), 'dd/MM/yyyy HH:mm');
};
