import { BankLogo, Select, SelectProps } from "src/components"
import { BankCode } from "src/types"

export interface BankSelectProps extends SelectProps<BankCode> {}

const bankOptions = [
  "KBANK",
  "SCB",
  "TRUEWALLET",
  "BAAC",
  "BAY",
  "BBL",
  "CIMB",
  "GHBANK",
  "GSB",
  "IBANK",
  "KK",
  "KTB",
  "LHBANK",
  "TTB",
  "UOB",
] as BankCode[]

function BankSelect(props: BankSelectProps) {
  return (
    <Select allowClear placeholder={<BankLogo />} {...props}>
      {bankOptions.map((option) => (
        <Select.Option key={option} value={option} style={{ padding: "8px 12px" }}>
          <BankLogo
            style={{ overflow: "hidden", whiteSpace: "normal" }}
            bankCode={option}
            showName
          />
        </Select.Option>
      ))}
    </Select>
  )
}

BankSelect.defaultProps = {
  width: "80px",
  dropdownWidth: "117px",
}

export default BankSelect
