import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Col, Modal, Row, Skeleton, Space } from "antd";
import { colors } from "src/constants";
import { useCoinWalletLogs } from "src/hooks";
import { CoinWalletLog } from "src/types";
import { datetime } from "src/utils";

export type CoinWalletViewMoreModalProps = {
  walletUid: string;
};

const CoinWalletViewMoreModal = NiceModal.create(({ walletUid }: CoinWalletViewMoreModalProps) => {
  const { data } = useCoinWalletLogs(walletUid);
  const { visible, hide, remove } = useModal();

  const generateRemark = (log: CoinWalletLog) => {
    const action = { UPDATE: "แก้ไข", REVOKE: "ยกเลิก", CREATED: "สร้าง" }[log.actionType];
    let shortName = 'ลูกค้าถอนเงิน';
    if (log?.actionBy) {
      shortName = log.actionBy.email.split("@")[0];
    }
    return `${action}: {${shortName}}`;
  };

  return (
    <Modal
      focusTriggerAfterClose={false}
      visible={visible}
      afterClose={remove}
      title="ข้อมูลเพิ่มเติม"
      closable={false}
      footer={null}
      width={600}
      centered
    >
      {!data ? (
        <Skeleton active />
      ) : (
        <Space direction="vertical" size="large" style={{ padding: "0 20px", width: "100%" }}>
          {!!data?.total && (
            <div>
              <Row>
                <Col span={9}>
                  <div style={{ fontWeight: 700, color: colors.cloud6 }}>วันเวลาทำรายการ</div>
                </Col>
                <Col span={15}>
                  <div style={{ fontWeight: 700, color: colors.cloud6 }}>ทำรายการ</div>
                </Col>
              </Row>
              {data.results.map((log) => (
                <Row key={log.uid}>
                  <Col span={9}>{datetime(log.createdAt).format("DD/MM/YYYY HH:mm")}</Col>
                  <Col span={15}>{generateRemark(log)}</Col>
                </Row>
              ))}
            </div>
          )}
        </Space>
      )}
      <Row justify="center" style={{ marginTop: "32px" }}>
        <Button type="primary" size="large" style={{ width: 150 }} onClick={hide}>
          เข้าใจแล้ว
        </Button>
      </Row>
    </Modal>
  );
});

export default CoinWalletViewMoreModal;
