import { Divider } from 'antd';
import * as React from 'react';
import { BankCode } from 'src/types';
import { formatDateTime } from 'src/utils/date';
import { addCommas } from 'src/utils/number/number';
import { getBankFormat, getPhoneFormat } from 'src/utils/textOperation/textOperation';
import BankLogo from '../BankLogo';
import './UserInfo.scss';

interface IUserInfoProps {
  isResultMatch?: boolean;
  title?: string;
  userCode?: string;
  phone?: string;
  bankCode?: BankCode;
  bankAccountName?: string;
  bankAccountNumber?: string;
  lastTransactionAt?: Date;
  lastTransactionAmount?: number;
  transactionType?: 'DEPOSIT' | 'WITHDRAW';
  balance?: number;
}

const UserInfo: React.FunctionComponent<IUserInfoProps> = (props) => {
  const { isResultMatch = true, transactionType = 'DEPOSIT' } = props;
  return (
    <div className={`search-result-wrapper ${isResultMatch ? "matched" : "not-matched"}`}>
      <div className="search-result-column">
        {props.title && (<div className="title">{props.title}</div>)}
        <div className="subtitle">ข้อมูลลูกค้า</div>
        <div className="search-result-value">
          <div className="key">รหัสลูกค้า</div>
          <div className="value">{props.userCode}</div>
        </div>
        <div className="search-result-value">
          <div className="key">เบอร์โทรศัพท์มือถือ</div>
          <div className="value">{getPhoneFormat(props.phone || '-')}</div>
        </div>
        <div className="search-result-value">
          <div className="key">ธนาคาร</div>
          <div className="value bank">
            {props.bankCode && <BankLogo bankCode={props.bankCode} showName rtl />}
          </div>
        </div>
        <div className="search-result-value">
          <div className="key">ชื่อบัญชี</div>
          <div className="value">{props.bankAccountName || '-'}</div>
        </div>
        <div className="search-result-value">
          <div className="key">เลขที่บัญชี</div>
          <div className="value">{getBankFormat(props.bankAccountNumber || '-')}</div>
        </div>
      </div>
      <Divider type="vertical" style={{ height: "initial", margin: "0px" }} />
      <div className="search-result-column">
        {/* <div className="subtitle right-column">ทำรายการล่าสุด</div>
        <div className="search-result-value">
          <div className="key">วันเวลา</div>
          <div className="value">{props.lastTransactionAt && formatDateTime(props.lastTransactionAt)}</div>
        </div>
        <div className="search-result-value">
          <div className="key">จำนวนเงินที่{transactionType === 'DEPOSIT' ? 'เติม' : 'ถอน'}</div>
          <div className="value">{props.lastTransactionAmount && addCommas(props.lastTransactionAmount.toFixed(2))}</div>
        </div> */}
        <div className="subtitle last-item">ยอดเงินล่าสุด</div>
        <div className="search-result-value">
          <div className="key">ยอดคงเหลือปัจจุบัน</div>
          <div className="value">{props.balance && addCommas(props.balance.toFixed(2))}</div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
