import { useMutation, UseMutationOptions } from "react-query";
import { toast } from "src/components";
import { CoinWalletCreate } from "src/types";
import { api } from "src/utils";

export function useCreateCoinWallet(
  options?: UseMutationOptions<unknown, unknown, CoinWalletCreate, unknown>
) {
  return useMutation((wallet) => api.post("payment/coin-wallets", wallet), {
    onError: () => toast.error("เพิ่มโบนัสไม่สำเร็จ"),
    onSuccess: () => toast.success("เพิ่มโบนัสสำเร็จ"),
    ...options,
  });
}
