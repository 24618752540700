import dayjs from "dayjs";
import React from "react";
import { useRecoilValue } from "recoil";
import { toast } from "src/components";
import { authState } from "src/recoils";
import { GetSystemStatus } from "src/services/systemStatus";
import { FeatureItem, ProductType, PutSystemStatus } from "src/services/systemStatus/systemStatus";
import { datetime, today } from "src/utils";

export type SystemStatusItem = {
  id: string;
  key: string;
  enabled: boolean;
  name: string;
  text: string;
  toast: boolean;
  notification: boolean;

  startTime?: Date;
  endTime?: Date;
  startDatetime?: Date;
  endDatetime?: Date;
  daily: string[];
  type: string;
};

export type SystemStatusState = {
  systemStatusList: SystemStatusItem[];
  isLoading: boolean;
  updateSystemStatus: (item: SystemStatusItem) => void;
};

type SystemStatusHookType = {
  productType: ProductType;
};

export const withoutGameProvider = (featureItem: SystemStatusItem): boolean => {
  return !featureItem.key.startsWith('GameProvider');
};

export const onlyGameProvider = (featureItem: SystemStatusItem): boolean => {
  return featureItem.key.startsWith('GameProvider');
};

export const useSystemStatus = ({ productType }: SystemStatusHookType): SystemStatusState => {
  const { token = "" } = useRecoilValue(authState);
  const [isLoading, setIsLoading] = React.useState(false);
  const [systemStatusList, setSystemStatusList] = React.useState<SystemStatusItem[]>([]);

  const getSystemStatusList = async () => {
    setIsLoading(true);
    const response = await GetSystemStatus({ token, product_type: productType });

    if (response.service_code === "BOE-2000") {
      setSystemStatusList(
        response.data.feature_list.map((item) => {
          return {
            id: item.uid,
            key: item.code,
            name: item.name,
            enabled: item.enabled,
            text: item.message,
            startTime: item.start_datetime ? today(item.start_datetime).toDate() : undefined,
            endTime: item.end_datetime ? today(item.end_datetime).toDate() : undefined,
            startDatetime: item.start_datetime ? datetime(item.start_datetime).toDate() : undefined,
            endDatetime: item.end_datetime ? datetime(item.end_datetime).toDate() : undefined,
            daily: item.days_week ? item.days_week.split(",") : [],
            type: item.type_time || "now",
            toast: item.toast,
            notification: item.notification,
          };
        })
      );
    }
    setIsLoading(false);
  };

  const updateSystemStatus = async (item: SystemStatusItem) => {
    try {
      const response = await PutSystemStatus({
        token,
        uid: item.id,
        code: item.key,
        name: item.name,
        enabled: item.enabled,
        message: item.text || "",
        toast: item.toast,
        notification: item.notification,
        days_week:
          item.daily.length > 0 ? item.daily.reduce((acc, day) => `${acc},${day}`) : undefined,
        start_datetime: item.startDatetime
          ? dayjs(item.startDatetime).utc().format("YYYY-MM-DD HH:mm:ss")
          : undefined,
        end_datetime: item.endDatetime
          ? dayjs(item.endDatetime).utc().format("YYYY-MM-DD HH:mm:ss")
          : undefined,
        start_time: item.startTime ? dayjs(item.startTime).utc().format("HH:mm:ss") : undefined,
        end_time: item.endTime ? dayjs(item.endTime).utc().format("HH:mm:ss") : undefined,
        type_time: item.type,
        product: productType,
      });
      if (response.service_code === "BOE-2000") {
        toast.success("อัพเดทสำเร็จ", { autoClose: 1000 });
        getSystemStatusList();
      }
    } catch {
      toast.error("อัพเดทไม่สำเร็จ", { autoClose: 1000 });
    }
  };

  React.useEffect(() => {
    getSystemStatusList();
  }, []);

  return {
    isLoading,
    systemStatusList,
    updateSystemStatus,
  };
};
