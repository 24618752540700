import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import qs from "qs";
import { QueryClient } from "react-query";
import snakecaseKeys from "snakecase-keys";
import { API_URL, MOCK_USER_TOKEN } from "src/models/buildtime-constant";

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
})

export const api = axios.create({
  baseURL: `${API_URL}/v1`,
  paramsSerializer: (params) =>
    qs.stringify(snakecaseKeys(params), { skipNulls: true, arrayFormat: "repeat" }),
});

api.interceptors.response.use(
  (response) => {
    if (response.data && response.headers["content-type"] === "application/json") {
      const shouldIgnoreAutoCastResponse = response.config.url === '/loyalty-shop/backoffice/quest/games';
      if (shouldIgnoreAutoCastResponse) return response;
      response.data = camelcaseKeys(response.data, { deep: true });
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  (config) => {
    const recoilPersist = localStorage.getItem("recoil-persist");
    const token = recoilPersist ? JSON.parse(recoilPersist).AUTH.token : MOCK_USER_TOKEN;
    if (token && config.headers) config.headers.token = token;
    if (["post", "put", "patch", "delete"].includes(config.method!))
      config.data = snakecaseKeys(config.data, { deep: true });

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
