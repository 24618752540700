import { Col, Row } from 'antd';
import * as React from 'react';
import colors from 'src/theme/colors.json';
import './Pill.less';

export type PillStyle = {
  wrapper: React.CSSProperties;
  left: React.CSSProperties;
  right: React.CSSProperties;
};

export type IPillProps = {
  customStyles?: PillStyle;
  left: JSX.Element;
  right: JSX.Element;
}

export const OUTLINE = (color: string, fontColor?: string): PillStyle => ({
  wrapper: { border: `1px solid ${color}`, color: `${fontColor || color}`, },
  left: { borderRight: `1px solid ${color}`, },
  right: {},
})

export const SOLID = (params: { backgroundLeft?: string, backgroundRight?: string, fontColorLeft?: string, fontColorRight?: string }): PillStyle => ({
  wrapper: { },
  left: { background: params.backgroundLeft, color: params.fontColorLeft, borderRadius: '4px 0 0 4px' },
  right: { background: params.backgroundRight, color: params.fontColorRight, borderRadius: '0 4px 4px 0' },
});

const DEFAULT_STYLE = OUTLINE(colors['@cloud-4'], colors['@cloud-10'])

const Pill: React.FunctionComponent<IPillProps> = (props) => {
  const { customStyles, left, right } = props;
  const styles = customStyles || DEFAULT_STYLE;
  return (
    <div className='Pill' style={styles.wrapper} >
      <Row>
        <Col span={12} className="Pill__Sector--left" style={styles.left} >
          {left}
        </Col>
        <Col span={12} className="Pill__Sector--right" style={styles.right}>
          {right}
        </Col>
      </Row>
    </div>
  );
};

export default Pill;
