import NiceModal from "@ebay/nice-modal-react";
import { Col, Form, Input, Row, Spin, Space, Statistic as AntStatistic, StatisticProps } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { colors } from "src/constants";
import {
  Int,
  TicketManagementModal,
  TicketManagementImportModal,
  ColumnType,
  DatePicker,
  PageContainer,
  SearchButton,
  AddButton,
  Table,
  ViewMoreButton,
} from "src/components";
import { TicketManagementHook, TicketManagementLogsFilters } from "src/hooks";
import { TicketManagementAPI } from "src/types";
import { formatDatetime, datetime } from "src/utils";
import moment from 'moment';

function Statistic({ title, ...props }: StatisticProps) {
  return (
    <AntStatistic
      {...props}
      title={<div style={{ color: colors.gray1, opacity: 0.6 }}>{title}</div>}
      valueStyle={{ color: "white", fontSize: 18, fontWeight: 700 }}
      precision={0}
    />
  );
}

function TicketManagement() {
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<TicketManagementLogsFilters>();

  const { data, remove, isFetching, isError } = TicketManagementHook(filters, {
    enabled: !!filters,
    keepPreviousData: true,
  });

  const onFinish = (values: any) => {
    remove();
    setFilters({
      start: formatDatetime(values.datetimeRange[0]),
      end: formatDatetime(values.datetimeRange[1]),
      customerCode: values.customerCode,
    });
  };

  const columns: ColumnType<TicketManagementAPI>[] = [
    {
      title: "วันเวลา",
      dataIndex: "createdAt",
      width: 150,
      render: (value) => {
        return datetime(value).format("DD/MM/YYYY HH:mm");
      },
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix()
    },
    {
      title: "รายการ",
      dataIndex: "questName",
      render: (value, record) => {
        if (record.gameName) {
          return record.questName + '\n' + record.gameName;
        } else {
          return value;
        }
      },
      sorter: (a, b) => {
        if (a && a.questName && a.questName.length && b && b.questName && b.questName.length) {
          return a.questName.length - b.questName.length;
        } else if (a && a.questName && a.questName.length) {
          // That means be has null rechargeType, so a will come first.
          return -1;
        } else if (b && b.questName && b.questName.length) {
          // That means a has null rechargeType so b will come first.
          return 1;
        }
        // Both rechargeType has null value so there will be no order change.
        return 0;
      }
    },
    {
      title: "ประเภท",
      dataIndex: "ticketType",
      width: 100,
      sorter: (a, b) => a.ticketType.localeCompare(b.ticketType)
    },
    {
      title: "Ticket ก่อนหน้า",
      dataIndex: "ticketBefore",
      width: 150,
      render: (value) => <Int value={value} />,
      sorter: (a, b) => a.ticketBefore - b.ticketBefore,
    },
    {
      title: "จำนวน Ticket",
      dataIndex: "ticketUsed",
      width: 150,
      render: (value) => <Int value={value} />,
      sorter: (a, b) => a.ticketUsed - b.ticketUsed,
    },
    {
      title: "Ticket คงเหลือ",
      dataIndex: "ticketAfter",
      width: 150,
      render: (value) => <Int value={value} />,
      sorter: (a, b) => a.ticketAfter - b.ticketAfter,
    },
    {
      key: "viewMore",
      title: "รูป BigWin",
      dataIndex: ["bigwintransactionUid", "imageUrl"],
      width: 100,
      render: (value, record) => {
        if (record.imageUrl) {
          return (
            <Space>
              <ViewMoreButton
                size="small"
                onClick={() => NiceModal.show(TicketManagementModal, { imageUrl: record.imageUrl })}
              />
            </Space>
          )
        }
      },
    },
  ];

  return (
    <PageContainer
      header={
        <>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              group: 1,
              datetimeRange: [
                dayjs().hour(0).minute(0).second(0).millisecond(0),
                dayjs().hour(23).minute(59).second(59).millisecond(999),
              ],
            }}
          >
            <Row className="SearchPanel" gutter={[8, 0]}>
              <Col flex="1 1 200px">
                <Row gutter={[8, 0]}>
                  <Col span={8}>
                    <Form.Item name="customerCode" label="รหัสลูกค้า">
                      <Input size="large" placeholder="ใส่รหัสลูกค้า" allowClear />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="datetimeRange"
                      label={
                        <Row style={{ width: "340px" }}>
                          <Col span={12}>{"วันเวลาเริ่มต้น"}</Col>
                          <Col span={12}>{"วันเวลาสิ้นสุด"}</Col>
                        </Row>
                      }
                    >
                      <DatePicker.RangePicker
                        size="large"
                        allowClear={false}
                        format="DD/MM/YYYY HH:mm"
                        showTime={{ format: "HH:mm" }}
                        style={{ width: "340px" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label={<></>}
                      shouldUpdate={(prevValue, curValue) =>
                        prevValue.customerCode !== curValue.customerCode
                      }
                    >
                      {() => (
                        <SearchButton
                          size="large"
                          htmlType="submit"
                          disabled={!form.getFieldValue("customerCode")}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item label=" ">
                  <AddButton
                    key="add"
                    onClick={() =>
                      NiceModal.show(TicketManagementImportModal)
                    }
                  >
                    เพิ่มรายการ
                  </AddButton>
                </Form.Item>
            </Col>
            </Row>
          </Form>

          {data && (
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <Spin spinning={isFetching}>
                  <Row gutter={16} style={{ marginBottom: 16 }}>
                    <Col span={24}>
                      <div
                        style={{
                          borderRadius: 8,
                          padding: "8px 16px",
                          background: "linear-gradient(225deg, #9737C1 0%, #5B4ABF 100%)",
                        }}
                      >
                        <h3 style={{ color: "white" }}>จำนวน Ticket ปัจจุบัน</h3>
                        <Row>
                          <Col span={8}>
                            <Statistic title="Tickets" value={data?.totalTicket} />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Spin>
              </Col>
            </Row>
          )}
        </>
      }
    >
      <Table
        style={{ whiteSpace: 'pre' }}
        rowKey="uid"
        columns={columns}
        dataSource={isError ? [] : data?.results}
        loading={{
          size: "large",
          spinning: isFetching,
          style: { maxHeight: "calc(100vh - 310px)" },
        }}
        pagination={{ total: data?.total }}
      />
    </PageContainer>
  );
}

export default TicketManagement;
