import {
  Col,
  DatePicker,
  Row,
  Space,
  Spin,
  Statistic,
  Typography
} from 'antd';
import classnames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import TransferTag from 'src/components/Affiliate/TransferTag';
import BankLogo from 'src/components/BankLogo';
import { useFeatureToggle } from 'src/hooks/useFeatureToggle';
import { modalState } from 'src/recoils';
import { getBankFormat, getPhoneFormat } from 'src/utils/textOperation/textOperation';
import { WITHDRAW_STATUS } from '../../../hooks/useTransaction/type';
import { TransactionState } from '../../../hooks/useTransaction/useTransaction';
import { useWithdraw, VALIDATE_RESULT_ENUM } from '../../../hooks/useTransaction/useWithdraw';
import { formatDateTime } from '../../../utils/date';
import { addCommas } from '../../../utils/number/number';
import { BankSelect } from '../../Bank/BankSelect';
import Button from '../../Button/Button';
import AlertIcon from '../../Icon/Alert';
import Check from '../../Icon/Check';
import Icon from '../../Icon/Icon';
import TimePicker from '../../Input/TimePicker';
import Modal from '../../Modal/Modal';
import Label from '../../Text/Label';
import LabelAndValue from '../../Text/LabelAndValue';
import './WithdrawModal.less';

export type IWithdrawModalProps = TransactionState & {
};


const WithdrawModalComponent: React.FunctionComponent<IWithdrawModalProps> = (props) => {
  const featureToggle = useFeatureToggle();
  const [, setModal] = useRecoilState(modalState);
  const {
    withdrawingTransaction,
    cancelReserveTransaction,
    doCallWithdrawTransaction,
    searchCriteria,
  } = props;
  const {
    validateBank,
    withdrawBankList,
    getWithdrawBankList,
    validateResult,
    saveWithdrawTransaction,
    rejectWithdrawTransaction,
    isValidating,
    selectedWithdrawBankAccount,
    setSelectedWithdrawBankAccount,
    transferAt,
    setTransferAt,
  } = useWithdraw();

  React.useEffect(() => {
    getWithdrawBankList();
  }, []);

  React.useEffect(() => {
    if (withdrawingTransaction && featureToggle.validateBank) {
      validateBank(withdrawingTransaction);
    }
    setTransferAt(new Date());
  }, [withdrawingTransaction]);

  const onCloseWithdrawModal = () => {
    if (withdrawingTransaction) {
      cancelReserveTransaction(withdrawingTransaction, WITHDRAW_STATUS.PENDING, true);
      doCallWithdrawTransaction(searchCriteria);
    }
  }
  const renderValidateResult = () => {
    if (isValidating) return null;
    if (validateResult) {
      const isValidateWarning = validateResult.status === VALIDATE_RESULT_ENUM.WARNING;
      const isValidateSuccess = validateResult.status === VALIDATE_RESULT_ENUM.SUCCESS;
      const isValidateFail = validateResult.status === VALIDATE_RESULT_ENUM.FAIL;
      return (
        <>
          <div className={classnames('withdraw-modal-noti-message', {
            'withdraw-modal-noti-message-success': isValidateSuccess,
            'withdraw-modal-noti-message-warning': isValidateWarning,
            'withdraw-modal-noti-message-fail': isValidateFail,
          })}>
            <Space className="withdraw-modal-noti-message-title">
              <div style={{ lineHeight: '1rem' }}>
                {isValidateSuccess && (<Check size={24} />)}
                {isValidateFail && (<AlertIcon type="danger" />)}
                {isValidateWarning && (<AlertIcon type="warning" />)}
              </div>
              <div>
                {validateResult.message}
              </div>
            </Space>
            {validateResult.moreInfo && (
              <div className="withdraw-modal-noti-message-description">
                {validateResult.moreInfo}
              </div>
            )}
          </div>
        </>
      )
    }
  };

  const onClickReject = () => {
    if (withdrawingTransaction) {
      rejectWithdrawTransaction(
        withdrawingTransaction,
        onCloseWithdrawModal,
      )
    }
  }

  const onClickSave = () => {
    if (withdrawingTransaction && transferAt && selectedWithdrawBankAccount) {
      saveWithdrawTransaction(
        withdrawingTransaction,
        {
          transferAt,
          selectedBankAccount: selectedWithdrawBankAccount,
        },
        onCloseWithdrawModal,
      );
    }
  };

  const renderCountDown = () => {
    if (withdrawingTransaction) {
      return (
        <Statistic.Countdown
          format="mm:ss"
          value={Date.now() + 60 * 1000 * 5}
          valueStyle={{ fontSize: '16px' }}
          className="withdraw-modal-countdown"
          onFinish={() => cancelReserveTransaction(withdrawingTransaction, WITHDRAW_STATUS.PENDING)}
        />
      );
    }
  };

  const isFormFullFill = () => {
    return transferAt && selectedWithdrawBankAccount;
  };

  /*
        <Statistic.Countdown
          title="Test"
          format="mm:ss"
          value={Date.now() + 10 * 1000}
          onFinish={() => { if (withdrawingTransaction) cancelReserveTransaction(withdrawingTransaction, WITHDRAW_STATUS.PENDING) }}
        />
        */
  return (
    <Modal
      width={850}
      centered
      className='withdraw-modal'
      title={(
        <Space>
          <div>โอนเงินผ่านแอปธนาคาร</div>
          <TransferTag />
        </Space>
      )}
      visible={withdrawingTransaction !== undefined}
      footer={(
        <>
          <Button
            size="large"
            style={{ float: 'left', width: 176 }}
            onClick={() => { if (withdrawingTransaction) cancelReserveTransaction(withdrawingTransaction, WITHDRAW_STATUS.PENDING, true) }}
          >
            ยกเลิก
          </Button>
          <Button
            style={{ width: 176 }}
            size="large"
            type="danger"
            onClick={onClickReject}
          >
            <Icon name="filled-clear-2" size="22px" />&nbsp;ปฏิเสธรายการ
          </Button>
          <Button
            disabled={isValidating || !isFormFullFill()}
            style={{ width: 176 }}
            size="large"
            type="success"
            onClick={onClickSave}
          >
            <Icon name="filled-select-1" size="22px" />&nbsp;บันทึกการโอน
          </Button>
        </>
      )}
    >
      {withdrawingTransaction !== undefined && (
        <Spin spinning={isValidating}>
          {renderValidateResult()}
          {renderCountDown()}
          <Row className="withdraw-modal-infomation">
            <Col span={12} className="withdraw-modal-infomation-detail">
              <div>
                <Typography.Title level={5} >ข้อมูลแจ้งถอนเงิน</Typography.Title>
                <LabelAndValue label="วันเวลาลูกค้าถอนเงิน" value={formatDateTime(new Date(withdrawingTransaction.createdAt))} />
                <LabelAndValue label="จำนวนเงิน" value={addCommas(`${withdrawingTransaction.amount.toFixed(2)}`)} />
              </div>
              <div>
                <Typography.Title level={5} >ข้อมูลลูกค้า</Typography.Title>
                <LabelAndValue label="รหัสลูกค้า" value={withdrawingTransaction.userAccount} />
                <LabelAndValue label="เบอร์โทรศัพท์มือถือ" value={getPhoneFormat(withdrawingTransaction.customerPhoneNumber) || '-'} />
                <LabelAndValue label="ธนาคาร" value={(<Space><BankLogo bankCode={withdrawingTransaction.customerBank.bankCode} showName rtl /></Space>)} />
                <LabelAndValue label="ชื่อบัญชี" value={withdrawingTransaction.customerBank.accountName} />
                <LabelAndValue label="เลขที่บัญชี" value={getBankFormat(withdrawingTransaction.customerBank.accountNumber || '')} />
              </div>
            </Col>
            <Col span={12} className="withdraw-modal-infomation-input">
              <div>
                <Typography.Title level={5} >ข้อมูลการโอนเงิน</Typography.Title>
                <div>
                  <Label>วันที่โอน</Label>
                  <DatePicker
                    size="large"
                    value={moment(transferAt)}
                    allowClear={false}
                    style={{ width: '100%' }}
                    onChange={(_date) => { setTransferAt(_date?.toDate()) }}
                    defaultPickerValue={moment(new Date())}
                  />
                </div>
                <div>
                  <Label>เวลาที่โอน</Label>
                  <TimePicker
                    autoFocus
                    value={moment(transferAt)}
                    allowClear={false}
                    onChange={(_date) => { setTransferAt(_date?.toDate()) }}
                    style={{ width: '100%' }}
                    defaultPickerValue={moment(new Date())}
                  />
                </div>
                <div>
                  <Label>บัญชีที่ PIGSPIN</Label>
                  <BankSelect bankList={withdrawBankList} onChange={(_b) => { setSelectedWithdrawBankAccount(_b) }} />
                </div>

              </div>
            </Col>
          </Row>
        </Spin>
      )}
    </Modal >
  );
};

const WithdrawModal: React.FunctionComponent<IWithdrawModalProps> = (props) => {
  return React.useMemo(() => <WithdrawModalComponent {...props} />, [props.withdrawingTransaction]);
};

export default WithdrawModal;
