import { Tooltip } from "antd";
import { Button, ButtonProps } from "src/components";
import { colors } from "src/constants";
import { OutlineMore1 } from "src/icons";
import "./ViewMoreButton.less";

export interface ViewMoreButtonProps extends Omit<ButtonProps, "type"> {}

function ViewMoreButton(props: ViewMoreButtonProps) {
  return (
    <Tooltip title="เพิ่มเติม" color={colors.cloud6} overlayStyle={{ fontSize: 12 }}>
      <Button {...props} className="view-more-btn" icon={<OutlineMore1 />} />
    </Tooltip>
  );
}

export default ViewMoreButton;
