import dayjs from "dayjs";
import { useQuery, useMutation } from "react-query";
import { RangeValue } from 'rc-picker/lib/interface'
import { toast } from "src/components";
import { mapToBankName } from "src/mapper/mapToBank";
import { CamelCaseBaseResponse } from "src/services/service.type";
import { BankAccount, BankCode } from "src/types";
import { api, formatDatetime } from "src/utils";
import { formatDateRequest } from "src/utils/date";
import { AdjustBalanceRequest, ADJUST_BALANCE_TYPE, CHANNEL } from "./useRealtimeTransfer";

export enum STATUS {
  ALL = 'ALL',
  WAITING_VERIFY = 'WAITING_VERIFY',
};

export type HitorySearchCriteria = {
  offset: number;
  limit: number;
  adjustType: ADJUST_BALANCE_TYPE | 'ALL';
  channel: CHANNEL | 'ALL'
  status: STATUS;
  calendar: RangeValue<dayjs.Dayjs>;
};

export type HistoryItemLog = {
  uid: string;
  remark: string;
  createdAt: Date;
  byUser?: {
    uid: string;
    email: string;
    name: string;
  };
};

export type HistoryItem = {
  uid: string;
  transferType: ADJUST_BALANCE_TYPE;
  amount: number;
  feeAmount: number;
  fromBankAccount_uid: string;
  fromBankAccountCode: BankCode;
  fromBankAccountName: string;
  fromBankAccountNumber: string;
  toBankAccountUid: string;
  toBankAccountCode: BankCode;
  toBankAccountName: string;
  toBankAccountNumber: string;
  channel: CHANNEL;
  remark: string;
  logs: HistoryItemLog[];
  isConfirmed: boolean;
  timestamp: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type HistoryResponse = {
  total: {
    all: number;
    waitingVerify: number;
  };
  results: HistoryItem[];
};

export type RealtimeTransferHistoryItem = {
  id: string;
  createdAt: Date;
  timestamp: Date;
  isAutoSystem: boolean;
  depositAccount: BankAccount;
  withdrawAccount: BankAccount;
  channel: CHANNEL;
  amount: number;
  fee: number;
  remark: string;
  adjustType: ADJUST_BALANCE_TYPE;
  logs: {
    logId: string;
    remark: string;
    createdAt: Date;
    email: string;
    name: string;
  }[];
}

export const mapToRealtimeHistory = (_historyItem: HistoryItem): RealtimeTransferHistoryItem => {
  const isAutoSystem = !(_historyItem.logs[0] && _historyItem.logs[0].byUser?.uid);
  return {
    isAutoSystem,
    id: _historyItem.uid,
    createdAt: _historyItem.createdAt,
    amount: _historyItem.amount,
    fee: _historyItem.feeAmount,
    channel: _historyItem.channel,
    remark: _historyItem.remark,
    adjustType: _historyItem.transferType,
    timestamp: _historyItem.timestamp,
    depositAccount: {
      accountName: _historyItem.toBankAccountName,
      accountNumber: _historyItem.toBankAccountNumber,
      bankCode: _historyItem.toBankAccountCode,
      bankName: mapToBankName(_historyItem.toBankAccountCode),
    },
    withdrawAccount: {
      accountName: _historyItem.fromBankAccountName,
      accountNumber: _historyItem.fromBankAccountNumber,
      bankCode: _historyItem.fromBankAccountCode,
      bankName: mapToBankName(_historyItem.fromBankAccountCode),
    },
    logs: _historyItem.logs.map(log => ({
      logId: log.uid,
      remark: log.remark,
      createdAt: log.createdAt,
      email: log?.byUser?.email || '',
      name: log?.byUser?.name || '',
    })),
  };
};

export type AdjustBalanceByIdRequest = {
  id: string;
}

export type EditAdjustHistoryRequest = AdjustBalanceRequest & AdjustBalanceByIdRequest;
const putAdjustBalance = async (request: EditAdjustHistoryRequest) => {
  const { id, ...restRequest } = request;
  const { data: response } = await api.put<CamelCaseBaseResponse<{}>>(`/realtime-transfer/history-log/${id}`, {
    amount: restRequest.amount,
    fee_amount: restRequest.fee_amount,
    from_bank_account_uid: restRequest.from_bank_account?.uid,
    from_bank_account_code: restRequest.from_bank_account?.bank_code,
    from_bank_account_name: restRequest.from_bank_account?.bank_account_name,
    from_bank_account_number: restRequest.from_bank_account?.bank_account_number,
    to_bank_account_uid: restRequest.to_bank_account?.uid,
    to_bank_account_code: restRequest.to_bank_account?.bank_code,
    to_bank_account_name: restRequest.to_bank_account?.bank_account_name,
    to_bank_account_number: restRequest.to_bank_account?.bank_account_number,
    channel: restRequest.channel,
    remark: restRequest.remark,
    timestamp: restRequest.timestamp,
  });
  return response.data;
};

const callDeleteAdjustBalance = async (request: AdjustBalanceByIdRequest) => {
  const { data: response } = await api.delete<CamelCaseBaseResponse<{}>>(`/realtime-transfer/history-log/${request.id}`, { data: { id: request.id } });
  return response.data;
};

const callConfirmAdjustHistory = async (request: AdjustBalanceByIdRequest) => {
  const { data: response } = await api.post<CamelCaseBaseResponse<{}>>(`/realtime-transfer/history-log/${request.id}`, { data: { id: request.id } });
  return response.data;
};

export const useRealtimeTransferHistory = (historyRequest: HitorySearchCriteria) => {
  const { data: historyResult, isFetching: isHistoryFetching, refetch: getHistoryList } = useQuery(
    ["realtime", "transfer", "bgb-bank", "limit-transfer", historyRequest],
    async () => {
      const { calendar, adjustType, channel } = historyRequest;
      let params = new URLSearchParams({
        limit: `${historyRequest.limit}`,
        offset: `${historyRequest.limit * historyRequest.offset}`,
        start: `${formatDateRequest(calendar && calendar[0] ? calendar[0].toDate() : new Date())}`,
        end: `${formatDateRequest(calendar && calendar[1] ? calendar[1].toDate() : new Date())}`,
        is_confirmed: `${historyRequest.status !== STATUS.WAITING_VERIFY}`,
      });
      if (adjustType !== 'ALL') {
        params.append('adjust_type', adjustType);
      }
      if (channel !== 'ALL') {
        params.append('channel', channel)
      }
      const response = await api.get<CamelCaseBaseResponse<HistoryResponse>>(`/realtime-transfer/history-log?${params}`);
      return response.data.data;
    },
    {
      enabled: false,
      select: (responseData) => {
        return {
          historyList: responseData.results.map(mapToRealtimeHistory),
          total: responseData.total.all,
          totalWaitingVerify: responseData.total.waitingVerify,
        }
      },
    },
  );

  const { mutate: editAdjustBalance, } = useMutation(putAdjustBalance, {
    onSuccess: data => {
      toast.success('แก้ไขการปรับบัญชีสำเร็จ');
    },
    onError: data => {
      toast.error('แก้ไขการปรับบัญชีไม่สำเร็จ');
    },
  })

  const { mutate: deleteAdjustBalance, } = useMutation(callDeleteAdjustBalance, {
    onSuccess: data => {
      toast.success('ยกเลิกรายการสำเร็จ');
    },
    onError: data => {
      toast.error('ยกเลิกรายการไม่สำเร็จ');
    },
  })

  const { mutate: confirmAdjustHistory, } = useMutation(callConfirmAdjustHistory, {
    onSuccess: data => {
      toast.success('ยืนยันการตรวจสอบสำเร็จ');
    },
    onError: data => {
      toast.error('ยืนยันการตรวจสอบไม่สำเร็จ');
    },
  })

  return {
    historyList: historyResult?.historyList || [],
    total: historyResult?.total || 0,
    totalWaitingVerify: historyResult?.totalWaitingVerify || 0,
    isHistoryFetching,
    getHistoryList,
    editAdjustBalance,
    deleteAdjustBalance,
    confirmAdjustHistory,
  }
};
