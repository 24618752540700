import { Tooltip } from "antd";
import { Button, ButtonProps } from "src/components";
import { colors } from "src/constants";
import { OutlineEdit1 } from "src/icons";

export interface EditButtonProps extends Omit<ButtonProps, "type" | "icon"> {}

function EditButton(props: EditButtonProps) {
  return (
    <Tooltip title="แก้ไข" color={colors.cloud6} overlayStyle={{ fontSize: 12 }}>
      <Button {...props} type="lightdark" icon={<OutlineEdit1 />} />
    </Tooltip>
  );
}

export default EditButton;
