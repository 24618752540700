import { Col, DatePicker, Row, Typography, Statistic } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useRefund } from '../../../hooks/useTransaction/useRefund';
import { TransactionState } from '../../../hooks/useTransaction/useTransaction';
import { addCommas } from '../../../utils/number/number';
import Account from '../../Bank/Account';
import { BankSelect } from '../../Bank/BankSelect';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import TimePicker from '../../Input/TimePicker';
import Modal from '../../Modal/Modal';
import Label from '../../Text/Label';
import './RefundModal.less';

export type IRefundModalProps = TransactionState & {
}

const RefundModalComponent: React.FunctionComponent<IRefundModalProps> = (props) => {
  const {
    searchCriteria,
    refundingTransaction,
    cancelReserveTransaction,
    doCallWithdrawTransaction,
  } = props;
  const {
    withdrawBankList,
    getWithdrawBankList,
    selectedWithdrawBankAccount,
    setSelectedWithdrawBankAccount,
    transferAt,
    setTransferAt,
    doCallRefund,
  } = useRefund();

  React.useEffect(() => {
    getWithdrawBankList();
  }, []);


  React.useEffect(() => {
    if (refundingTransaction) {
      setTransferAt(new Date());
    }
  }, [refundingTransaction]);

  const isFormFullFill = () => {
    return transferAt && selectedWithdrawBankAccount;
  };

  const onClickSave = () => {
    if (refundingTransaction) {
      doCallRefund(
        refundingTransaction,
        () => {
          cancelReserveTransaction(refundingTransaction);
          doCallWithdrawTransaction(searchCriteria)
        });
    }
  }

  const renderCountDown = () => {
    if (refundingTransaction) {
      return (
        <Statistic.Countdown
          format="mm:ss"
          value={Date.now() + 60 * 1000 * 5}
          valueStyle={{ fontSize: '16px' }}
          className="withdraw-modal-countdown"
          onFinish={() => cancelReserveTransaction(refundingTransaction)}
        />
      );
    }
  };

  return (
    <Modal
      width={614}
      centered
      className='RefundModal'
      title="โอนเงินผ่านแอปธนาคาร"
      visible={refundingTransaction !== undefined}
      footer={(
        <>
          <Button
            size="large"
            style={{ float: 'left', width: 100 }}
            onClick={() => { if (refundingTransaction) cancelReserveTransaction(refundingTransaction, undefined, true) }}
          >
            ยกเลิก
          </Button>
          <Button
            disabled={!isFormFullFill()}
            style={{ width: 176 }}
            size="large"
            type="success"
            onClick={onClickSave}
          >
            <Icon name="filled-select-1" size="22px" />&nbsp;บันทึกการโอน
          </Button>
        </>
      )}
    >
      {refundingTransaction && (
        <>
          <div className="RefundModal-info" >
            <div style={{ width: '55%', margin: 'auto', padding: '1rem 0' }} >
              <Row className="RefundModal-info-title-column">
                <Col span={12}>
                  บัญชีลูกค้า
                </Col>
                <Col span={12}>
                  จำนวนเงิน
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Account {...refundingTransaction.customerBank} />
                </Col>
                <Col className='RefundModal-info-amount' span={12}>
                  {addCommas(refundingTransaction.amount.toFixed(2))}
                </Col>
              </Row>
            </div>
          </div>
          <div className='RefundModal-form' style={{ width: '55%', margin: '1rem auto', marginBottom: '2rem' }}>
            <Typography.Title level={5} >ข้อมูลการโอนเงิน</Typography.Title>
            <div>
              <Label>วันที่โอน</Label>
              <DatePicker
                size="large"
                allowClear={false}
                value={moment(transferAt)}
                style={{ width: '100%' }}
                onChange={(_date) => { setTransferAt(_date?.toDate()) }}
              />
            </div>
            <div>
              <Label>เวลาที่โอน</Label>
              <TimePicker
                autoFocus
                allowClear={false}
                value={moment(transferAt)}
                onChange={(_date) => { setTransferAt(_date?.toDate()) }}
                style={{ width: '100%' }}
              />
            </div>
            <div>
              <Label>บัญชีที่ PIGSPIN</Label>
              <BankSelect bankList={withdrawBankList} onChange={(_b) => { setSelectedWithdrawBankAccount(_b) }} />
            </div>

          </div>
        </>
      )}
    </Modal>
  );
};
const RefundModal = (props: IRefundModalProps) => React.useMemo(() => <RefundModalComponent {...props} />, [props.refundingTransaction]);
export default RefundModal;
