import { decodeToken } from 'react-jwt';
import { selector } from 'recoil';
import { ENABLE_DEV_MODE } from 'src/models/buildtime-constant';
import { ScopePermission } from 'src/types';
import { KEYS } from '../keys';
import authState, { AuthState } from './auth';

export type AuthInfoState = Partial<AuthState> & {
  token: string;
  userId: string;
  scope: ScopePermission;
}

export type DecodeToken = {
  created_at: Date;
  scope_list: string[];
  user_uid: string;
}

const defaultDecodeToken = {
  created_at: new Date(),
  scope_list: [],
  user_uid: ''
}

const authInfoState = selector<AuthInfoState>({
  key: KEYS.AUTH_INFO,
  get: ({ get }) => {
    const auth = get(authState);
    const info: DecodeToken = decodeToken(auth.token || '') as DecodeToken || defaultDecodeToken
    const backdoorScope = ENABLE_DEV_MODE ? new URLSearchParams(window.location.search).get('scope')?.split(',') || [] : []
    const disableScope = ENABLE_DEV_MODE ? new URLSearchParams(window.location.search).get('disable_scope')?.split(',') || [] : []
    let scopeList = info.scope_list.filter(function (scope) {
      return disableScope.indexOf(scope) === -1;
    });
    scopeList = [...scopeList, ...backdoorScope]

    const scope = scopeList.reduce((map, scopeKey: string) => {
      return {
        ...map,
        [scopeKey]: {
          enable: true,
        },
      }
    }, {})
    return {
      ...auth,
      userId: info.user_uid,
      token: auth.token || '',
      scope: {
        ...scope,
        ALL: { enable: true },
      },
    }
  }
});

export default authInfoState;