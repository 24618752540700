import { useQuery } from "react-query";
import { api } from "src/utils";
import { PubnubTokenStatus } from "src/types";


export function ChatStatus(
  user_uuid?: string,
  enabled: boolean = true,
) {
  return useQuery(
    ["ChatStatus", user_uuid],
    () => api.get("/loyalty-shop/pubnub/status", { params: { userUuid: user_uuid } }).then((res) => res.data.data as {
              results: PubnubTokenStatus
            }),
    { enabled }
  )
}

export function BanRain(
  user_uuid?: string,
  status?: boolean,
  enabled: boolean = true,
) {
  return useQuery(
    ["banRain", user_uuid, status],
    () => api.get("/loyalty-shop/chat/banrain", { params: { userUuid: user_uuid, status }}).then((res) => res.data.data as {
      status: string;
      message: string;
    }),
    { enabled }
  )
}

export function BanChatManual(
  user_uuid?: string,
  day_ban?: number,
  enabled: boolean = true,
) {
  return useQuery(
    ["banChatManual", user_uuid, day_ban],
    () => api.get("/loyalty-shop/pubnub/banchat", { params: { userUuid: user_uuid, dayBan: day_ban } }).then((res) => res.data.data as {
      status: string;
      startDate: string;
      endDate: string;
    }),
    { enabled }
  )
}

export function CancelBanChat(
  user_uuid?: string,
  enabled: boolean = true,
) {
  return useQuery(
    ["cancelBanChat", user_uuid],
    () => api.get("/loyalty-shop/pubnub/cancel-banchat", { params: { userUuid: user_uuid } }).then((res) => res.data.data as {
      status: string;
    }),
    { enabled }
  )
}
