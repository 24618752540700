import { Transfer, Tree } from 'antd';
import type { TransferDirection, TransferItem } from 'antd/es/transfer';
import type { DataNode, EventDataNode } from 'antd/es/tree';
import "./Transfer.less";


interface TreeTransferProps {
  dataSource: DataNode[];
  targetKeys: string[];
  onChange: (targetKeys: string[], direction: TransferDirection, moveKeys: string[]) => void;
}

// Customize Table Transfer
const isChecked = (selectedKeys: (string | number)[], eventKey: string | number) =>
  selectedKeys.includes(eventKey);

const generateTree = (treeNodes: DataNode[] = [], checkedKeys: string[] = [], reverse?: boolean): DataNode[] => {
  const filteredObjects = treeNodes.map(object => {
    let children: DataNode[] = [];
    if (object.children && object.children.length > 0) {
      children = object.children.filter(child => reverse ? checkedKeys.includes(`${child.key}`) : !checkedKeys.includes(`${child.key}`))
    }
    return { ...object, children: children };
  });
  return filteredObjects.filter(object => object.children.length > 0).sort((a, b) => a.key > b.key ? 1 : -1);
};

export const TreeTransfer = ({ dataSource, targetKeys, ...restProps }: TreeTransferProps) => {
  const transferDataSource: TransferItem[] = [];
  function flatten(list: DataNode[] = []) {
    list.forEach((item) => {
      if (item.children && item.children.length > 0) {
        flatten(item.children);
      } else {
        transferDataSource.push(item as TransferItem);
      }
    });
  }
  flatten(dataSource);

  return (
    <Transfer
      {...restProps}
      targetKeys={targetKeys}
      dataSource={transferDataSource}
      className="tree-transfer"
      render={(item) => item.title!}
      showSelectAll={false}
      titles={['ซ่อนค่ายเกมฝั่งหน้าเว็บ', 'แสดงค่ายเกมฝั่งหน้าเว็บ']}
      listStyle={{
        width: 350,
        height: 260,
        overflow: 'auto',
        scrollBehavior: "smooth",
      }}
      onSelectChange={(targetSelectedKeys) => console.log('select: ', targetSelectedKeys)}
    >
      {({ direction, onItemSelect, onItemSelectAll, selectedKeys }) => {
        const checkingTree = (node: EventDataNode) => {
          const { key, children } = node;
          if (children) {
            const groupCheck = !isChecked(selectedKeys, children[0].key)
            const keys = children.map(item => item.key);
            onItemSelectAll(keys as string[], groupCheck);
          } else {
            onItemSelect(key as string, !isChecked(selectedKeys, key));
          }
        }
        if (direction === 'left') {
          return (
            <div style={{ padding: '10px' }}>
              <Tree
                blockNode
                checkable
                defaultExpandAll
                autoExpandParent
                checkedKeys={selectedKeys}
                treeData={generateTree(dataSource, targetKeys)}
                onCheck={(_, { node }) => checkingTree(node)}
                onSelect={(_, { node }) => checkingTree(node)}
              />
            </div>
          );
        }
        if (direction === 'right') {
          return (
            <div style={{ padding: '10px' }}>
              <Tree
                blockNode
                checkable
                defaultExpandAll
                autoExpandParent
                checkedKeys={selectedKeys}
                treeData={generateTree(dataSource, targetKeys, true)}
                onCheck={(_, { node }) => checkingTree(node)}
                onSelect={(_, { node }) => checkingTree(node)}
              />
            </div>
          );
        }
      }}
    </Transfer>
  );
};

