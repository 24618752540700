import { countBy } from "lodash";
import { Option } from "src/components/RadioButton/RadioButton";

interface GetFilterOptionsTypes {
  columnName: string;
  allData: Record<string, any>[];
  /** withAll: with menu all filter. Ex.ทั้งหมด (28) */
  withAll?: boolean;
  withFormat?: (key: string) => string;
}

export function getFilterOptions({
  columnName,
  allData,
  withAll,
  withFormat,
}: GetFilterOptionsTypes): Option[] {
  const uniqFieldsCount = countBy(allData, columnName);
  const options = Object.entries(uniqFieldsCount).map(([key, count]): Option => {
    return {
      key,
      text: `${withFormat ? withFormat(key) : key} (${count})`,
      value: key
    };
  });
  if (withAll) {
    return [
      {
        key: 'all',
        text: `ทั้งหมด (${allData?.length ?? 0})`,
		value: ''
      },
      ...options,
    ];
  }
  console.log('options', options);

  return options;
}
