import { AxiosError } from "axios"
import { useQuery, UseQueryOptions, useMutation } from "react-query";
import { Modal, toast } from "src/components"
import { TicketManagementAPI, Pagination } from "src/types";
import { api } from "src/utils";
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { pigspinBackofficeApiInstance } from "../utils/api/AxiosInstance";
import { fetchWithJSON } from "../utils/api/fetch";
import { BaseResponse2, BaseResponse, BaseRequest } from "../services/service.type";

export interface TicketManagementLogsFilters {
  customerCode: string;
  start?: string;
  end?: string;
}

export type TicketManagementImportRequest = BaseRequest & {
  user_code: [];
  campaign_code: string;
  campaign_name: string;
  tickets: string;
}

export type TicketManagementImportRespones = {
  code: number;
  data: {
    message: string;
    status: 'SUCCESS' | 'ERROR';
    users: [];
  };
  service_code: string;
  service_message: string;
  status: 'SUCCESS' | 'ERROR';
};

interface PaginationWithSummary extends Pagination<TicketManagementAPI> {
  summary?: {
    ticketBefore: number;
    ticketUsed: number;
    ticketAfter: number;
  };
}

export function TicketManagementHook<T = PaginationWithSummary>(
  filters?: TicketManagementLogsFilters,
  options?: UseQueryOptions<PaginationWithSummary, unknown, T, any[]>
) {
  return useQuery(
    ["totalTicket", filters],
    async () => {
      if (filters?.customerCode) {
        const response = await api.get("loyalty-shop/backoffice/ticketlogs", {
          params: { customerCode: filters?.customerCode, from_date: filters?.start, to_date: filters?.end },
        });
        return response.data.data as {
          customerCode: string;
          totalTicket: number;
          results: TicketManagementAPI[];
          total: number;
        };
      }
    },
    { keepPreviousData: true },
    
  );
}


export const TicketManagementVerifyHook = async (request: TicketManagementImportRequest): Promise<TicketManagementImportRespones> => {
  try {
    const { token, ...rest } = request;
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/loyalty-shop/backoffice/ticket/campaign/verify',
      method: 'POST',
      headers: { token },
      data: rest,
    };
    return await fetchWithJSON<TicketManagementImportRespones>(pigspinBackofficeApiInstance, requestConfig).catch((e) => ({ ...e.response.data }));
  } catch (error: any) {
    console.log(error.response.data)
    return error.response.data;
  }
}


export const TicketManagementImportHook = async (request: TicketManagementImportRequest): Promise<TicketManagementImportRespones> => {
  try {
    const { token, ...rest } = request;
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/loyalty-shop/backoffice/ticket/campaign/import',
      method: 'POST',
      headers: { token },
      data: rest,
    };
    return await fetchWithJSON<TicketManagementImportRespones>(pigspinBackofficeApiInstance, requestConfig).catch((e) => ({ ...e.response.data }));
  } catch (error: any) {
    console.log(error.response.data)
    return error.response.data;
  }
}
