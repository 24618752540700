import Button, { ButtonProps } from "antd-button-color";
import { OutlineAdd1 } from "src/icons";

export interface AddButtonProps extends Omit<ButtonProps, "type" | "icon"> {}

function AddButton(props: AddButtonProps) {
  return (
    <Button
      size="large"
      {...props}
      type="primary"
      icon={<OutlineAdd1 style={{ fontSize: 18 }} />}
    />
  );
}

export default AddButton;
