import dayjs, { Dayjs } from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault("Asia/Bangkok")

export function datetime(value: string | Date): Dayjs {
  return dayjs.utc(value).tz()
}

export function today(at: string | Date): Dayjs {
  return datetime(at).year(dayjs().year()).month(dayjs().month()).date(dayjs().date());
}

export function datetimeUtc(value: string): Dayjs {
  return dayjs.utc(value);
};

export function formatDatetime(date: Dayjs, time?: Dayjs): string {
  if (time) {
    const dateString = date.format("YYYY-MM-DD")
    const timeString = time.format("HH:mm:ss:SSS")
    return dayjs.tz(`${dateString} ${timeString}`).format()
  }
  return dayjs.tz(date).toISOString()
}

export function formatUtcTime(date: Dayjs): string {
  return datetimeUtc(date.toString()).format('HH:mm');
}