import { Switch, TableColumnsType, Button } from "antd";
import { FilterValue } from "antd/lib/table/interface";
import { BrandList } from "src/hooks/useGameBrand";
import { GameBrands } from "src/types";
import { getColumnFilters } from "src/utils/table";
import { datetime } from "src/utils";


interface GameBrandColumnsTypes {
  brands?: GameBrands[];
  filteredInfo: Record<string, FilterValue | null>;
  handleUpdateGame: (params: BrandList) => void;
  handleMenuFilter: (value: GameBrands) => void;
}

export const GameBrandColumns = ({
  brands = [],
  filteredInfo,
  handleUpdateGame,
  handleMenuFilter,
}: GameBrandColumnsTypes): TableColumnsType<GameBrands> => [
  {
    key: "name",
    title: "ค่ายเกม",
    dataIndex: "name",
    width: 200,
    filters: getColumnFilters("name", brands as Record<string, any>[]),
    filteredValue: filteredInfo.name || null,
    filterSearch: true,
    onFilter: (value, record: GameBrands) => {
      return record?.name === value;
    },
    render: (value, record) => {
      if (record?.totalGame === 0) {
        return <span>{value}</span>;
      }
      return (
        <Button type="link" style={{ padding: "0" }} onClick={(e) => handleMenuFilter(record)}>
          {value}
        </Button>
      );
    },
  },
  {
    key: "providerName",
    title: "สาย",
    dataIndex: "providerName",
    width: 200,
  },
  {
    key: "totalGame",
    title: "จำนวนเกม",
    dataIndex: "totalGame",
  },
  {
    title: "วันเวลาเปิด/ปิดล่าสุด",
    dataIndex: "lastIsActiveUpdated",
    key: "lastIsActiveUpdated",
    render: (value) => {
      if (value) return datetime(value).format("DD/MM/YYYY HH:mm");
      else return null;
    },
    width: 200,
  },
  {
    title: "อัพเดทโดย",
    dataIndex: "adminName",
    key: "adminName",
  },
  {
    title: "การใช้งาน",
    dataIndex: "isUnderConstruction",
    key: "isUnderConstruction",
    render: (status, record) => (
      <Switch
        checked={!record.isUnderConstruction}
        checkedChildren="เปิด"
        unCheckedChildren="ปิด"
        onChange={(checked: boolean) =>
          handleUpdateGame({ ...record, isUnderConstruction: !checked, name: record.name })
        }
      />
    ),
  },
];
