import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal, Row, Col, Spin, Statistic  } from "antd";
import { FilterValue } from "antd/lib/table/interface";
import 'src/components/VIP/Vip.scss' 
import { Table, ColumnType } from "src/components";
import { VipHistoryAPI, VipHistoryResult } from "src/types";
import { useState, useEffect } from "react";
import { datetime } from "src/utils";
import moment from 'moment';
import { useListVipHistory, VipHistoryRequest } from "src/hooks";
import { TableProps } from "antd/es/table";
import { getColumnFilters } from "src/utils/table";

export type VipRedeemHistoryProps = {
  customer_code: string;
  onClose?: () => void;
  onSuccess?: () => void;
};

interface PaginationTypes {
  limit: number;
  current: number;
}

const initialPagination: PaginationTypes = {
  limit: 10,
  current: 1,
};

export const VipRedeemHistory = NiceModal.create(({customer_code, ...props}: VipRedeemHistoryProps) => {
  const { visible, hide, remove } = useModal();

  const handleClose = () => {
    hide();
    props.onClose?.();
  };

  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [pagination, setPagination] = useState<PaginationTypes>(initialPagination);
  const [filters, setFilters] = useState<VipHistoryRequest>();
  const { data, isFetching, refetch } = useListVipHistory(filters);

  useEffect(() => {
    setFilters({ customerCode: customer_code });
  }, []);

  const handleChange: TableProps<VipHistoryAPI<VipHistoryResult>>['onChange'] = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  const columns: ColumnType<VipHistoryResult>[] = [
    {
      title: "รหัสลูกค้า",
      dataIndex: "customerCode",
      width: 100,
    },
    {
      title: "ระดับ VIP",
      dataIndex: "tier",
      width: 100,
      filters: getColumnFilters("tier", data?.result as Record<string, any>[] || []),
      filteredValue: filteredInfo.tier || null,
      filterSearch: true,
      onFilter: (value, record: VipHistoryResult) => {
        return record?.tier === value;
      },
    },
    {
      title: "เลเวล",
      dataIndex: "level",
      width: 100,
      sorter: (a, b) => a.level - b.level,
    },
    {
      title: "วันเวลาที่ได้รับของ",
      dataIndex: "receivedAt",
      width: 150,
      render: (value) => {
        return datetime(value).format("DD/MM/YYYY HH:mm");
      },
      sorter: (a, b) => moment(a.receivedAt).unix() - moment(b.receivedAt).unix(),
    },
    {
      title: "ประเภทของที่ได้รับ",
      dataIndex: "type",
      width: 150,
      filters: getColumnFilters("type", data?.result as Record<string, any>[] || []),
      filteredValue: filteredInfo.type || null,
      filterSearch: true,
      onFilter: (value, record: VipHistoryResult) => {
        console.log(value)
        console.log(record)
        return record?.type === value;
      },
    },
    {
      title: "คำอธิบาย",
      dataIndex: "detail",
      width: 200,
    },
  ];

  return (
    <Modal
        centered
        className="vip-history-modal"
        width={1000}
        visible={visible}
        afterClose={remove}
        focusTriggerAfterClose={false}
        closable={false}
        title="ประวัติ VIP"
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button type='primary' key="cancel" size="large" onClick={handleClose} style={{ width: "100px" }}>
              ปิด
            </Button>
          </div>
        }
      >
        {data && (
          <Row gutter={16}>
            <Col span={24}>
              <Spin spinning={isFetching}>
                <Row gutter={16} style={{ marginBottom: 16 }}>
                  <Col span={24}>
                    <div
                      style={{
                        borderRadius: 8,
                        padding: "8px 16px",
                        background: "linear-gradient(225deg, #9737C1 0%, #5B4ABF 100%)",
                        color: "white"
                      }}
                    >
                      <Row>
                        <Col span={4}  style={{ color: "white" }}>
                          <Statistic title="Vip Level" value={data?.vipLevel} />
                        </Col>
                        <Col span={8}>
                          <Statistic title="Total Turn Over" value={data?.turnOver} style={{ color: "white" }}/>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Spin>
            </Col>
          </Row>
        )}
        <Table
          style={{ whiteSpace: 'pre' }}
          rowKey="customerCode"
          columns={columns}
          dataSource={data?.result}
          onChange={handleChange}
          loading={isFetching }
          pagination={{
            current: pagination.current,
            pageSize: pagination.limit,
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, current: page, limit: pageSize });
            },
          }}
        />
      </Modal>
  );
});

export default VipRedeemHistory;
