import { useQuery, UseQueryOptions } from "react-query";

import { BaseResponse2 } from "src/services/service.type";
import { Pagination, QRPaymentReportItem } from "src/types";
import { api } from "src/utils";

export interface UseQRPaymentReportFilters {
  searchCustomer?: string;
  startTime?: Date;
  endTime?: Date;
}

type QRPaymentResponseData = Omit<Pagination<QRPaymentReportItem>, "page" | "pageType" | "pageSize">;

export function useQRPaymentReport<T = QRPaymentResponseData>(
  filters?: UseQRPaymentReportFilters,
  options?: UseQueryOptions<QRPaymentResponseData, unknown, T, any[]>
) {
  return useQuery(
    ["qrPaymentReport", filters],
    async (): Promise<QRPaymentResponseData> => {
      const response = await api.get<BaseResponse2<QRPaymentResponseData>>("report/qr-payment", {
        params: filters,
      });
      return response.data.data
    },
    options,
  );
}
