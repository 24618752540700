import React from "react";
import NiceModal from "@ebay/nice-modal-react";
import dayjs, { Dayjs } from "dayjs";
import isEmpty from 'lodash/isEmpty';
import { QueryFunctionContext, RefetchOptions, RefetchQueryFilters, useQuery, useMutation } from "react-query";
import { api, datetime } from "src/utils";
import { CamelCaseBaseResponse } from "src/services/service.type";
import {
  AFFILIATE_STATUS,
  AFFILIATE_TYPE,
  AffiliateCustomer,
  AffiliateTransaction,
  ApiAffiliateCustomer,
  AffiliateTransactionItem,
  AffiliateTransactionData,
  StatisticSummaryData,
  ApiMonthlyTransactionItem,
  MonthlyTransaction,
} from "./type";
import { isExpired } from "src/components/Affiliate/Columns";
import { mapToCustomerText } from "src/mapper/mapToCustomer";
import { numberWithoutCommas } from "src/utils/number/number";
import { CommonNiceModal } from "src/components/Modal/Modal";
import { toast } from "src/components";

export type OrgChart = {
  downline: AffiliateCustomer[];
  self?: AffiliateCustomer;
  upline?: AffiliateCustomer;
};

export type StatisticSummary = {
  balance: number;
  income: number;
  firstDeposit: number;
  kyc: number;
  lossing: number;
  withdraw: number;
};

export type AffiliateState = OrgChart & {
  breadcrumb: string[];
  affilateTransactions: AffiliateTransaction[];
  monthlyTransactions: MonthlyTransaction[];
  isOrgchartLoading: boolean;
  isTransactionLoading: boolean;
  isCreatingTransaction: boolean;
  isDeletingTransaction: boolean;
  isSummaryFetched: boolean;
  getOrgChart: () => void;
  getStatisticSummary: () => void;
  setBreadcrumb: (breadcrumb: string[]) => void;
  getAffiliateTransactions: (options?: RefetchOptions & RefetchQueryFilters<any>) => void;
  getAffiliateMonthlyTransactions: () => void;
  createAffiliateTransaction: (request: AffiliateTransactionRequest) => any;
  deleteAffiliateTransaction: (id: string) => void;
  summary: StatisticSummary;
};

const mapCustomer = (customer: ApiAffiliateCustomer): AffiliateCustomer => {
  const hasKyc = customer.identityNumber;
  return {
    key: customer.uid,
    userCode: customer.playerUsername,
    phone: customer.phoneNumber,
    name: customer.displayName,
    channel: customer.refBy === 'LINK' ? 'ลิงก์รหัสแนะนำ' : 'รหัสแนะนำ',
    createdAt: datetime(customer.createdAt),
    income: customer.downlineInfo?.summaryIncome || 0,
    hasChild: customer.downlineUserUid ? customer.downlineUserUid.length > 0 : false,
    status: customer.status,
    kycText: hasKyc ? 'ยืนยันตัวตนแล้ว' : 'ยังไม่ยืนยันตัวตน',
    hasKyc,
    statusText: mapToCustomerText(customer.status),
  };
};

export const mapToAffiliateTypeText = (affiliateType: AFFILIATE_TYPE): string => {
  if (affiliateType === AFFILIATE_TYPE.KYC) return 'ยืนยันตัวตน';
  if (affiliateType === AFFILIATE_TYPE.FIRST_DEPOSIT) return 'เติมเงินครั้งแรก';
  if (affiliateType === AFFILIATE_TYPE.LOSSING) return 'ยอดเสีย';
  return '-';
};

const mapToStatusText = (item: AffiliateTransaction): string => {
  const { affiliateType, status, type } = item;
  if (type === 'WITHDRAW') {
    if (status === AFFILIATE_STATUS.SUCCESS) return 'ถอนเงินสำเร็จ';
    if (status === AFFILIATE_STATUS.REJECTED) return 'ปฏิเสธถอนเงิน';
    return 'กำลังดำเนินการ';
  }
  if (status === AFFILIATE_STATUS.DELETED) return 'โดนยกเลิก';
  if (affiliateType === AFFILIATE_TYPE.FIRST_DEPOSIT) {
    if (status === AFFILIATE_STATUS.SUCCESS) return 'ทำเทิร์นสำเร็จ';
    if (status === AFFILIATE_STATUS.PENDING) return 'กำลังทำเทิร์น';
    if (status === AFFILIATE_STATUS.REJECTED) return 'ทำเทิร์นไม่สำเร็จ';
  }
  if (affiliateType === AFFILIATE_TYPE.KYC) {
    if (status === AFFILIATE_STATUS.SUCCESS) return 'สำเร็จ';
    if (status === AFFILIATE_STATUS.PENDING) return 'กำลังดำเนินการ';
    if (status === AFFILIATE_STATUS.REJECTED) return 'ไม่สำเร็จ';
  }
  if (affiliateType === AFFILIATE_TYPE.LOSSING) {
    if (status === AFFILIATE_STATUS.SUCCESS) {
      if (isExpired(item)) return 'หมดอายุ'
      return 'สำเร็จ';
    }
    if (status === AFFILIATE_STATUS.PENDING) return 'กำลังดำเนินการ';
    if (status === AFFILIATE_STATUS.REJECTED) return 'ไม่สำเร็จ';
  }
  if (affiliateType === AFFILIATE_TYPE.WITHDRAW) {
    if (status === AFFILIATE_STATUS.SUCCESS) return 'ถอนเงินสำเร็จ';
    if (status === AFFILIATE_STATUS.REJECTED) return 'ปฏิเสธถอนเงิน';
  }
  return '';
};

const mapToAffiliateTransaction = (item: AffiliateTransactionItem): AffiliateTransaction => {
  const isAffiliateWithdraw = item.transactionType === 'CREDIT';
  const baseTransaction: AffiliateTransaction = {
    uid: item.uid,
    createdAt: datetime(item.createdAt),
    createReferAt: datetime(item.downlineInfo ? item.downlineInfo.createdAt : ''),
    expiredAt: datetime(item.downlineInfo?.expiredAt || ''),
    type: isAffiliateWithdraw ? 'WITHDRAW' : 'DEPOSIT',
    downlineUserCode: item.downlineInfo ? item.downlineInfo.userNickname : '-',
    affiliateType: isAffiliateWithdraw ? AFFILIATE_TYPE.WITHDRAW : item.affiliateType,
    status: item.status,
    amount: numberWithoutCommas(item.amount || 0),
    bonus: numberWithoutCommas(item.bonus || 0),
    turnoverAmount: numberWithoutCommas(item.turnoverAmount || 0),
    turnovered: numberWithoutCommas(item.turnoverSuccessAmount || 0),
    income: item.bonus ? numberWithoutCommas(item.bonus || 0) : numberWithoutCommas(item.incomeAmount || 0),
    balance: numberWithoutCommas(item.remainingBalance || 0),
    latestActionAt: datetime(item.updatedAt),
    isAuto: typeof item.remarkType !== 'string',
    remark: item.remark,
    remarkType: item.remarkType,
    logs: item.logs,
  };
  return {
    ...baseTransaction,
    typeText: baseTransaction.type === 'DEPOSIT' ? (baseTransaction.bonus > 0 ? 'รายได้(โบนัส)':'รายได้') : 'ถอนเงิน',
    affiliateTypeText: mapToAffiliateTypeText(item.affiliateType),
    statusText: mapToStatusText(baseTransaction),
  }
};

const mapMonthlyTransaction = (item: ApiMonthlyTransactionItem): MonthlyTransaction => {
  const [year, month] = item.month.split('-')
  return {
    month: dayjs().set('year', numberWithoutCommas(year)).set('month', numberWithoutCommas(month) - 1).endOf('month'),
    summary: item.kycVerified + item.firstDeposit + item.reachTarget,
    kyc: item.kycVerified,
    firstDeposit: item.firstDeposit,
    lossing: item.reachTarget,
  };
};

export type AffiliateTransactionType = 'CREDIT' | 'DEBIT';

export type AffiliateParams = {
  userCode: string;
  from: Dayjs;
  to: Dayjs;
  affiliateType?: AffiliateTransactionType;
}

export type AffiliateTransactionRequest = {
  upline: string;
  downline: string;
  affiliateType: AFFILIATE_TYPE;
  amount: number;
  incomeAmount: number;
  turnover: number
  remarkType: string; // NOTE : dropdown
  remark?: string; // NOTE : for อื่นๆ
};


export const useAffiliate = ({
  userCode,
  from,
  to,
  affiliateType,
}: AffiliateParams): AffiliateState => {
  const [breadcrumb, setBreadcrumb] = React.useState<string[]>([]);
  const { data: orgChartData, refetch: getOrgChart, isFetching: isOrgchartLoading } = useQuery<OrgChart>(
    ['orgChart', userCode],
    async (context: QueryFunctionContext) => {
      const response = await api.get<CamelCaseBaseResponse<any>>(`/affiliate/organization?player_username=${userCode}`);
      if (response.data.serviceCode === 'BOE-2000') {
        const self = !isEmpty(response.data.data.me)
          ? {
            ...mapCustomer(response.data.data.me),
            income: response.data.data.downline.reduce((total: number, downline: ApiAffiliateCustomer) => {
              return total + (downline.downlineInfo?.summaryIncome || 0);
            }, 0),
            channel: '-',
          }
          : undefined

        const downline: AffiliateCustomer[] = response.data.data.downline.map(mapCustomer);
        if (self) {
          const youAreInBreadCrumbAt = breadcrumb.indexOf(self.userCode);
          const areYouInBreadCrumb = youAreInBreadCrumbAt >= 0;

          if (!areYouInBreadCrumb) {
            const first = breadcrumb[0];
            const isGoingToParent = first && downline.some((_d) => _d.userCode === first);
            if (isGoingToParent) {
              setBreadcrumb((prev) => ([self.userCode]));
            } else {
              setBreadcrumb((prev) => ([...prev, self.userCode]));
            }
          }

          if (areYouInBreadCrumb) { setBreadcrumb(breadcrumb.slice(0, youAreInBreadCrumbAt + 1)); };
        }
        return {
          self,
          downline: response.data.data.downline.map(mapCustomer),
          upline: !isEmpty(response.data.data.upline)
            ? {
              ...mapCustomer(response.data.data.upline),
              income: 0,
              channel: '-',
            }
            : undefined,
        };
      }
      return {
        downline: [],
        self: undefined,
        upline: undefined,
      };
    },
    {
      enabled: false,
    },
  )

  const { data: affiliateTransactionResult, refetch: getAffiliateTransactions, isFetching: isTransactionLoading } = useQuery(
    ['affiliateTransactions', userCode],
    async (context: QueryFunctionContext) => {
      const params = new URLSearchParams({
        start_date: from.format(),
        end_date: to.format(),
        player_code_or_phone: userCode,
        order_by: '-created_at',
      })
      if (affiliateType) params.append('transaction_type', affiliateType);
      const response = await api.get<CamelCaseBaseResponse<AffiliateTransactionData>>(`/affiliate/overview/transactions?${params.toString()}`);
      if (response.data.serviceCode === 'BOE-2000') {
        const data = response.data.data;
        return {
          all: data.total.all,
          first_deposit: data.total.first_deposit,
          kyc_verified: data.total.kyc_verified,
          reach_target: data.total.reach_target,
          withdraw: data.total.withdraw,
          transactions: data.results.map(mapToAffiliateTransaction),
        }
      }
      return {
        all: 0,
        first_deposit: 0,
        kyc_verified: 0,
        reach_target: 0,
        withdraw: 0,
        transactions: [],
      }
    },
    {
      enabled: false,
    },
  )

  const { data: affiliateMonthlyTransactions, refetch: getAffiliateMonthlyTransactions } = useQuery(
    ['affiliateMonthlyTransactions', userCode],
    async (context: QueryFunctionContext) => {
      const params = new URLSearchParams({
        start_date: from.format(),
        end_date: to.format(),
        player_code_or_phone: userCode,
      })
      const response = await api.get<CamelCaseBaseResponse<ApiMonthlyTransactionItem[]>>(`/affiliate/overview/monthly?${params.toString()}`);
      if (response.data.serviceCode === 'BOE-2000') {
        return response.data.data.map(mapMonthlyTransaction);
      }
      return [];
    },
    {
      enabled: false,
    },
  )

  const { data: summary, refetch: getStatisticSummary, isFetched: isSummaryFetched } = useQuery(
    ['statisticSummary', userCode],
    async (context: QueryFunctionContext) => {
      const response = await api.get<CamelCaseBaseResponse<StatisticSummaryData>>(`/affiliate/overview/summary-income?player_code_or_phone=${userCode}`);
      const data = response.data.data;
      return {
        balance: data.balance,
        income: data.incomeOverview.summary,
        firstDeposit: data.incomeOverview.firstDeposit,
        kyc: data.incomeOverview.kycVerified,
        lossing: data.incomeOverview.reachTarget,
        withdraw: data.incomeOverview.withdraw,
      };
    },
    {
      enabled: false,
    },
  )

  const { mutate: createAffiliateTransaction, isLoading: isCreatingTransaction } = useMutation(async (request: AffiliateTransactionRequest) => {
    await api.post<CamelCaseBaseResponse<{}>>('/affiliate/transaction', request);
  }, {
    onSuccess: () => {
      getStatisticSummary();
      getAffiliateTransactions();
      getAffiliateMonthlyTransactions();
      NiceModal.hide(CommonNiceModal);
      toast.success('เพิ่มรายการสำเร็จ');
    },
    onError: () => {
      toast.error('เพิ่มรายการไม่สำเร็จ');
    },
  });

  const { mutate: deleteAffiliateTransaction, isLoading: isDeletingTransaction } = useMutation(async (uid: string) => {
    await api.delete<CamelCaseBaseResponse<{}>>(`/affiliate/transaction?transaction_uid=${uid}`, { data: { uid } });
  }, {
    onSuccess: () => {
      getStatisticSummary();
      getAffiliateTransactions();
      getAffiliateMonthlyTransactions();
      NiceModal.hide(CommonNiceModal);
      toast.success('ยกเลิกรายได้สำเร็จ');
    },
    onError: () => {
      toast.error('ยกเลิกรายได้ไม่สำเร็จ');
    },
  });

  return {
    breadcrumb,
    getOrgChart,
    setBreadcrumb,
    getAffiliateTransactions,
    getStatisticSummary,
    getAffiliateMonthlyTransactions,
    createAffiliateTransaction,
    deleteAffiliateTransaction,
    isDeletingTransaction,
    isCreatingTransaction,
    isOrgchartLoading,
    isTransactionLoading,
    isSummaryFetched,
    downline: orgChartData?.downline || [],
    self: orgChartData?.self,
    upline: orgChartData?.upline,
    affilateTransactions: affiliateTransactionResult?.transactions || [],
    monthlyTransactions: affiliateMonthlyTransactions || [],
    summary: {
      balance: summary?.balance || 0,
      income: summary?.income || 0,
      firstDeposit: summary?.firstDeposit || 0,
      kyc: summary?.kyc || 0,
      lossing: summary?.lossing || 0,
      withdraw: summary?.withdraw || 0,
    },
  };
};
