import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal, Row, Skeleton, Space } from "antd";

export type TicketManagementModalProps = {
  imageUrl: string;
};

export const TicketManagementModal = NiceModal.create(({ imageUrl }: TicketManagementModalProps) => {
  const { visible, hide, remove } = useModal();

  return (
    <Modal
      focusTriggerAfterClose={false}
      visible={visible}
      afterClose={remove}
      title="รูป BigWin"
      closable={false}
      footer={null}
      width={600}
      centered
    >
      {!imageUrl ? (
        <Skeleton active />
      ) : (
        <Space direction="vertical" size="large" style={{ padding: "0 20px", width: "100%" }}>
          <div className="container">
            <img
              src={ imageUrl }
              height="100%"
              width="100%"
            />
          </div>
          
        </Space>
      )}
      <Row justify="center" style={{ marginTop: "32px" }}>
        <Button type="primary" size="large" style={{ width: 150 }} onClick={hide}>
          ปิด
        </Button>
      </Row>
    </Modal>
  );
});

export default TicketManagementModal;
