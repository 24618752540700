import { Form, Input, Statistic, Spin } from 'antd';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { AlertModal } from "..";
import { DEPOSIT_STATUS } from '../../../hooks/useTransaction/type';
import { authState, modalState } from "../../../recoils";
import { requestCreateRefundTransaction } from "../../../services/createRefundTransaction";
import { requestValidateBank } from "../../../services/validateBank";
import "./RefundRequestModal.scss";
import Button from "src/components/Button/Button";
import { CreateRefundTransactionResponse } from "src/services/createRefundTransaction/requestCreateRefundTransaction";
import Modal from 'src/components/Modal/Modal';
import { BankCode } from 'src/types';
import { TransactionState } from 'src/hooks/useTransaction/useTransaction';
import React from 'react';
import BankSelect from 'src/components/BankSelect';

export type RefundRequestModalProps = {
  depositState: TransactionState;
}

const RefundRequestModalComponent: React.FunctionComponent<RefundRequestModalProps> = ({
  depositState,
}: RefundRequestModalProps) => {
  const [, setModal] = useRecoilState(modalState);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { token = '' } = useRecoilValue(authState);
  const { cancelReserveTransaction, refundRequestingTransaction } = depositState;
  const bankCode = refundRequestingTransaction?.customerBank.bankCode || BankCode.NONE;
  const transactionId = refundRequestingTransaction?.transactionId || '';
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields()
    setIsValid(false)
    if (refundRequestingTransaction) cancelReserveTransaction(refundRequestingTransaction, DEPOSIT_STATUS.REJECTED);
  }

  useEffect(() => {
    form.setFieldsValue({
      bankCode: refundRequestingTransaction?.customerBank.bankCode,
      bankName: refundRequestingTransaction?.customerBank.bankName,
      bankAccountName: refundRequestingTransaction?.customerBank.accountName,
      bankAccountNumber: refundRequestingTransaction?.customerBank.accountNumber,
    });
    if (refundRequestingTransaction) {
      const { accountName, accountNumber } = refundRequestingTransaction?.customerBank;
      if (accountName && accountNumber) { setIsValid(true) }
    }
  }, [refundRequestingTransaction]);

  const onSubmit = async (values: any) => {
    if (isLoading) return;
    setIsLoading(true);
    const validateBankResponse = await requestValidateBank({
      bankCode: values?.bankCode,
      token,
      bankAccountName: values?.bankAccountName,
      bankAccountNumber: values?.bankAccountNumber,
    });

    const is_bank_valid = validateBankResponse?.data?.is_valid || false;

    if (is_bank_valid) {
      try {
        const transactionStatusResponse = await requestCreateRefundTransaction({
          transaction_uid: transactionId,
          bank_code: values?.bankCode,
          bank_account_name: values?.bankAccountName || '',
          bank_account_number: values?.bankAccountNumber || '',
          token
        });
        setIsLoading(false);
        if (transactionStatusResponse?.status === 'SUCCESS') {
          toast.success("แจ้งคืนเงินสำเร็จ");
        } else {
          toast.warning("แจ้งคืนเงินไม่สำเร็จ");
        }
        onClose();
      } catch (e: any) {
        const response: CreateRefundTransactionResponse = e.response.data;
        const isAlreadyRequest = response.service_code === 'BOE-4002';
        if (isAlreadyRequest) {
          setModal({
            isModalVisible: true,
            type: 'error',
            title: 'ไม่สามารถทำรายการได้',
            content: 'เนื่องจากรายการนี้ถูกดำเนินการแล้ว',
            buttonType: 'confirm',
            onConfirm: () => {
              onClose();
            },
          });
        } else {
          toast.warning("แจ้งคืนเงินไม่สำเร็จ");
        }
      }
    } else {
      setIsLoading(false);
      form.setFields([
        { name: 'bankAccountName', errors: ['ชื่อบัญชีไม่ถูกต้อง'] },
        { name: 'bankAccountNumber', errors: ['เลขที่บัญชีไม่ถูกต้อง'] }
      ])
    }
  }

  const renderCountDown = () => {
    return (
      <Statistic.Countdown
        format="mm:ss"
        value={Date.now() + 60 * 1000 * 5}
        valueStyle={{ fontSize: '16px' }}
        className="withdraw-modal-countdown"
        onFinish={() => onClose()}
      />
    );
  };

  const renderFooter = () => (
    <div className="footer">
      <Button
        size="large"
        style={{ width: 100 }}
        onClick={onClose}
      >
        ยกเลิก
      </Button>
      <Button
        type="primary"
        size="large"
        htmlType="submit"
        disabled={!isValid}
        style={{ width: 176 }}
      >
        แจ้งคืนเงิน
      </Button>
    </div>
  );

  return (
    <div>
      <AlertModal
        isModalVisible={isAlertVisible}
        title="ไม่สามารถทำรายการได้"
        content="เนื่องจากรายการนี้มี Admin ท่านอื่นทำรายการอยู่"
        type="error"
        buttonType="confirm"
        onConfirm={() => setIsAlertVisible(false)}
        closeable={false}
        maskClosable={false}
      />
      <Modal
        centered
        className="RefundRequestModal"
        title="การแจ้งคืนเงิน"
        visible={refundRequestingTransaction !== undefined}
        onOk={onClose}
        onCancel={onClose}
        footer={false}
      >
        <Spin spinning={isLoading}>
          {renderCountDown()}
          <div className="content">กรุณากรอกข้อมูลบัญชีลูกค้า เพื่อโอนเงินกลับให้ลูกค้า</div>
          <Form
            className="form-register"
            onAbort={() => { }}
            onFinish={onSubmit}
            onFieldsChange={(_from, _fields) => {
              const isValid = _fields.every(_field => _field.value);
              setIsValid(isValid);
            }}
            initialValues={{ bankCode: bankCode }}
            autoComplete="off"
            layout="vertical"
            form={form}
            requiredMark={false}
          >
            <Form.Item
              label='ธนาคาร'
              name='bankCode'
              validateTrigger="onBlur"
            >
              <BankSelect disabled={bankCode !== BankCode.NONE} width='100%' />
            </Form.Item>
            <Form.Item
              label='ชื่อบัญชี'
              name='bankAccountName'
              validateTrigger="onBlur"
            >
              <Input size="large" placeholder="ใส่ชื่อบัญชี" />
            </Form.Item>
            <Form.Item
              label='เลขที่บัญชี'
              name='bankAccountNumber'
              validateTrigger="onBlur"
            >
              <Input size="large" placeholder="ใส่เลขที่บัญชี" />
            </Form.Item>
            <Form.Item>
              {renderFooter()}
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
}

const RefundRequestModal = (props: RefundRequestModalProps) => React.useMemo(() => <RefundRequestModalComponent {...props} />, [props.depositState.refundRequestingTransaction]);
export default RefundRequestModal;
