import {
  Space,
  Form,
  Modal,
  Radio,
  Checkbox,
  Select,
  Input,
  TimePicker,
  DatePicker,
} from "antd";
import * as React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button } from "src/components";
import { addDivTag, removeDivTag } from "src/utils/textOperation/textOperation";
import moment, { Moment } from "moment";
import { SystemStatusItem } from "src/hooks/useSystemStatus";

type EditForm = Partial<{
  content: string;
  customTime: [Moment, Moment];
  name: string;
  scheduleTime: [Moment, Moment];
  textPositions: string[];
  type: string;
}>;


type SystemStatusEditFormProps = {
  item: SystemStatusItem;
  submit: (form: EditForm, daily: string[]) => void;
};

export const SystemStatusEditForm = NiceModal.create((props: SystemStatusEditFormProps) => {
  const { item, submit } = props;
  const { visible, remove } = useModal();
  const [isValidForm, setIsValidForm] = React.useState(false);
  const [selectingType, setSelectingType] = React.useState(item.type);
  const [daily, setDaily] = React.useState<string[]>(item.daily);
  const [form] = Form.useForm<EditForm>();

  const renderDailyButton = (text: any, value: string) => {
    const isActiveButton = daily.some((i) => i === value);
    return (
      <Button
        type={isActiveButton ? "primary" : "default"}
        shape="circle"
        disabled={selectingType !== "schedule"}
        onClick={() => {
          setDaily((current) => {
            if (isActiveButton) return current.filter((d) => d !== value);
            return [...current, value];
          });
        }}
      >
        {text}
      </Button>
    );
  };
  const validateForm = (_form: EditForm, _daily: string[]) => {
    const { type, textPositions, name, customTime, scheduleTime, content } = _form;
    const checkedNotification = textPositions?.some((i) => i === "notification");
    const isValidType =
      type === "now" ||
      (type === "schedule" &&
        daily.length > 0 &&
        (scheduleTime ? scheduleTime.every((d) => d !== undefined) : false)) ||
      (type === "custom" && (customTime ? customTime.every((d) => d !== undefined) : false));
    setIsValidForm(
      isValidType &&
        textPositions !== undefined &&
        textPositions?.length > 0 &&
        name !== "" &&
        (checkedNotification ? (content ? content?.length > 0 : false) : true)
    );
  };
  React.useEffect(() => {
    validateForm(form.getFieldsValue(), daily);
  }, [daily]);
  const isUndercontruct = item.key === "UnderConstruction";
  return (
    <Modal
      visible={visible}
      title={"แก้ไขข้อความแจ้งเตือน"}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
          <Button
            key="cancel"
            size="large"
            onClick={() => {
              remove();
            }}
            style={{ width: "100px" }}
          >
            ยกเลิก
          </Button>
          <Button
            disabled={!isValidForm}
            key="submit"
            size="large"
            type="primary"
            style={{ width: "160px" }}
            onClick={() => {
              submit(
                {
                  ...form.getFieldsValue(),
                  content: addDivTag(form.getFieldsValue().content),
                },
                daily
              );
              remove();
            }}
          >
            บันทึก
          </Button>
        </div>
      }
      closable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          type: item.type,
          content: removeDivTag(item.text),
          scheduleTime: [
            item.startTime ? moment(item.startTime) : undefined,
            item.endTime ? moment(item.endTime) : undefined,
          ],
          customTime: [
            item.startDatetime ? moment(item.startDatetime) : undefined,
            item.endDatetime ? moment(item.endDatetime) : undefined,
          ],
          textPositions: isUndercontruct
            ? ["toast", "notification"]
            : [item.toast ? "toast" : null, item.notification ? "notification" : null].filter(
                (i) => i !== null
              ),
        }}
        onValuesChange={(value, _form) => {
          validateForm(_form, daily);
          setSelectingType(_form.type || "");
        }}
      >
        <Form.Item name="type" label="วันเวลาแสดงข้อความ">
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="now">ทันที</Radio>
              <Radio value="schedule">
                กำหนดวันและเวลา
                <div>
                  <Space>
                    <Button
                      shape="round"
                      type={daily.length === 7 ? "primary" : "default"}
                      disabled={selectingType !== "schedule"}
                      onClick={() => {
                        if (daily.length === 7) return setDaily([]);
                        setDaily(["0", "1", "2", "3", "4", "5", "6"]);
                      }}
                    >
                      ทุกวัน
                    </Button>
                    {renderDailyButton("อา", "6")}
                    {renderDailyButton("จ", "0")}
                    {renderDailyButton("อ", "1")}
                    {renderDailyButton("พ", "2")}
                    {renderDailyButton("พฤ", "3")}
                    {renderDailyButton("ศ", "4")}
                    {renderDailyButton("ส", "5")}
                  </Space>
                  <Form.Item name="scheduleTime" label="เวลาเริ่มต้น" style={{ marginBottom: 0 }}>
                    <TimePicker.RangePicker
                      placeholder={["เวลาเริ่มต้น", "เวลาสิ้นสุด"]}
                      disabled={selectingType !== "schedule"}
                      size="large"
                      style={{ width: "300px" }}
                    />
                  </Form.Item>
                </div>
              </Radio>
              <Radio value="custom">
                <div>กำหนดระยะเวลาแบบละเอียด</div>
                <div>
                  <Form.Item name="customTime" label="เวลาเริ่มต้น" style={{ marginBottom: 0 }}>
                    <DatePicker.RangePicker
                      disabled={selectingType !== "custom"}
                      size="large"
                      format="DD/MM/YY HH:mm"
                      showTime={{ format: "HH:mm" }}
                      style={{ width: "300px" }}
                    />
                  </Form.Item>
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="textPositions" label="ตำแหน่งที่แสดงข้อความ">
          <Checkbox.Group disabled={isUndercontruct}>
            <Space direction="vertical">
              <Checkbox value="toast">ป้ายข้อความหน้าเว็บ</Checkbox>
              <Checkbox value="notification">กล่องข้อความแจ้งเตือน</Checkbox>
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item name="name" label="สถานะระบบ">
          <Select placeholder="" defaultValue={item.name} disabled size="large">
            <Select.Option value={item.name}>{item.name}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="content" label="ข้อความ">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
