import { Empty as AntEmpty, EmptyProps as AntEmptyProps } from "antd";

export interface EmptyProps extends AntEmptyProps {}

function Empty({ style, ...props }: EmptyProps) {
  return (
    <AntEmpty
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
        ...style,
      }}
      {...props}
    />
  );
}

export default Empty;
