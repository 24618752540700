import {
  useCookies as externalUseCookies,
} from 'react-cookie';
import { addDays } from 'date-fns';

enum COOKIES_KEY {
  DEPOSIT_SELECTED_TAB = 'DEPOSIT_SELECTED_TAB',
  WITHDRAW_SELECTED_TAB = 'WITHDRAW_SELECTED_TAB',
  BANK_SELECTED_TAB = 'BANK_SELECTED_TAB',
};

export interface CookieSetOptions {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | 'none' | 'lax' | 'strict';
  encode?: (value: string) => string;
}
export type Cookies = { [key in COOKIES_KEY]?: any };
export type SetCookies = (name: string, value: any, options?: CookieSetOptions) => void;
export type RemoveCookies = (name: string, options?: CookieSetOptions) => void;

export const useCookies = (dependencies?: string[]): [Cookies, SetCookies, RemoveCookies] => {
  // NOTE : pre-execution

  const [cookies, setCookies, removeCookies] = externalUseCookies(dependencies);

  // NOTE : post-execution
  const setCookiesHandle = (name: string, value: any, options?: CookieSetOptions) => {
    const nextThreeDays = addDays(new Date(), 3);
    const defaultOption: CookieSetOptions = {
      expires: nextThreeDays,
      path: '/',
    };
    setCookies(name, value, options || defaultOption);
  };

  const removeCookiesHandle = (name: string, options?: CookieSetOptions) => {
    const defaultOption: CookieSetOptions = { path: '/' };
    removeCookies(name, options || defaultOption);
  };

  return [cookies, setCookiesHandle, removeCookiesHandle];
};

export {
  COOKIES_KEY,
};
