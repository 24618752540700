import React from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { authState, modalState } from "../../recoils";
import { PostRefund, RefundResponse } from "../../services/payment/payment";
import { formatDateRequest } from "../../utils/date";
import { Transaction } from "./type";
import { useBank, UseBankState } from "./useBank";

export type UseRefundState = UseBankState & {
  transferAt?: Date;
  setTransferAt: (_d?: Date) => void;
  doCallRefund: (_t: Transaction, next?: () => void) => void;
};

export const useRefund = (): UseRefundState => {
  const bankState = useBank();
  const [, setModal] = useRecoilState(modalState);
  const [transferAt, setTransferAt] = React.useState<Date>();
  const { token = '' } = useRecoilValue(authState);

  const doCallRefund = async (_transaction: Transaction, next?: () => void) => {
    try {
      const response = await PostRefund({
        token,
        bank_account_name: bankState.selectedWithdrawBankAccount?.accountName || '',
        bank_account_number: bankState.selectedWithdrawBankAccount?.accountNumber || '',
        bank_code: bankState.selectedWithdrawBankAccount?.bankCode || '',
        transferred_at: formatDateRequest(new Date(transferAt || '')),
        transaction_uid: _transaction.transactionId,
      });
      if (response.service_code === 'BOE-2000') {
        toast.success('คืนเงินสำเร็จ');
      }
      if (next) {
        next();
      }
    } catch (e: any) {
      const response: RefundResponse = e.response.data;
      const isAlreadyWithdraw = response.service_code === 'BOE-4002';
      if (isAlreadyWithdraw) {
        setModal({
          isModalVisible: true,
          type: 'error',
          title: 'ไม่สามารถทำรายการได้',
          content: 'เนื่องจากรายการนี้ถูกดำเนินการแล้ว',
          buttonType: 'confirm',
        });
      } else {
        toast.warning('คืนเงินไม่สำเร็จ');
      }
    }
  }

  return {
    ...bankState,
    transferAt,
    setTransferAt,
    doCallRefund,
  }
}
