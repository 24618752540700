import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Form, Modal } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { Button, MoneyInput, Select } from "src/components";
import { useCreateCoinWallet, usePromotions, useUpdateCoinWallet } from "src/hooks";
import { modalState } from "src/recoils";
import { CoinWallet, CoinWalletStatus, Promotion } from "src/types";
import { decimal, formatDatetime } from "src/utils";

export type CoinWalletModalFormProps = {
  wallet?: CoinWallet;
  customerCode?: string;
  onClose?: () => void;
  onSuccess?: () => void;
};

interface FormValues {
  promotionUid: string | null;
  initialBalance: number;
  totalTurnover: number;
  convertLimitAmount: number;
}

const CoinWalletModalForm = NiceModal.create(
  ({ wallet, customerCode, onClose, onSuccess }: CoinWalletModalFormProps) => {
    const [, setModal] = useRecoilState(modalState);
    const { visible, hide, remove } = useModal();

    const [isValid, setIsValid] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    const [form] = Form.useForm<FormValues>();

    const { data: promotions } = usePromotions<Promotion[]>({
      select: (data) => {
        return data.results.sort((a, b) =>
          a.name.localeCompare(b.name, "th-TH", { numeric: true })
        );
      },
    });
    const { mutate: create } = useCreateCoinWallet();
    const { mutate: update } = useUpdateCoinWallet();

    const handleClose = () => {
      hide();
      onClose?.();
    };

    const handleSuccess = () => {
      handleClose();
      onSuccess?.();
    };

    const handleSelectPromotion = (promotionUid: string) => {
      const promotion = promotions?.find((promotion) => promotion.uid === promotionUid);
      if (wallet && promotion) {
        const initialBalance =
          promotion.initialCoinAmount +
          (wallet?.cashWalletTransaction?.transactionAmount || wallet.initialBalance) * promotion.bonusRatio;
        if (
          promotion.uid !== wallet.promotionUid ||
          decimal(initialBalance) !== decimal(wallet.initialBalance)
        ) {
          setHasChanged(true);
        } else {
          setHasChanged(false);
        }

        form.setFieldsValue({ initialBalance: initialBalance });
      }
    };

    const handleValuesChange = (_: any, values: FormValues) => {
      if (wallet) {
        if (
          wallet.promotionUid !== values.promotionUid ||
          decimal(wallet.initialBalance) !== decimal(values.initialBalance) ||
          decimal(wallet.totalTurnover) !== decimal(values.totalTurnover || wallet.totalTurnover)
        ) {
          setHasChanged(true);
        } else {
          setHasChanged(false);
        }
      }
    };

    const handleFieldsChange = (_: any[], allFields: any[]) => {
      setIsValid(
        allFields
        .filter((field) => field.name[0] !== 'convertLimitAmount')
        .every((field) => field.value !== undefined && field.errors.length === 0)
      );
    };

    const handleFinish = (values: FormValues) => {
      setModal({
        isModalVisible: true,
        type: "warning",
        title: wallet ? "แก้ไขโบนัส" : "เพิ่มรายการโบนัสใหม่",
        content: wallet ? "ต้องการแก้ไขโบนัสใช่หรือไม่" : "ต้องการเพิ่มรายการโบนัสใหม่ใช่หรือไม่",
        buttonType: "question",
        onConfirm: () => {
          const promotion = promotions?.find((promotion) => promotion.uid === values.promotionUid);
          if (wallet) {
            const totalTurnover = promotion
              ? values.initialBalance * promotion.turnoverRatio
              : values.totalTurnover;
            update(
              {
                isForceWithdraw: promotion?.isForceWithdraw || false,
                walletId: wallet.uid,
                promotionUid: values.promotionUid,
                initialBalance: values.initialBalance,
                totalTurnover: totalTurnover,
                turnOverRatio: totalTurnover / values.initialBalance,
                name: promotion ? promotion.name : "กำหนดเอง",
                currentBalance: Math.max(
                  0,
                  values.initialBalance - (wallet.initialBalance - wallet.currentBalance)
                ),
                status:
                  wallet.status === CoinWalletStatus.USED ? CoinWalletStatus.ACTIVE : wallet.status,
                expireAt:
                  wallet.status === CoinWalletStatus.USED
                    ? formatDatetime(
                      dayjs().add(promotion ? promotion.durationInHours : 720, "hour")
                    )
                    : wallet.expireAt,
                isActive: true,
                convertLimitRatio: promotion?.convertLimitRatio || 0,
              },
              { onSuccess: handleSuccess }
            );
          } else if (customerCode) {
            const isCustomBonus = form.getFieldValue("promotionUid") === null;
            create(
              {
                customerCode: customerCode,
                name: promotion ? promotion.name : "กำหนดเอง",
                promotionUid: values.promotionUid,
                initialBalance: values.initialBalance,
                turnOverRatio: promotion
                  ? promotion.turnoverRatio
                  : values.totalTurnover / values.initialBalance,
                initial: false,
                isForceWithdraw: promotion?.isForceWithdraw || false,
                convertLimitAmount: isCustomBonus && form.getFieldsValue().convertLimitAmount ? form.getFieldsValue().convertLimitAmount : promotion?.convertLimitAmount || 0,
                convertLimitRatio: promotion?.convertLimitRatio || 0,
                expireAt: formatDatetime(
                  dayjs().add(promotion ? promotion.durationInHours : 720, "hour")
                ),
              },
              { onSuccess: handleSuccess }
            );
          }
        },
      });
    };

    return (
      <Modal
        focusTriggerAfterClose={false}
        visible={visible}
        afterClose={remove}
        title={wallet ? "แก้ไขโบนัส" : "เพิ่มรายการโบนัสใหม่"}
        closable={false}
        width={600}
        centered
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
            <Button key="cancel" size="large" onClick={handleClose} style={{ width: "100px" }}>
              ยกเลิก
            </Button>
            <Button
              key="submit"
              size="large"
              type="primary"
              style={{ width: "160px" }}
              onClick={() => form.submit()}
              disabled={wallet ? !hasChanged || !isValid : !isValid}
            >
              {wallet ? "บันทึก" : "เพิ่มโบนัส"}
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          onFinish={handleFinish}
          initialValues={{
            promotionUid: wallet ? wallet.promotionUid : null,
            initialBalance: wallet ? decimal(wallet.initialBalance) : undefined,
            totalTurnover: wallet ? decimal(wallet.totalTurnover) : undefined,
            convertLimitAmount: wallet && wallet.convertLimitAmount ? decimal(wallet.convertLimitAmount) : undefined,
          }}
          onValuesChange={handleValuesChange}
          onFieldsChange={handleFieldsChange}
        >
          <Form.Item name="promotionUid" label="ประเภทโปรโมชัน">
            <Select width="100%" onSelect={handleSelectPromotion}>
              <Select.Option key={"custom"} value={null}>
                {"กำหนดเอง"}
              </Select.Option>
              {promotions?.map(({ uid, name }) => (
                <Select.Option key={uid} value={uid}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="initialBalance" label="จำนวนโบนัส (THB)">
            <MoneyInput size="large" placeholder="0.00" />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.promotionUid !== curValues.promotionUid
            }
          >
            {() =>
              form.getFieldValue("promotionUid") === null && (
                <Form.Item name="totalTurnover" label="เทิร์นโอเวอร์ (THB)">
                  <MoneyInput size="large" placeholder="0.00" />
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.promotionUid !== curValues.promotionUid
            }
          >
            {() =>
              form.getFieldValue("promotionUid") === null && (
                <Form.Item name="convertLimitAmount" label="โบนัสสูงสุด (THB)">
                  <MoneyInput size="large" placeholder="0.00" disabled={wallet !== undefined} />
                </Form.Item>
              )
            }
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

export default CoinWalletModalForm;
