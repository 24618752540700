import { useQuery, UseQueryOptions } from "react-query";

import { BaseResponse2 } from "src/services/service.type";
import { Pagination, TrueWalletReportItem } from "src/types";
import { api } from "src/utils";

export interface UseTrueWalletReportFilters {
  trueWalletAccount?: string;
  startTime?: Date;
  endTime?: Date;
}

type TrueWalletResponseData = Omit<Pagination<TrueWalletReportItem>, "page" | "pageType" | "pageSize">;

export function useTrueWalletReport<T = TrueWalletResponseData>(
  filters?: UseTrueWalletReportFilters,
  options?: UseQueryOptions<TrueWalletResponseData, unknown, T, any[]>
) {
  return useQuery(
    ["trueWalletReport", filters],
    async (): Promise<TrueWalletResponseData> => {
      const response = await api.get<BaseResponse2<TrueWalletResponseData>>("report/true-wallet", {
        params: filters,
      });
      return response.data.data
    },
    options,
  );
}
