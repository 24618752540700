import { useQuery, UseQueryOptions } from "react-query";
import { Pagination, Promotion } from "src/types";
import { api } from "src/utils";

export function usePromotions<T = Pagination<Promotion>>(
  options?: UseQueryOptions<Pagination<Promotion>, unknown, T, any[]>
) {
  return useQuery(
    ["promotions"],
    async () => {
      const response = await api.get("promotions");
      return response.data.data;
    },
    options
  );
}
