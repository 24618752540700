import { toast } from "react-toastify";
import { Card, Col, Row } from 'antd';
import * as React from 'react';
import { autoLineBreakerWithBr } from '../../utils/textOperation/textOperation';
import Button from '../Button/Button';
import './CopyableMessageBox.less';

export type ICopyableMessageBoxProps = {
  topic: string;
  message: string;
  render?: (_message: string) => JSX.Element;
  onCopy?: (_message: string) => void;
}

const CopyableMessageBox: React.FunctionComponent<ICopyableMessageBoxProps> = (props) => {
  const onCopy = () => {
    navigator.clipboard.writeText(props.message);
    toast.success("คัดลอกข้อความสำเร็จ");
    if (props.onCopy) {
      props.onCopy(props.message);
    }
  }

  return (
    <Card className="copyable-message-box">
      <Row>
        <Col span={20}>
          <div className="copyable-message-box-topic">
            {props.topic}
          </div>
        </Col>
        <Col span={4}>
          <Button shape="round" onClick={() => { onCopy() }}>คัดลอก</Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="copyable-message-box-message">
            {props.render ? props.render(props.message) : autoLineBreakerWithBr(props.message)}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default CopyableMessageBox;
