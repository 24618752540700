import React, { useEffect, useState } from 'react';
import { useJwt } from "react-jwt";
import { useRecoilValue } from "recoil";
import { Modal, Form, Input, Select } from 'antd';
import { authState } from "../../../recoils";
import {
  verifyEmail,
  verifyPersonalNo,
  registerAdmin,
  VERIFY_EMAIL_STATUS,
  VERIFY_PERSONAL_NUMBER_STATUS
} from '../../../services/registerAdmin';
import { getEnum } from '../../../services/utils';
import './AddAdminUserModal.scss';
import Button from 'src/components/Button/Button';

type DecodedToken = {
  created_at: string;
  scope_list: string[];
  user_uid: string;
}

export type AddAdminUserModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (value: boolean) => void;
}

const AddAdminUserModal = ({
  open,
  onClose,
  onConfirm,
}: AddAdminUserModalProps) => {
  const [positionList, setPositionList] = useState<any[]>()
  const [scopeList, setScopeList] = useState<any[]>()
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isPersonalNoValid, setIsPersonalNumberValid] = useState(false)
  const [isPositionValid, setIsPositionValid] = useState(false)
  const [form] = Form.useForm();
  const { token = '' } = useRecoilValue(authState);
  const { decodedToken } = useJwt(token);

  const unmountAllState = () => {
    setIsEmailValid(false)
    setIsPersonalNumberValid(false)
    setIsPositionValid(false)
  }

  const onSubmit = async (values: any) => {
    let addAdminUserStatus = false
    const requestBody = {
      email: values.email,
      personal_number: values.personal_number,
      role_uuid: values.role
    }
    const isRegisterAdminSuccess = await registerAdmin(requestBody, token)
    unmountAllState()
    if (isRegisterAdminSuccess !== null) {
      addAdminUserStatus = true
	  form.resetFields()
    }
    onConfirm(addAdminUserStatus)
  }

  const header = (
    <div>เพิ่มพนักงาน</div>
  )

  const footer = (
    <div className="footer">
      <Button size="large" style={{ padding: '0 1.5rem' }} onClick={() => { unmountAllState(); onClose() }} htmlType="reset">ยกเลิก</Button>
      <Button
        size="large"
        type="primary"
        htmlType="submit"
        style={{ width: 160 }}
        disabled={!isEmailValid || !isPersonalNoValid || !isPositionValid}
      >
        เพิ่มพนักงาน
      </Button>
    </div>
  )

  const checkEmailAvailable = (email: string) => {
    const requestBody = {
      email
    }
    const isEmailVerified = verifyEmail(requestBody, token)
    return isEmailVerified
  }

  const checkPersonalNumberAvailable = (personalNumber: string) => {
    const requestBody = {
      personal_number: personalNumber
    }
    const isPersonalNumberVerified = verifyPersonalNo(requestBody, token)
    return isPersonalNumberVerified
  }

  useEffect(() => {
    if (decodedToken) {
      const tokenData = decodedToken as DecodedToken;
      const scopeList = tokenData.scope_list;

      setScopeList(scopeList)
    }
  }, [decodedToken])

  useEffect(() => {
    getEnum(token).then((data: any) => {
      const optionItems = data.role.map((position: any) => {
        const item = {
          label: position.name,
          value: position.uid,
        }
        return item
      });

      if (optionItems && scopeList) {
        const scopeListData = [...scopeList];
        const filteredPositionScope = scopeListData.filter(data => data.includes('REGISTER-USER.'))
        const filteredPositionOptions = optionItems.filter(
          ({ label }: { label: string }) => filteredPositionScope.includes(`REGISTER-USER.${label}`)
        );
        setPositionList(filteredPositionOptions)
      }
    })
  }, [token, scopeList])


  return (
    <Modal
      title={header}
      visible={open}
      footer={false}
      closable={false}
      wrapClassName="add-admin-user-modal"
      centered
      destroyOnClose={true}
    >
      <div className="content-wrapper">
        <Form
          className="form-register"
          onAbort={onClose}
          onFinish={onSubmit}
          autoComplete="off"
          layout="vertical"
          form={form}
          requiredMark={false}
        >
          <div className="form-wrapper">
            <div className="content-header">
              <span>กรอกข้อมูลของพนักงานใหม่ให้ถูกต้องและครบถ้วน</span>
            </div>
            <Form.Item
              label='อีเมล'
              name='email'
              rules={[
                {
                  async validator(_, value) {
                    const emailFormat = String(value)
                      .toLowerCase()
                      .match(
                        // eslint-disable-next-line no-useless-escape
                        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$$/
                      )
                    if (!value) {
                      setIsEmailValid(false)
                      return Promise.reject('กรุณาใส่อีเมล')
                    }
                    if (emailFormat !== null) {
                      const isEmailVerified = await checkEmailAvailable(value)
                      if (isEmailVerified === VERIFY_EMAIL_STATUS.SUCCESS) {
                        setIsEmailValid(true)
                        return Promise.resolve()
                      } else if (isEmailVerified === VERIFY_EMAIL_STATUS.EXIST) {
                        setIsEmailValid(false)
                        return Promise.reject('อีเมลนี้ถูกใช้แล้ว')
                      }
                      setIsEmailValid(false)
                      return Promise.reject('อีเมลนี้ไม่สามารถใช้งานได้')
                    }
                    setIsEmailValid(false)
                    return Promise.reject('รูปแบบไม่ถูกต้อง')
                  }
                },
              ]}
              validateTrigger="onBlur"
            >
              <Input placeholder="ใส่อีเมล" />
            </Form.Item>
            <Form.Item
              label='รหัสพนักงาน'
              name='personal_number'
              rules={[
                {
                  async validator(_, value) {
                    const personalNoFormat = String(value)
                      .toLowerCase()
                      .match(
                        /^\d{4}$/
                      )
                    if (!value) {
                      setIsPersonalNumberValid(false)
                      return Promise.reject('กรุณาใส่รหัสพนักงาน')
                    }
                    if (personalNoFormat) {
                      const isPersonalNumberAvailable = await checkPersonalNumberAvailable(value)
                      if (isPersonalNumberAvailable === VERIFY_PERSONAL_NUMBER_STATUS.SUCCESS) {
                        setIsPersonalNumberValid(true)
                        return Promise.resolve()
                      } else if (isPersonalNumberAvailable === VERIFY_PERSONAL_NUMBER_STATUS.EXIST) {
                        setIsPersonalNumberValid(false)
                        return Promise.reject("รหัสนี้ถูกใช้แล้ว")
                      }
                      setIsPersonalNumberValid(false)
                      return Promise.reject("รหัสพนักงานนี้ไม่สามารถใช้งานได้")
                    }
                    setIsPersonalNumberValid(false)
                    return Promise.reject("ใส่เป็นตัวเลข 4 ตัว")
                  }
                }
              ]}
              validateTrigger="onBlur"
            >
              <Input placeholder="ใส่รหัสพนักงาน" />
            </Form.Item>
            <Form.Item
              label='ตำแหน่ง'
              name={['role']}
              rules={[
                {
                  validator(_, value) {
                    if (!value) {
                      setIsPositionValid(false)
                      return Promise.reject('กรุณาเลือกตำแหน่ง')
                    }
                    return Promise.resolve()
                  }
                }
              ]}
              validateTrigger="onBlur"
            >
              <Select
                className="role-selector"
                placeholder="เลือกตำแหน่ง"
                onSelect={(value: any) => { setIsPositionValid(true); form.validateFields(['role']); }}
                options={positionList}
              >
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            {footer}
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddAdminUserModal;