import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal, Form, Input, Row, Col, Typography } from "antd";
import { GetGamesHook, CreateWeeklyQuest, WeeklyQuestCreateForm } from "src/hooks";
import * as React from 'react';
import { Select } from 'src/components';
import './QuestManagement.less'
import { QuestLevel, QuestType } from "src/types";

export type DailyQuestCreateProps = {
  onClose?: () => void;
  onSuccess?: () => void;
};

export const WeeklyQuestCreate = NiceModal.create(({...props}: DailyQuestCreateProps) => {
  const { visible, hide, remove } = useModal();

  const handleClose = () => {
    hide();
    props.onClose?.();
  };

  const [form] = Form.useForm<WeeklyQuestCreateForm>();
  const { mutate: create } = CreateWeeklyQuest();
  const handleFinish = (values: WeeklyQuestCreateForm) => {
    create(
      {
        gameRefType: values.gameRefType.toUpperCase(),
        gameId: values.gameId,
        value: values.value,
        ticket: values.ticket,
      },
      { onSuccess: handleClose }
    );
  };

  const { data: questGames, refetch: fetchQuestGames } = GetGamesHook()
  const [gameRefType, setGameRefType] = React.useState<any[]>([])
  const [currentGameRefType, setCurrentGameRefType] = React.useState<string>('')
  const [games, setGames] = React.useState<any[]>([])
  const [currentGames, setCurrentGames] = React.useState<any[]>([])

  const fetchGames = async () => {
    const fetchGames = await fetchQuestGames()
    const keys = fetchGames ? Object.keys(fetchGames.data) : [];
    setGameRefType(keys)
    setGames(fetchGames.data)
  } 
  
  const mapGames = () => {
    form.setFieldsValue({ gameId: undefined });
    Object.entries(games).find((item, i) => {
        if (item[0] === currentGameRefType) {
          setCurrentGames(item[1])
        }
    })
  }

  React.useEffect(() => {
    mapGames()
  }, [currentGameRefType]);

  React.useEffect(() => {
    fetchGames()
  }, []);

  return (
    <Modal
        centered
        className="ticket-import-modal-form"
        width={600}
        visible={visible}
        afterClose={remove}
        focusTriggerAfterClose={false}
        closable={false}
        title="สร้าง Weekly Quest"
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
            <Button key="cancel" size="large" onClick={handleClose} style={{ width: "100px" }}>
              ยกเลิก
            </Button>
            <Button
              key="submit"
              type="primary"
              size="large" 
              onClick={() => form.submit()}
              style={{ width: "160px" }}
            >
              ยืนยัน
            </Button>
          </div>
        }
      >
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={true}
            preserve={false}
            style={{ overflow: "auto" }}
            onFinish={handleFinish}
          >
            <div>
              <Form.Item label="ค่ายเกมส์" name="gameRefType" required
                rules={[{ required: true, message: 'โปรดเลือกค่ายเกมส์' }]}>
                <Select {...props} onChange={(e: any) => {setCurrentGameRefType(e);}}>
                  {gameRefType.map(( key: string ) => (
                    <Select.Option key={key} value={key}>{key.toUpperCase()}</Select.Option>
                  ))} 
                </Select>
              </Form.Item>
            
              <Form.Item label="เกมส์" name="gameId" required
                rules={[{ required: true, message: 'โปรดเลือกเกมส์' }]}>
                <Select {...props}>
                  {currentGames.map(( key: any ) => (
                    <Select.Option key={key.gameId} value={key.gameId}>{key.gameName}</Select.Option>
                  ))} 
                </Select>
              </Form.Item>
               
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item label="ยอด earn" name="value" rules={[{ required: true, message: 'โปรดระบุยอด earn' }]}>
                    <Input></Input>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
      </Modal>
  );
});

export default WeeklyQuestCreate;
