import React from "react";
import Select, { OptionsOrGroups, GroupBase } from "react-select";
import { BankRow } from "src/types";
import Account from "./Account";
import colors from '../../theme/colors.json';
import './BankSelect.less';

export type BankSelectItem = BankRow & {
};

export type IBankSelectProps = {
  disabled?: boolean;
  bankList: OptionsOrGroups<BankSelectItem, GroupBase<BankSelectItem>>;
  onChange?: (bankAccount: BankRow) => void;
};

const formatGroupLabel = (data: GroupBase<BankSelectItem>) => {
  const bank = data.options[0];
  const isAutoDepositBank = bank.type === 'DEPOSIT';
  const isAutoWithdrawBank = bank.type === 'WITHDRAW' && bank.isAuto;
  const isManualWithdrawBank = bank.type === 'WITHDRAW' && !bank.isAuto;
  let backgroundColor = colors["@cloud-2"];
  if (isAutoDepositBank) backgroundColor = colors["@green-2"];
  if (isAutoWithdrawBank) backgroundColor = colors["purple-3"];
  if (isManualWithdrawBank) backgroundColor = colors["@red-3"];
  return (
    <div style={{ background: backgroundColor, padding: 12, color: colors["@cloud-10"] }}>
      <span>{data.label}</span>&nbsp;
      <span>({data.options.length})</span>
    </div>
  )
};

export const BankSelect: React.FC<IBankSelectProps> = (props) => {

  return (
    <Select
      {...props}
      isClearable
      isDisabled={props.disabled}
      menuPlacement="auto"
      placeholder="เลือกบัญชีธนาคาร"
      options={props.bankList}
      onChange={(_bankAccount) => { if (props.onChange) props.onChange(_bankAccount as BankRow) }}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={(_bankAccount) => <Account className="bank-select-option" {..._bankAccount} />}
      theme={(_theme) => {
        return {
          ..._theme,
          colors: {
            ..._theme.colors,
            primary: colors["purple-2"],
            primary75: colors["purple-2"],
            primary50: colors["purple-2"],
            primary25: colors["purple-1"],
          }
        }
      }}
      styles={{
        groupHeading: (provided, state) => {
          return {
            ...provided,
            padding: 0,
            margin: 0,
            fontSize: 14,
            fontWeight: 'bold',
          };
        },
        menu: (provided, state) => {
          return {
            ...provided,
            zIndex: 1000,
          }
        },
        control: (provided, state) => {
          return {
            ...provided,
            height: 40,
          };
        },
        option: (provided, state) => {
          return {
            ...provided,
            backgroundColor: state.isFocused ? colors["purple-2"] : colors["@gray-1"],
          }
        },
      }}
    />
  );
}
