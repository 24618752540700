import { Form, FormInstance, Input, Spin } from 'antd';
import * as React from 'react';
import { AFFILIATE_TYPE, mapToAffiliateTypeText } from 'src/hooks/affiliate';
import MoneyInput, { amountValidator } from '../MoneyInput';
import Select from '../Select';

interface IAddAffiliateFormProps {
  form?: FormInstance<AffiliateForm>
  isLoading?: boolean;
}

export type AffiliateForm = {
  affiliateType: AFFILIATE_TYPE;
  amount: string;
  turnOver: string;
  remark: string;
  remarkType: string;
  userCode: string;
}

const AddAffiliateForm: React.FunctionComponent<IAddAffiliateFormProps> = (props) => {
  const [form] = Form.useForm<AffiliateForm>(props.form);
  const [shouldShowRemark, setShouldShowRemark] = React.useState<boolean>();
  return (
    <div>
      <Spin spinning={props.isLoading}>
        <Form form={form} layout="vertical">
          <Form.Item label="รหัสลูกค้า" name="userCode" rules={[{ required: true }]}>
            <Input size="large" placeholder="PSxxxxxxxx" />
          </Form.Item>
          <Form.Item
            label="ที่มา"
            name="affiliateType"
            rules={[{ required: true }]}
          >
            <Select
              width='100%'
              size='large'
              placeholder="เลือกที่มา"
              options={[
                { label: mapToAffiliateTypeText(AFFILIATE_TYPE.KYC), value: AFFILIATE_TYPE.KYC },
                { label: mapToAffiliateTypeText(AFFILIATE_TYPE.FIRST_DEPOSIT), value: AFFILIATE_TYPE.FIRST_DEPOSIT },
                { label: mapToAffiliateTypeText(AFFILIATE_TYPE.LOSSING), value: AFFILIATE_TYPE.LOSSING },
              ]}
            />
          </Form.Item>
          <Form.Item label="จำนวนรายได้ (THB)" name="amount" rules={[{ required: true }, { validator: amountValidator }]}>
            <MoneyInput size="large" placeholder="0.00" />
          </Form.Item>
          <Form.Item label="เทิร์นโอเวอร์ (THB)" name="turnOver" rules={[{ validator: amountValidator }]}>
            <MoneyInput size="large" placeholder="0.00" />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 16 }}
            label="หมายเหตุ"
            name="remarkType"
            rules={[{ required: true }]}
          >
            <Select
              width='100%'
              size='large'
              placeholder="เลือกหมายเหตุ"
              onChange={(value) => { setShouldShowRemark(value === 'อื่นๆ'); }}
              options={[
                { label: 'ระบบขัดข้อง', value: 'ระบบขัดข้อง' },
                { label: 'ระบบคำนวณผิดพลาด', value: 'ระบบคำนวณผิดพลาด' },
                { label: 'ลูกค้าได้รับรายได้ผิดประเภท', value: 'ลูกค้าได้รับรายได้ผิดประเภท' },
                { label: 'อื่นๆ', value: 'อื่นๆ' },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="remark"
          >
            {shouldShowRemark && (
              <Input size="large" placeholder="โปรดระบุ" />
            )}
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default AddAffiliateForm;
