import { Form, Input, Select as AntdSelect, Spin } from 'antd';
import * as React from 'react';
import BankSelect from '../BankSelect';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Modal, { ModalProps } from '../Modal/Modal';
import colors from 'src/theme/colors.json';
import './AddBankForm.scss';
import { BankCode, BANK_TYPE } from 'src/types';
import { BANK_TAB_TYPE, UseBankState } from 'src/hooks/useTransaction/useBank';
import { useBankCreate } from 'src/hooks/useTransaction/useBankCreate';
import { ValidateInput } from 'src/hooks/type';
import { TEN_DIGITS_BANK } from 'src/mapper/mapToBank';

interface IAddBankFormProps {
  bankState: UseBankState;
}

export type AddBankForm = {
  type: BANK_TYPE;
  bank: string;
  accountName: string;
  accountNumber: string;
  promptpay: string;
};

export type AddBankResult = {
  accountName: ValidateInput;
  accountNumber: ValidateInput;
  promptpay: ValidateInput;
}

const AddBankFormComponent: React.FunctionComponent<IAddBankFormProps> = (props) => {
  const { bankState } = props;
  const {
    createBank,
    createBankResult,
    setCreateBankResult,
    isCreating,
  } = useBankCreate()
  const [form] = Form.useForm<AddBankForm>()
  const types = [
    { label: 'บัญชีรับเงิน', value: BANK_TYPE.DEPOSIT }, { label: 'บัญชีโอนเงิน', value: BANK_TYPE.WITHDRAW }
  ]
  const [shouldShowPromptpayInput, setShouldShowPromptpayInput] = React.useState<boolean>(true)
  const [maxDigitsBank, setMaxDigitsBank] = React.useState<number>(10);
  const withdrawTypes = [BANK_TAB_TYPE.WITHDRAW_ALL, BANK_TAB_TYPE.WITHDRAW_BANK, BANK_TAB_TYPE.WITHDRAW_TRUE_MONEY]
  const shouldSelectWithdraw = withdrawTypes.some((w) => w === bankState.bankSelectedTab)
  const [isValidForm, setIsValidForm] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!bankState.isOpenAddingBank) {
      setCreateBankResult({})
    }
    const defultBankType = shouldSelectWithdraw ? BANK_TYPE.WITHDRAW : BANK_TYPE.DEPOSIT; 
    form.setFieldsValue({
      type: defultBankType,
    })
    setShouldShowPromptpayInput(defultBankType === BANK_TYPE.DEPOSIT && form.getFieldsValue().bank !== BankCode.TrueMoney);
  }, [bankState.isOpenAddingBank]);

  const onSubmit = () => {
    if (form) {
      createBank(form.getFieldsValue(), () => {
        bankState.setIsOpenAddingBank(false)
        bankState.getDepositBankList()
        bankState.getWithdrawBankList()
        form.resetFields()
      })
    }
  }

  return (
    <Modal
      visible={bankState.isOpenAddingBank}
      centered
      className="AddBankForm"
      title="เพิ่มบัญชีธนาคาร"
      footer={(
        <>
          <Button size='large' style={{ width: 100 }} onClick={() => bankState.setIsOpenAddingBank(false)} >
            ยกเลิก
          </Button>
          <Button
            disabled={!isValidForm}
            htmlType='submit' size='large' type='primary' style={{ width: 180 }} onClick={onSubmit}>
            เพิ่มบัญชี
          </Button>
        </>
      )}
    >
      <div style={{ paddingLeft: 60, paddingRight: 60 }}>
        <div style={{ textAlign: 'center' }}>
          กรอกข้อมูลบัญชีให้ถูกต้อง
        </div>
        <Spin spinning={isCreating}>
          <Form
            form={form}
            name="addBankForm"
            layout="vertical"
            autoComplete="off"
            onValuesChange={(value, form) => {
              setShouldShowPromptpayInput(form.bank !== BankCode.TrueMoney && form.type === BANK_TYPE.DEPOSIT)
            }}
            onFieldsChange={(_from, _fields) => {
              const allRequireField = _fields
                .filter(_fields => {
                  return _fields.name.toString() !== 'promptpay'
                })
                .every(_field => _field.value !== undefined);
              const hasErrorField = form.getFieldsError().some(_f => _f.errors.length > 0);
              setIsValidForm(allRequireField && !hasErrorField);
            }}
          >
            <Form.Item
              label="ประเภทบัญชี"
              name="type"
              rules={[{ required: true }]}
            >
              <AntdSelect
                size='large'
                suffixIcon={(<Icon name='outline-arrow-down-1' color={colors['@cloud-6']} />)}
                options={types}
              />
            </Form.Item>
            <Form.Item
              label="ธนาคาร"
              name="bank"
              rules={[{ required: true }]}
            >
              <BankSelect
                width='100%'
                placeholder="เลือกธนาคาร"
                suffixIcon={(<Icon name='outline-arrow-down-1' color={colors['@cloud-6']} />)}
                onChange={(value) => {
                  const isTenDigitsBank = TEN_DIGITS_BANK.some(b => b === value)
                  const changingMaxDigitsBank = isTenDigitsBank ? 10 : 12
                  if (changingMaxDigitsBank !== maxDigitsBank) {
                    setMaxDigitsBank(changingMaxDigitsBank)
                    form.setFields([{ name: 'accountNumber', value: '' }])
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="ชื่อบัญชี"
              name="accountName"
              validateStatus={createBankResult?.accountName?.validateStatus}
              help={createBankResult?.accountName?.help}
              rules={[{ required: true }]}
            >
              <Input placeholder='ใส่ชื่อบัญชี' size='large' />
            </Form.Item>
            <Form.Item
              label="เลขบัญชี"
              name="accountNumber"
              validateStatus={createBankResult?.accountNumber?.validateStatus}
              help={createBankResult?.accountNumber?.help}
              rules={[
                { required: true },
                { pattern: new RegExp(`(^[0-9]{${maxDigitsBank}})|^\S*$`), message: 'รูปแบบไม่ถูกต้อง' },
              ]}
            >
              <Input placeholder='ใส่เลขที่บัญชี' size='large' maxLength={maxDigitsBank} />
            </Form.Item>
            <Form.Item
              hidden={!shouldShowPromptpayInput}
              label="เบอร์พร้อมเพย์"
              name="promptpay"
              validateStatus={createBankResult?.promptpay?.validateStatus}
              help={createBankResult?.promptpay?.help}
              rules={[
                { pattern: new RegExp(/(0){1}(\d{9})/), message: 'รูปแบบไม่ถูกต้อง' },
              ]}
            >
              <Input placeholder='ใส่เบอร์พร้อมเพย์ (ถ้ามี)' size='large' maxLength={10} />
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
};

export default AddBankFormComponent;
