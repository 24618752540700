import camelcaseKeys from "camelcase-keys";
import { BankAccount, BankCode, DepositTransaction, WithdrawTransaction } from "src/types";
import { datetime } from "src/utils/datetime";
import { numberWithoutCommas } from "src/utils/number/number";
import { AdjustType, DEPOSIT_STATUS, Transaction, TransactionItem, WITHDRAW_STATUS } from "../hooks/useTransaction/type"
import { mapToBankName } from "./mapToBank"
import { formatDate, formatTime } from "../utils/date";

export const TRANSACTION_STATUS = {
  [DEPOSIT_STATUS.PENDING]: 'รอเติมเงิน',
  [DEPOSIT_STATUS.WAITING_TRANSFER]: 'รอคืนเงิน',
  [DEPOSIT_STATUS.TRANSFER_BACK]: 'คืนเงินสำเร็จ',
  [DEPOSIT_STATUS.REJECTED]: 'รอแจ้งคืนเงิน',
  [DEPOSIT_STATUS.COMPLETED]: 'เติมเงินสำเร็จ',
  [DEPOSIT_STATUS.CANCELLED]: 'ยกเลิกรายการเติมเงิน',
  [WITHDRAW_STATUS.PENDING]: 'รอถอนเงิน',
  [WITHDRAW_STATUS.REJECTED]: 'ปฏิเสธถอนเงิน',
  [WITHDRAW_STATUS.COMPLETED]: 'ถอนเงินสำเร็จ',
};

export const TRANSACTION_COLOR: { [key: string]: string } = {
  [DEPOSIT_STATUS.PENDING]: 'warning',
  [DEPOSIT_STATUS.WAITING_TRANSFER]: 'warning',
  [DEPOSIT_STATUS.TRANSFER_BACK]: 'success',
  [DEPOSIT_STATUS.REJECTED]: 'warning',
  [DEPOSIT_STATUS.COMPLETED]: 'success',
  [DEPOSIT_STATUS.CANCELLED]: 'danger',
  [WITHDRAW_STATUS.PENDING]: 'warning',
  [WITHDRAW_STATUS.REJECTED]: 'danger',
  [WITHDRAW_STATUS.COMPLETED]: 'success',
};
export const TRANSACTION_TAG: { [key: string]: string } = {
  [DEPOSIT_STATUS.PENDING]: 'warning',
  [DEPOSIT_STATUS.WAITING_TRANSFER]: 'warning',
  [DEPOSIT_STATUS.TRANSFER_BACK]: 'success',
  [DEPOSIT_STATUS.REJECTED]: 'warning',
  [DEPOSIT_STATUS.COMPLETED]: 'success',
  [DEPOSIT_STATUS.CANCELLED]: 'red',
  [WITHDRAW_STATUS.PENDING]: 'warning',
  [WITHDRAW_STATUS.REJECTED]: 'red',
  [WITHDRAW_STATUS.COMPLETED]: 'success',
};

export const TO_BE_DEPOSIT_STATUS: { [key: string]: string } = {
  [DEPOSIT_STATUS.PENDING]: 'PENDING',
  [DEPOSIT_STATUS.REJECTED]: 'REJECTED',
  [DEPOSIT_STATUS.COMPLETED]: 'COMPLETED',
  [DEPOSIT_STATUS.CANCELLED]: 'CANCELLED',
};

export const TO_BE_REFUND_STATUS: { [key: string]: string } = {
  [DEPOSIT_STATUS.WAITING_TRANSFER]: 'WAITING_TRANSFER',
  [DEPOSIT_STATUS.TRANSFER_BACK]: 'TRANSFER_BACK',
};

export const TO_BE_WITHDRAW_STATUS: { [key: string]: string } = {
  [WITHDRAW_STATUS.PENDING]: 'PENDING',
  [WITHDRAW_STATUS.REJECTED]: 'REJECTED',
  [WITHDRAW_STATUS.COMPLETED]: 'COMPLETED',
};

export const isDepositTransaction = (_transaction: Transaction): boolean => {
  return [...Object.values(DEPOSIT_STATUS), 'EDIT_DEPOSIT', 'DELETE_MANUAL_DEPOSIT'].some(key => key === _transaction.status);
};

export const isWithdrawTransaction = (_transaction: Transaction): boolean => {
  return Object.values(WITHDRAW_STATUS).some(key => key === _transaction.status);
};

export const mapToDepositStatus = (_transactionItem: TransactionItem): DEPOSIT_STATUS => {
  const {
    status,
  } = _transactionItem as DepositTransaction | WithdrawTransaction;
  switch (status) {
    case 'PENDING':
      return DEPOSIT_STATUS.PENDING;
    case 'COMPLETED':
      return DEPOSIT_STATUS.COMPLETED;
    case 'WAITING_TRANSFER':
      return DEPOSIT_STATUS.WAITING_TRANSFER;
    case 'TRANSFER_BACK':
      return DEPOSIT_STATUS.TRANSFER_BACK;
    case 'REJECTED':
      return DEPOSIT_STATUS.REJECTED;
    case 'CANCELLED':
      return DEPOSIT_STATUS.CANCELLED;
    default:
      return DEPOSIT_STATUS.NONE;
  }
};

export const mapToWithdrawStatus = (_transactionItem: TransactionItem): WITHDRAW_STATUS | DEPOSIT_STATUS => {
  const {
    status,
  } = _transactionItem as DepositTransaction | WithdrawTransaction;
  switch (status) {
    case 'PENDING':
      if (_transactionItem.note?.trim()?.startsWith("ธนาคารขัดข้อง")) {
        return WITHDRAW_STATUS.UNCONFIRM;
      }
      return WITHDRAW_STATUS.PENDING;
    case 'COMPLETED':
      return WITHDRAW_STATUS.COMPLETED;
    case 'REJECTED':
      return WITHDRAW_STATUS.REJECTED;
    case 'TRANSFER_BACK':
      return DEPOSIT_STATUS.TRANSFER_BACK;
    default:
      return WITHDRAW_STATUS.NONE;
  }
};

const doingTransactionFormat = (_transactionItem: TransactionItem): string => {
  return `${_transactionItem.editingBy?.email.split('@')[0]} กำลังทำรายการ`;
}

export const mapToStatusMessage = (status: WITHDRAW_STATUS | DEPOSIT_STATUS, _transactionItem: TransactionItem): string => {
  if (_transactionItem.editingBy) {
    return doingTransactionFormat(_transactionItem);
  }
  if (status === DEPOSIT_STATUS.PENDING) {
    return 'รอเติมเงิน';
  }
  if (status === DEPOSIT_STATUS.WAITING_TRANSFER) {
    return 'รอคืนเงิน';
  }
  if (status === DEPOSIT_STATUS.TRANSFER_BACK) {
    return 'คืนเงินสำเร็จ';
  }
  if (status === DEPOSIT_STATUS.REJECTED) {
    return 'รอแจ้งคืนเงิน';
  }
  if (status === DEPOSIT_STATUS.COMPLETED) {
    return 'เติมเงินสำเร็จ';
  }
  if (status === WITHDRAW_STATUS.PENDING || status === WITHDRAW_STATUS.UNCONFIRM) {
    return 'รอถอนเงิน';
  }
  if (status === WITHDRAW_STATUS.REJECTED) {
    return 'ปฏิเสธถอนเงิน';
  }
  if (status === WITHDRAW_STATUS.COMPLETED) {
    return 'ถอนเงินสำเร็จ';
  }
  if (status === DEPOSIT_STATUS.CANCELLED) {
    return 'ยกเลิกรายการเติมเงิน';
  }
  return '';
};

export const mapToAdjustType = (_transactionItem: TransactionItem): AdjustType => {
  switch (_transactionItem.adjustType) {
    case 'WRONG_SLIP':
      return 'WRONG_SLIP_UNVOIDABLE';
    case 'VOIDED_WRONG_SLIP':
      return 'WRONG_SLIP_VOIDABLE';
    case 'WRONG_USER':
      return 'WRONG_USER_UNVOIDABLE';
    case 'VOIDED_WRONG_USER':
      return 'WRONG_USER_VOIDABLE';
    default:
      return "NONE";
  }
}

export const mapToTransaction = (_transactionItem: TransactionItem): Transaction => {
  let status = _transactionItem.type === 'DEPOSIT' ? mapToDepositStatus(_transactionItem) : mapToWithdrawStatus(_transactionItem);
  // TODO : Refactor SOON
  // For refund transaction, it comes from "transaction_ref" or "refund" randomly (such a studpid response)
  // transaction_ref for Deposit, Transfer page
  // refund for Search transaction page.
  const refundTransaction = _transactionItem.refund;
  if (_transactionItem.type === 'REFUND') {
    if (_transactionItem.transactionRef?.status === "TRANSFER_BACK") { status = DEPOSIT_STATUS.TRANSFER_BACK; }
    if (_transactionItem.transactionRef?.status === "WAITING_TRANSFER") { status = DEPOSIT_STATUS.WAITING_TRANSFER; }
  }
  const fromBank: BankAccount = {
    bankCode: _transactionItem.fromBankCode as BankCode || '',
    bankName: mapToBankName(_transactionItem.fromBankCode as BankCode) || '',
    accountName: _transactionItem.fromBankAccountName || '',
    accountNumber: _transactionItem.fromBankAccountNumber || '',
  };

  const toBank: BankAccount = {
    bankCode: _transactionItem.toBankCode || '',
    bankName: mapToBankName(_transactionItem.toBankCode) || '',
    accountName: _transactionItem.toBankAccountName || '',
    accountNumber: _transactionItem.toBankAccountNumber || '',
  };


  let customerBank: BankAccount = fromBank;
  if (refundTransaction) {
    customerBank = {
      bankCode: refundTransaction.toBankCode as BankCode || '',
      bankName: mapToBankName(refundTransaction.toBankCode as BankCode) || '',
      accountName: refundTransaction.toBankAccountName || '',
      accountNumber: refundTransaction.toBankAccountNumber || '',
    };
  } else if (_transactionItem.type === 'WITHDRAW' || _transactionItem.type === 'REFUND') {
    customerBank = toBank;
  } else if (_transactionItem.type === 'DEPOSIT') {
    customerBank = fromBank;
  }

  let adminBank: BankAccount = toBank;
  if (refundTransaction) {
    adminBank = {
      bankCode: refundTransaction.fromBankCode as BankCode || '',
      bankName: mapToBankName(refundTransaction.fromBankCode as BankCode) || '',
      accountName: refundTransaction.fromBankAccountName || '',
      accountNumber: refundTransaction.fromBankAccountNumber || '',
    };
  } else if (_transactionItem.type === 'WITHDRAW' || _transactionItem.type === 'REFUND') {
    adminBank = fromBank;
  } else if (_transactionItem.type === 'DEPOSIT') {
    adminBank = toBank;
  }

  let _transferAt = datetime(_transactionItem.transferredAt).toDate();
  let _updatedAt = datetime(_transactionItem.latestActionAt).toDate();
  if (status === DEPOSIT_STATUS.WAITING_TRANSFER) {
    _transferAt = datetime(_transactionItem.transactionRef.transferredAt).toDate();
    _updatedAt = datetime(_transactionItem.transactionRef.latestActionAt).toDate();
  }
  return {
    transactionId: _transactionItem.uid,
    adjustType: mapToAdjustType(_transactionItem),
    transferAt: _transferAt,
    transferAtDate: formatDate(_transferAt),
    transferAtTime: formatTime(_transferAt),
    updatedAt: _updatedAt,
    createdAt: datetime(_transactionItem.createdAt).toDate(),
    customerPhoneNumber: _transactionItem.customerPhoneNumber || '',
    customerBank: customerBank,
    customerBankNumber: customerBank.accountNumber || '',
    userAccount: _transactionItem.customerCode || _transactionItem?.transactionRef?.customerCode || '',
    userUid: _transactionItem.customerUid || '',
    adminBank: adminBank,
    amount: numberWithoutCommas(_transactionItem.amount),
    isBonus: _transactionItem.isBonus || false,
    bonusType: _transactionItem.bonusType,
    bonus: _transactionItem.coinAmount ? numberWithoutCommas(_transactionItem.coinAmount) : 0,
    status: status,
    statusMessage: mapToStatusMessage(status, _transactionItem),
    raw_status: _transactionItem.status,
    remark: _transactionItem.note || '',
    editingBy: _transactionItem.editingBy?.uid || '',
    emailEditingBy: _transactionItem.editingBy?.email || '',
    editTransaction: _transactionItem.editTransaction,
    matchingState: _transactionItem.matchingState,
    type: _transactionItem.type,
    isSending: _transactionItem.isSent || false,
    rawData: camelcaseKeys(_transactionItem, { deep: true }) as any,
  }
}
