import { BankAccount } from "src/components";
import { Transaction } from "src/hooks/useTransaction/type";

export interface AdminBankAccountProps {
  transaction: Transaction;
}

export function AdminBankAccount({ transaction }: AdminBankAccountProps) {
  return (
    <BankAccount
      bankCode={transaction.adminBank.bankCode}
      accountName={transaction.adminBank.accountName}
      accountNumber={transaction.adminBank.accountNumber}
    />
  );
}

export default AdminBankAccount;
