import { AxiosRequestConfig } from "axios";
import { pigspinBackofficeApiInstance } from "src/utils/api/AxiosInstance";
import { fetchWithJSON } from "src/utils/api/fetch";
import { BaseRequest, BaseResponse2, CustomerItem } from "../service.type"
import { formatDateDashCase } from "src/utils/date";
import { BankCode, CustomerStatus } from "src/types";

export type CustomerRequest = BaseRequest & {
  offset: number;
  limit: number;
  userAccount?: string;
  phone?: string;
  createdAt?: Date;
};

export type CustomerReponse = BaseResponse2<{
  total_page: number;
  offset: number;
  limit: number;
  total_results_user: number;
  results: CustomerItem[];
}>;

export const GetCustomerList = (request: CustomerRequest): Promise<CustomerReponse> => {
  const { userAccount, phone, createdAt, limit, offset } = request;
  var query = new URLSearchParams();
  if (userAccount) query.append("player_username", userAccount);
  if (phone) query.append("player_username", phone);
  if (createdAt) query.append("date_of_app", formatDateDashCase(createdAt, true));
  query.append("limit", limit.toString());
  query.append("offset", offset.toString());
  const requestConfig: AxiosRequestConfig = {
    url: `v1/payment/customer?${query}`,
    method: 'GET',
    headers: {
      token: request.token,
    }
  };
  return fetchWithJSON<CustomerReponse>(pigspinBackofficeApiInstance, requestConfig);
};

export type CustomerOverviewRequest = BaseRequest;
export type CustomerOverviewResponse = BaseResponse2<{
  total_user: number,
  total_user_active: number,
  total_user_disabled: number,
  total_user_register: number,
}>;

export const GetCustomerOverview = async (request: CustomerOverviewRequest): Promise<CustomerOverviewResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: `v1/payment/customer/info-entity`,
    method: 'GET',
    headers: {
      token: request.token,
    }
  };
  return fetchWithJSON<CustomerOverviewResponse>(pigspinBackofficeApiInstance, requestConfig);
}

export type CustomerBaseRequest = {
  bank_code?: BankCode;
  bank_account_number?: string;
  bank_account_name?: string;
  bank_account_name_en?: string;
  phone_number?: string;
  true_wallet_id?: string;
  line_user_id?: string;
  default_bgb_deposit_bank_code?: string;
  default_group_bank_code?: string;
}
export type CustomerBaseResponse = {
  phone_number_existed?: boolean;
  bank_account_existed?: boolean;
  true_wallet_id_existed?: boolean;
}

export type PutCustomerRequest = BaseRequest & CustomerBaseRequest & {
  user_code: string;
  player_username: string;
  status: CustomerStatus;
};
export type PutCustomerResponse = BaseResponse2<CustomerBaseResponse>;

export const PutCustomer = async (request: PutCustomerRequest): Promise<PutCustomerResponse> => {
  const { token, ...restRequest } = request;
  const requestConfig: AxiosRequestConfig = {
    url: `v1/payment/customer`,
    method: 'PUT',
    headers: {
      token: token,
    },
    data: restRequest,
  };
  return fetchWithJSON<PutCustomerResponse>(pigspinBackofficeApiInstance, requestConfig);
}

export type PostCustomerRequest = BaseRequest & CustomerBaseRequest & {
  pin: string;
};
export type PostCustomerResponse = BaseResponse2<CustomerBaseResponse>;

export const PostCustomer = async (request: PostCustomerRequest): Promise<PostCustomerResponse> => {
  const { token, ...restRequest } = request;
  const requestConfig: AxiosRequestConfig = {
    url: `v1/payment/customer/register`,
    method: 'POST',
    headers: {
      token: token,
    },
    data: restRequest,
  };
  return fetchWithJSON<PostCustomerResponse>(pigspinBackofficeApiInstance, requestConfig);
}

export type ClearCustomerRequest = BaseRequest & {
  user_code: string;
};

export const ClearCustomer = async (request: ClearCustomerRequest): Promise<PutCustomerResponse> => {
  const { token, ...restRequest } = request;
  const requestConfig: AxiosRequestConfig = {
    url: `v1/payment/customer/disable-user`,
    method: 'PUT',
    headers: {
      token: token,
    },
    data: restRequest,
  };
  return fetchWithJSON<PutCustomerResponse>(pigspinBackofficeApiInstance, requestConfig);
}

export type DeleteCustomerRequest = BaseRequest & {
  user_code?: string;
};

export const DeleteCustomer = async (request: DeleteCustomerRequest): Promise<PutCustomerResponse> => {
  const { token, ...restRequest } = request;
  if (request.user_code) {}
  const requestConfig: AxiosRequestConfig = {
    url: `v1/payment/customer/`,
    method: 'DELETE',
    headers: {
      token: token,
    },
    data: restRequest,
  };
  return fetchWithJSON<PutCustomerResponse>(pigspinBackofficeApiInstance, requestConfig);
}