import { PageHeader as AntPageHeader, PageHeaderProps as AntPageHeaderProps } from "antd";
import "./PageHeader.less";

interface PageHeaderProps extends AntPageHeaderProps {
  children?: React.ReactNode;
}

function PageHeader({ children, ...props }: PageHeaderProps) {
  return (
    <AntPageHeader {...props} className="bo-page-header">
      {children}
    </AntPageHeader>
  );
}

export default PageHeader;
