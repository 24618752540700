import { AxiosRequestConfig } from 'axios';
import { TransactionItem } from 'src/hooks/useTransaction/type';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse2 } from "../service.type";

export type WrongSlipRequest = {
  transaction_uid: string;
  user_uid: string;
  token: string;
};

export type WrongSlipResponse = BaseResponse2<TransactionItem>;

export const wrongSlip = async ({
  transaction_uid,
  token,
  user_uid,
}: WrongSlipRequest): Promise<WrongSlipResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/payment/deposit/wrong-slip',
    method: 'POST',
    data: {
      transaction_uid,
      customer_code: user_uid,
    },
    headers: {
      token,
     }
  };
  return fetchWithJSON<WrongSlipResponse>(pigspinBackofficeApiInstance, requestConfig);
};
