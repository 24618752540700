import { BankCode, BANK_NAME, BANK_TYPE } from "../types";

export const TEN_DIGITS_BANK: BankCode[] = [
  BankCode.KBANK,
  BankCode.SCB,
  BankCode.KTB,
  BankCode.BBL,
  BankCode.BAY,
  BankCode.TTB,
  BankCode.LHBANK,
  BankCode.IBANK,
  BankCode.UOB,
  BankCode.CIMB,
  BankCode.KK,
  BankCode.TrueMoney,
]

export const TWELVE_DIGITS_BANK: BankCode[] = [
  BankCode.GSB,
  BankCode.BAAC,
  BankCode.GHBANK,
]

export const mapToBankName = (bankCode: BankCode): BANK_NAME => {
  const mapper = {
    [BankCode.KBANK]: BANK_NAME.KBANK,
    [BankCode.SCB]: BANK_NAME.SCB,
    [BankCode.KTB]: BANK_NAME.KTB,
    [BankCode.BBL]: BANK_NAME.BBL,
    [BankCode.BAY]: BANK_NAME.BAY,
    [BankCode.LHBANK]: BANK_NAME.LHBANK,
    [BankCode.IBANK]: BANK_NAME.IBANK,
    [BankCode.UOB]: BANK_NAME.UOB,
    [BankCode.GSB]: BANK_NAME.GSB,
    [BankCode.BAAC]: BANK_NAME.BAAC,
    [BankCode.CIMB]: BANK_NAME.CIMB,
    [BankCode.KK]: BANK_NAME.KK,
    [BankCode.GHBANK]: BANK_NAME.GHBANK,
    [BankCode.TTB]: BANK_NAME.TTB,
    [BankCode.TrueMoney]: BANK_NAME.TrueMoney,
    [BankCode.NONE]: BANK_NAME.NONE,
  };
  return mapper[bankCode];
}

export const mapToBankTypeText = (type: BANK_TYPE): string => {
  const mapper = {
    [BANK_TYPE.DEPOSIT]: 'บัญชีรับเงิน',
    [BANK_TYPE.WITHDRAW]: 'บัญชีโอนเงิน',
    [BANK_TYPE.NONE]: 'NONE',
  }
  return mapper[type];
}


export type BankFormatParam = {
  customerBankCode?: BankCode;
  adminBankCode: BankCode;
  accountNumber: string;
}

/**
 * @deprecated soon
 */
export const mapToBankFormat = (params: BankFormatParam): string => {
  return params.accountNumber;
  switch (params.adminBankCode) {
    case 'BAY':
      return adminIsBay(params);
    case 'SCB':
      return adminIsScb(params);
    case 'KBANK':
      return adminIsKbank(params);
    default:
      return params.accountNumber;
  }
}

export const isTenDigitsBank = (bankCode: BankCode) => {
  return TEN_DIGITS_BANK.some(b => b === bankCode)
}

export const isTwelveDigitsBank = (bankCode: BankCode) => {
  return TWELVE_DIGITS_BANK.some(b => b === bankCode)
}

export const adminIsBay = (params: BankFormatParam): string => {
  if(!params.customerBankCode) return params.accountNumber;
  if (isTwelveDigitsBank(params.customerBankCode)) return `xxxx${params.accountNumber}`; // x{4}\d{8}
  if (isTenDigitsBank(params.customerBankCode)) return `xx${params.accountNumber}`; // x{2}\d{8}
  return params.accountNumber;
}

export const adminIsScb = (params: BankFormatParam): string => {
  if(!params.customerBankCode) return params.accountNumber;
  if (params.customerBankCode === 'SCB') return `xxxxxx${params.accountNumber}`; // x{6}\d{4}
  if (isTwelveDigitsBank(params.customerBankCode)) return `xxxxxx${params.accountNumber}`; // x{6}\d{6}
  if (isTenDigitsBank(params.customerBankCode)) return `xxxx${params.accountNumber}`; // x{4}\d{6}
  return params.accountNumber;
}

export const adminIsKbank = (params: BankFormatParam): string => {
  if (params.customerBankCode === 'KBANK') {
    if (params.accountNumber.length === 4) return `xxxxx${params.accountNumber}x`; // x{5}\d{4}x
    return `xxx${params.accountNumber}x`; // x{3}\d{6}x
  }
  return params.accountNumber;
}

export type BankDigitRuleParam = {
  customerBankCode: BankCode;
  adminBankCode: BankCode;
}

export const DEFAULT_DIGITS_REQUIRE = 6;
/**
 * @deprecated
 */
export const getCustomerBankDigitsRequire = (params: BankDigitRuleParam): number => {
  const { customerBankCode, adminBankCode } = params;
  const bothAreScb = adminBankCode === 'SCB' && customerBankCode === 'SCB'
  if (bothAreScb || adminBankCode === 'KBANK') return 4;
  if (adminBankCode === 'BAY') return 8;
  return DEFAULT_DIGITS_REQUIRE;
}
