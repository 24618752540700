import { AxiosRequestConfig } from 'axios';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse } from "../service.type";

type EnumResponse = BaseResponse & {
  data: {};
}

export const getEnum = async (token: string = '') => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: 'v1/users/enum',
      method: 'GET',
      headers: { token }
    };
    const result = await fetchWithJSON<EnumResponse>(pigspinBackofficeApiInstance, requestConfig);
    return result.data;
  }
  catch (error) {
    return null;
  }
}
