import React, {useEffect, useState} from 'react';
import { useJwt } from 'react-jwt';
import { useRecoilValue } from 'recoil';
import { Modal, Form, Input, Select, Radio } from 'antd';
import { authState } from '../../../recoils';
import {
  verifyEmail,
  verifyPersonalNo,
  VERIFY_EMAIL_STATUS,
  VERIFY_PERSONAL_NUMBER_STATUS
} from '../../../services/registerAdmin';
import { editAdmin } from '../../../services/editAdmin';
import { UserInfo } from '../../../services/userList';
import { getEnum } from '../../../services/utils';
import ActiveLogo from '../../../assets/logo/active.svg';
import InactiveLogo from '../../../assets/logo/inactive.svg';
import TerminateLogo from '../../../assets/logo/terminate.svg';
import './EditAdminUserModal.scss';
import Button from 'src/components/Button/Button';


type DecodedToken = {
  created_at: string;
  scope_list: string[];
  user_uid: string;
}

export type EditAdminUserModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (value: boolean) => void;
  adminUserData: UserInfo;
}

const EditAdminUserModal = ({
                             open,
                             onClose,
                             onConfirm,
                             adminUserData,
                           }: EditAdminUserModalProps) => {
  const [ positionList, setPositionList ] = useState<any[]>()
  const [ scopeList, setScopeList ] = useState<any[]>()
  const [ isEmailChanged, setIsEmailChanged ] = useState(false)
  const [ isPersonalNoChanged, setIsPersonalNumberChanged ] = useState(false)
  const [ isPositionChanged, setIsPositionChanged ] = useState(false)
  const [ isStatusChanged , setIsStatusChanged ] = useState(false)
  const [ isEmailValid, setIsEmailValid ] = useState(true)
  const [ isPersonalNoValid, setIsPersonalNumberValid ] = useState(true)
  const [ isPositionValid, setIsPositionValid ] = useState(true)
  const [ form ] = Form.useForm();
  const { token = '' } = useRecoilValue(authState);
  const { decodedToken } = useJwt(token);

  const unmountAllState = () => {
    setIsEmailChanged(false)
    setIsPersonalNumberChanged(false)
    setIsPositionChanged(false)
    setIsStatusChanged(false)
    setIsEmailValid(true)
    setIsPersonalNumberValid(true)
    setIsPositionValid(true)
  }

  const onSubmit = async (values: any) => {
    let editAdminUserStatus = false
    const requestBody = {
      user_uuid: adminUserData.uid,
      email: values.email,
      personal_number: values.personal_number,
      role_uuid: values.role,
      status: values.status,
    }
    const isEmailAdminSuccess = await editAdmin(requestBody, token)
    unmountAllState()
    if (isEmailAdminSuccess !== null) {
      editAdminUserStatus = true
    }
    onConfirm(editAdminUserStatus)
  }

  const isFormChanged = () => {
    return isEmailChanged
      || isPersonalNoChanged
      || isPositionChanged
      || isStatusChanged
  }

  const isFormValid = () => {
    return isEmailValid
      && isPersonalNoValid
      && isPositionValid
  }

  const header = (
    <div>แก้ไขพนักงาน</div>
  )

  const footer = (
    <div className="footer">
      <Button size="large" style={{ padding: '0 1.5rem' }} onClick={() => {unmountAllState(); form.resetFields(); onClose()}} htmlType="reset">ยกเลิก</Button>
      <Button
        size="large"
        style={{ width: 160 }}
        type="primary"
        htmlType="submit"
        disabled={!isFormChanged() || !isFormValid()}
      >
        บันทึก
      </Button>
    </div>
  )

  const checkEmailAvailable = (email: string) => {
    const requestBody = {
      email
    }
    const isEmailVerified = verifyEmail(requestBody, token)
    return isEmailVerified
  }

  const checkPersonalNumberAvailable = (personalNumber: string) => {
    const requestBody = {
      personal_number: personalNumber
    }
    const isPersonalNumberVerified = verifyPersonalNo(requestBody, token)
    return isPersonalNumberVerified
  }

  useEffect(() => {
    if(decodedToken) {
      const tokenData = decodedToken as DecodedToken;
      const scopeList = tokenData.scope_list;

      setScopeList(scopeList)
    }
  }, [decodedToken])

  useEffect(() => {
    getEnum(token).then((data: any) => {
      const optionItems = data.role.map((position: any) => {
        const item = {
          label: position.name,
          value: position.uid,
        }
        return item
      });

      if(optionItems && scopeList) {
        const scopeListData = [...scopeList];
        const filteredPositionScope = scopeListData.filter(data => data.includes('REGISTER-USER.'))
        const filteredPositionOptions = optionItems.filter(
          ({ label }: { label: string }) => filteredPositionScope.includes(`REGISTER-USER.${label}`)
        );
        setPositionList(filteredPositionOptions)
      }
    })
  }, [token, scopeList])

  useEffect(() => {
    form.setFieldsValue({
      email: adminUserData.email,
      personal_number: adminUserData.personal_number,
      role: positionList?.find(data => data.label === adminUserData.role)?.value,
      status: adminUserData.status
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminUserData])


  return (
    <Modal
      title={header}
      visible={open}
      footer={false}
      closable={false}
      wrapClassName="edit-admin-user-modal"
      centered
      destroyOnClose={true}
    >
      <div className="content-wrapper">
        <Form
          className="form-register"
          onAbort={() => {unmountAllState(); form.resetFields(); onClose()}}
          onFinish={onSubmit}
          autoComplete="off"
          layout="vertical"
          form={form}
          requiredMark={false}
          initialValues={{
            email: adminUserData.email,
            personal_number: adminUserData.personal_number,
            role: positionList?.find(data => data.label === adminUserData.role)?.value,
            status: adminUserData.status
          }}
        >
          <div className="form-wrapper">
            <div className="content-header">
              <span>ชื่อ-นามสกุล: {adminUserData.name ? adminUserData.name:'-'}</span>
            </div>
            <Form.Item
              label='อีเมล'
              name='email'
              rules={[
                {
                  async validator(_,value) {
                    const emailFormat = String(value)
                      .toLowerCase()
                      .match(
                        // eslint-disable-next-line no-useless-escape
                        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$$/
                      )
                    if (!value) {
                      setIsEmailValid(false)
                      return Promise.reject('กรุณาใส่อีเมล')
                    }
                    if (emailFormat !== null) {
                      if (adminUserData.email === value) {
                        setIsEmailValid(true)
                        setIsEmailChanged(false)
                        return Promise.resolve()
                      } else {
                        const isEmailVerified = await checkEmailAvailable(value)
                        setIsEmailChanged(true)
                        if (isEmailVerified === VERIFY_EMAIL_STATUS.SUCCESS) {
                          setIsEmailValid(true)
                          return Promise.resolve()
                        } else if (isEmailVerified === VERIFY_EMAIL_STATUS.EXIST) {
                          setIsEmailValid(false)
                          return Promise.reject('อีเมลนี้ถูกใช้แล้ว')
                        }
                        setIsEmailValid(false)
                        return Promise.reject('อีเมลนี้ไม่สามารถใช้งานได้')
                      }
                    }
                    setIsEmailValid(false)
                    return Promise.reject('รูปแบบไม่ถูกต้อง')
                  }
                },
              ]}
              validateTrigger="onBlur"
              shouldUpdate={true}
            >
              <Input placeholder="ใส่อีเมล" />
            </Form.Item>
            <Form.Item
              label='รหัสพนักงาน'
              name='personal_number'
              rules={[
                {
                  async validator(_, value) {
                    const personalNoFormat = String(value)
                      .toLowerCase()
                      .match(
                        /^\d{4}$/
                      )
                    if (!value) {
                      setIsPersonalNumberValid(false)
                      return Promise.reject('กรุณาใส่รหัสพนักงาน')
                    }
                    if (personalNoFormat) {
                      if (adminUserData.personal_number === value) {
                        setIsPersonalNumberValid(true)
                        setIsPersonalNumberChanged(false)
                        return Promise.resolve()
                      } else {
                        setIsPersonalNumberChanged(true)
                        const isPersonalNumberAvailable = await checkPersonalNumberAvailable(value)
                        if (isPersonalNumberAvailable === VERIFY_PERSONAL_NUMBER_STATUS.SUCCESS) {
                          setIsPersonalNumberValid(true)
                          return Promise.resolve()
                        } else if (isPersonalNumberAvailable === VERIFY_PERSONAL_NUMBER_STATUS.EXIST) {
                          setIsPersonalNumberValid(false)
                          return Promise.reject("รหัสนี้ถูกใช้แล้ว")
                        }
                        setIsPersonalNumberValid(false)
                        return Promise.reject("รหัสพนักงานนี้ไม่สามารถใช้งานได้")
                      }
                    }
                    setIsPersonalNumberValid(false)
                    return Promise.reject("ใส่เป็นตัวเลข 4 ตัว")
                  }
                }
              ]}
              validateTrigger="onBlur"
            >
              <Input placeholder="ใส่รหัสพนักงาน" />
            </Form.Item>
            <Form.Item
              label='ตำแหน่ง'
              name={['role']}
              rules={[
                {
                  validator(_, value) {
                    if (!value) {
                      setIsPositionValid(false)
                      return Promise.reject('กรุณาเลือกตำแหน่ง')
                    } else if (adminUserData.role === value) {
                      setIsPositionChanged(false)
                      return Promise.resolve()
                    }
                    setIsPositionValid(true)
                    return Promise.resolve()
                  }
                }
              ]}
              validateTrigger="onBlur"
            >
              <Select
                className="role-selector"
                placeholder="เลือกตำแหน่ง"
                onSelect={(value: any) => {
                  if (positionList) {
                    let position = positionList.find(item => item["value"] === value)
                      if (position['label'] === adminUserData.role) {
                        setIsPositionChanged(false)
                      } else {
                        setIsPositionChanged(true)
                      }
                    }
                  form.validateFields(['role']);
                  }}
                options={positionList}
              >
              </Select>
            </Form.Item>
            <Form.Item
              name="status"
              label="สถานะ:"
              className="ant-form-item--status"
            >
              <Radio.Group
                onChange={(e) => {
                  if (e.target.value === adminUserData.status) {
                    setIsStatusChanged(false)
                  } else {
                    setIsStatusChanged(true)
                  }
                }}
                size="large"
              >
                <Radio value="ACTIVE" style={{display: "block"}}>
                  <img
                    className="status-image"
                    src={ActiveLogo}
                    height="18"
                    width="18"
                    alt="active"
                  />
                  เปิดใช้งาน
                </Radio>
                <Radio value="INACTIVE" style={{display: "block"}}>
                  <img
                    className="status-image"
                    src={InactiveLogo}
                    height="18"
                    width="18"
                    alt="inactive"
                  />
                  หยุด/พักงาน
                </Radio>
                <Radio value="TERMINATE" style={{display: "block"}}>
                  <img
                    className="status-image"
                    src={TerminateLogo}
                    height="18"
                    width="18"
                    alt="terminate"
                  />
                  พ้นสภาพพนักงาน
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Form.Item>
            {footer}
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditAdminUserModal;
