import { Dropdown, DropDownProps, Menu } from "antd";
import { ButtonSize } from "antd/es/button";
import { EditButton } from "src/components";
import { OutlineClear1, OutlineClose2, OutlineEdit1 } from "src/icons";

export interface EditDropdownProps extends Omit<DropDownProps, "overlay"> {
  size?: ButtonSize;
  showCancel?: boolean;
  showDelete?: boolean;
  showEdit?: boolean;
  editText?: string;
  cancelText?: string;
  deleteText?: string;
  onEdit?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

function EditDropdown({
  size,
  showCancel,
  showDelete,
  showEdit,
  editText,
  cancelText,
  deleteText,
  onEdit,
  onCancel,
  onDelete,
  ...props
}: EditDropdownProps) {
  return (
    <Dropdown
      placement="bottomRight"
      trigger={["click"]}
      arrow
      {...props}
      overlay={
        <Menu>
          {/* {showEdit && (
            <Menu.Item
              key="update"
              icon={<OutlineEdit1 style={{ fontSize: "16px" }} />}
              onClick={onEdit}
              style={{ padding: "8px 16px" }}
            >
              {editText || "แก้ไขข้อมูล"}
            </Menu.Item>
          )} */}
          {showCancel && (
            <Menu.Item
              key="cancel"
              icon={<OutlineClose2 style={{ fontSize: "16px" }} />}
              onClick={onCancel}
              style={{ padding: "8px 16px" }}
            >
              {cancelText || "ยกเลิกรายการ"}
            </Menu.Item>
          )}
          {showDelete && (
            <Menu.Item
              key="delete"
              icon={<OutlineClear1 style={{ fontSize: "16px" }} />}
              onClick={onDelete}
              style={{ padding: "8px 16px" }}
            >
              {deleteText || "ลบรายการ"}
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <EditButton disabled={props.disabled} size={size} />
    </Dropdown>
  );
}

export default EditDropdown;
