import { useQuery, UseQueryOptions } from "react-query";
import { CustomerGameStat, Pagination } from "src/types";
import { api } from "src/utils";

export interface UseCustomerGameStatsFilters {
  startDate?: string;
  endDate?: string;
}

export function useCustomerGameStats<T = CustomerGameStat[]>(
  customerCode: string,
  filters?: UseCustomerGameStatsFilters,
  options?: UseQueryOptions<Pagination<CustomerGameStat[]>, unknown, T, any[]>
) {
  return useQuery(
    ["customerGameStats", customerCode, filters],
    async () => {
      const response = await api.get(`payment/customer/${customerCode}/game-stats`, {
        params: filters,
      });
      return response.data.data;
    },
    options
  );
}
