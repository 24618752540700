import { Form, Input, Space, Spin, Tag, Typography } from 'antd';
import * as React from 'react';
import { MoneyInput, UserInfo } from 'src/components';
import Modal from 'src/components/Modal/Modal';
import { toast } from "src/components";
import Button from 'src/components/Button/Button';
import { useUserInfo } from 'src/hooks/useTransaction/useUserInfo';
import UserInfoNotFound from 'src/components/UserInfo/UserInfoNotFound';
import { numberWithCommas, numberWithoutCommas } from 'src/utils/number/number';
import { useRecoilState, useRecoilValue } from 'recoil';
import { affiliateStateAtom, authState, modalState } from 'src/recoils';
import { PostManualWithdraw, PostManualWithdrawRespones } from 'src/services/transactions/transactions';
import { ValidateInput } from 'src/hooks/type';
import TransferTag from 'src/components/Affiliate/TransferTag';

interface IManualWithdrawProps {
  visible: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

export type ManualWithdrawForm = {
  withdrawamount: number;
}

const ManualWithdrawCreateComponent: React.FunctionComponent<IManualWithdrawProps> = (props) => {
  const affiliateState = useRecoilValue(affiliateStateAtom);
  const { onSuccess } = props;
  const [, setModal] = useRecoilState(modalState);
  const inputRef = React.useRef<Input>(null)
  const [form] = Form.useForm<ManualWithdrawForm>();
  const { token = '' } = useRecoilValue(authState);
  const [withdrawAmount, setWithdrawAmount] = React.useState<string>('')
  const [validateInput, setValidateInput] = React.useState<ValidateInput>();
  const [isValidForm, setIsValidForm] = React.useState(true);
  const userInfoState = useUserInfo();
  const { userInfo, doSearchingUserInfo, isLoading, setUserInfo } = userInfoState;

  const isInWithdrawRange = (_amount: number): boolean => {
    return _amount >= 20 && _amount <= 100000;
  }

  const createManualWithdraw = async () => {
    try {
      const response = await PostManualWithdraw({
        token,
        amount: numberWithoutCommas(withdrawAmount),
        phone_number: userInfo.phoneNumber || '',
        bank_code: userInfo.bankAccount?.bankCode || '',
        bank_account_number: userInfo.bankAccount?.accountNumber || '',
        bank_account_name: userInfo.bankAccount?.accountName || '',
        customer_uid: userInfo.userCode || '',
        customer_code: userInfo.userAccount || '',
        transfer_type: affiliateState.isAffiliateMode ? 'AFFILIATE' : undefined,
      });
      if (response.service_code === 'BOE-2000') {
        toast.success('เพิ่มรายการถอนเงินสำเร็จ');
        onSuccess();
      } else {
        toast.error('เพิ่มรายการถอนเงินไม่สำเร็จ');
      }
    } catch (e: any) {
      const response: PostManualWithdrawRespones = e.response.data;
      if (response.service_code === 'BOE-4045') {
        toast.error('เงินในบัญชีของลูกค้าไม่เพียงพอ')
        setValidateInput({ validateStatus: 'error', help: 'เงินในบัญชีของลูกค้าไม่เพียงพอ', })
      } else {
        toast.error('เพิ่มรายการถอนเงินไม่สำเร็จ')
      }
    }
  };

  const renderManualAddFooter = () => {
    return (
      <>
        <Button
          className="button cancel-button"
          style={{ width: 100 }}
          size="large"
          onClick={props.onCancel}
        >
          ยกเลิก
        </Button>
        <Button
          style={{ width: 176 }}
          type="primary"
          size="large"
          disabled={!isValidForm}
          onClick={() => {
            setModal({
              isModalVisible: true,
              type: 'warning',
              title: 'เพิ่มรายการถอนเงิน',
              content: 'ต้องการเพิ่มรายการถอนเงินใช่หรือไม่',
              buttonType: 'question',
              onConfirm: () => {
                createManualWithdraw();
              },
            });
          }}
        >
          เพิ่มรายการถอนเงิน
        </Button>
      </>
    )
  };

  const onSearch = (value: string) => {
    doSearchingUserInfo(value)
    form.resetFields();
  }

  React.useEffect(() => {
    setValidateInput(undefined);
    setWithdrawAmount('')
    setUserInfo({ isComplete: false, });
    setIsValidForm(false);
    inputRef.current?.setValue('');
  }, [props.visible]);

  const currentBalance = affiliateState.isAffiliateMode ? userInfo.affiliateBalance : userInfo.currentBalance;

  return (
    <Modal
      visible={props.visible}
      centered
      width={968}
      title={(
        <Space>
          <div>
            เพิ่มรายการถอนเงิน
          </div>
          <TransferTag />
        </Space>
      )}
      footer={renderManualAddFooter()}
      onCancel={props.onCancel}
    >
      <Spin spinning={isLoading}>
        <div style={{
          padding: '12px 83px',
          minHeight: 450,
        }}
        >
          <Typography.Title level={5}>ค้นหาข้อมูลลูกค้า</Typography.Title>
          <Input.Search
            ref={inputRef}
            autoFocus
            className="search-bar"
            placeholder="รหัสหรือเบอร์โทร"
            allowClear enterButton
            size="large"
            onSearch={onSearch}
          />
          {userInfo?.isFound && (
            <>
              <UserInfo
                userCode={userInfo.userAccount}
                phone={userInfo.phoneNumber}
                bankCode={userInfo.bankAccount?.bankCode}
                bankAccountName={userInfo.bankAccount?.accountName}
                bankAccountNumber={userInfo.bankAccount?.accountNumber}
                lastTransactionAt={userInfo.lastWithdrawDatetime}
                lastTransactionAmount={userInfo.lastWithdrawAmount}
                transactionType="WITHDRAW"
                balance={currentBalance}
              />
              <br />
              <Typography.Title level={5}>จำนวนเงิน (บาท)</Typography.Title>
              <Form
                form={form}
                onFieldsChange={(_from, _fields) => {
                  const hasErrorField = form.getFieldsError().some(_f => _f.errors.length > 0);
                  setIsValidForm(!hasErrorField);
                }}
              >
                <Form.Item
                  name="withdrawamount"
                  validateStatus={validateInput?.validateStatus}
                  help={validateInput?.help}
                  rules={[
                    {
                      required: true,
                      async validator(_, value) {
                        const isDecimal = value % 1 !== 0;
                        if (!value) return Promise.reject('กรอกจำนวนถอน');
                        if (!value.match(/^\d*\.?\d*$/)) return Promise.reject('ใส่ตัวเลขเท่านั้น');
                        if (isDecimal) return Promise.reject('ใส่เลขจำนวนเต็ม');
                        if (!isInWithdrawRange(value)) return Promise.reject('ถอนเงินได้ระหว่าง 20 - 100,000 บาท')
                        if (value > (currentBalance || 0)) return Promise.reject(`ยอดเงินที่สามารถถอนได้ ${numberWithCommas(currentBalance || 0).split('.')[0]} บาท`)
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <MoneyInput
                    size="large"
                    value={withdrawAmount}
                    placeholder="ใส่จำนวนเงิน"
                    onChange={(value) => { setWithdrawAmount(value) }}
                  />
                </Form.Item>
              </Form>
            </>
          )}
          {userInfo?.isComplete && !userInfo?.isFound && <UserInfoNotFound />}
        </div>
      </Spin>
    </Modal>
  );
};

const ManualWithdrawCreate: React.FunctionComponent<IManualWithdrawProps> = (props) => {
  return React.useMemo(() => <ManualWithdrawCreateComponent {...props} />, [props.visible]);
};

export default ManualWithdrawCreate;
