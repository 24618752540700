import UndoOutlined from '@ant-design/icons/lib/icons/UndoOutlined';
import * as React from 'react';
import { BonusType } from 'src/types';
import Icon from '../Icon/Icon';

interface IBonusTypeProps {
  isBonus: boolean | string;
  bonusType?: BonusType;
}

const BonusColumn: React.FunctionComponent<IBonusTypeProps> = (props) => {
  if (props.bonusType === 'CASHBACK') return <UndoOutlined />
  if (!props.isBonus) return <Icon name="outline-close-1" size="1rem" />
  return <Icon name="outline-check-1-1" size="1rem" />
};

export default BonusColumn;
