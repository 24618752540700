import { useMutation, UseMutationOptions } from "react-query";
import { toast } from "src/components";
import { api } from "src/utils";

export function useRevertRejectWithdraw(
  options?: UseMutationOptions<unknown, unknown, string, unknown>
) {
  return useMutation(
    (uid: string) => api.put("payment/withdraw/cancel-reject-transaction", { transactionUid: uid }),
    {
      onError: () => toast.error("ยกเลิกปฏิเสธการถอนไม่สำเร็จ"),
      onSuccess: () => toast.success("ยกเลิกปฏิเสธการถอนสำเร็จ"),
      ...options,
    }
  );
}
