import { Input, Row, Col, Space, Typography, DatePicker, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { CopyOutlined, UndoOutlined } from '@ant-design/icons';
import * as React from 'react';
import classnames from 'classnames';
import DeskPanel from 'src/components/DeskPanel/DesktPanel';
import Icon from 'src/components/Icon/Icon';
import PageHeader from 'src/components/PageHeader/PageHeader';
import { formatDate } from 'src/utils/date';
import colors from 'src/theme/colors.json';
import Table from 'src/components/Table';
import { CUSTOMER_TAB_TYPE, SearchCustomerResult, useCustomer } from 'src/hooks/useCustomer';
import { BankCode, CustomerRow, CustomerStatus, Scope } from 'src/types';
import { getBankFormat, getPhoneFormat } from 'src/utils/textOperation/textOperation';
import { AddButton, BankLogo, ColumnType, Tabs, toast } from 'src/components';
import { mapToBankName } from 'src/mapper/mapToBank';
import Status, { IconStatus, IconStatus2 } from 'src/components/Bank';
import Button from 'src/components/Button/Button';
import { numberWithCommas } from 'src/utils/number/number';
import CustomerForm from 'src/components/CustomerForm/CustomerForm';

import './Customer.less';
import { useRecoilValue } from 'recoil';
import { authInfoState } from 'src/recoils';
import { getColumnFilters } from 'src/utils/table';
import BonusColumn from 'src/components/TableColumns/BonusTypeColumn';
import { useRecoilState } from "recoil";
import { modalState } from "src/recoils";
import NiceModal from "@ebay/nice-modal-react";
import VipRedeemHistory from 'src/components/VIP/VipRedeemHistory';

interface ICustomerPageProps {
}

export const InfoTitle: React.FC = (props) => {
  return <Typography.Title level={5} style={{ fontSize: '14px' }}>{props.children}</Typography.Title>
}

export const InfoLabel: React.FC = (props) => {
  return <Typography.Text style={{ color: colors['@cloud-6'] }}>{props.children}</Typography.Text>
}

export const InfoValue: React.FC = (props) => {
  return <Typography.Text strong style={{ fontSize: '20px' }}>{props.children}</Typography.Text>
}


export const CustomerPage: React.FunctionComponent<ICustomerPageProps> = (props) => {
  const {
    isLoading,
    customerResult,
    editingCustomer,
    newUser,
    totalResult,
    totalCustomer,
    activeCustomer,
    inActiveCustomer,
    setEditCustomer,
    searchingDate,
    setSearchingDate,
    searchCriteria,
    handleSetSearchCriteria,
    textSearch,
    setTextSearch,
    getCustomerOverview,
    getCustomerList,
    selectedCustomerTab,
    setSelectedCustomerTab,
  } = useCustomer();
  const { scope } = useRecoilValue(authInfoState);
  const [isCreating, setIsCreating] = React.useState<boolean>(false);
  const [isDateOpened, setIsDateOpened] = React.useState<boolean>(false);
  const searchRef = React.useRef(null);

  const [, setModal] = useRecoilState(modalState);

  const getBaseTableProps = (customerResult: SearchCustomerResult) => {
    return {
      isSearchNotFound: customerResult.list.length < 1,
      dataSource: customerResult.list || [],
      totalItem: customerResult.totalResultCustomer,
      limit: searchCriteria.limit,
      offset: searchCriteria.offset,
      onPaginationChange: (offset: number, limit: number) => {
        handleSetSearchCriteria({
          ...searchCriteria,
          offset,
          limit,
        })
      }
    }
  };

  const renderSearchPanel = () => {
    const isEmptyTextSearch = textSearch === '';
    const enableSearch = !isEmptyTextSearch; // JUST IN CASE : if there is some logic to disable search
    return (
      <DeskPanel
        style={{ height: '100%' }}
        body={(
          <>
            <Row gutter={[40, 40]}>
              <Col span={16}>
                <Tabs className="SearchPanel" onChange={(value) => { setSelectedCustomerTab(value as CUSTOMER_TAB_TYPE) }} >
                  <Tabs.TabPane tab="ข้อมูลลูกค้า" key={CUSTOMER_TAB_TYPE.USER_ACCOUNT}>
                    <Input.Search
                      className={classnames('backoffice-search', {
                        'backoffice-search-disabled': !enableSearch,
                      })}
                      value={textSearch}
                      onChange={(e) => {
                        setTextSearch(e.target.value);
                      }}
                      size='large'
                      allowClear
                      enterButton
                      placeholder='ใส่คำค้นหา'
                      onSearch={(value) => {
                        const isEmpty = value.length < 1;
                        if (isEmpty) return;
                        const isUserAccountSearch = value.startsWith('PS');
                        if (enableSearch) {
                          handleSetSearchCriteria({
                            ...searchCriteria,
                            offset: 0,
                            userAccount: isUserAccountSearch ? textSearch.toUpperCase() : '',
                            phone: isUserAccountSearch ? '' : textSearch,
                            createdAt: isEmptyTextSearch ? new Date() : undefined,
                          })
                        }
                      }} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="วันที่สมัคร" key={CUSTOMER_TAB_TYPE.CREATED_AT}>
                    <DatePicker
                      allowClear
                      ref={searchRef}
                      size='large'
                      format="DD/MM/YYYY"
                      style={{ width: '100%', }}
                      open={isDateOpened}
                      showToday={false}
                      disabledDate={(date) => date?.isAfter(moment())}
                      value={moment(searchingDate)}
                      onBlur={() => setIsDateOpened(false)}
                      onFocus={() => setIsDateOpened(true)}
                      onChange={(date) => { setSearchingDate(date?.toDate()) }}
                      renderExtraFooter={(mode) => {
                        return (
                          <Row style={{ padding: '10px 0' }}>
                            <Col span={12}>
                              <Button
                                onClick={() => {
                                  setSearchingDate(new Date());
                                }}>
                                วันนี้
                              </Button>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                              <Button type="primary" onClick={() => {
                                setIsDateOpened(false);
                                handleSetSearchCriteria({
                                  ...searchCriteria,
                                  userAccount: '',
                                  phone: '',
                                  createdAt: searchingDate,
                                })
                                const blur = (document.activeElement as HTMLElement).blur();
                              }}>
                                ตกลง
                              </Button>
                            </Col>
                          </Row>
                        )
                      }}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </Col>
              <Col span={8}>
                <InfoTitle>ผลการค้นหา</InfoTitle>
                <div style={{ opacity: 0 }} ><InfoLabel>{formatDate(customerResult.searchingAt, true)}</InfoLabel></div>
                <InfoLabel><InfoValue>{numberWithCommas(totalResult)}</InfoValue>&nbsp;คน</InfoLabel>
              </Col>
            </Row>
          </>
        )}
      />
    )
  };

  const renderOverview = () => {
    return (
      <DeskPanel
        style={{ height: '100%' }}
        body={(
          <>
            <Row gutter={[16, 16]} align="bottom">
              <Col span={8}>
                <InfoTitle>จำนวนลูกค้า</InfoTitle>
                <InfoLabel>ลูกค้าทั้งหมด</InfoLabel><br />
                <InfoValue>{numberWithCommas(totalCustomer)}</InfoValue>
              </Col>
              <Col span={8}>
                <InfoLabel>เปิดใช้งาน</InfoLabel><br />
                <InfoValue>{numberWithCommas(activeCustomer)}</InfoValue>
              </Col>
              <Col span={8}>
                <InfoLabel>ถูกระงับใช้งาน</InfoLabel><br />
                <InfoValue>{numberWithCommas(inActiveCustomer)}</InfoValue>
              </Col>

            </Row>
          </>
        )}
      />
    )
  }

  const onCopyInfo = (customer: CustomerRow) => {
    const copyingText = `${customer.customerCode} - ${customer.bank.accountName}`
    navigator.clipboard.writeText(copyingText);
    toast.success((
      <div>
        คัดลอกข้อความสำเร็จ
        <br />
        <div>
          {copyingText}
        </div>
      </div>
    ))

  }

  const getAllColumns = (): Record<string, ColumnType<CustomerRow>> => {
    return {
      customerCode: {
        title: 'รหัสลูกค้า',
        dataIndex: 'customerCode',
        key: 'customerCode',
        width: 150,
        render: (value: string, row: CustomerRow) => {
          return (
            <Space>
              <span>
                {value}
              </span>
              <span style={{ cursor: 'pointer' }} onClick={() => { onCopyInfo(row); }}>
                <CopyOutlined />
              </span>
              {/* <BankLogo bankCode={value} />
              {mapToBankName(value)} */}
            </Space>
          )
        },
      },
      phone: {
        title: 'เบอร์โทรศัพท์',
        dataIndex: 'phone',
        key: 'phone',
        width: 120,
        render: (value: string) => {
          return (
            <Space>
              {value ? getPhoneFormat(value) : '-'}
            </Space>
          )
        },
      },
      promotionState: {
        title: <div style={{ textAlign: 'center' }}>
          <Tooltip placement="top" title="รับโบนัส">
            <Button type="text" style={{ padding: 0 }}>
              <Icon name="outline-tag" size="1rem" />
            </Button>
          </Tooltip>
        </div>,
        dataIndex: 'promotionState.status',
        key: 'promotionState',
        width: 50,
        render: (value: string, row: CustomerRow) => {
          return (
            <div style={{ textAlign: 'center' }}>
              <BonusColumn isBonus={row.promotionState.status} bonusType={row.promotionState.type} />
            </div>
          )
        },
      },
      bank: {
        title: 'ธนาคาร',
        dataIndex: 'bank',
        key: 'bank',
        width: 100,
        render: (value: string, row: CustomerRow) => {
          return (
            <Space>
              {row.bank.bankCode !== BankCode.NONE ? (
                <BankLogo showName width={25} height={25} bankCode={row.bank.bankCode} />
              ) : (
                <>
                  -
                </>
              )}
            </Space>
          )
        },
      },
      accountName: {
        title: 'ชื่อบัญชี',
        dataIndex: 'bank',
        key: 'accountName',
        width: 150,
        render: (value: string, row: CustomerRow) => {
          return (
            <Space>
              {row.bank.accountName || '-'}
            </Space>
          )
        },
      },
      accountNameEN: {
        title: 'ชื่อบัญชี EN',
        dataIndex: 'bank',
        key: 'accountNameEN',
        width: 150,
        render: (value: string, row: CustomerRow) => {
          return (
            <Space>
              {row.bank.accountNameEN || '-'}
            </Space>
          )
        },
      },
      displayName: {
        title: 'ฉายา',
        dataIndex: 'name',
        key: 'displayName',
        width: 150,
      },
      accountNumber: {
        title: 'เลขที่บัญชี',
        dataIndex: 'bank',
        key: 'accountNumber',
        width: 150,
        render: (value: string, row: CustomerRow) => {
          return (
            <Space>
              {row.bank.accountNumber ? getBankFormat(row.bank.accountNumber) : '-'}
            </Space>
          )
        },
      },
      trueWallet: {
        title: 'ทรูวอลเล็ท ไอดี',
        dataIndex: 'trueWalletId',
        key: 'trueWallet',
        width: 128,
        render: (value: string, row: CustomerRow) => {
          return (
            <Space>
              {value || '-'}
            </Space>
          )
        },
      },
      lineLinked: {
        title: 'ไลน์',
        dataIndex: 'isLineLinked',
        key: 'isLineLinked',
        width: 100,
        render: (value: boolean, row: CustomerRow) => {
          return (
            <Space>
              {value ? (
                <>
                  <Icon name="outline-linkline-1" color={colors['@green-6']} />
                  เชื่อมต่อ
                </>
              ) : (
                <>
                  <Icon name="outline-unlinkline-1" color={colors['@cloud-6']} />
                  ไม่เชื่อมต่อ
                </>
              )}
            </Space>
          )
        },
      },
      status: {
        title: 'สถานะ',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        filters: getColumnFilters("statusText", customerResult.list || []),
        onFilter: (value, row) => row.statusText?.includes(value.toString()) || false,
        render: (value: CustomerStatus, row: CustomerRow) => {
          return (
            <Space>
              <IconStatus type={value} />
              {row.statusText}
            </Space>
          )
        },
      },
      vip_action: {
        title: '',
        key: 'action',
        width: 100,
        render: (record) => (
          <>
            <div style={{ textAlign: 'center' }}>
              <Button
                type='primary'
                style={{ padding: "5px" }}
                onClick={(e) => NiceModal.show(VipRedeemHistory, {customer_code: record.customerCode})}
              >ประวัติ VIP
              </Button>
            </div>
          </>
        ),
      },
      edit: {
        title: '',
        dataIndex: 'status',
        key: 'edit',
        fixed: 'right',
        width: 50,
        render: (value: number, row: CustomerRow) => {
          return (
            <div style={{ textAlign: 'center' }}>
              <Button
                type='lightdark'
                style={{
                  padding: 0, width: 24, height: 24,
                }}
                onClick={(e) => {
                  setEditCustomer(row)
                }}
              ><Icon name="outline-edit-1" />
              </Button>
            </div>
          )
        },
      },
      createdAt: {
        title: 'วันที่สมัคร',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 114,
        render: (value: Date, row: CustomerRow) => {
          return row.createdAt?.format('DD/MM/YYYY HH:mm');
        }
      },
      referrer: {
        title: 'ผู้แนะนำ',
        dataIndex: 'upline',
        key: 'upline',
        width: 121,
        render: (value: string, row: CustomerRow) => {
          return row.upline.player_username || '-';
        },
      },
      kyc: {
        title: (
          <>
            ยืนยัน
            <br />
            ตัวตน
          </>
        ),
        dataIndex: 'hasKyc',
        key: 'hasKyc',
        width: 50,
        render: (hasKyc: boolean, row: CustomerRow) => {
          return (
            <Tooltip title={(<>
              <Space>
                <IconStatus2 type={hasKyc ? 'active' : 'stop'} />
                {hasKyc ? 'ยืนยันตัวตนแล้ว' : 'ยังไม่ยืนยันตัวตน'}
              </Space>
              <br />
            </>
            )}>
              <div style={{ textAlign: 'center' }}>
                {hasKyc ? (
                  <Icon name="outline-check-1-1" size="1rem" />
                ) : (
                  <Icon name="outline-close-1" size="1rem" />
                )}
              </div>
            </Tooltip>
          );
        },
      }
    }
  }

  const orderColumns = () => {
    const {
      customerCode,
      phone,
      promotionState,
      kyc,
      bank,
      accountName,
      accountNumber,
      trueWallet,
      referrer,
      createdAt,
      lineLinked,
      displayName,
      status,
      vip_action,
      edit,
    } = getAllColumns();
    const columsWithOutEdit = [
      customerCode,
      phone,
      promotionState,
      kyc,
      bank,
      accountName,
      displayName,
      accountNumber,
      trueWallet,
      referrer,
      createdAt,
      lineLinked,
      status,
      vip_action,
    ]
    if (scope[Scope.EditCustomer]?.enable)
      return [
        ...columsWithOutEdit,
        edit,
      ]
    return columsWithOutEdit;
  }

  return (
    <div id="CustomerPage">
      <PageHeader
        title=" "
        extra={[
          scope[Scope.RegisterCustomer]?.enable && (
            <AddButton key="add" onClick={() => setIsCreating(true)}>
              สมัครสมาชิก
            </AddButton>
          ),
        ]}
      />
      <Row gutter={[16, 16]}>
        <Col span={10}>{renderSearchPanel()}</Col>
        <Col span={4}>
          <DeskPanel
            style={{ height: '100%' }}
            body={(
              <>
                <InfoTitle>ผู้สมัครวันนี้</InfoTitle>
                <div><InfoLabel>{formatDate(new Date(), true)}</InfoLabel></div>
                <InfoLabel><InfoValue>{numberWithCommas(newUser)}</InfoValue>&nbsp;คน</InfoLabel>
              </>
            )} />
        </Col>
        <Col span={10}>{renderOverview()}</Col>
        <Col span={24}>
          <DeskPanel
            body={(
              <Table
                {...getBaseTableProps(customerResult)}
                offsetBuffer={1}
                isLoading={isLoading}
                dataSource={customerResult.list}
                columns={orderColumns()}
              />
            )}
          />
        </Col>
      </Row>
      <CustomerForm
        open={isCreating}
        editingCustomer={editingCustomer}
        onFinish={() => {
          setIsCreating(false);
          setEditCustomer(undefined);
          getCustomerList(searchCriteria);
          getCustomerOverview();
        }}
        onCancel={() => {
          setIsCreating(false);
          setEditCustomer(undefined);
        }}
        setSearchCriteria = {() => {
          handleSetSearchCriteria({
            ...searchCriteria,
          });
        }}
      />
    </div>
  );
};

export default CustomerPage;
