import * as React from 'react';
import { Transaction } from 'src/hooks/useTransaction/type';
import { formatDate, formatTime } from 'src/utils/date';
import { addCommas } from 'src/utils/number/number';
import Account from '../Bank/Account';
import './TransactionInfo.scss';

interface ITransactionInfoProps {
  transaction: Transaction;
}

const TransactionInfo: React.FunctionComponent<ITransactionInfoProps> = (props) => {
  const { transaction } = props;
  return (
    <div className="modal-body-wrapper">
      <div className="transaction-detail">
        <div className="title">วันเวลาที่ลูกค้าเติมเงิน</div>
        <div className="value">{formatDate(transaction.transferAt)}</div>
        <div className="value">{formatTime(transaction.transferAt)}</div>
      </div>
      <div className="transaction-detail">
        <div className="title">บัญชีลูกค้า</div>
        <Account
          {...transaction.customerBank}
          bankLogoStyles={{ fontSize: 16 }}
          font="blod"
          size="large"
        />
      </div>
      <div className="transaction-detail">
        <div className="title">บัญชี PIGSPIN</div>
        <Account
          {...transaction.adminBank}
          bankLogoStyles={{ fontSize: 16 }}
          font="blod"
          size="large"
        />
      </div>
      <div className="transaction-detail">
        <div className="title">จำนวนเงิน</div>
        <div className="value">{addCommas(transaction.amount.toFixed(2))}</div>
      </div>
    </div>);
};

export default TransactionInfo;
