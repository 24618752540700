import { Checkbox, Switch, TableColumnsType } from "antd";
import { FilterValue } from "antd/lib/table/interface";
import { UpdateGameRequest } from "src/hooks/useGames";
import { Games, GameStatus } from "src/types";
import { getColumnFilters } from "src/utils/table";
import toString from 'lodash/toString';
import isEmpty from "lodash/isEmpty";

interface GameColumnsTypes {
  games?: Games[];
  filteredInfo: Record<string, FilterValue | null>;
  handleUpdateGame: (params: UpdateGameRequest) => void;
  refType: string;
}

export const GameColumns = ({
  games = [],
  filteredInfo,
  handleUpdateGame,
  refType,
}: GameColumnsTypes): TableColumnsType<Games> => {
  const gameList:any[] = !isEmpty(games) ? games : [];
  return [
    {
      key: "refType",
      title: "ค่ายเกม",
      dataIndex: "refType",
      filterIcon: ()=>null,
      filterDropdown: ()=>null,
      filters: getColumnFilters("refType", gameList as Record<string, any>[]),
      filteredValue: filteredInfo.refType || null,
      filterSearch: true,
      onFilter: (value, record: Games) => {
        return record?.refType === value;
      },
      width: 100,
    },
    {
      key: "gameName",
      title: "ชื่อเกม",
      dataIndex: "gameName",
      filters: getColumnFilters("gameName", gameList.filter(game => game.refType === refType) as Record<string, any>[]),
      filteredValue: filteredInfo.gameName || null,
      filterSearch: true,
      onFilter: (value, record: Games) => {
        return record?.gameName === value;
      },
    },
    {
      key: "gameCode",
      title: "รหัสเกม",
      dataIndex: "gameCode",
      filters: getColumnFilters("gameCode", gameList.filter(game => game.refType === refType).sort((prev, current) => {
        if (prev.gameCode > current.gameCode) return 1;
        if (prev.gameCode < current.gameCode) return -1;
        return 0;}) as Record<string, any>[]),
      filteredValue: filteredInfo.gameCode || null,
      filterSearch: true,
      onFilter: (value, record: Games) => {
        return record?.gameCode === value;
      },
    },
    {
      title: "แบนเนอร์ล้อบบี้",
      dataIndex: "isLobbyBanner",
      key: "isLobbyBanner",
      filters: getColumnFilters("isLobbyBanner", gameList as Record<string, any>[]),
      filteredValue: filteredInfo.isLobbyBanner || null,
      filterSearch: true,
      onFilter: (value, record: Games) => {
        return toString(record?.isLobbyBanner) === toString(value);
      },
      render: (isLobbyBanner, record) => (
        <Checkbox
          checked={isLobbyBanner}
          onChange={(e) => handleUpdateGame({ ...record, isLobbyBanner: e.target.checked })}
        />
      ),
      width: 100,
    },
    {
      title: "การ์ดเกมมาใหม่",
      dataIndex: "isNewCard",
      key: "isNewCard",
      filters: getColumnFilters("isNewCard", gameList as Record<string, any>[]),
      filteredValue: filteredInfo.isNewCard || null,
      filterSearch: true,
      onFilter: (value, record: Games) => {
        return toString(record?.isNewCard) === toString(value);
      },
      render: (isNewCard, record) => (
        <Checkbox
          checked={isNewCard}
          onChange={(e) => handleUpdateGame({ ...record, isNewCard: e.target.checked })}
        />
      ),
      width: 100,
    },
    {
      title: "เกมมาใหม่",
      dataIndex: "isNew",
      key: "isNew",
      filters: getColumnFilters("isNew", gameList as Record<string, any>[]),
      filteredValue: filteredInfo.isNew || null,
      filterSearch: true,
      onFilter: (value, record: Games) => {
        return toString(record?.isNew) === toString(value);
      },
      render: (isNew, record) => (
        <Checkbox
          checked={isNew}
          onChange={(e) => handleUpdateGame({ ...record, isNew: e.target.checked })}
        />
      ),
      width: 100,
    },
    {
      title: "เกมยอดนิยม",
      dataIndex: "isPopular",
      key: "isPopular",
      filters: getColumnFilters("isPopular", gameList as Record<string, any>[]),
      filteredValue: filteredInfo.isPopular || null,
      filterSearch: true,
      onFilter: (value, record: Games) => {
        return toString(record?.isPopular) === toString(value);
      },
      render: (isPopular, record) => (
        <Checkbox
          checked={isPopular}
          onChange={(e) => handleUpdateGame({ ...record, isPopular: e.target.checked })}
        />
      ),
      width: 100,
    },
    {
      title: "การใช้งาน",
      dataIndex: "status",
      key: "status",
      filteredValue: filteredInfo.status || null,
      filters: [
        {
          text: "เปิดการใช้งาน",
          value: GameStatus.active,
        },
        {
          text: "ปิดการใช้งาน",
          value: GameStatus.inactive,
        },
      ],
      onFilter: (value, record: Games) => record?.status === value,
      render: (status, record) => (
        <Switch
          checked={status === GameStatus.active}
          checkedChildren="เปิด"
          unCheckedChildren="ปิด"
          onChange={(checked: boolean) =>
            handleUpdateGame({ ...record, status: checked ? GameStatus.active : GameStatus.inactive })
          }
        />
      ),
    },
  ];
}
