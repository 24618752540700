import { AxiosRequestConfig } from 'axios';
import { BankCode } from 'src/types';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse } from "../service.type";

export type ValidateBankRequest = {
  bankCode: BankCode;
  bankAccountNumber: string;
  bankAccountName: string;
  token: string;
};

export const requestValidateBank = async ({
  bankCode,
  bankAccountNumber,
  bankAccountName,
  token
}: ValidateBankRequest): Promise<BaseResponse|null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/payment/validate',
      method: 'POST',
      data: {
        bank_code: bankCode,
        bank_account_number: bankAccountNumber,
        bank_account_name: bankAccountName,
      },
      headers: { token }
    };

    const result = await fetchWithJSON<BaseResponse>(pigspinBackofficeApiInstance, requestConfig);

    return result;
  }
  catch (error) {
    return null;
  }
};
