import * as React from "react";
import dayjs from "dayjs";
import { Row, Col, TableColumnsType, Form } from "antd";
import isEqual from "react-fast-compare";

import colors from "src/theme/colors.json";
import { BankCode, TrueWalletReportItem, BankRow, BANK_TYPE } from "src/types";

import { getPhoneFormat } from "src/utils/textOperation/textOperation";
import { getColumnFilters } from "src/utils/table";
import { useTrueWalletReport, UseTrueWalletReportFilters } from "src/hooks/useTrueWalletReport";
import { useBank } from "src/hooks/useTransaction/useBank";

import Button from "src/components/Button/Button";
import BankLogo from "src/components/BankLogo";
import PageHeader from "src/components/PageHeader/PageHeader";
import Space from "src/components/Space/Space";
import DeskPanel from "src/components/DeskPanel/DesktPanel";
import { DatePicker, SearchButton, Table } from "src/components";
import { AmountColumn } from "src/components/Table";
import { BankSelect } from "src/components/Bank/BankSelect";

import "./TrueWalletReport.scss";

interface ITrueWalletPageProps {}

const DISPLAY_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
const DEFAULT_ACCOUNT_OPTION: BankRow = {
  bankId: "",
  groupBankId: '',
  bankCode: BankCode.NONE,
  bankName: "",
  isAuto: false,
  promptpay: "",
  status: "Closed",
  type: BANK_TYPE.DEPOSIT,
  accountName: "ทุกบัญชี",
  accountNumber: "",
  bankBalance: 0,
  balance: 0,
};

export const TrueWalletReportPage: React.FunctionComponent<ITrueWalletPageProps> = (_) => {
  const [isDateOpened, setIsDateOpened] = React.useState(false);
  const [fetchFilters, setFetchFilters] = React.useState<UseTrueWalletReportFilters>({
    startTime: dayjs().startOf("day").toDate(),
    endTime: dayjs().endOf("day").toDate(),
  });

  const [form] = Form.useForm();
  const { data, isFetching, refetch } = useTrueWalletReport(fetchFilters);
  const bankState = useBank();

  const { getDepositBankList, depositBankList } = bankState;
  const getDepositTrueWalletBankList = () =>
    [DEFAULT_ACCOUNT_OPTION].concat(
      depositBankList.filter((b) => b.bankCode === BankCode.TrueMoney)
    );
  const getDisplayReportResults = () =>
    data?.results.map((rawReport) => {
      let formattedTrueWalletAccount = rawReport.customerAccountNumber || "";
      if (rawReport.customerAccountNumber?.match(/^\d+$/)) {
        formattedTrueWalletAccount = getPhoneFormat(formattedTrueWalletAccount);
      }
      return {
        ...rawReport,
        createdDateTime: dayjs(rawReport.createdDateTime).format(DISPLAY_DATE_TIME_FORMAT),
        loggingDateTime: dayjs(rawReport.loggingDateTime).format(DISPLAY_DATE_TIME_FORMAT),
        customerAccountNumber: formattedTrueWalletAccount,
        pigAccountNumber: getPhoneFormat(rawReport.pigAccountNumber || ""),
      };
    });

  const depositTrueWalletBankList = React.useMemo(getDepositTrueWalletBankList, [depositBankList]);
  const displayReportResults = React.useMemo(getDisplayReportResults, [data]);

  const renderBankAccountCell = (
    bankAccountName: string | null,
    bankAccountNumber: string | null
  ) => {
    return (
      <Space>
        <Col>
          <BankLogo bankCode={BankCode.TrueMoney} />
        </Col>
        <Col>
          <Row>{bankAccountName}</Row>
          <Row style={{ color: colors["@cloud-7"] }}>{bankAccountNumber}</Row>
        </Col>
      </Space>
    );
  };

  const getAllColumns = (): TableColumnsType<TrueWalletReportItem> => {
    return [
      {
        className: "table-cell",
        title: "วันเวลาทำรายการ",
        dataIndex: "loggingDateTime",
        key: "loggingDateTime",
        filterSearch: true,
        // NOTE : This is example for filtering
        filters: getColumnFilters("loggingDateTime", displayReportResults || []),
        onFilter: (value, row) => row.loggingDateTime?.includes(value.toString()) || false,
      },
      {
        className: "table-cell",
        title: "บัญชีลูกค้า",
        dataIndex: "customerAccountName",
        key: "customerAccountName",
        filterSearch: true,
        filters: getColumnFilters("customerAccountNumber", displayReportResults || []),
        onFilter: (value, row) => row.customerAccountNumber?.includes(value.toString()) || false,
        render: (value, row) => renderBankAccountCell(value, row.customerAccountNumber),
      },
      {
        className: "table-cell",
        title: "จำนวนเงิน (THB)",
        dataIndex: "amount",
        key: "amount",
        render: (value) => <AmountColumn amount={value} />,
      },
      {
        className: "table-cell",
        title: "บัญชี PIGSPIN",
        dataIndex: "pigAccountName",
        key: "pigAccountName",
        render: (value, row) => renderBankAccountCell(value, row.pigAccountNumber),
      },
      {
        className: "table-cell",
        title: "ประเภท",
        dataIndex: "type",
        key: "type",
        render: (value, row) => row.type
      },
      {
        className: "table-cell",
        title: "หัวข้อ",
        dataIndex: "title",
        key: "title",
        render: (value, row) => row.title
      },
      {
        className: "table-cell",
        title: "วันเวลาระบบลงรายการ",
        dataIndex: "createdDateTime",
        key: "createdDateTime",
        filterSearch: true,
        filters: getColumnFilters("createdDateTime", displayReportResults || []),
        onFilter: (value, row) => row.createdDateTime?.includes(value.toString()) || false,
      },
    ];
  };

  const onFinish = (value: any) => {
    const newFetchFilters: UseTrueWalletReportFilters = {
      trueWalletAccount: value?.trueWalletAccount.accountNumber,
      startTime: value?.transferAt.startOf("day").toDate(),
      endTime: value?.transferAt.endOf("day").toDate(),
    };
    setFetchFilters(newFetchFilters);
    if (isEqual(fetchFilters, newFetchFilters)) {
      refetch();
    } else {
      setFetchFilters(newFetchFilters);
    }
  };

  React.useEffect(() => {
    getDepositBankList();
  }, [getDepositBankList]);

  return (
    <div className="TrueWalletReportPage">
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          transferAt: dayjs().startOf("day"),
          trueWalletAccount: undefined,
        }}
      >
        <Row gutter={8} wrap={false} align="bottom">
          <Col flex="auto">
            <Form.Item name="trueWalletAccount" label="บัญชี PIGSPIN">
              <BankSelect bankList={depositTrueWalletBankList} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="transferAt" label="วันที่">
              <DatePicker
                allowClear
                size="large"
                showToday={false}
                value={form.getFieldValue("transferAt")}
                onChange={(date) => form.setFieldsValue({ transferAt: date })}
                onOk={(date) => form.setFieldsValue({ transferAt: date })}
                onBlur={() => setIsDateOpened(false)}
                onFocus={() => setIsDateOpened(true)}
                style={{ width: "180px", height: "44px" }}
                open={isDateOpened}
                renderExtraFooter={(_) => {
                  return (
                    <Row style={{ padding: "10px 0" }}>
                      <Col span={12}>
                        <Button
                          onClick={() => form.setFieldsValue({ transferAt: dayjs() })}
                          style={{ fontWeight: "bold", borderColor: colors["@cloud-6"] }}
                        >
                          วันนี้
                        </Button>
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            setIsDateOpened(false);
                            (document.activeElement as HTMLElement).blur();
                          }}
                        >
                          ตกลง
                        </Button>
                      </Col>
                    </Row>
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item shouldUpdate>
              {() => (
                <SearchButton
                  className="search-btn"
                  size="large"
                  htmlType="submit"
                  disabled={!form.getFieldValue("transferAt") || isFetching}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <DeskPanel
        body={
          <Table
            className="main-table"
            rowKey="reportId"
            dataSource={displayReportResults}
            columns={getAllColumns()}
            loading={isFetching}
            size="small"
            bordered
          />
        }
      />
    </div>
  );
};

export default TrueWalletReportPage;
