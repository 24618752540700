import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  useQueryClient,
} from "react-query";
import { toast } from "src/components";
import { api } from "src/utils";
import { PaymentSetting, PaymentSettingType } from "src/types";


export function useListPaymentSetting<T = PaymentSetting[]>(
  options?: UseQueryOptions<PaymentSetting[], unknown, T, any[]>
) {
  return useQuery(
    ["paymentList"],
    async () => {
      const response = await api.get("payment-setting");
      return response.data?.data;
    },
    options
  );
}

export interface UpdatePaymentSettingRequest {
  uid: string;
  name: string;
  type: PaymentSettingType;
  isActived: boolean;
}

export function useUpdatePaymentSetting(
  options?: UseMutationOptions<any, unknown, UpdatePaymentSettingRequest, unknown>
) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ uid, name, type, isActived }) => {
      return api.put(`payment-setting/${uid}`, {
        name: name,
        type: type,
        is_actived: isActived
      });
    },
    {
      onError: () => {
        toast.error("แก้ไขไม่สำเร็จ");
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["paymentList"]);
        toast.success("แก้ไขสำเร็จ");
      },
      ...options,
    }
  );
}
