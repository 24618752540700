export const addCommas = (number: string): string => {
  const integer = number.split('.')[0] || '';
  const decimal = number.split('.')[1] || '';
  const formatInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if(decimal) {
    return `${formatInteger}.${decimal}`;
  }
  return formatInteger;
};

export const removeCommas = (number: string): string => {
  return number.replace(/,/g, '');
};

export const numberWithCommas = (number: number, fractionDigits?: number): string => {
  let numStr = number.toString();
  if (fractionDigits != null && fractionDigits > 0) {
    numStr = parseFloat(numStr).toFixed(fractionDigits);
  }
  const [numVal, decimalVal] = numStr.split('.');
  if (decimalVal) {
    return `${addCommas(numVal)}.${decimalVal}`;
  }
  return addCommas(numStr);
};


export const numberWithoutCommas = (number: number | string): number => {
  const result = parseFloat(removeCommas(number.toString()));
  return result || 0;
};
