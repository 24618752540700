import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ColumnType, toast } from 'src/components';
import Button from 'src/components/Button/Button';
import Icon from 'src/components/Icon/Icon';
import { COOKIES_KEY, useCookies } from 'src/hooks/useCookies';
import { authInfoState, modalState } from 'src/recoils';
import DeskPanel from '../../components/DeskPanel/DesktPanel';
import PageHeader from '../../components/PageHeader/PageHeader';
import RadioButton from '../../components/RadioButton/RadioButton';
import Table from '../../components/Table';
import { BANK_TAB_TYPE, useBank } from '../../hooks/useTransaction/useBank';
import { BankRow, BankCode } from 'src/types';
import AddBankFormComponent from 'src/components/AddBankForm/AddBankForm';
import EditBankForm from 'src/components/EditBankForm/EditBankForm';
import { EditForm, useBankEdit } from 'src/hooks/useBankEdit';
import { AddButton, Tabs } from 'src/components';
import { BankColumns } from 'src/components/TableColumns/BankColumn';

interface IBankPageProps {
}


export const SELECTED_TAP: Record<BANK_TAB_TYPE, BANK_TAB_TYPE> = {
  [BANK_TAB_TYPE.DEPOSIT_ALL]: BANK_TAB_TYPE.DEPOSIT_ALL,
  [BANK_TAB_TYPE.DEPOSIT_BANK]: BANK_TAB_TYPE.DEPOSIT_ALL,
  [BANK_TAB_TYPE.DEPOSIT_TRUE_MONEY]: BANK_TAB_TYPE.DEPOSIT_ALL,
  [BANK_TAB_TYPE.WITHDRAW_ALL]: BANK_TAB_TYPE.WITHDRAW_ALL,
  [BANK_TAB_TYPE.WITHDRAW_BANK]: BANK_TAB_TYPE.WITHDRAW_ALL,
  [BANK_TAB_TYPE.WITHDRAW_TRUE_MONEY]: BANK_TAB_TYPE.WITHDRAW_ALL,
  [BANK_TAB_TYPE.INACTIVE_WITHDRAW_ALL]: BANK_TAB_TYPE.INACTIVE_DEPOSIT_ALL,
  [BANK_TAB_TYPE.INACTIVE_DEPOSIT_ALL]: BANK_TAB_TYPE.INACTIVE_DEPOSIT_ALL,
}

export const BankPage: React.FunctionComponent<IBankPageProps> = (props) => {
  const [globalModal, setModal] = useRecoilState(modalState);
  const [, setCookies] = useCookies([]);
  const [editingBank, setEditingBank] = React.useState<BankRow>();
  const { scope } = useRecoilValue(authInfoState);

  const bankState = useBank();
  const editBankState = useBankEdit();
  const {
    depositBankList,
    withdrawBankList,
    inActiveDepositBankList,
    inActivewithdrawBankList,
    getWithdrawBankList,
    getDepositBankList,
    setIsOpenAddingBank,
    bankSelectedTab,
    setBankSelectedTab,
  } = bankState;

  React.useEffect(() => {
    getWithdrawBankList();
  }, []);

  const getBaseTableProps = (bankList: BankRow[]) => {
    return {
      hideOnSinglePage: true,
      isEmpty: bankList.length < 1,
      dataSource: bankList || [],
      totalItem: bankList ? bankList.length : 0,
      limit: 100,
      offset: 0,
      onPaginationChange: (offset: number, limit: number) => {
      }
    }
  };

  const doCallEditBank = (form: EditForm, successText?: string, errorText?: string) => {
    editBankState.editBank(form, {
      onSuccess: () => {
        toast.success(successText || 'แก้ไขสำเร็จ');
        getDepositBankList();
        getWithdrawBankList();
        setEditingBank(undefined);
      },
      onError: () => {
        toast.error(errorText || 'แก้ไขไม่สำเร็จ');
      },
    });
  }

  const getAllColumn = (): Record<any, ColumnType<BankRow>> => {
    return {
      ...BankColumns(),
      editBankAction: {
        title: '',
        dataIndex: 'status',
        key: 'edit',
        fixed: 'right',
        width: 50,
        render: (value: number, row: BankRow) => {
          return (
            <div style={{ textAlign: 'center' }}>
              <Button
                type='lightdark'
                style={{
                  padding: 0, width: 24, height: 24,
                }}
                onClick={(e) => {
                  setEditingBank(row)
                }}
              ><Icon name="outline-edit-1" />
              </Button>
            </div>
          )
        },
      },
    }
  }

  const getTableColumn = (): any => {
    const {
      bank,
      promptpay,
      accountName,
      accountNumber,
      bankBalance,
      status,
      autoTransaction,
      editBankAction,
      limitAutoPerTransaction,
      remark,
      autoWebTransaction,
    } = getAllColumn();

    const withEditAction = (columns: any[]): any[] => {
      if (scope['EDIT-BANK']?.enable) return [...columns, editBankAction];
      return columns;
    }

    switch (bankSelectedTab) {
      case BANK_TAB_TYPE.DEPOSIT_ALL:
      case BANK_TAB_TYPE.DEPOSIT_BANK:
      case BANK_TAB_TYPE.DEPOSIT_TRUE_MONEY:
        return withEditAction([
          bank,
          accountName,
          accountNumber,
          bankBalance,
          promptpay,
          status,
          autoTransaction,
        ]);
      case BANK_TAB_TYPE.WITHDRAW_ALL:
      case BANK_TAB_TYPE.WITHDRAW_BANK:
      case BANK_TAB_TYPE.WITHDRAW_TRUE_MONEY:
        return withEditAction([
          bank,
          accountName,
          accountNumber,
          bankBalance,
          limitAutoPerTransaction,
          status,
          {
            title: 'ระบบอัตโนมัติ',
            children: [
              { ...autoTransaction, title: 'ระบบหลังบ้าน' },
              { ...autoWebTransaction, title: 'หน้าเว็บไซต์' },
            ],
          },
        ]);
      case BANK_TAB_TYPE.INACTIVE_DEPOSIT_ALL:
        return [
          bank,
          accountName,
          accountNumber,
          promptpay,
          remark,
        ]
      case BANK_TAB_TYPE.INACTIVE_WITHDRAW_ALL:
        return [
          bank,
          accountName,
          accountNumber,
          promptpay,
          bankBalance,
          remark,
        ]
      default:
        return [
          bank,
          accountName,
          accountNumber,
        ];
    }
  };

  const onTabChange = (tab: string) => {
    setBankSelectedTab(tab as BANK_TAB_TYPE)
    setCookies(COOKIES_KEY.BANK_SELECTED_TAB, tab)
  };

  const isTrueMoney = (_bank: BankRow): boolean => {
    return _bank.bankCode === BankCode.TrueMoney;
  }

  const isNotTrueMoney = (_bank: BankRow): boolean => {
    return !isTrueMoney(_bank);
  }

  const getSelectedBankList = (_bankSelectedTab: BANK_TAB_TYPE): BankRow[] => {
    switch (_bankSelectedTab) {
      case BANK_TAB_TYPE.DEPOSIT_ALL:
        return depositBankList;
      case BANK_TAB_TYPE.DEPOSIT_BANK:
        return depositBankList.filter(isNotTrueMoney);
      case BANK_TAB_TYPE.DEPOSIT_TRUE_MONEY:
        return depositBankList.filter(isTrueMoney);
      case BANK_TAB_TYPE.WITHDRAW_ALL:
        return withdrawBankList;
      case BANK_TAB_TYPE.WITHDRAW_BANK:
        return withdrawBankList.filter(isNotTrueMoney);
      case BANK_TAB_TYPE.WITHDRAW_TRUE_MONEY:
        return withdrawBankList.filter(isTrueMoney);
      case BANK_TAB_TYPE.INACTIVE_DEPOSIT_ALL:
        return inActiveDepositBankList;
      case BANK_TAB_TYPE.INACTIVE_WITHDRAW_ALL:
        return inActivewithdrawBankList;
      default:
        return [];
    }
  }

  const radioButtons = React.useMemo(() => {
    switch (bankSelectedTab) {
      case BANK_TAB_TYPE.DEPOSIT_ALL:
      case BANK_TAB_TYPE.DEPOSIT_BANK:
      case BANK_TAB_TYPE.DEPOSIT_TRUE_MONEY:
        return [
          { text: `ทั้งหมด (${getSelectedBankList(BANK_TAB_TYPE.DEPOSIT_ALL).length})`, value: BANK_TAB_TYPE.DEPOSIT_ALL },
          { text: `ธนาคาร (${getSelectedBankList(BANK_TAB_TYPE.DEPOSIT_BANK).length})`, value: BANK_TAB_TYPE.DEPOSIT_BANK },
          { text: `ทรูมันนี่ (${getSelectedBankList(BANK_TAB_TYPE.DEPOSIT_TRUE_MONEY).length})`, value: BANK_TAB_TYPE.DEPOSIT_TRUE_MONEY },
        ];
      case BANK_TAB_TYPE.WITHDRAW_ALL:
      case BANK_TAB_TYPE.WITHDRAW_BANK:
      case BANK_TAB_TYPE.WITHDRAW_TRUE_MONEY:
        return [
          { text: `ทั้งหมด (${getSelectedBankList(BANK_TAB_TYPE.WITHDRAW_ALL).length})`, value: BANK_TAB_TYPE.WITHDRAW_ALL },
          { text: `ธนาคาร (${getSelectedBankList(BANK_TAB_TYPE.WITHDRAW_BANK).length})`, value: BANK_TAB_TYPE.WITHDRAW_BANK },
          { text: `ทรูมันนี่ (${getSelectedBankList(BANK_TAB_TYPE.WITHDRAW_TRUE_MONEY).length})`, value: BANK_TAB_TYPE.WITHDRAW_TRUE_MONEY },
        ];
      case BANK_TAB_TYPE.INACTIVE_DEPOSIT_ALL:
      case BANK_TAB_TYPE.INACTIVE_WITHDRAW_ALL:
        return [
          { text: `บัญชีรับเงิน (${getSelectedBankList(BANK_TAB_TYPE.INACTIVE_DEPOSIT_ALL).length})`, value: BANK_TAB_TYPE.INACTIVE_DEPOSIT_ALL },
          { text: `บัญชีโอนเงิน (${getSelectedBankList(BANK_TAB_TYPE.INACTIVE_WITHDRAW_ALL).length})`, value: BANK_TAB_TYPE.INACTIVE_WITHDRAW_ALL },
        ];
      default:
        return [];
    }

  }, [bankSelectedTab])

  return (
    <div className="BankPage">
      <PageHeader
        title={(
          <Tabs defaultActiveKey={SELECTED_TAP[bankSelectedTab]} onChange={onTabChange} >
            <Tabs.TabPane tab="บัญชีรับเงิน" key={BANK_TAB_TYPE.DEPOSIT_ALL} />
            <Tabs.TabPane tab="บัญชีโอนเงิน" key={BANK_TAB_TYPE.WITHDRAW_ALL} />
            <Tabs.TabPane tab="บัญชีถูกปิด" key={BANK_TAB_TYPE.INACTIVE_DEPOSIT_ALL} />
          </Tabs>
        )}
        extra={scope['ADD-BANK']?.enable && (
          <AddButton key="add" onClick={() => setIsOpenAddingBank(true)}>
            เพิ่มบัญชี
          </AddButton>
        )}
      />
      <DeskPanel
        header={(
          <>
            <RadioButton
              value={bankSelectedTab}
              options={radioButtons}
              onChange={(e) => {
                onTabChange(e.target.value);
              }}
            />
          </>
        )}
        body={(
          <Table
            {...getBaseTableProps(getSelectedBankList(bankSelectedTab))}
            columns={getTableColumn()}
          />
        )}
      />
      <AddBankFormComponent
        bankState={bankState}
      />
      <EditBankForm
        editBankState={editBankState}
        editingBank={editingBank}
        onCancel={() => { setEditingBank(undefined) }}
        onEditConfirm={doCallEditBank}
      />
    </div>
  );
};

export default BankPage;
