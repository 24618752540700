import * as React from 'react';
import Space from '../Space/Space';
import './DeskPanel.scss';

interface IDeskPanelProps {
  header?: JSX.Element;
  body?: JSX.Element;
  style?: React.CSSProperties;
}

const DeskPanel: React.FunctionComponent<IDeskPanelProps> = (props) => {
  return (
    <div className='desk-panel' style={props.style} >
      <Space className="desk-panel-header">
        {props.header}
      </Space>
      <div className='desk-panel-body'>
        {props.body}
      </div>
    </div>
  );
};

export default DeskPanel;
