import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal, Form, Input, Row, Col, Typography } from "antd";
import { UpdateQuest, DailyQuestEditForm, DailyQuestUpdateRequest } from "src/hooks";
import * as React from 'react';
import './QuestManagement.less'
import { QuestLevel, QuestType } from "src/types";

export interface QuestEditValue {
  questUid: string;
  questName: string;
  gameName: string;
  gameRefType: string;
  questTypeEdit: QuestType;
  questLevel: QuestLevel;
  reward: string;
  rules: string;
}

export type DailyQuestEditProps = {
  quest: QuestEditValue;
  onClose?: () => void;
  onSuccess?: () => void;
};

export const DailyQuestEdit = NiceModal.create(({quest, ...props}: DailyQuestEditProps) => {
  const { visible, hide, remove } = useModal();

  const handleClose = () => {
    hide();
    props.onClose?.();
  };

  const [form] = Form.useForm<DailyQuestEditForm>();
  const { mutate: update } = UpdateQuest();

  const questType = quest.questTypeEdit;

  const initValue = () => {
    if(quest.questTypeEdit === QuestType.ANY) {
      const rules = JSON.parse(quest.rules)
      form.setFieldsValue({ 
        questValue: parseInt(rules.turnover), 
        questTicket: parseInt(quest.reward) 
      });
    } else if (quest.questTypeEdit === QuestType.LOGIN){
      form.setFieldsValue({ 
        questTicket: parseInt(quest.reward) 
      });
    }
  };

  const handleFinish = (values: DailyQuestEditForm) => {
    const questForm: DailyQuestUpdateRequest = {
      questUid: quest.questUid,
      questType: questType,
      value: values.questValue,
      ticket: values.questTicket
    };

    update(questForm, { onSuccess: handleClose })
  };
  
  React.useEffect(() => {
    initValue()
  }, []);

  return (
    <Modal
        centered
        className="ticket-import-modal-form"
        width={600}
        visible={visible}
        afterClose={remove}
        focusTriggerAfterClose={false}
        closable={false}
        title="แก้ไข Quest"
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
            <Button key="cancel" size="large" onClick={handleClose} style={{ width: "100px" }}>
              ยกเลิก
            </Button>
            <Button
              key="submit"
              type="primary"
              size="large" 
              onClick={() => form.submit()}
              style={{ width: "160px" }}
            >
              ยืนยัน
            </Button>
          </div>
        }
      >
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={true}
            preserve={false}
            style={{ overflow: "auto" }}
            onFinish={handleFinish}
          >
            <Form.Item label="ประเภท">
              <Input value={questType} disabled></Input>
            </Form.Item>
            {quest.questTypeEdit === "any" && (
              <div>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item label="ค่ายเกมส์">
                      <Input value={quest.gameRefType} disabled></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="เกมส์">
                      <Input value={quest.gameName} disabled></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Typography.Title level={5}>{quest.questLevel}</Typography.Title>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item label="ยอด turnover" name="questValue" required
                      rules={[{ required: true, message: 'โปรดระบุยอด turnover' }]}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="รางวัล (Ticket)" name="questTicket" required
                      rules={[{ required: true, message: 'โปรดระบุรางวัล (Ticket)' }]}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
            {quest.questTypeEdit === "login" && (
              <div>
              <Typography.Title level={5}>{quest.questName}</Typography.Title>
              <Typography.Title level={5}>{quest.questLevel}</Typography.Title>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item label="รางวัล (Ticket)" name="questTicket" required>
                    <Input></Input>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            )}
          </Form>
      </Modal>
  );
});

export default DailyQuestEdit;
