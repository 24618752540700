import * as React from 'react';
import IconNotFound from '../../assets/images/no-data.svg'

interface IUserInfoNotFoundProps {
}

const UserInfoNotFound: React.FunctionComponent<IUserInfoNotFoundProps> = (props) => {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "56px",
      }}
    >
      <img src={IconNotFound} alt="Not found" />
      <div style={{ marginTop: "16px", fontSize: "14px", fontWeight: 400, color: "#85949E", lineHeight: "20px" }}>ไม่พบรายการ</div>
    </div>
  );
};

export default UserInfoNotFound;
