import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal, Form, Input, Row, Col, Typography } from "antd";
import { UpdateWeeklyQuest, WeeklyQuestEditForm } from "src/hooks";
import * as React from 'react';
import './QuestManagement.less'

export interface QuestEditValue {
  uid: string;
  questId: string;
  questName: string;
  gameName: string;
  gameId: string;
  gameRefType: string;
  reward: string;
  questRules: string;
}

export type WeeklyQuestEditProps = {
  quest: QuestEditValue;
  onClose?: () => void;
  onSuccess?: () => void;
};

export const WeeklyQuestEdit = NiceModal.create(({quest, ...props}: WeeklyQuestEditProps) => {
  const { visible, hide, remove } = useModal();

  const handleClose = () => {
    hide();
    props.onClose?.();
  };

  const [form] = Form.useForm<WeeklyQuestEditForm>();
  const { mutate: update } = UpdateWeeklyQuest();

  const initValue = () => {
    const rules = JSON.parse(quest.questRules)
    form.setFieldsValue({ 
      value: parseInt(rules.earn), 
      ticket: parseInt(quest.reward) 
    });
  };

  const handleFinish = (values: WeeklyQuestEditForm) => {
    const questForm: WeeklyQuestEditForm = {
      questUid: quest.uid,
      gameRefType: quest.gameRefType,
      gameId: quest.gameId,
      value: values.value,
      ticket: values.ticket
    };

    update(questForm, { onSuccess: handleClose })
  };
  
  React.useEffect(() => {
    initValue()
  }, []);

  return (
    <Modal
        centered
        className="ticket-import-modal-form"
        width={600}
        visible={visible}
        afterClose={remove}
        focusTriggerAfterClose={false}
        closable={false}
        title="แก้ไข Weekly Quest"
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
            <Button key="cancel" size="large" onClick={handleClose} style={{ width: "100px" }}>
              ยกเลิก
            </Button>
            <Button
              key="submit"
              type="primary"
              size="large" 
              onClick={() => form.submit()}
              style={{ width: "160px" }}
            >
              ยืนยัน
            </Button>
          </div>
        }
      >
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={true}
            preserve={false}
            style={{ overflow: "auto" }}
            onFinish={handleFinish}
          >
            <div>
              <Form.Item label="ค่ายเกมส์">
                <Input value={quest.gameRefType} disabled></Input>
              </Form.Item>
              <Form.Item label="เกมส์">
                <Input value={quest.gameName} disabled></Input>
              </Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item label="ยอด earn" name="value" required rules={[{ required: true, message: 'โปรดระบุยอด earn' }]}>
                    <Input></Input>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
      </Modal>
  );
});

export default WeeklyQuestEdit;
