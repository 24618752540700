import { Spin, SpinProps, Table as AntTable, TableProps as AntTableProps } from "antd";
import { ColumnType as AntColumnType } from "antd/es/table";
import classnames from "classnames";
import { Empty } from "src/components";
import { NoData1, NoData2 } from "src/icons";
import "./Table.less";

export type ColumnType<T> = AntColumnType<T> & { exclude?: boolean };

export interface TableProps<RecordType extends object = any>
  extends Omit<AntTableProps<RecordType>, "columns"> {
  columns: ColumnType<RecordType>[];
  keepShowTable?: boolean;
}

export interface PaginationTypes {
  limit: number;
  current: number;
}

export function Table({ pagination, style, columns, keepShowTable = false, ...props }: TableProps) {
  let spinProps: SpinProps | undefined;
  if (typeof props.loading === "boolean") {
    spinProps = { spinning: props.loading };
  } else if (typeof props.loading === "object") {
    spinProps = { spinning: true, ...props.loading };
  }
  if (!props.dataSource?.length && !keepShowTable) {
    return (
      <Spin {...spinProps}>
        {!props.dataSource ? (
          <Empty
            style={{ height: spinProps?.style?.maxHeight }}
            image={<NoData1 style={{ fontSize: "100px" }} />}
            description="ยังไม่มีรายการ"
          />
        ) : (
          <Empty
            style={{ height: spinProps?.style?.maxHeight }}
            image={<NoData2 style={{ fontSize: "100px" }} />}
            description="ไม่พบรายการ"
          />
        )}
      </Spin>
    );
  }


  const table = (
    <AntTable
      sticky
      loading={props.loading}
      scroll={{ x: "max-content" }}
      columns={columns?.filter((column) => !column.exclude)}
      pagination={{
        size: 'small',
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `รายการทั้งหมด ${total}`,
        ...pagination,
      }}
      {...props}
      style={{ width: "100%", ...style }}
      className={classnames(props.className, 'backoffice-table')}
    />
  )
  if (keepShowTable) {
    return (
      <Spin {...spinProps}>
        {table}
      </Spin>
    );
  }
  return table;

}

Table.Summary = AntTable.Summary;

export * from "./Column";
export * from "./ColumnRenderers";
export { default, SELECTION_COLUMN } from "./Table";
