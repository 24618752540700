import { Table } from "src/components";
import { PaymentSettingColumns } from "src/components/TableColumns/PaymentSettingColumn";
import DeskPanel from "src/components/DeskPanel/DesktPanel";
import { UpdatePaymentSettingRequest, useListPaymentSetting, useUpdatePaymentSetting } from "src/hooks/usePaymentSetting";
import { useState } from "react";
import { TableProps } from "antd/es/table";
import { PaymentSetting } from "src/types";
import { FilterValue } from "antd/lib/table/interface";
import "./PaymentSetting.scss";

interface IPaymentPageProps {}

export const PaymentSettingPage: React.FunctionComponent<IPaymentPageProps> = () => {
  const { data: paymentList, isLoading } = useListPaymentSetting();
  const { mutate: updatePaymentSetting, isLoading: isUpdating } = useUpdatePaymentSetting();
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>();
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});

  const handleChange: TableProps<PaymentSetting>["onChange"] = (_, filters) => {
    setFilteredInfo(filters);
  };

  const handleUpdatePaymentSetting = (params: UpdatePaymentSettingRequest) => {
    updatePaymentSetting(params);
  };

  return (
    <div className="game-page">
      <DeskPanel
        body={
          <Table
            columns={PaymentSettingColumns({ paymentList, filteredInfo, handleUpdatePaymentSetting })}
            dataSource={paymentList}
            onChange={handleChange}
            loading={isLoading || isUpdating}
          />
        }
      />
    </div>
  );
};

export default PaymentSettingPage;
