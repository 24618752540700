import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom'
import { Space } from 'antd';
import { useJwt } from "react-jwt";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { getUserList, UserInfo } from '../../services/userList';
import { authState } from "../../recoils";
import ActiveLogo from '../../assets/logo/active.svg';
import InactiveLogo from '../../assets/logo/inactive.svg';
import TerminateLogo from '../../assets/logo/terminate.svg';
import EditUserLogo from '../../assets/logo/edit.svg';
import { AddAdminUserModal } from "../../components/Modals";
import { EditAdminUserModal } from "../../components/Modals";

import PageHeader from 'src/components/PageHeader/PageHeader';
import Icon from 'src/components/Icon/Icon';
import Button from 'src/components/Button/Button';
import DeskPanel from 'src/components/DeskPanel/DesktPanel';
import RadioButton from 'src/components/RadioButton/RadioButton';
import { Table } from 'src/components/Table';
import { ColumnsType, ColumnType } from 'antd/lib/table';


type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'TERMINATE';

const statusSrc = {
  "ACTIVE": ActiveLogo,
  "INACTIVE": InactiveLogo,
  "TERMINATE": TerminateLogo,
};

const statusContent = {
  "ACTIVE": "เปิดใช้งาน",
  "INACTIVE": "หยุด/พักงาน",
  "TERMINATE": "พ้นสภาพพนักงาน",
};

const renderStatusIcon = (status: StatusEnum) => (
  <img
    className="status-image"
    src={statusSrc[status]}
    height="18"
    width="18"
    alt={status}
  />
);

const renderStatus = (value: StatusEnum, row: any, index: any) => ({
  children: <span>{renderStatusIcon(value)}{statusContent[value]}</span>,
  props: {},
});

type UserDashBoard = UserInfo & {
  key: number;
}

type DecodedToken = {
  created_at: string;
  scope_list: string[];
  user_uid: string;
}

export const Users = () => {
  const defaultUserList: UserDashBoard[] = [];
  const defaultUserData: UserInfo = {
    uid: "",
    updated_at: "",
    created_at: "",
    name: "",
    email: "",
    role: "",
    status: "",
    personal_number: "",
  };
  const [userData, setUserData] = useState(defaultUserData)
  const [userList, setUserList] = useState(defaultUserList);
  const [selectedFilter, setSelectedFilter] = useState('ALL');
  const [isRenderPage, setIsRenderPage] = useState(true);
  const [userListDashBoard, setUserListDashBoard] = useState(defaultUserList);
  const [openAddAdminUserModal, setOpenAddAdminUserModal] = useState(false)
  const [openEditAdminUserModal, setOpenEditAdminUserModal] = useState(false)
  const { token = '' } = useRecoilValue(authState);
  const { decodedToken } = useJwt(token);

  const renderEditButton = (id: string, record: UserDashBoard) => {
    const tokenData = decodedToken as DecodedToken;
    const scopeList = tokenData.scope_list;
    const currentButtonScope = `EDIT-USER.${record.role}`;

    const havePermission = scopeList.includes(currentButtonScope);
    const editIcon = (
      <img
        src={EditUserLogo}
        height="14"
        width="14"
        alt="search"
      />
    )

    return ({
      children: (
        <Button
          type="lightdark"
          className="button--edit-user"
          icon={editIcon}
          disabled={!havePermission}
          onClick={() => { handleOpenEditAdminUserModal(record) }}
        >แก้ไข</Button>
      ),
      props: {},
    });
  }

  const withFilter = (column: ColumnType<UserDashBoard>, dataIndex: keyof UserDashBoard): ColumnType<UserDashBoard> => ({
    ...column,
    dataIndex: dataIndex,
    key: column.key || dataIndex,
    filters: userListDashBoard.map(user => ({ text: user[dataIndex], value: user[dataIndex] })).filter((item, index, self) => {
      return index === self.findIndex(o => o.value === item.value);
    }),
    filterSearch: true,
    onFilter: (value: any, record: UserDashBoard) => {
      return `${record[dataIndex]}`?.startsWith(`${value}`)
    },
  });


  const columns: ColumnsType<UserDashBoard> = [{
    title: 'รหัสพนักงาน',
    dataIndex: 'personal_number',
    key: 'personal_number',
  }, withFilter({
    title: 'ชื่อ-นามสกุล',
  }, 'name'), {
    title: 'ตำแหน่ง',
    dataIndex: 'role',
    key: 'role',
  }, withFilter({
    title: 'อีเมล',
  }, 'email'), {
    title: 'สถานะ',
    dataIndex: 'status',
    key: 'status',
    render: renderStatus,
  }, {
    title: '',
    dataIndex: 'uid',
    key: 'uid',
    render: (id: string, record: UserDashBoard) => renderEditButton(id, record)
  }];

  const handleStatusFilter = (status: string) => {
    setSelectedFilter(status);
  }

  useEffect(() => {
    const userListData: UserDashBoard[] = [...userList];
    const filteredUserList = selectedFilter === 'ALL' ? userList : userListData.filter((user, index) => {
      if (user.status === selectedFilter) {
        return {
          ...user,
          key: index
        }
      }
      return null;
    });
    setUserListDashBoard(filteredUserList);
  }, [selectedFilter, userList])

  const countUserStatus = (userList: UserInfo[], status: string) => {
    return status === 'ALL' ?
      userList.length :
      userList.filter(user => user.status === status).length;
  };

  const refreshUserDashBoard = () => {
    getUserList(token).then(data => {
      const defaultUserList = data?.results || [];
      const userListWithKey: UserDashBoard[] = defaultUserList.map((user, index) => {
        const mapperUser: UserDashBoard = { ...user, key: index };
        return mapperUser;
      });
      setUserList(userListWithKey);
      setUserListDashBoard(userListWithKey);
    });
  }

  useEffect(() => {
    getUserList(token).then(data => {
      const defaultUserList = data?.results || [];
      const userListWithKey: UserDashBoard[] = defaultUserList.map((user, index) => {
        const mapperUser: UserDashBoard = { ...user, key: index };
        return mapperUser;
      });
      setUserList(userListWithKey);
      setUserListDashBoard(userListWithKey);
    });
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      const tokenData = decodedToken as DecodedToken;
      const scopeList = tokenData.scope_list;

      if (!scopeList.includes("MENU.USER-MANAGEMENT")) {
        setIsRenderPage(false)
      }
    }
  }, [decodedToken])

  const handleOpenAddAdminUserModal = () => {
    setOpenAddAdminUserModal(true)
  }

  const handleCloseAddAdminUserModal = () => {
    setOpenAddAdminUserModal(false)
  }

  const handleConfirmAddAdminUserModal = (addAdminStatus: boolean) => {
    setOpenAddAdminUserModal(false)
    if (addAdminStatus) {
      toast.success("เพิ่มพนักงานสำเร็จ")
      refreshUserDashBoard()
    } else {
      toast.warning("เพิ่มพนักงานไม่สำเร็จ")
    }
  }

  const handleOpenEditAdminUserModal = (record: UserDashBoard) => {
    setOpenEditAdminUserModal(true)
    setUserData(record)
  }

  const handleCloseEditAdminUserModal = () => {
    setOpenEditAdminUserModal(false)
    setUserData(defaultUserData)
  }

  const handleConfirmEditAdminUserModal = (editAdminStatus: boolean) => {
    setOpenEditAdminUserModal(false)
    setUserData(defaultUserData)
    if (editAdminStatus) {
      toast.success("แก้ไขพนักงานสำเร็จ")
      refreshUserDashBoard()
    } else {
      toast.warning("แก้ไขพนักงานไม่สำเร็จ")
    }
  }

  if (!isRenderPage) {
    return <Navigate to="/" />
  }
  return (
    <div id="AdminPage">
      <PageHeader
        title=" "
        extra={(
          <Button type='primary' size='large' style={{ float: 'right', paddingLeft: '1rem', paddingRight: '1rem' }} onClick={() => { handleOpenAddAdminUserModal() }}>
            <Space>
              <Icon name='outline-add-1' size="18px" />
              เพิ่มพนักงาน
            </Space>
          </Button>
        )}
      >

      </PageHeader>
      <DeskPanel
        header={(
          <RadioButton
            value={selectedFilter}
            options={[
              { text: `พนักงานทั้งหมด (${countUserStatus(userList, 'ALL')})`, value: 'ALL' },
              { text: `เปิดใช้งาน (${countUserStatus(userList, 'ACTIVE')})`, value: 'ACTIVE' },
              { text: `หยุด/พักงาน (${countUserStatus(userList, 'INACTIVE')})`, value: 'INACTIVE' },
              { text: `พ้นสภาพพนักงาน (${countUserStatus(userList, 'TERMINATE')})`, value: 'TERMINATE' },
            ]}
            onChange={(e) => {
              handleStatusFilter(e.target.value)
            }}
          />
        )}
        body={(
          <Table
            columns={columns}
            dataSource={userListDashBoard || []}
            scroll={undefined}
          />
        )}
      />
      <AddAdminUserModal
        open={openAddAdminUserModal}
        onClose={handleCloseAddAdminUserModal}
        onConfirm={(value: boolean) => { handleConfirmAddAdminUserModal(value) }}
      />
      <EditAdminUserModal
        open={openEditAdminUserModal}
        onClose={handleCloseEditAdminUserModal}
        onConfirm={(value: boolean) => { handleConfirmEditAdminUserModal(value) }}
        adminUserData={userData}
      />
    </div>
  );
};
