import { Checkbox, Switch, TableColumnsType } from "antd";
import { FilterValue } from "antd/lib/table/interface";
import { UpdatePaymentSettingRequest } from "src/hooks/usePaymentSetting";
import { PaymentSetting } from "src/types";
import { getColumnFilters } from "src/utils/table";

interface PaymentSettingColumnsTypes {
  paymentList?: PaymentSetting[];
  filteredInfo: Record<string, FilterValue | null>;
  handleUpdatePaymentSetting: (params: UpdatePaymentSettingRequest) => void;
}

export const PaymentSettingColumns = ({
  paymentList = [],
  filteredInfo,
  handleUpdatePaymentSetting,
}: PaymentSettingColumnsTypes): TableColumnsType<PaymentSetting> => [
  {
    key: "name",
    title: "ระบบชำระเงิน",
    dataIndex: "name",
    filters: getColumnFilters("name", paymentList as Record<string, any>[]),
    filteredValue: filteredInfo.name || null,
    filterSearch: true,
    onFilter: (value, record: PaymentSetting) => {
      return record?.name === value;
    },
  },
  {
    title: "เปิด/ปิด",
    dataIndex: "isActived",
    key: "isActived",
    filteredValue: filteredInfo.isActived || null,
    filters: [
      {
        text: "เปิดการใช้งาน",
        value: true,
      },
      {
        text: "ปิดการใช้งาน",
        value: false,
      },
    ],
    onFilter: (value, record: PaymentSetting) => record?.isActived === value,
    render: (isActived, record) => (
      <Switch
        checked={isActived === true}
        checkedChildren="เปิด"
        unCheckedChildren="ปิด"
        onChange={(checked: boolean) =>
          handleUpdatePaymentSetting({ ...record, isActived: checked ? true : false })
        }
      />
    ),
  },
];
