import { Space } from 'antd';
import * as React from 'react';

export type BannerProps = {
  color: string;
  background: string;
  content: JSX.Element;
  style?: React.CSSProperties;
}

const Banner: React.FunctionComponent<BannerProps> = (props) => {
  return (
    <div style={{
      textAlign: 'center',
      background: props.background,
      color: props.color,
      fontSize: 16,
      fontWeight: 700,
      padding: '16px',
      paddingTop: '20px',
      borderRadius: '4px',
      marginTop: '1rem',
      ...props.style,
    }}>
      <Space style={{ lineHeight: 1 }} >
        {props.content}
      </Space>
    </div>
  );
};

export default Banner;
