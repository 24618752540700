import { atom } from "recoil";
import { KEYS } from "../keys";

export type AffiliateStateType = {
  isAffiliateMode: boolean;
};

const defaultState = {
  isAffiliateMode: false,
};

const affiliateState = atom({
  key: KEYS.AFFILIATE,
  default: defaultState,
});

export default affiliateState;