import { Tag } from 'antd';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { affiliateStateAtom } from 'src/recoils';
import colors from 'src/theme/colors.json';

interface ITransferTagProps {
}

const TransferTag: React.FunctionComponent<ITransferTagProps> = () => {
  const [affiliateState] = useRecoilState(affiliateStateAtom);
  return <Tag style={{ padding: '6px 16px', fontSize: '14px' }} color={colors['purple-6']}>{affiliateState.isAffiliateMode ? 'แนะนำเพื่อน' : 'ทั่วไป'}</Tag>;
};

export default TransferTag;
