import { useQuery, UseQueryOptions } from "react-query";
import { CoinWalletLog, Pagination } from "src/types";
import { api } from "src/utils";

export function useCoinWalletLogs<T = Pagination<CoinWalletLog>>(
  uid: string,
  options?: UseQueryOptions<Pagination<CoinWalletLog>, unknown, T, any[]>
) {
  return useQuery(
    ["coin-wallets", uid, "logs"],
    async () => {
      const response = await api.get(`payment/coin-wallets/${uid}/logs`);
      return response.data.data;
    },
    options
  );
}
