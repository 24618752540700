import React from 'react';
import { Menu as AntdMenu, MenuItemProps, MenuProps } from 'antd';

export const Menu: React.FC<MenuProps> = (props) => {
  return (
    <AntdMenu
      {...props}
      className="backoffice-menu"
      style={{
        padding: '8px',
      }}
    >
      {props.children}
    </AntdMenu>
  )
}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  return (
    <AntdMenu.Item
      {...props}
      className="backoffice-menu-item"
      style={{
        opacity: props.disabled ? 0.4 : 1,
        padding: '0.75rem 1rem',
      }}
    >
      {props.children}
    </AntdMenu.Item>
  )
}