import { colors } from "src/constants";
import { CoinWallet, CoinWalletStatus as Status } from "src/types";

export interface CoinWalletStatusProps {
  wallet: CoinWallet;
}

function CoinWalletStatus({ wallet }: CoinWalletStatusProps) {
  switch (wallet.status) {
    case Status.ACTIVE:
      return <div style={{ color: colors.yellow7 }}>ใช้งานได้</div>;
    case Status.CONVERTED:
      return <div style={{ color: colors.green7 }}>ทำเทิร์นสำเร็จ</div>;
    case Status.CANCELLED:
      return <div style={{ color: colors.red6 }}>ผิดเงื่อนไข</div>;
    case Status.REVOKED:
      return <div style={{ color: colors.red6 }}>โดนยกเลิก</div>;
    case Status.USED:
      return <div style={{ color: colors.gray9 }}>ทำเทิร์นไม่สำเร็จ</div>;
    case Status.EXPIRED:
      return <div style={{ color: colors.gray9 }}>หมดอายุ</div>;
    default:
      return null;
  }
}

export default CoinWalletStatus;
