import { PickerTimeProps } from "antd/es/date-picker/generatePicker"
import { Dayjs } from "dayjs"
import { forwardRef } from "react"
import { DatePicker } from "src/components"

export interface TimePickerProps extends Omit<PickerTimeProps<Dayjs>, "picker"> {}


/**
 * @deprecated remove soon
 */
const TimePicker = forwardRef<any, TimePickerProps>((props, ref) => {
  return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
})

TimePicker.displayName = "TimePicker"

TimePicker.defaultProps = {
  format: "HH:mm",
  style: { width: 100 },
}

export default TimePicker
