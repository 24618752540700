import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal, Form, Input, Row, Col, Typography } from "antd";
import { GetGamesHook, CreateQuest, QuestLevelProps, DailyQuestCreateForm } from "src/hooks";
import * as React from 'react';
import { Select, toast } from 'src/components';
import './QuestManagement.less'
import { QuestLevel, QuestType } from "src/types";

export type DailyQuestCreateProps = {
  onClose?: () => void;
  onSuccess?: () => void;
};

export const DailyQuestCreate = NiceModal.create(({...props}: DailyQuestCreateProps) => {
  const { visible, hide, remove } = useModal();

  const handleClose = () => {
    hide();
    props.onClose?.();
  };

  const [form] = Form.useForm<DailyQuestCreateForm>();
  const { mutate: create } = CreateQuest();
  const handleFinish = (values: DailyQuestCreateForm) => {
    const questLevel: QuestLevelProps[] = [];
    var show_toast = true;

    values.questFormLevel.map((value : QuestLevelProps, k)=>{
      if(value.number && value.ticket){
        if (k === 0) { 
          value.label = QuestLevel.EASY;
        }
        if (k === 1) { 
          value.label = QuestLevel.MEDIUM;
        }
        if (k === 2) { 
          value.label = QuestLevel.HARD;
        }

        if (values.questType === QuestType.ANY && (value.value === null || value.value === 0 || value.value === undefined)){
          toast.error("กรุณาระบุยอด turnover ใน " + value.label);
          show_toast = false;
        } else {
          questLevel.push(value);
        }
      }
    });

    if (questLevel.length > 0) {
      create(
        {
          questType: values.questType,
          gameRefType: values.questType === QuestType.ANY ? values.gameRefType.toUpperCase() : values.gameRefType,
          gameId: values.gameId,
          questLevel: questLevel
        },
        { onSuccess: handleClose }
      );
    } else {
      if (show_toast) {
        toast.error("กรุณาระบุจำนวนเควสและ ticket");
      }
    }
  };

  const { data: questGames, refetch: fetchQuestGames } = GetGamesHook()
  const [questType, setQuestType] = React.useState<string>('')
  const [gameRefType, setGameRefType] = React.useState<any[]>([])
  const [currentGameRefType, setCurrentGameRefType] = React.useState<string>('')
  const [games, setGames] = React.useState<any[]>([])
  const [currentGames, setCurrentGames] = React.useState<any[]>([])

  const fetchGames = async () => {
    const fetchGames = await fetchQuestGames()
    const keys = fetchGames ? Object.keys(fetchGames.data) : [];
    setGameRefType(keys)
    setGames(fetchGames.data)
  } 
  
  const mapGames = () => {
    form.setFieldsValue({ gameId: undefined });
    const a = Object.entries(games).find((item, i) => {
        return item[0] === currentGameRefType
    })
    if (a && a[1]){
      setCurrentGames(a[1])
    }
  }

  React.useEffect(() => {
    mapGames()
  }, [currentGameRefType]);

  React.useEffect(() => {
    fetchGames()
  }, []);

  return (
    <Modal
        centered
        className="ticket-import-modal-form"
        width={600}
        visible={visible}
        afterClose={remove}
        focusTriggerAfterClose={false}
        closable={false}
        title="สร้าง Quest"
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
            <Button key="cancel" size="large" onClick={handleClose} style={{ width: "100px" }}>
              ยกเลิก
            </Button>
            <Button
              key="submit"
              type="primary"
              size="large" 
              onClick={() => form.submit()}
              style={{ width: "160px" }}
            >
              ยืนยัน
            </Button>
          </div>
        }
      >
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={true}
            preserve={false}
            style={{ overflow: "auto" }}
            onFinish={handleFinish}
          >
            <Form.Item label="ประเภท" name="questType" required 
              rules={[{ required: true, message: 'โปรดเลือกประเภทของเควส' }]}>
              <Select {...props} onChange={(e: any) => {setQuestType(e);}}>
                  <Select.Option key="any" value="any">ทํา turnover</Select.Option>
                  <Select.Option key="login" value="login">Log In</Select.Option>
              </Select>
            </Form.Item>
            {questType === "any" && (
              <div>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item label="ค่ายเกมส์" name="gameRefType" required
                      rules={[{ required: true, message: 'โปรดเลือกค่ายเกมส์' }]}>
                      <Select {...props} onChange={(e: any) => {setCurrentGameRefType(e);}}>
                        {gameRefType.map(( key: string ) => (
                          <Select.Option key={key} value={key}>{key.toUpperCase()}</Select.Option>
                        ))} 
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="เกมส์" name="gameId" required
                      rules={[{ required: true, message: 'โปรดเลือกเกมส์' }]}>
                      <Select {...props}>
                        {currentGames.map(( key: any ) => (
                          <Select.Option key={key.gameId} value={key.gameId}>{key.gameName}</Select.Option>
                        ))} 
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Typography.Title level={5}>แบบง่าย</Typography.Title>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item label="จำนวน" name={['questFormLevel', 0, 'number']}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="ยอด turnover" name={['questFormLevel', 0, 'value']}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="รางวัล (Ticket)" name={['questFormLevel', 0, 'ticket']}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Typography.Title level={5}>แบบปานกลาง</Typography.Title>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item label="จำนวน" name={['questFormLevel', 1, 'number']}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="ยอด turnover" name={['questFormLevel', 1, 'value']}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="รางวัล (Ticket)" name={['questFormLevel', 1, 'ticket']}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Typography.Title level={5}>แบบยาก</Typography.Title>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                      <Form.Item label="จำนวน" name={['questFormLevel', 2, 'number']}>
                        <Input></Input>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="ยอด turnover" name={['questFormLevel', 2, 'value']}>
                        <Input></Input>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="รางวัล (Ticket)" name={['questFormLevel', 2, 'ticket']}>
                        <Input></Input>
                      </Form.Item>
                    </Col>
                </Row>
              </div>
            )}
            {questType === "login" && (
              <div>
                <Typography.Title level={5}>แบบง่าย</Typography.Title>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item label="จำนวน" name={['questFormLevel', 0, 'number']}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="รางวัล (Ticket)" name={['questFormLevel', 0, 'ticket']}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </Form>
      </Modal>
  );
});

export default DailyQuestCreate;
