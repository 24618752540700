import * as React from 'react';
import './Label.less';

interface ILabelProps {
}

const Label: React.FunctionComponent<ILabelProps> = (props) => {
  return (
    <label className="backoffice-label">{props.children}</label>
  );
};

export default Label;
