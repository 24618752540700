import generatePicker, { PickerProps } from "antd/es/date-picker/generatePicker"
import "antd/es/date-picker/style/index"
import { Dayjs } from "dayjs"
import dayjsGenerateConfig from "./dayjsConfig"
import './DatePicker.less';

export type DatePickerProps = PickerProps<Dayjs>

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

DatePicker.defaultProps = {
  size: "large",
  format: "DD/MM/YYYY",
  allowClear: false,
  style: { width: "150px" },
}

export default DatePicker
