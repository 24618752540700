import React from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BankAccount, BankRow } from 'src/types';
import { BankSelectItem } from '../../components/Bank/BankSelect';
import Button from '../../components/Button/Button';
import { authState, modalState } from '../../recoils';
import { PaymentValidate, PaymentValidateResponse, PaymentWithdrawResponse, PostRejectWithdraw, PostWithdraw, RejectWithdrawResponse } from '../../services/payment/payment';
import { formatDateRequest } from '../../utils/date';
import { Transaction } from './type';
import { useBank, UseBankState } from './useBank';

export enum VALIDATE_RESULT_ENUM {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  FAIL = 'FAIL',
  // BANK_MISSMATCH = 'BANK_MISSMATCH',
  // CANNOT_VALIDATE = 'CANNOT_VALIDATE',
  // BANK_SERVICE_IS_DOWN = 'BANK_SERVICE_IS_DOWN'
}

export type ValidateResult = {
  status: VALIDATE_RESULT_ENUM;
  message: string | JSX.Element;
  moreInfo?: string | JSX.Element;
};

export type SaveWithDrawTransactionForm = {
  transferAt: Date;
  selectedBankAccount: BankRow;
}

export type UseWithdrawState = UseBankState & {
  saveWithdrawTransaction: (_t: Transaction, form: SaveWithDrawTransactionForm, next?: () => void) => void;
  rejectWithdrawTransaction: (_t: Transaction, next?: () => void) => void;
  validateResult?: ValidateResult;
  isValidating: boolean;
  validateBank: (_t: Transaction) => void;
  transferAt?: Date;
  setTransferAt: (_d?: Date) => void;
}

export type InitialState = {
  // depositingTransaction: Transaction;
};

export const useWithdraw = (): UseWithdrawState => {
  const [, setModal] = useRecoilState(modalState);
  const bankState = useBank();
  const [validateResult, setValidateResult] = React.useState<ValidateResult>();

  const [transferAt, setTransferAt] = React.useState<Date>();

  const [isValidating, setIsValidating] = React.useState<boolean>(false);
  const { token = '' } = useRecoilValue(authState);

  const doCallWithdraw = async (
    _transaction: Transaction,
    _selectedBankAccount: BankSelectItem,
    next?: (_transaction: Transaction) => void,
  ) => {
    try {
      const response = await PostWithdraw({
        token,
        bank_code: _selectedBankAccount.bankCode,
        bank_account_number: _selectedBankAccount.accountNumber,
        bank_account_name: _selectedBankAccount.accountName,
        transaction_uid: _transaction.transactionId,
        transfered_at: formatDateRequest(new Date(transferAt || '')),
      });
      if (response.service_code === 'BOE-2000') {
        toast.success('บันทึกสำเร็จ');
      } else {
        toast.warning('บันทึกไม่สำเร็จ');
      }
      if (next) next(_transaction);
    } catch (e: any) {
      const response: PaymentWithdrawResponse = e.response.data;
      const isAlreadyWithdraw = response.service_code === 'BOE-4002';
      if (isAlreadyWithdraw) {
        setModal({
          isModalVisible: true,
          type: 'error',
          title: 'ไม่สามารถทำรายการได้',
          content: 'เนื่องจากรายการนี้ถูกดำเนินการแล้ว',
          buttonType: 'confirm',
          onConfirm: () => {
          },
        })
      } else {
        toast.warning('บันทึกไม่สำเร็จ');
      }
    }
  }

  const doCallRejectWithdraw = async (_transaction: Transaction, next?: (_transaction: Transaction) => void,) => {
    try {
      const response = await PostRejectWithdraw({
        token,
        transaction_uid: _transaction.transactionId,
      });
      if (response.service_code === 'BOE-2000') {
        toast.success('ปฏิเสธรายการสำเร็จ');
      } else {
        toast.warning('ปฏิเสธรายการไม่สำเร็จ');
      }
      if (next) next(_transaction);
    } catch (e: any) {
      const response: RejectWithdrawResponse = e.response.data;
      const isAlreadyWithdraw = response.service_code === 'BOE-4002';
      if (isAlreadyWithdraw) {
        setModal({
          isModalVisible: true,
          type: 'error',
          title: 'ไม่สามารถทำรายการได้',
          content: 'เนื่องจากรายการนี้ถูกดำเนินการแล้ว',
          buttonType: 'confirm',
          onConfirm: () => {
          },
        })
      } else {
        toast.warning('ปฏิเสธรายการไม่สำเร็จ');
      }
    }
  }

  const rejectWithdrawTransaction = (
    _transaction: Transaction,
    next?: (_transaction: Transaction) => void,) => {
    setModal({
      isModalVisible: true,
      type: 'warning',
      title: 'ปฏิเสธรายการ',
      content: 'ต้องการปฏิเสธรายการใช่หรือไม่',
      buttonType: 'question',
      onConfirm: () => {
        doCallRejectWithdraw(_transaction, next);
      },
    })
  };

  const saveWithdrawTransaction = (
    _transaction: Transaction,
    form: SaveWithDrawTransactionForm,
    next?: (_transaction: Transaction) => void,
  ) => {
    setModal({
      isModalVisible: true,
      type: 'warning',
      title: 'บันทึกการโอน',
      content: 'ต้องการบันทึกการโอนใช่หรือไม่',
      buttonType: 'question',
      onConfirm: () => {
        doCallWithdraw(_transaction, form.selectedBankAccount, next);
      },
    })
  };

  const validateBank = async (_transaction: Transaction) => {
    setIsValidating(true);
    try {
      const response = await PaymentValidate({
        token,
        bankAccountName: _transaction.customerBank.accountName,
        bankAccountNumber: _transaction.customerBank.accountNumber,
        bankCode: _transaction.customerBank.bankCode,
      });
      const isSuccess = response.data.is_valid;
      const isBankMissMatch = !response.data.is_valid;
      if (isBankMissMatch) {
        setValidateResult({
          status: VALIDATE_RESULT_ENUM.FAIL,
          message: 'บัญชีธนาคารไม่ตรงกับที่ลงทะเบียน',
          moreInfo: `ชื่อบัญชี: ${response.data.actual_bank_account_name}`,
        })
      } else if (isSuccess) {
        setValidateResult({
          status: VALIDATE_RESULT_ENUM.SUCCESS,
          message: 'บัญชีธนาคารตรงกับที่ลงทะเบียน',
        })
      }
    } catch (e: any) {
      if (e.message === 'timeout') {
        setValidateResult({
          status: VALIDATE_RESULT_ENUM.WARNING,
          message: (<>มีบางอย่างผิดพลาด <Button type="link" onClick={() => { validateBank(_transaction) }} >ลองอีกครั้ง</Button></>),
        })
      } else {
        const response: PaymentValidateResponse = e.response.data;
        const cannotValidate = response.service_code === 'BOE-5013';
        const doesBankServiceDown = response.service_code === 'BOE-5014';
        if (doesBankServiceDown) {
          setValidateResult({
            status: VALIDATE_RESULT_ENUM.WARNING,
            message: 'ระบบธนาคารล่ม/ปิดปรับปรุง',
          })
        } else if (cannotValidate) {
          setValidateResult({
            status: VALIDATE_RESULT_ENUM.WARNING,
            message: (<>ไม่สามารถตรวจสอบข้อมูลบัญชีกับธนาคารได้ <Button type="link" onClick={() => { validateBank(_transaction) }} >ลองอีกครั้ง</Button></>),
          })
        } else {
          setValidateResult({
            status: VALIDATE_RESULT_ENUM.WARNING,
            message: (<>มีบางอย่างผิดพลาด <Button type="link" onClick={() => { validateBank(_transaction) }} >ลองอีกครั้ง</Button></>),
          })
        }
      }
    }
    setIsValidating(false);
  };


  return {
    ...bankState,
    saveWithdrawTransaction,
    rejectWithdrawTransaction,
    isValidating,
    validateResult,
    validateBank,
    transferAt,
    setTransferAt,
  }
}