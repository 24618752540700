import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from "react-query";
import { toast } from "src/components"
import { QuestManagementAPI, Pagination, QuestType } from "src/types";
import { api } from "src/utils";
import { QuestLevel } from "src/types";
import camelcaseKeys from "camelcase-keys";

export type QuestLevelProps = {
  label: QuestLevel;
  number: number;
  value: number;
  ticket: number;
}

export interface QuestManagementFilters {
  questType: QuestType;
}

export interface DailyQuestCreateForm {
  questType: QuestType;
  gameRefType: string;
  gameId: string;
  questFormLevel: QuestLevelProps[];
}

export interface DailyQuestEditForm {
  questValue: number;
  questTicket: number;
}

export interface DailyQuestCreateRequest {
  questType: QuestType;
  gameRefType: string;
  gameId: string;
  questLevel: QuestLevelProps[];
}

export interface DailyQuestUpdateRequest {
  questUid: string;
  questType: QuestType;
  value: number;
  ticket: number;
}

export interface DailyQuestDeleteRequest {
  questUid: string;
}

export interface WeeklyQuestCreateForm {
  gameRefType: string;
  gameId: string;
  value: number;
  ticket: number;
}

export interface WeeklyQuestEditForm {
  questUid: string;
  gameRefType: string;
  gameId: string;
  value: number;
  ticket: number;
}

export interface WeeklyQuestEditTicketForm {
  ticket: number;
}

interface PaginationWithSummary extends Pagination<QuestManagementAPI> {
  summary?: {};
}

export function QuestManagementHook<T = PaginationWithSummary>(
  filters?: QuestManagementFilters,
  options?: UseQueryOptions<PaginationWithSummary, unknown, T, any[]>
) {
  return useQuery(
    ["questManangement", filters],
    async () => {
      if (filters?.questType) {
        const response = await api.get("loyalty-shop/backoffice/quest/list", {
          params: { questType: filters?.questType },
        });
        return response.data.data as {
          results: QuestManagementAPI[];
          total: number;
        };
      }
    },
    { keepPreviousData: true },
  );
}

const castCamelCaseToChildren = (object: object) => {
  return Object.entries(object).reduce((acc, cur) => ({
    ...acc,
    [cur[0]]: camelcaseKeys(cur[1] as any, { deep: true }),
  }), {}) as any;
};

export function GetGamesHook(
  enabled: boolean = true,
) {
  return useQuery(
    ["GetGames"],
    async () => {
      const response = await api.get("/loyalty-shop/backoffice/quest/games");
      return castCamelCaseToChildren(response.data.data);
    },
    {
      enabled,
    }
  )
}

export function CreateQuest(
  options?: UseMutationOptions<unknown, unknown, DailyQuestCreateRequest, unknown>
) {
  return useMutation((quest) => api.post("/loyalty-shop/backoffice/quest/create", quest), {
    onError: () => toast.error("เพิ่มเควสไม่สำเร็จ"),
    onSuccess: () => toast.success("เพิ่มเควสสำเร็จ"),
    ...options,
  });
}

export function UpdateQuest(
  options?: UseMutationOptions<unknown, unknown, DailyQuestUpdateRequest, unknown>
) {
  return useMutation((quest) => api.post("/loyalty-shop/backoffice/quest/update", quest), {
    onError: () => toast.error("แก้ไขเควสไม่สำเร็จ"),
    onSuccess: () => toast.success("แก้ไขเควสสำเร็จ"),
    ...options,
  });
}

export function DeleteQuest(
  options?: UseMutationOptions<unknown, unknown, DailyQuestDeleteRequest, unknown>
) {
  return useMutation((quest) => api.post("/loyalty-shop/backoffice/quest/delete", quest), {
    onError: () => toast.error("ลบเควสไม่สำเร็จ"),
    onSuccess: () => toast.success("ลบเควสสำเร็จ"),
    ...options,
  });
}

export function WeeklyQuestManagementHook() {
  return useQuery(
    ["weeklyQuestManangement"],
    async () => {
      const response = await api.get("loyalty-shop/backoffice/weekly/quest/list");
      return response.data.data as {
        ticket: number;
        results: QuestManagementAPI[];
        total: number;
      };
    },
    { keepPreviousData: true },
  );
}

export function CreateWeeklyQuest(
  options?: UseMutationOptions<unknown, unknown, WeeklyQuestCreateForm, unknown>
) {
  return useMutation((quest) => api.post("/loyalty-shop/backoffice/weekly/quest/create", quest), {
    onError: () => toast.error("เพิ่มเควสไม่สำเร็จ"),
    onSuccess: () => toast.success("เพิ่มเควสสำเร็จ"),
    ...options,
  });
}

export function UpdateWeeklyQuest(
  options?: UseMutationOptions<unknown, unknown, WeeklyQuestEditForm, unknown>
) {
  return useMutation((quest) => api.post("/loyalty-shop/backoffice/weekly/quest/create", quest), {
    onError: () => toast.error("แก้ไขเควสไม่สำเร็จ"),
    onSuccess: () => toast.success("แก้ไขเควสสำเร็จ"),
    ...options,
  });
}

export function DeleteWeeklyQuest(
  options?: UseMutationOptions<unknown, unknown, DailyQuestDeleteRequest, unknown>
) {
  return useMutation((quest) => api.post("/loyalty-shop/backoffice/weekly/quest/delete", quest), {
    onError: () => toast.error("ลบเควสไม่สำเร็จ"),
    onSuccess: () => toast.success("ลบเควสสำเร็จ"),
    ...options,
  });
}

export function UpdateWeeklyQuestTicket(
  options?: UseMutationOptions<unknown, unknown, WeeklyQuestEditTicketForm, unknown>
) {
  return useMutation((quest) => api.post("/loyalty-shop/backoffice/weekly/quest/update/ticket", quest), {
    onError: () => toast.error("แก้ไข ticket ไม่สำเร็จ"),
    onSuccess: () => toast.success("แก้ไข ticket สำเร็จ"),
    ...options,
  });
}
