import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Col, Modal, Row, Skeleton, Space } from "antd";
import CopyableMessageBox from "src/components/CopyableMessageBox/CopyableMessageBox";
import { colors } from "src/constants";
import { useTransaction } from "src/hooks";
import { isDepositTransaction, isWithdrawTransaction, PolymorphicTransaction } from "src/types";
import { datetime, decimal } from "src/utils";
import TransferTag from "../Affiliate/TransferTag";

export type TransactionViewMoreModalProps = {
  transactionUid: string;
};

const TransactionViewMoreModal = NiceModal.create(
  ({ transactionUid }: TransactionViewMoreModalProps) => {
    const { data } = useTransaction(transactionUid);
    const { visible, hide, remove } = useModal();

    const copyMessage = (transaction: PolymorphicTransaction) => {
      const amount = decimal(transaction.amount);
      if (isDepositTransaction(transaction)) {
        switch (transaction.status) {
          case "REJECTED":
            return `ขออภัยไม่สามารถเติมเงิน ${amount} บาท ได้ค่ะ\nเนื่องจากเลขที่บัญชีไม่ตรงกับที่คุณลงทะเบียน\nกรุณาแจ้งข้อมูลธนาคารเพื่อใช้โอนเงินกลับค่ะ`;
          case "COMPLETED":
            return `จำนวนเงิน ${amount} บาท ถูกเติมเรียบร้อยแล้วค่ะ`;
          case "WAITING_TRANSFER":
            return `เรากำลังดำเนินการคืนเงิน ${amount} บาท ให้กับลูกค้า\nกรุณารอทำรายการซักครู่ค่ะ`;
          case "TRANSFER_BACK":
            return `จำนวนเงิน ${amount} บาท โอนคืนลูกค้าเรียบร้อยแล้วค่ะ`;
        }
      } else if (isWithdrawTransaction(transaction)) {
        switch (transaction.status) {
          case "REJECTED":
            return `จำนวนเงิน ${amount} บาท ไม่สามารถดำเนินการถอนเงินได้\nเนื่องจากบัญชีไม่ตรงกับที่ท่านลงทะเบียนค่ะ`;
          case "COMPLETED":
            return `จำนวนเงิน ${amount} บาท ถูกถอนเรียบร้อยแล้วค่ะ`;
        }
      }

      return "กรุณารอทำรายการซักครู่ค่ะ";
    };

    return (
      <Modal
        focusTriggerAfterClose={false}
        visible={visible}
        afterClose={remove}
        title={(
          <Space>
            <div>ข้อมูลเพิ่มเติม</div>
            {data && isWithdrawTransaction(data as PolymorphicTransaction) && <TransferTag />}
          </Space>
        )}
        closable={false}
        footer={null}
        width={600}
        centered
      >
        {!data ? (
          <Skeleton active />
        ) : (
          <Space direction="vertical" size="large" style={{ padding: "0 20px", width: "100%" }}>
            {!!data.logs?.length && (
              <div>
                <Row>
                  <Col span={9}>
                    <div style={{ fontWeight: 700, color: colors.cloud6 }}>วันเวลาทำรายการ</div>
                  </Col>
                  <Col span={15}>
                    <div style={{ fontWeight: 700, color: colors.cloud6 }}>ทำรายการ</div>
                  </Col>
                </Row>
                {data.logs?.map((log) => (
                  <Row key={log.uid}>
                    <Col span={9}>{datetime(log.createdAt).format("DD/MM/YYYY HH:mm")}</Col>
                    <Col span={15}>{log.remark}</Col>
                  </Row>
                ))}
              </div>
            )}
            <CopyableMessageBox topic="ข้อความแจ้งลูกค้า" message={copyMessage(data)} />
          </Space>
        )}
        <Row justify="center" style={{ marginTop: "32px" }}>
          <Button type="primary" size="large" style={{ width: 150 }} onClick={hide}>
            เข้าใจแล้ว
          </Button>
        </Row>
      </Modal>
    );
  }
);

export default TransactionViewMoreModal;
