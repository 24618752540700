import { useQuery, useMutation } from "react-query";
import { CamelCaseBaseResponse } from "src/services/service.type";
import { toast } from "src/components";
import { BankCode } from "src/types";
import { api } from "src/utils";

export type ConfigItem = {
  id: string;
  name: string;
  value: number;
};

export type ConfigState = {
  configList: ConfigItem[];
  getConfigList: () => void;
  callUpdateConfig: (request: UpdateConfigRequest) => void;
};

export type UpdateConfigRequest = {
  bankCode: string;
  delayTime: number;
}

const updateConfig = async (request: UpdateConfigRequest) => {
  const { data: response } = await api.post<CamelCaseBaseResponse<{}>>('/payment/manage-bank/delay-time-transaction-deposit', request);
  return response.data;
};

export const useConfig = (): ConfigState => {
  const { data, isFetching, refetch: getConfigList } = useQuery(
    ["configList"],
    async () => {
      const response = await api.get<CamelCaseBaseResponse<{ bankCode: BankCode; delayTimeTransaction: number; }[]>>(`/payment/manage-bank/delay-time-transaction-deposit`);
      return response.data.data;
    },
    {
      select: (responseData) => {
        return responseData.sort((prev, current) => {
          if (prev.bankCode > current.bankCode) return 1;
          if (prev.bankCode < current.bankCode) return -1;
          return 0;
        }).map((item) => ({
          id: item.bankCode,
          name: item.bankCode,
          value: item.delayTimeTransaction,
        }));
      },
    },
  );

  const { mutate: callUpdateConfig, } = useMutation(updateConfig, {
    onSuccess: data => {
      toast.success('บันทึกค่าสำเร็จ');
      getConfigList();
    },
    onError: data => {
      toast.error('บันทึกค่าไม่สำเร็จ');
      getConfigList();
    },
  })
  return {
    configList: data || [],
    getConfigList,
    callUpdateConfig,
  }
};
