import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal, Form, Input, Steps, Row, Col, Typography } from "antd";
import { TicketManagementVerifyHook, TicketManagementImportHook, TicketManagementImportRequest } from "src/hooks";
import { useRecoilState, useRecoilValue } from 'recoil';
import { authState, modalState } from 'src/recoils';
import * as React from 'react';
import { toast } from "src/components";
import { MoneyInput, Table, ColumnType } from 'src/components';
import Label from "../Text/Label";
import './TicketManagementModal.less'
import colors from 'src/theme/colors.json';
import Icon from '../Icon/Icon';


export type TicketManagementImportModalProps = {
  importTicket: TicketManagementImportForm;
  onClose?: () => void;
  onSuccess?: () => void;
};

export interface TicketManagementImportForm {
  userCode: [];
  campaignCode: string;
  campaignName: string;
  tickets: number;
}

export interface TicketManagementUsersResponse {
  message: string;
  status: boolean;
  user_code: string;
}

export const TicketManagementImportModal = NiceModal.create(({ 
  importTicket, ...props
}: TicketManagementImportModalProps) => {
  const { visible, hide, remove } = useModal();

  const handleClose = () => {
    hide();
    props.onClose?.();
  };

  const [form] = Form.useForm<TicketManagementImportForm>();

  const { token = '' } = useRecoilValue(authState);
  const [canSubmit, setCanSubmit] = React.useState<boolean>(true)
  const [users, setUsers] = React.useState<any>(null)
  const [currentStep, setcurrentStep] = React.useState<number>(0)

  const verify = async () => {
    var pass = true;
    var str = form.getFieldValue('userCode');
    var userCode = [];
    if (str === undefined || str.length <= 0) {
      form.setFields([{
        name: 'userCode',
        errors: ['กรุณาใส่ PS_code']
      }]);
      pass = false;
    } else {
      userCode = str.split(",\n")
    }
    if(form.getFieldsValue().campaignCode === undefined){
      form.setFields([{
        name: 'campaignCode',
        errors: ['กรุณาใส่รหัสแคมเปญ']
      }]);
      pass = false;
    }
    if(form.getFieldsValue().campaignName === undefined){
      form.setFields([{
        name: 'campaignName',
        errors: ['กรุณาใส่ชื่อแคมเปญ']
      }]);
      pass = false;
    }
    if(form.getFieldsValue().tickets === undefined){
      form.setFields([{
        name: 'tickets',
        errors: ['กรุณาใส่จำนวน tickets']
      }]);
      pass = false;
    }

    if (pass) {
      const response = await TicketManagementVerifyHook({
          token,
          user_code: userCode,
          campaign_code: form.getFieldValue('campaignCode'),
          campaign_name: form.getFieldValue('campaignName'),
          tickets: form.getFieldValue('tickets'),
        });
        
        if (response.code === 200) {
          setUsers(response.data?.users)
          
          if (response.data?.status === 'ERROR') {
            if (response.data?.users) { 
              setCanSubmit(false)
              next()
            } 
            toast.error(response.data?.message);
          } else {
            next()
          }
        } else {
          toast.error('มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลอีกครั้ง');
        }
    }
  };
  
  const importTicketForm = async () => {

    var str = form.getFieldValue('userCode')
    var userCode = str.split(",\n")

    if (str === undefined || str.length <= 0) {
      toast.error('กรุณาใส่ PS_code');
    } else {
      const response = await TicketManagementImportHook({
        token,
        user_code: userCode,
        campaign_code: form.getFieldValue('campaignCode'),
        campaign_name: form.getFieldValue('campaignName'),
        tickets: form.getFieldValue('tickets'),
      });
      
      if (response.code === 200) {
        if (response.data?.status === 'ERROR') {
          toast.error(response.data?.message);
        } else {
          toast.success(response.data?.message);
        }
      } else {
        toast.error('มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลอีกครั้ง');
      }

      handleClose()
    }
  };

  // const userCodeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   var str = e.target.value
  //   setUserCode(str.split(",\n"));
  //   setDisplayUserCode(str)
  // };

  const columns: ColumnType<TicketManagementUsersResponse>[] = [
    {
      title: "",
      dataIndex: "status",
      align: "center",
      width: 20,
      render: (value) => {
        if (value) {
          return <Icon name='outline-active-1' color={colors['@green-6']} size="18px" />;
        } else {
          return <Icon name='outline-close-2' color={colors['@red-6']} size="18px" />;
        }
      },
    },
    {
      title: "PS_code",
      dataIndex: "user_code",
      width: 100,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "message",
      width: 150,
      render: (value) => {
        return <Typography.Text style={{ color: colors['@red-6'] }}>{value}</Typography.Text>
      }
    },
    
  ];

  const steps = [
    {
      title: "เพิ่ม Ticket ในระบบ",
      content: <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                requiredMark={true}
                preserve={true}

                style={{ overflow: "auto" }}
              >
                <Form.Item label="PS_code" name="userCode" required>
                  <Input.TextArea rows={5} placeholder="PS123450,&#13;&#10;PS123451,&#13;&#10;PS123452"/>
                </Form.Item>
                <Form.Item label="รหัสแคมเปญ" name="campaignCode" required>
                  <Input placeholder="ใส่รหัสแคมเปญ"/>
                </Form.Item>
                <Form.Item label="ชื่อแคมเปญ" name="campaignName" required>
                  <Input placeholder="ใส่ชื่อแคมเปญ"/>
                </Form.Item>
                <Form.Item label="จำนวน Ticket" name="tickets" required>
                  <MoneyInput placeholder="ใส่จำนวน Ticket"/>
                </Form.Item>
              </Form>
    },
    {
      title: "Preview : เพิ่ม Ticket ในระบบ",
      content: <>
                <Table
                  style={{ whiteSpace: 'pre' }}
                  rowKey="uid"
                  columns={columns}
                  dataSource={users}
                  loading={{
                    size: "large",
                    spinning: false,
                    style: { maxHeight: "calc(100vh - 310px)" },
                  }}
                  pagination={{ total: users?.length  }}
                />
                <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
                  <Row gutter={[20, 0]} style={{ marginBottom: 5, marginTop: 5 }}>
                    <Col>
                      <Label>รหัสแคมเปญ:</Label> <span className="item-label">{form.getFieldValue('campaignCode')}</span>
                    </Col>
                    <Col>
                      <Label>ชื่อแคมเปญ:</Label> <span className="item-label">{form.getFieldValue('campaignName')}</span>
                    </Col>
                    <Col>
                      <Label>จำนวน Ticket:</Label> <span className="item-label">{form.getFieldValue('tickets')}</span>
                    </Col>
                  </Row>
                </div>
              </>
    }
  ];

  const next = async () => {
    setcurrentStep(currentStep + 1);
  }

  const prev = async () => {
    setCanSubmit(true)
    setcurrentStep(currentStep - 1);
  }

  return (
    <Modal
        centered
        className="ticket-import-modal-form"
        width={600}
        visible={visible}
        afterClose={remove}
        focusTriggerAfterClose={false}
        closable={false}
        title={
          <div style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>
            {steps[currentStep].title}
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
              {currentStep == 0 && (
                <Button key="cancel" size="large" onClick={handleClose} style={{ width: "100px" }}>
                  ยกเลิก
                </Button>
              )}
              {currentStep == 0 && (
                <Button
                  key="submit"
                  type="primary"
                  size="large" 
                  onClick={() => verify()}
                  disabled={!canSubmit}
                  style={{ width: "160px" }}
                >
                  เพิ่ม Ticket
                </Button>
              )}
              {currentStep == 1 && (
                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                  ย้อนกลับ
                </Button>
              )}
              {currentStep == 1 && (
                <Button
                  key="submit"
                  type="primary"
                  size="large" 
                  onClick={() => importTicketForm()}
                  disabled={!canSubmit}
                  style={{ width: "160px" }}
                >
                  ยืนยัน
                </Button>
              )}
          </div>
        }
      >
        <div className="steps-content">{steps[currentStep].content}</div>
      </Modal>
  );
});

export default TicketManagementImportModal;
