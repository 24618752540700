import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { TransactionItem } from 'src/hooks/useTransaction/type';
import { BankCode, DepositTransactionStatus } from 'src/types';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseResponse2, BaseResponse, BaseRequest } from "../service.type";

export type DateTimeSortingType = 'created_by' | 'updated_by' | 'transferred_at'

export type DepositTransactionRequest = {
  token: string;
  limit: number;
  offset: number;
  datetime_order_by: 'ASC' | 'DESC';
  status_list?: string;
  only_me?: boolean;
  datetime_sorting_type: DateTimeSortingType;
  start: string;
  end: string;
};

export type WithdrawTransactionRequest = {
  token: string;
  limit: number;
  offset: number;
  datetime_order_by: 'ASC' | 'DESC';
  status_list?: string;
  only_me?: boolean;
  datetime_sorting_type: DateTimeSortingType;
};

export type ConfirmDepositTransactionRequest = {
  transaction_uid: string;
  user_code: string;
  customer_code: string;
  transaction_status: string;
  token: string;
}

export enum DEPOSIT_TRANSACTION_STATUS {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  TRANSFER_BACK = 'TRANSFER_BACK',
  COMPLETED = 'COMPLETED',
  WAITING_TRANSFER = 'WAITING_TRANSFER',
  CANCELLED = 'CANCELLED',
};

export enum WITHDRAW_TRANSACTION_STATUS {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
}

export type TransactionItemOld = {
  from_bank_account_name: string;
  customer_uid: string | null;
  customer_code: string | null;
  customer_phone_number: string | null;
  from_bank_account_number: string;
  app_name: string;
  updated_at: Date;
  to_bank_code: BankCode;
  is_sent: boolean;
  created_at: Date;
  to_bank_account_name: string;
  transferred_at: string;
  uid: string;
  to_bank_account_number: string;
  created_by_uid: string | null;
  status: DEPOSIT_TRANSACTION_STATUS | WITHDRAW_TRANSACTION_STATUS;
  updated_by_uid: string | null;
  amount: number;
  from_bank_code: BankCode;
  note: string | null;
  matching_state: "AUTO" | "MANUAL"
  type: "DEPOSIT" | "WITHDRAW" | "REFUND",
  logs?: TransactionLog[]
  transaction_ref_uid?: string,
  editing_by: {
    updated_at: Date;
    uid: string;
    updated_by_uid: string | null;
    name: string;
    status: string;
    created_at: Date;
    created_by_uid: string | null;
    email: string;
    personal_number: string;
    position_uid: string;
  } | null;
  refund_detail: {
    amount: number;
    created_at: Date;
    created_by_uid: string;
    from_bank_account_name: string;
    from_bank_account_number: string;
    from_bank_code: BankCode;
    is_sent: boolean;
    to_bank_account_name: string;
    to_bank_account_number: string;
    to_bank_code: BankCode;
    transaction_ref_uid: string;
    transferred_at: Date;
    uid: string;
    updated_at: Date;
    updated_by_uid: string;
  } | null;
};

export type DepositTransactionResult = {
  page: number;
  pageSize: number;
  total: number;
  results: TransactionItem[];
};

export type WithdrawTransactionResult = {
  page: number;
  pageSize: number;
  total: number;
  results: TransactionItem[];
};

export type DepositTransactionResponse = AxiosResponse<BaseResponse2<DepositTransactionResult>>;
export type WithdrawTransactionResponse = AxiosResponse<BaseResponse2<WithdrawTransactionResult>>;

export type TransactionLogRequest = {
  transaction_uid: string;
  token: string;
};

export type TransactionLog = {
  uid: string;
  transaction_uid: string;
  action_by_uid: string;
  updated_at: Date;
  created_at: Date;
  remark: string;
}

export type TransactionDepositResponse = BaseResponse & {
  data: {
    total: number;
    result: TransactionLog[];
  };
};

export const requestPostConfirmTransaction = async ({ transaction_uid, user_code, customer_code, transaction_status, token }: ConfirmDepositTransactionRequest): Promise<BaseResponse | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/payment/deposit/confirm-transactions',
      method: 'POST',
      data: { transaction_uid, user_code, customer_code, transaction_status },
      headers: { token }
    };

    const result = await fetchWithJSON<BaseResponse>(pigspinBackofficeApiInstance, requestConfig);
    return result;
  } catch (error: any) {
    return error.response.data;
  }
}

export type PutTransactionRequest = BaseRequest & {
  transaction_uid: string;
  transaction_status: DepositTransactionStatus;
}

export type PutTransactionResponse = BaseResponse2<{}>;

export const putDepositTransaction = async (request: PutTransactionRequest): Promise<PutTransactionResponse> => {
  const { token, transaction_uid, transaction_status } = request;
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/payment/deposit/transactions',
    method: 'PUT',
    data: { transaction_uid, transaction_status },
    headers: { token }
  };
  const result = await fetchWithJSON<PutTransactionResponse>(pigspinBackofficeApiInstance, requestConfig);
  return result;
};

export const requestDepositLog = async ({ transaction_uid, token }: TransactionLogRequest): Promise<TransactionDepositResponse | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/payment/deposit/transactions-history',
      method: 'GET',
      params: { transaction_uid },
      headers: { token }
    };

    const result = await fetchWithJSON<TransactionDepositResponse>(pigspinBackofficeApiInstance, requestConfig);
    return result;
  }
  catch (error) {
    return null;
  }
};

export const requestWithdrawLog = async ({ transaction_uid, token }: TransactionLogRequest): Promise<TransactionDepositResponse | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/payment/withdraw/transactions-history',
      method: 'GET',
      params: { transaction_uid },
      headers: { token }
    };

    const result = await fetchWithJSON<TransactionDepositResponse>(pigspinBackofficeApiInstance, requestConfig);

    return result;
  }
  catch (error) {
    return null;
  }
};

export type PostManualWithdrawRequest = BaseRequest & {
  amount: number;
  phone_number: string;
  bank_code: string;
  bank_account_number: string;
  bank_account_name: string;
  customer_uid: string;
  customer_code: string;
  transfer_type?: string;
};

export type PostManualWithdrawRespones = BaseResponse2<{}>;

export const PostManualWithdraw = async (request: PostManualWithdrawRequest): Promise<PostManualWithdrawRespones> => {
  const { token, ...rest } = request;
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/payment/withdraw/manual-request-transactions',
    method: 'POST',
    headers: { token },
    data: rest,
  };

  return await fetchWithJSON<PostManualWithdrawRespones>(pigspinBackofficeApiInstance, requestConfig);
}

export type PutManualWithdrawRequest = BaseRequest & {
  transaction_uid: string;
  amount: number;
  status: WITHDRAW_TRANSACTION_STATUS;
}
export type PutManualWithdrawResponse = BaseResponse2<{}>;

export const PutManualWithdraw = async (request: PutManualWithdrawRequest): Promise<PutManualWithdrawResponse> => {
  const { token, ...rest } = request;
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/payment/withdraw/manual-request-transactions',
    method: 'PUT',
    headers: { token },
    data: rest,
  };

  return await fetchWithJSON<PutManualWithdrawResponse>(pigspinBackofficeApiInstance, requestConfig);
}


export type GetTransactionDetailRequest = BaseRequest & {
  transaction_uid: string;
  user_uid: string;
}
export type TransactionDetailItem = {
  amount: number;
  cash_balance: number;
  coin_bonus: number;
  coin_balance: number;
  customer_code: string;
  from_bank_account_name: string;
  from_bank_account_number: string;
  from_bank_code: string;
  phone_number: string;
  to_bank_account_name: string;
  to_bank_account_number: string;
  to_bank_code: string;
  transferred_at: string;
}

export type GetTransactionDetailResponse = BaseResponse2<TransactionDetailItem>;

export const GetTransactionDetail = async (request: GetTransactionDetailRequest): Promise<GetTransactionDetailResponse> => {
  const { token, transaction_uid, user_uid } = request;
  var query = new URLSearchParams();
  query.append("transaction_uid", transaction_uid);
  query.append("customer_code", user_uid);
  const requestConfig: AxiosRequestConfig = {
    url: `/v1/payment/deposit/transaction-details?${query}`,
    method: 'GET',
    headers: { token },
  };

  return await fetchWithJSON<GetTransactionDetailResponse>(pigspinBackofficeApiInstance, requestConfig);
}
