import * as React from 'react';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import classnames from 'classnames';
import { Modal as AntdModal, ModalProps as AntdModalProps } from 'antd';
import './Modal.less';

export type ModalProps = AntdModalProps & {

}

const Modal: React.FunctionComponent<ModalProps> = (props) => {
  return (
    <AntdModal
      destroyOnClose
      closable={false}
      maskClosable={false}
      keyboard={false}
      {...props}
      className={classnames('backoffice-modal', props.className)}
    >
      {props.children}
    </AntdModal>
  );
};

export const CommonNiceModal = NiceModal.create((props: React.PropsWithChildren<ModalProps>) => {
  const modal = useModal();
  return (
    <Modal
      okButtonProps={{ size: 'large', style: { minWidth: 160 } }}
      cancelButtonProps={{ size: 'large', style: { minWidth: 100 } }}
      {...props}
      visible={modal.visible}
      onCancel={(e) => {
        if (props.onCancel) { props.onCancel(e); }
        modal.hide();
      }}
      onOk={(e) => {
        if (props.onOk) { props.onOk(e); }
      }}
    >
      {props.children}
    </Modal>
  );
});


export default Modal;
