import { BankAccount } from "src/components";
import { Transaction } from "src/hooks/useTransaction/type";
export interface CustomerBankAccountProps {
  transaction: Transaction;
}

export function CustomerBankAccount({ transaction }: CustomerBankAccountProps) {
  return (
    <BankAccount
      bankCode={transaction.customerBank.bankCode}
      accountName={transaction.customerBank.accountName}
      accountNumber={transaction.customerBank.accountNumber}
    />
  );
}

export default CustomerBankAccount;
