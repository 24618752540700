import { Form, Spin, Typography } from 'antd';
import * as React from 'react';
import { UserInfo } from 'src/components';
import Modal from 'src/components/Modal/Modal';
import { toast } from "src/components";
import Button from 'src/components/Button/Button';
import InputNumber from 'src/components/InputNumber';
import { useUserInfo } from 'src/hooks/useTransaction/useUserInfo';
import UserInfoNotFound from 'src/components/UserInfo/UserInfoNotFound';
import { numberWithoutCommas } from 'src/utils/number/number';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authState, modalState } from 'src/recoils';
import { PutManualWithdraw, PutManualWithdrawResponse, WITHDRAW_TRANSACTION_STATUS } from 'src/services/transactions/transactions';
import { Transaction } from 'src/hooks/useTransaction/type';
import { ValidateInput } from 'src/hooks/type';

interface IManualWithdrawProps {
  editingTransaction?: Transaction;
  onSuccess: () => void;
  onCancel: () => void;
}

const ManualWithdrawCreate: React.FunctionComponent<IManualWithdrawProps> = (props) => {
  const { onSuccess, editingTransaction } = props;
  const [, setModal] = useRecoilState(modalState);
  const { token = '' } = useRecoilValue(authState);
  const [withdrawAmount, setWithdrawAmount] = React.useState<string>('')
  const [validateInput, setValidateInput] = React.useState<ValidateInput>();
  const [isValidForm, setIsValidForm] = React.useState(true);
  const { userInfo, doSearchingUserInfo, isLoading, setUserInfo } = useUserInfo();

  const editManualWithdraw = async () => {
    if (!editingTransaction) return;
    try {
      const response = await PutManualWithdraw({
        token,
        amount: numberWithoutCommas(withdrawAmount),
        transaction_uid: editingTransaction.transactionId,
        status: editingTransaction.raw_status as WITHDRAW_TRANSACTION_STATUS,
      });
      if (response.service_code === 'BOE-2000') {
        toast.success('แก้ไขรายการถอนเงินสำเร็จ');
        onSuccess();
      } else {
        toast.error('แก้ไขรายการถอนเงินไม่สำเร็จ');
      }
    } catch (e: any) {
      const response: PutManualWithdrawResponse = e.response.data;
      if (response.service_code === 'BOE-4045') {
        toast.error('เงินในบัญชีของลูกค้าไม่เพียงพอ')
        setValidateInput({ validateStatus: 'error', help: 'เงินในบัญชีของลูกค้าไม่เพียงพอ', })
      } else {
        toast.error('เพิ่มรายการถอนเงินไม่สำเร็จ')
      }
    }
  };

  const renderManualAddFooter = () => {
    return (
      <>
        <Button
          className="button cancel-button"
          style={{ width: 100 }}
          size="large"
          onClick={props.onCancel}
        >
          ยกเลิก
        </Button>
        <Button
          style={{ width: 176 }}
          type="primary"
          size="large"
          disabled={!isValidForm}
          onClick={() => {
            setModal({
              isModalVisible: true,
              type: 'warning',
              title: 'แก้ไขรายการถอนเงิน',
              content: 'ต้องการแก้ไขรายการถอนเงินใช่หรือไม่',
              buttonType: 'question',
              onConfirm: () => {
                editManualWithdraw();
              },
            });
          }}
        >
          แก้ไขรายการถอนเงิน
        </Button>
      </>
    )
  };

  React.useEffect(() => {
    if (editingTransaction) {
      doSearchingUserInfo(editingTransaction.userAccount);
      setWithdrawAmount(`${editingTransaction.amount}`);
    } else {
      setUserInfo({ isComplete: false })
      setValidateInput(undefined);
      setWithdrawAmount('')
    }
  }, [editingTransaction]);

  React.useEffect(() => {
    if (!withdrawAmount) {
      setIsValidForm(false)
      return;
    }
    const amount = numberWithoutCommas(withdrawAmount)
    setIsValidForm(amount > 0 && userInfo.currentBalance && amount <= userInfo.currentBalance ? true : false)
  }, [withdrawAmount])

  return (
    <Modal
      visible={props.editingTransaction !== undefined}
      centered
      width={968}
      title="แก้ไขรายการถอนเงิน"
      footer={renderManualAddFooter()}
      onCancel={props.onCancel}
    >
      <Spin spinning={isLoading}>
        <div style={{
          padding: '12px 83px',
          minHeight: 450,
        }}>
          <Typography.Title level={5}>ค้นหาข้อมูลลูกค้า</Typography.Title>
          {userInfo?.isFound && (
            <>
              <UserInfo
                userCode={userInfo.userAccount}
                phone={userInfo.phoneNumber}
                bankCode={userInfo.bankAccount?.bankCode}
                bankAccountName={userInfo.bankAccount?.accountName}
                bankAccountNumber={userInfo.bankAccount?.accountNumber}
                lastTransactionAt={userInfo.lastWithdrawDatetime}
                lastTransactionAmount={userInfo.lastWithdrawAmount}
                transactionType="WITHDRAW"
                balance={userInfo.currentBalance}
              />
              <br />
              <Typography.Title level={5}>จำนวนเงิน (บาท)</Typography.Title>
              <Form>
                <Form.Item
                  validateStatus={validateInput?.validateStatus}
                  help={validateInput?.help}
                >
                  <InputNumber
                    size="large"
                    style={{ width: '100%' }}
                    value={withdrawAmount}
                    placeholder="ใส่จำนวนเงิน"
                    onChange={(value) => { setWithdrawAmount(value) }}
                  />
                </Form.Item>
              </Form>
            </>
          )}
          {userInfo?.isComplete && !userInfo?.isFound && <UserInfoNotFound />}
        </div>
      </Spin>
    </Modal>
  );
};

export default ManualWithdrawCreate;
