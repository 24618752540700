import { Select as AntSelect, SelectProps as AntSelectProps } from "antd"
import { SelectValue } from "antd/es/select"
import { OutlineArrowDown1 } from "src/icons"

export interface SelectProps<T extends SelectValue> extends Omit<AntSelectProps<T>, "mode"> {
  width?: string
  dropdownWidth?: string
}

function Select<T extends SelectValue>({
  width,
  style,
  dropdownWidth,
  dropdownStyle,
  children,
  ...props
}: SelectProps<T>) {
  return (
    <AntSelect
      style={{ ...style, width: width }}
      dropdownStyle={{ ...dropdownStyle, minWidth: dropdownWidth }}
      suffixIcon={<OutlineArrowDown1 style={{ fontSize: "14px", pointerEvents: "none" }} />}
      {...props}
    >
      {children}
    </AntSelect>
  )
}

Select.Option = AntSelect.Option

Select.defaultProps = {
  size: "large",
  width: "220px",
}

export default Select
